import React, { useState } from "react";

import ReactPaginate from "react-paginate";
import styles from "./pagnationComp.module.scss";

export const PaginNew = ({
  post,
  total,
  handlePageClick,
  postPerPage,
  setPostPerPage,
  page
}) => {

  return (
    <div>
      <div className={`${styles.paginationDiv}`}>
        <div className={`${styles.paginationNumber}`}>
          <ReactPaginate
            breakLabel={"..."}
            pageCount={total>0 ? Math.ceil(total  / postPerPage) : 1}
            nextLabel={">"}
            previousLabel={"<"}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={handlePageClick}
            containerClassName={`${styles.pagination}`}
            pageClassName={`page-item`}
            pageLinkClassName={`${styles.pageNum}`}
            previousClassName={"page-item"}
            previousLinkClassName={`${styles.pageNum}`}
            nextClassName={"page-item"}
            nextLinkClassName={`${styles.pageNum}`}
            breakClassName={"page-item"}
            breakLinkClassName={`${styles.pageNum}`}
            activeClassName={`${styles.activeColor}`}
            activeLinkClassName={`${styles.activeColor}`}
            disabledLinkClassName={`${styles.disableButtons}`}
            forcePage={page>0? page-1 : 0}
          />
        </div>
        <div className={`${styles.selectDiv}`}>
          <select
            className={`${styles.selectTag}`}
            aria-label="Default select example"
            onChange={(e) => setPostPerPage(e.target.value)}
          >            
            <option value="9">9/page</option>
            {total>9 ? <option value="15">15/page</option>:''}
            {total>15 ? <option value="21">21/page</option>:''}
            {total>21 ? <option value="30">30/page</option>:''}
          </select>
        </div>
      </div>
    </div>
  );
};
