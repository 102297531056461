import TaxiIcon from "../../assets/images/Taxi-sanchalak-icon-image-2.png";
import PageNotFound from "../../assets/images/Page-not-found.jpg";
import styles from "./Error404.module.scss";
import { Link } from "react-router-dom";

export const Error404 = () => {
    
    const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");


    return (
        <div className={styles.PageNotFoundMainDiv}>
            <div className={styles.taxiIconDiv}>
                <img src={TaxiIcon} className={styles.taxiIcon} />
            </div>
            <div className={styles.PageNotFoundDiv}>
                <img src={PageNotFound} className={styles.PageNotFound} />
            </div>
            <div className={styles.dashboardButtonDiv}>
                <button className={styles.dashboardButton}>
                   { token ? <Link to="/dashboard/mybooking" style={{ color:'white' }}> Go To Dashboard </Link> :
                    <Link to="/login" style={{ color:'white' }} > Go To Dashboard </Link>
                   }
                </button>
            </div>
        </div>
    )
}