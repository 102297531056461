import React, { useState, useEffect } from "react";
import { FiEdit, FiUpload } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./driver.module.scss";
import { PaginNew } from "../CommonComp/PaginNew";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { BsPerson, BsXCircle } from "react-icons/bs";
import {
  API_ADD_DRIVER,
  API_DELETE_DRIVER,
  API_GET_MY_DRIVER,
  API_UPDATE_DRIVER,
  EMAIL_REGEX,
  IS_NUMBER_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
} from "../../../utils/consts/api";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";
import driver_placeholder from "../../../assets/images/driver_placeholder.jpg";
import { AiOutlinePlus } from "react-icons/ai";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DeleteuserComponent from "./DeleteVehicleModal/Delete-vehicle-modal";
import Spinner from "../CommonComp/Spinner/Spinner";
import { ImageCompressorHook } from "../CommonComp/ImageCompressor/ImageCompressor";

export const Drivers = () => {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [postPerPage, setPostPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const {imageCompressorFn,data} = ImageCompressorHook();

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const initialValues = {
    driverFullName: "",
    driverContactNumber: "",
    email: "",
    driverImageKey: "driverImage",
    licenseImageKeyFront: "lisenceFrontImage",
    licenseImageKeyBack: "lisenceBackImage",
  };

  const updateIntialValues = {
    driverId: "",
    driverFullName: "",
    driverContactNumber: "",
    email: "",
    driverImageToShow: "",
    lisenceFrontImageToShow: "",
    lisenceBackImageToShow: "",
    driverImageKey: "driverImage",
    licenseImageKeyFront: "lisenceFrontImage",
    licenseImageKeyBack: "lisenceBackImage",
  };

  const indexOfLastPage = page * postPerPage;
  const indexOfFirstpage = indexOfLastPage - postPerPage;
  const currentPost = items.slice(indexOfFirstpage, indexOfLastPage);
  const [formValues, setFormValues] = useState(initialValues);
  const [deletePlanID, setDeletePlanID] = useState("");
  const [updateFormValues, setUpdateFormValues] = useState(updateIntialValues);
  const [show, setShow] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [isUpdateValid, setIsUpdateValid] = useState(true);
  const [updateErrorMsg, setUpdateErrorMsg] = useState("");
  const [isUpdatePhoneValid, setIsUpdatePhoneValid] = useState(true);
  const [phoneUpdateErrorMsg, setUpdatePhoneErrorMsg] = useState("");

  const handleClose = () => {
    setShow(false);
    setIsValid(true);
    setAddImages(addImageIntialValues);
    setFormValues(initialValues);
    setIsPhoneValid(true);
  };
  const handleShow = () => setShow(true);

  const handleUpdateModelshow = (obj) => {
    setUpdateModel(true);
    setUpdateFormValues(obj);
  };

  const handleUpdateModelsClose = () => {
    setUpdateModel(false);
    setUpdateFormValues({});
    setUpdateImages(updateImageIntialValues);
    setIsUpdatePhoneValid(true);
    setIsUpdateValid(true);
    setUpdateImageState(updateImageStateIntialValues);
  };

  const addImageIntialValues = {
    driverImage: { preview: "", raw: "" },
    lisenceFrontImage: { preview: "", raw: "" },
    lisenceBackImage: { preview: "", raw: "" },
  };

  const [addImages, setAddImages] = useState(addImageIntialValues);

  const updateImageIntialValues = {
    driverImage: { preview: "", raw: "" },
    lisenceFrontImage: { preview: "", raw: "" },
    lisenceBackImage: { preview: "", raw: "" },
  };

  const [updateImages, setUpdateImages] = useState(updateImageIntialValues);

  const updateImageStateIntialValues = {
    lisenceFrontImageState: false,
    lisenceBackImageState: false,
  };

  const [updateImageState, setUpdateImageState] = useState(
    updateImageStateIntialValues
  );

  // ---------  get data here
  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const getData = async () => {
    setLoading(true);
    try {
      const res = await fetch(API_GET_MY_DRIVER, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      const data = await res.json();
      setItems(data.response.driverList);
      setTotal(data.response.driverList.length);
      setLoading(false);
    } catch (error) {
      errorToastMsg(error.message);
      setLoading(false);
    }
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDeleteaccept = (id) => {
    if (!deletePlanID) {
      errorToastMsg("Please try again");
      return;
    }
    setLoading(true);
    setModalShow(false);
    axios
      .post(
        API_DELETE_DRIVER,
        { driverId: deletePlanID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) errorToastMsg(res.data.errorMsg);
        else successToastMsg(res.data.successMsg);
        getData();
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
  };

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "driverContactNumber") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 10) {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      } else {
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "driverFullName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      } else {
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  const handleAdd = (e) => {
    e.preventDefault();
    formValues.email = formValues.email.toLowerCase();
    const formData = new FormData();
    formData.append("data", JSON.stringify(formValues));

    if (!addImages.driverImage.raw) {
      errorToastMsg("Please Upload the Profile-Picture");
      return;
    } else {
      formData.append(
        "driverImage",
        addImages.driverImage.raw,
        addImages.driverImage.raw.name
      );
    }

    if (!addImages.lisenceFrontImage.raw) {
      errorToastMsg("Please upload the Lisence front image");
      return;
    } else {
      formData.append(
        "lisenceFrontImage",
        addImages.lisenceFrontImage.raw,
        addImages.lisenceFrontImage.raw.name
      );
    }

    if (!addImages.lisenceBackImage.raw) {
      errorToastMsg("Please upload the Lisence back image");
      return;
    } else {
      formData.append(
        "lisenceBackImage",
        addImages.lisenceBackImage.raw,
        addImages.lisenceBackImage.raw.name
      );
    }
    setLoading(true);
    axios
      .post(API_ADD_DRIVER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
        }
        getData();
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
    handleClose(false);
    setAddImages({
      driverImage: { preview: "", raw: "" },
      lisenceFrontImage: { preview: "", raw: "" },
      lisenceBackImage: { preview: "", raw: "" },
    });
  };

  const handleUpdateInput = (e) => {
    const { name, value } = e.target;
    if (name === "driverContactNumber") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 10) {
        setUpdateFormValues({
          ...updateFormValues,
          [e.target.name]: e.target.value,
        });
      } else {
        setUpdateFormValues({
          ...updateFormValues,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "driverFullName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setUpdateFormValues({
          ...updateFormValues,
          [e.target.name]: e.target.value,
        });
      } else {
        setUpdateFormValues({
          ...updateFormValues,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else {
      setUpdateFormValues({
        ...updateFormValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    updateFormValues.email = updateFormValues.email.toLowerCase();
    const formData = new FormData();

    formData.append("data", JSON.stringify(updateFormValues));
    if (updateImages.driverImage.raw) {
      formData.append(
        "driverImage",
        updateImages.driverImage.raw,
        updateImages.driverImage.raw.name
      );
    }

    if (updateImages.lisenceFrontImage.raw) {
      formData.append(
        "lisenceFrontImage",
        updateImages.lisenceFrontImage.raw,
        updateImages.lisenceFrontImage.raw.name
      );
    } else if (updateImageState.lisenceFrontImageState) {
      errorToastMsg("Please upload the lisence front image");
      return;
    }

    if (updateImages.lisenceBackImage.raw) {
      formData.append(
        "lisenceBackImage",
        updateImages.lisenceBackImage.raw,
        updateImages.lisenceBackImage.raw.name
      );
    } else if (updateImageState.lisenceBackImageState) {
      errorToastMsg("Please upload the lisence back image");
      return;
    }

    setLoading(true);
    axios
      .post(API_UPDATE_DRIVER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
        }
        handleUpdateModelsClose();
        getData();
      })
      .catch((error) => {
        errorToastMsg(error.message);
        handleUpdateModelsClose();
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [postPerPage]);

  const handleImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      imageCompressorFn(image,e.target.name,'Add');
      setAddImages({
        ...addImages,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const handleUpdateImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      imageCompressorFn(image,e.target.name,'Update');
      setUpdateImages({
        ...updateImages,
        [e.target.name]: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
          value: e.target.value,
        },
      });
    }
  };

  const handleDeleteclose = () => {
    setModalShow(false);
  };

  const handleDeleteOpen = (id) => {
    setDeletePlanID(id);
    setModalShow(true);
  };

  const validateEmail = () => {
    const { email } = formValues;
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsValid(false);
        setErrorMsg("Invalid Email Address.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    const { driverContactNumber } = formValues;
    if (driverContactNumber) {
      if (!PHONE_REGEX.test(driverContactNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const updateValidateEmail = () => {
    const { email } = updateFormValues;
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsUpdateValid(false);
        setUpdateErrorMsg("Invalid Email Address.");
      } else {
        setIsUpdateValid(true);
        setUpdateErrorMsg("");
      }
    } else {
      setIsUpdateValid(true);
      setUpdateErrorMsg("");
    }
  };

  const validateUpdatePhoneNumber = () => {
    const { driverContactNumber } = updateFormValues;
    if (driverContactNumber) {
      if (!PHONE_REGEX.test(driverContactNumber)) {
        setIsUpdatePhoneValid(false);
        setUpdatePhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsUpdatePhoneValid(true);
        setUpdatePhoneErrorMsg("");
      }
    } else {
      setIsUpdatePhoneValid(true);
      setUpdatePhoneErrorMsg("");
    }
  };

  const handleAddDriverCrosss = (e) => {
    e.preventDefault();
    setAddImages({
      ...addImages,
      driverImage: { raw: "", preview: "", value: "" },
    });
  };

  const handleAddLisenceFrontCrosss = (e) => {
    e.preventDefault();
    setAddImages({
      ...addImages,
      lisenceFrontImage: { raw: "", preview: "", value: "" },
    });
  };

  const handleAddLisenceBackCrosss = (e) => {
    e.preventDefault();
    setAddImages({
      ...addImages,
      lisenceBackImage: { raw: "", preview: "", value: "" },
    });
  };

  const handleUpdateDriverCrosss = (e) => {
    e.preventDefault();
    setUpdateImages({
      ...updateImages,
      driverImage: { raw: "", preview: "", value: "" },
    });
  };

  const handleUpdateLisenceFrontCrosss = (e) => {
    e.preventDefault();
    setUpdateImages({
      ...updateImages,
      lisenceFrontImage: { raw: "", preview: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, lisenceFrontImageState: true });
  };

  const handleUpdateLisenceBackCrosss = (e) => {
    e.preventDefault();
    setUpdateImages({
      ...updateImages,
      lisenceBackImage: { raw: "", preview: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, lisenceBackImageState: true });
  };

  useEffect(()=>{
    if(data.type === 'Add'){
      if(data.image){
        if(data.imageName){
          setAddImages({
            ...addImages,
            [data.imageName]:{
              preview: URL.createObjectURL(data.image),
              raw: data.image
            }
          });
        }else{
          setAddImages({
            ...addImages,
              preview: URL.createObjectURL(data.image),
              raw: data.image
          });
        }
      }
    }if(data.type === 'Update'){
      if(data.image){
        if(data.imageName){
          setUpdateImages({
            ...updateImages,
            [data.imageName]:{
              preview: URL.createObjectURL(data.image),
              raw: data.image
            }
          });
        }else{
          setUpdateImages({
            ...updateImages,
              preview: URL.createObjectURL(data.image),
              raw: data.image
          });
        }
      }
    }
  },[data]);

  return (
    <div className="d-flex">
      <Sidebar />

      <div className={`bg-secondary bg-opacity-10 ${styles.mainContainer} `}>
        <div className="d-flex justify-content-between align-items-center my-3 mx-2 ">
          <div className={styles.pageTitle}>
            <h4>My Drivers</h4>
          </div>
          {/* Modal Starts here */}
          <div className={`${styles.addDriverBtnDiv}`}>
            <Button
              variant="warning"
              onClick={handleShow}
              className={`${styles.addDriverBtn}`}
            >
              + Add Driver
            </Button>
          </div>
        </div>
        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          scrollable
        >
          <div className={styles.modalDiv}>
            <h5 className={`${styles.modalTitle}`}>Add New Driver</h5>
            <div className={styles.modalCross} onClick={handleClose}>
              x
            </div>
          </div>
          <hr className="m-0" />
          <Modal.Body>
            <form onSubmit={handleAdd} autocomplete="off">
              <div className="d-flex align-items-center gap-4">
                {addImages.driverImage.preview ? (
                  <div>
                    <div
                      className={`cross ${styles.crossSign}`}
                      onClick={handleAddDriverCrosss}
                    >
                      <BsXCircle
                        color="#D7E0EE"
                        style={{
                          backgroundColor: "#272B41",
                          borderRadius: "50%",
                          position: "absolute",
                          top: 2,
                          right: 1,
                        }}
                      />
                    </div>
                    <img
                      src={addImages.driverImage.preview}
                      alt="userImage"
                      className={styles.profileIconImage}
                    />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <BsPerson className={styles.icons} />
                  </div>
                )}
                <div className={styles.driverImageNameDiv}>
                  <label htmlFor="file-input" className={styles.uploadImage}>
                    {addImages.driverImage.preview
                      ? addImages.driverImage.raw.name
                      : "Upload Picture "}
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    className="d-none"
                    name="driverImage"
                    value={addImages.driverImage.value}
                    onChange={handleImages}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label mt-3 fw-bold ${styles.labelFont}`}
                >
                  Name
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter name"
                  required
                  name="driverFullName"
                  onChange={handleInput}
                  value={formValues.driverFullName}
                />
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Mobile Number"
                  required
                  onBlur={validatePhoneNumber}
                  name="driverContactNumber"
                  onChange={handleInput}
                  value={formValues.driverContactNumber}
                />
              </div>
              {!isPhoneValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "3px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {phoneErrorMsg}
                </div>
              )}
              <div className="mb-2">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Email
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Email"
                  required
                  onChange={handleInput}
                  onBlur={validateEmail}
                  name="email"
                  value={formValues.customerEmail}
                />
              </div>
              {!isValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "7px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {errorMsg}
                </div>
              )}
              <div className={`form-label mt-3 fw-bold ${styles.labelFont}`}>
                Upload Lisence Images
                <div className="d-flex gap-4">
                  <div className={styles.uploadLisence}>
                    {" "}
                    <label
                      for="file-input-lisence-front"
                      className="d-flex align-items-center "
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {addImages.lisenceFrontImage.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleAddLisenceFrontCrosss}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={addImages.lisenceFrontImage.preview}
                                alt="lisenceFrontImage"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          )}
                          {!addImages.lisenceFrontImage.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload Lisence Front
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-lisence-front"
                          className="d-none"
                          value={addImages.lisenceFrontImage.value}
                          accept="image/*"
                          name="lisenceFrontImage"
                          onChange={handleImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadLisence}>
                    {" "}
                    <label
                      for="file-input-lisence-back"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {addImages.lisenceBackImage.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleAddLisenceBackCrosss}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={addImages.lisenceBackImage.preview}
                                alt="lisenceBackImage"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          )}
                          {!addImages.lisenceBackImage.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload Lisence Back
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-lisence-back"
                          className="d-none"
                          value={addImages.lisenceBackImage.value}
                          name="lisenceBackImage"
                          accept="image/*"
                          onChange={handleImages}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-start mb-3 mt-4 gap-4 ${styles.buttonBox}`}
              >
                <Button
                  type="submit"
                  className={styles.addDriver}
                  disabled={!isPhoneValid || !isValid}
                >
                  Add Driver
                </Button>
                <button onClick={handleClose} className={styles.closeBtn}>
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* ------------------ Update Modal hare -------------------- */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateModel}
          onHide={handleUpdateModelsClose}
          scrollable
        >
          <div className={styles.modalDiv}>
            <h5 className={`${styles.modalTitle}`}>Update Driver</h5>
            <div
              className={styles.modalCross}
              onClick={handleUpdateModelsClose}
            >
              x
            </div>
          </div>
          <hr className="m-0" />
          <Modal.Body>
            <form onSubmit={handleUpdate} autocomplete="off">
              <div className="d-flex align-items-center gap-4">
                <div>
                  {updateImages.driverImage.preview ? (
                    <div className={styles.imageBox}>
                      <div
                        className={`cross ${styles.crossSign}`}
                        onClick={handleUpdateDriverCrosss}
                      >
                        <BsXCircle
                          color="#D7E0EE"
                          style={{
                            backgroundColor: "#272B41",
                            borderRadius: "50%",
                            position: "absolute",
                            top: 2,
                            right: 1,
                          }}
                        />
                      </div>
                      <img
                        src={updateImages.driverImage.preview}
                        alt="userImage"
                        className={styles.profileIconImage}
                      />
                    </div>
                  ) : updateFormValues.driverImageToShow ? (
                    <img
                      src={updateFormValues.driverImageToShow}
                      alt="userImage"
                      className={styles.profileIconImage}
                    />
                  ) : (
                    <div>
                      {" "}
                      <BsPerson className={styles.icons} />
                    </div>
                  )}
                </div>
                <div className={styles.driverImageNameDiv}>
                  <label htmlFor="file-input" className={styles.uploadImage}>
                    {updateImages.driverImage.raw
                      ? updateImages.driverImage.raw.name
                      : "Update Picture (Optional)"}
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    className="d-none"
                    value={updateImages.driverImage.value}
                    name="driverImage"
                    onChange={handleUpdateImages}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Name
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter customer name"
                  name="driverFullName"
                  onChange={(e) => handleUpdateInput(e)}
                  value={updateFormValues.driverFullName}
                  required
                />
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  c
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Mobile Number
                </label>
                <input
                  type="number"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Customer Mobile Number"
                  name="driverContactNumber"
                  onBlur={validateUpdatePhoneNumber}
                  onChange={(e) => handleUpdateInput(e)}
                  value={updateFormValues.driverContactNumber}
                  required
                />
              </div>
              {!isUpdatePhoneValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "3px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {phoneUpdateErrorMsg}
                </div>
              )}
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Email
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  name="email"
                  placeholder="Enter Customer Email"
                  value={updateFormValues.customerEmail}
                  defaultValue={updateFormValues.email}
                  onBlur={updateValidateEmail}
                  onChange={(e) => handleUpdateInput(e)}
                  required
                />
              </div>
              {!isUpdateValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "3px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {updateErrorMsg}
                </div>
              )}
              <div className={`form-label fw-bold ${styles.labelFont}`}>
                Upload Lisence Images
                <div className="d-flex justify-content-between">
                  <div className={styles.uploadLisence}>
                    {" "}
                    <label
                      for="file-input-lisence-front"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {updateImages.lisenceFrontImage.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateLisenceFrontCrosss}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={updateImages.lisenceFrontImage.preview}
                                alt="lisenceImageFront"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          ) : updateFormValues.lisenceFrontImageToShow &&
                            !updateImageState.lisenceFrontImageState ? (
                            <img
                              src={updateFormValues.lisenceFrontImageToShow}
                              alt="lisenceFrontImage"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload Lisence front
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <input
                        type="file"
                        id="file-input-lisence-front"
                        className="d-none"
                        value={updateImages.lisenceFrontImage.value}
                        name="lisenceFrontImage"
                        accept="image/*"
                        onChange={handleUpdateImages}
                      />
                    </label>
                  </div>
                  <div className={styles.uploadLisence}>
                    {" "}
                    <label
                      for="file-input-lisence-back"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {updateImages.lisenceBackImage.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateLisenceBackCrosss}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={updateImages.lisenceBackImage.preview}
                                alt="lisenceImageBack"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          ) : updateFormValues.lisenceBackImageToShow &&
                            !updateImageState.lisenceBackImageState ? (
                            <img
                              src={updateFormValues.lisenceBackImageToShow}
                              alt="lisenceImageBack"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload Lisnce back
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-lisence-back"
                          className="d-none"
                          value={updateImages.lisenceBackImage.value}
                          name="lisenceBackImage"
                          accept="image/*"
                          onChange={handleUpdateImages}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-start  mb-2 gap-4 ${styles.buttonBox}`}
              >
                <button
                  className={styles.addDriver}
                  type="submit"
                  disabled={!isUpdatePhoneValid || !isUpdateValid}
                >
                  Update Driver
                </button>
                <button
                  className={styles.closeBtn}
                  onClick={handleUpdateModelsClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* -------------Update modal ends here----------------- */}
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {currentPost.length > 0 ? (
              <div className={`row mx-0 ${styles.driversGrid}`}>
                {currentPost.map((ele) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mx-0 p-2 mx-0 p-2"
                    key={ele.driverId}
                  >
                    {/* driver image:- */}

                    <div
                      className={styles.contentDiv}
                      style={{ padding: "30px 15px" }}
                    >
                      <div className={`${styles.driverImageDiv}`}>
                        <img src={ele.driverImage} alt="driver-image" />
                      </div>
                      <div className={`${styles.profileDiv}`}>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <p className={styles.nameTitle}>
                              {ele.driverFullName}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between gap-2">
                            {ele.status === 1 ? (
                              <span className={` ${styles.badge}`}>Active</span>
                            ) : (
                              <span className={` ${styles.deactiveBadge}`}>
                                Deactive
                              </span>
                            )}
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Click to Edit
                                </Tooltip>
                              }
                            >
                              <i
                                className={`${styles.iconStyle} fas fa-edit me-2`}
                                onClick={() =>
                                  handleUpdateModelshow({
                                    driverId: ele.driverId,
                                    driverFullName: ele.driverFullName,
                                    driverContactNumber:
                                      ele.driverContactNumber,
                                    email: ele.email,
                                    driverImageToShow: ele.driverImage,
                                    lisenceFrontImageToShow: ele.licenseImage,
                                    lisenceBackImageToShow:
                                      ele.licenseImageBack,
                                    driverImageKey: "driverImage",
                                    licenseImageKeyFront: "lisenceFrontImage",
                                    licenseImageKeyBack: "lisenceBackImage",
                                  })
                                }
                              ></i>
                              {/* <FiEdit /> */}
                            </OverlayTrigger>
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Click to Delete
                                </Tooltip>
                              }
                            >
                              <i
                                style={{ opacity: "0.8" }}
                                className={`${styles.iconStyle} fa fa-trash-alt`}
                                onClick={() => handleDeleteOpen(ele.driverId)}
                              ></i>
                              {/* <RiDeleteBin6Line /> */}
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div
                          className={`d-flex justify-content-between  ${styles.detailsSection}`}
                        >
                          <div>
                            <div>
                              <p
                                className={styles.otherContentTitle}
                                style={{ marginTop: "8px" }}
                              >
                                Email
                              </p>
                              <p className={styles.otherContentValue}>
                                {ele.email}
                              </p>
                            </div>
                          </div>
                          <div>
                            <div>
                              <p
                                className={styles.otherContentTitle}
                                style={{ marginTop: "8px" }}
                              >
                                Phone Number
                              </p>
                              <p className={styles.otherContentValue}>
                                {ele.driverContactNumber}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className={`d-flex justify-content-between  ${styles.detailsSection}`}
                        ></div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
        <PaginNew
          post={items}
          total={total}
          handlePageClick={handlePageClick}
          postPerPage={postPerPage}
          setPostPerPage={setPostPerPage}
          page={page}
        />
        <ToastContainer />

        {/* Delete Modal Component */}

        <DeleteuserComponent
          show={modalShow}
          onHide={handleDeleteclose}
          onAccept={handleDeleteaccept}
        />

        <div
          className={`d-flex justify-content-center align-items-center ${styles.addBookingDiv}`}
          onClick={handleShow}
        >
          <AiOutlinePlus className={styles.addBookingIcon} />
        </div>
      </div>
    </div>
  );
};
