import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { errorToastMsg, successToastMsg } from '../../../../common/ToastMessage';
import styles from "./SendReviewLink.module.scss";
import axios from 'axios';
import { API_SEND_REVIEW_LINK } from '../../../../utils/consts/api';
import PaymentErrorComponent from './PaymentErrorModal/payment-error-modal';

const SendReviewLinkModal = ({ callbackFunc, crmBookingId, gettingDataFun }) => {
    const [show, setShow] = useState(true);
    const [reviewLinkShow, setReviewLinkShow] = useState(false);
    const [dropDownValue, setdropdownValue] = useState()
    const handleClose = () => {
        setShow(false);
        callbackFunc(false)
    }
    
    const handleShow = () => setShow(true);

    const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

    const handleSubmit = (e) => {
        e.preventDefault();
        if (dropDownValue === undefined) {
            errorToastMsg("Please select the Review link");
            return;
        }

        axios.post(API_SEND_REVIEW_LINK,
            {
                "crmBookingId": crmBookingId,
                "linkType": dropDownValue
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`,
                },
            }
        )
            .then((res) => {
                if (res.data.responseCode === -1) {
                    setReviewLinkShow(true);
                } else {
                    successToastMsg(res.data.successMsg);
                    callbackFunc(false);
                    handleClose();
                    gettingDataFun();
                }
            }).catch((error) => {
                errorToastMsg(error.errorMsg);
            });
            setShow(false);
    };

    return (
        <>
            <Modal size="md" aria-labelledby="contained-modal-title-vcenter" centered show={show} onHide={handleClose}>
                <div className={styles.modalDiv}>
                    <div className={styles.modalTitle}>Send Review Link</div>
                    <div className={styles.modalCross} onClick={handleClose}>x</div>
                </div>
                <hr className="m-0" />
                <Modal.Body>
                    <form onSubmit={handleSubmit} autocomplete="off">
                        <div className="mb-3">
                            <select
                                className={`${styles.select}`}
                                id="dropdown-basic-button" title="Dropdown button"
                                onChange={(event) => setdropdownValue(event.target.value)} value={dropDownValue}
                            >
                                <option defaultValue="undefined" disabled selected hidden>
                                    Select Review Platform
                                </option>
                                <option value="fbUrl">Facebook</option>
                                <option value="justDialUrl">Just Dial</option>
                                <option value="googleUrl">Google</option>
                                <option value="tripAdvisorUrl">Trip Advisor</option>
                            </select>
                        </div>
                        <div className={`d-flex justify-content-end mx-3 mb-3 gap-2 ${styles.buttonDiv}`}>
                            <Button variant="light" onClick={handleClose} className="border">
                                Cancel
                            </Button>
                            <Button variant="warning" type='submit' className={`${styles.sendLink} border`}>
                                Send Link
                            </Button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {
                reviewLinkShow && (
                    <PaymentErrorComponent callbackFunc={ callbackFunc }/>
                )
            }
        </>
    )
}

export default SendReviewLinkModal