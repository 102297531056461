import React, { useState } from "react";
import styles from "./MyBooking.module.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IoMdArrowBack } from "react-icons/io";
import ConfirmBookingModal from "./ConfirmBookingModal/ConfirmBookingModal";
import AssignBookingModal from "./AssignBookingModal/AssignBookingModal";
import CompleteTripBookigmodal from "./CompleteTripBookigModal/CompleteTripBookingmodal";
import SendReviewLinkModal from "./SendReviewLink/SendReviewLinkModal";
import MarkBookigCompleteModal from "./MarkBookingCompleteModal/MarkBookingCompleteModal";
import moment from "moment";
import Popover from "react-bootstrap/Popover";
import axios from "axios";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Button, Modal, Tooltip } from "react-bootstrap";
import { errorToastMsg, successToastMsg } from "../../../common/ToastMessage";
import Profile_Icon from "../../../assets/images/driver_placeholder.jpg";

import {
  API_BACK_STATE,
  API_CANCEL_BOOKING,
  API_TRIP_STARTED,
} from "../../../utils/consts/api";
import { useDispatch, useSelector } from "react-redux";
import { thunkDispatchNotification } from "../../../redux/action/notification.action";

const ActiveBookings = ({ el, color, submit, gettingDataFun, status }) => {
  const [show, setShow] = useState(false);
  const [confirmBookigStatus, setconfirmBookigStatus] = useState(false);
  const [assignBookingStatus, setassignBookingStatus] = useState(false);
  const [CompleteTripBookigStatus, setCompleteTripBookigStatus] =
    useState(false);
  const [MarginEarnedBookigStatus, setMarginEarnedBookigStatus] =
    useState(false);
  const [reviewLinkStatus, setreviewLinkStatus] = useState(false);
  const [sendReviewShow, setSendReviewShow] = useState(false);
  const [cancelBookingShow, setCancelBookingShow] = useState(false);
  const [tripCompletedStatus, setTripCompletedStatus] = useState(false);

  const dispatch = useDispatch();

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const clickTripStarted = () => {
    axios
      .post(
        API_TRIP_STARTED,
        {
          crmBookingId: el.crmBookingId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          gettingDataFun();
          dispatch(thunkDispatchNotification());
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleSubmit = (e) => {
    if (e === "Confirm") {
      setconfirmBookigStatus(true);
      handleClose();
    } else if (e === "Assign") {
      setassignBookingStatus(true);
    } else if (e === "Trip Started") {
      clickTripStarted();
    } else if (e === "Complete Trip") {
      setCompleteTripBookigStatus(true);
    } else if (e === "Send Review Link") {
      setreviewLinkStatus(true);
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleshow = (el) => {
    setShow(true);
  };

  const handleCancelBooking = (id) => {
    const cancelBookingData = {
      crmBookingId: id,
    };

    axios
      .post(API_CANCEL_BOOKING, cancelBookingData, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) errorToastMsg(res.data.errorMsg);
        else {
          successToastMsg(res.data.successMsg);
          gettingDataFun();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
      });
    setCancelBookingShow(!cancelBookingShow);
  };

  const handleSucessfullCallBackFunction = () => {
    setMarginEarnedBookigStatus(true);
    setCompleteTripBookigStatus(false);
  };

  const handleSucessfullMarkBooking = () => {
    setMarginEarnedBookigStatus(false);
    setSendReviewShow(true);
  };

  const handlePickUpLocation = (pickUpLocation) => {
    const tempLocation = pickUpLocation.split(",");
    return tempLocation
      .slice(tempLocation.length - 3, tempLocation.length)
      .join(",");
  };
  const formatTime = (pickUpTime) => {
    let [hrs, mins] = pickUpTime.split(":");
    let AmOrPm = hrs >= 12 ? "pm" : "am";

    let hours = hrs % 12 || 12;

    if (hours < 10) {
      hours = "0" + hours;
    }

    let time = hours + ":" + mins + AmOrPm;

    return time;
  };

  const handleBackFunction = (BookingId) => {
    axios
      .post(
        API_BACK_STATE,
        {
          crmBookingId: BookingId,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          gettingDataFun();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
      });
  };

  return (
    <>
      <div className={styles.customers}>
        <div>
          {submit === "Assign" ? (
            <div className={`${styles.backStateDiv}`}>
              <OverlayTrigger
                placement="right"
                style={{ height: "40%" }}
                overlay={
                  <Tooltip
                    id={`tooltip-top`}
                    style={{ marginTop: "1px", marginLeft: "5px" }}
                  >
                    Move to Added
                  </Tooltip>
                }
              >
                <i onClick={() => handleBackFunction(el.crmBookingId)}>
                  <IoMdArrowBack />
                </i>
              </OverlayTrigger>
            </div>
          ) : submit === "Trip Started" ? (
            <div className={`${styles.backStateDiv}`}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-top`}>Move to Confirmed</Tooltip>
                }
              >
                <i onClick={() => handleBackFunction(el.crmBookingId)}>
                  <IoMdArrowBack />
                </i>
              </OverlayTrigger>
            </div>
          ) : submit === "Complete Trip" ? (
            <div className={`${styles.backStateDiv}`}>
              <OverlayTrigger
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-top`}>Move to Vehicle Alloted</Tooltip>
                }
              >
                <i onClick={() => handleBackFunction(el.crmBookingId)}>
                  <IoMdArrowBack />
                </i>
              </OverlayTrigger>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={styles.topSection}>
          <p className={styles.idNumber}>ID {el.crmBookingId}</p>
          <div className={styles.topRight}>
            <p
              className={
                el.bookingType === "oneWay" ? styles.oneWay : styles.roundTrip
              }
            >
              {el.bookingType}
            </p>
            {el.status === "completed" || status === "Payment Pending" ? (
              ""
            ) : (
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setCancelBookingShow(!cancelBookingShow)}
              >
                <OverlayTrigger
                  rootClose
                  onHide="null"
                  trigger="click"
                  defaultShow={cancelBookingShow}
                  placement="bottom"
                  overlay={
                    <Popover>
                      <Popover.Body
                        onClick={() => handleCancelBooking(el.crmBookingId)}
                        rootClose
                        style={{ padding: "8px", cursor: "pointer" }}
                        className={styles.modalcancle}
                      >
                        Cancel Booking
                      </Popover.Body>
                    </Popover>
                  }
                >
                  <MoreVertIcon className={styles.moreSettings} />
                </OverlayTrigger>
              </div>
            )}
          </div>
        </div>
        <div className={styles.midSection} onClick={() => handleshow(el)}>
          <div
            className={
              el.customerName && el.customerContactNumber
                ? styles.name
                : styles.noData
            }
          >
            {<p>{el.customerName ? el.customerName : "-"}</p>}
            <p>
              +91 {el.customerContactNumber ? el.customerContactNumber : "-"}
            </p>
          </div>
          <div className={el.pickUpDate ? styles.data : styles.noData}>
            <p>Trip Date & Time</p>
            <p>
              {moment(el.pickUpDate).format("DD-MM-YYYY")} {el.pickUpTime}
            </p>
          </div>
          {submit === "Send Review Link" && (
            <div className={el.pickUpDate ? styles.data : styles.noData}>
              <p>Trip Notes</p>
              <p>{el.tripNotes ? el.tripNotes : "-"}</p>
            </div>
          )}
          {submit === "Send Review Link" && (
            <div className={el.pickUpDate ? styles.data : styles.noData}>
              <p>Tour Description</p>
              <p>{el.tourDescription ? el.tourDescription : "-"}</p>
            </div>
          )}

          <div className={el.vehicleTypeId ? styles.data : styles.noData}>
            <p>Vehicle Type</p>
            <p>{el.vehicleTypeName}</p>
          </div>
          <div className={el.noOfDays ? styles.data : styles.noData}>
            <p>No.of Days</p>
            <p>
              {el.noOfDays
                ? el.noOfDays > 1
                  ? `${el.noOfDays} Days`
                  : `${el.noOfDays} Day `
                : "-"}{" "}
            </p>
          </div>
          <div className={el.pickUpLocationName ? styles.data : styles.noData}>
            <p>Pickup Location</p>
            <p>{handlePickUpLocation(el.pickUpLocationName)}</p>
          </div>
          {submit === "Send Review Link" && (
            <div
              className={el.bookingAmount ? styles.bookingAmt : styles.noData}
            >
              <p>Total Margin Earned</p>
              {el.marginEarned ? <p>&#8377;{el.marginEarned}*</p> : "-"}
            </div>
          )}
          <div className={el.bookingAmount ? styles.bookingAmt : styles.noData}>
            <p>Booking Amount</p>
            <p>&#8377;{el.bookingAmount}*</p>
          </div>
        </div>
        <hr className={styles.borderLine} />
        <div className={styles.submitSection}>
          <p
            className={
              el.assignedDriverId ? styles.blockAssigned : styles.noneAssigned
            }
          >
            {el.assigned}
          </p>
          <button
            style={{ backgroundColor: color }}
            className={styles.submit}
            name={submit}
            value={submit}
            onClick={(e) => handleSubmit(e.target.value)}
          >
            {submit}
          </button>
        </div>
        {confirmBookigStatus && (
          <ConfirmBookingModal
            element = {el}
            callbackFunc={setconfirmBookigStatus}
            crmBookingId={el.crmBookingId}
            gettingDataFun={gettingDataFun}
          />
        )}
        {assignBookingStatus && (
          <AssignBookingModal
            callbackFunc={setassignBookingStatus}
            crmBookingId={el.crmBookingId}
            gettingDataFun={gettingDataFun}
          />
        )}
        {CompleteTripBookigStatus && (
          <CompleteTripBookigmodal
            setTripCompletedStatus={setTripCompletedStatus}
            SecessfullcallbackFunc={handleSucessfullCallBackFunction}
            callbackFunc={setCompleteTripBookigStatus}
            crmBookingId={el.crmBookingId}
            gettingDataFun={gettingDataFun}
          />
        )}
        {reviewLinkStatus && (
          <SendReviewLinkModal
            callbackFunc={setreviewLinkStatus}
            crmBookingId={el.crmBookingId}
            gettingDataFun={gettingDataFun}
          />
        )}
        {MarginEarnedBookigStatus && (
          <MarkBookigCompleteModal
            tripCompletedStatus
            callbackFunc={setMarginEarnedBookigStatus}
            sucessfullCallbackFunction={handleSucessfullMarkBooking}
            crmBookingId={el.crmBookingId}
            gettingDataFun={gettingDataFun}
          />
        )}
        {sendReviewShow && (
          <SendReviewLinkModal
            callbackFunc={setSendReviewShow}
            crmBookingId={el.crmBookingId}
            gettingDataFun={gettingDataFun}
          />
        )}
      </div>
      {/* --------------------- modal here ---------------- */}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        show={show}
        onHide={handleClose}
      >
        <div className={styles.modalDiv}>
          <p className={styles.idNumber}>
            <strong>ID {el.crmBookingId}</strong>
          </p>
          <div className={styles.modalflex}>
            <p
              className={
                el.bookingType === "oneWay" ? styles.oneWay : styles.roundTrip
              }
            >
              {el.bookingType}
            </p>
            <div className={styles.modalCross} onClick={handleClose}>
              x
            </div>
          </div>
        </div>
        <hr
          className={styles.borderLine}
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            borderTop: "1px solid #575757",
          }}
        />
        <Modal.Body>
          <div className={styles.midSection} style={{ padding: "0px 10px" }}>
            <div
              className={
                el.customerName && el.customerContactNumber
                  ? styles.name
                  : styles.noData
              }
            >
              <p>{el.customerName}</p>
              <p>
                +91 {el.customerContactNumber}{" "}
                {el.customerEmail ? ` , ${el.customerEmail} ` : ""}
              </p>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div className={styles.data} style={{ width: "70%" }}>
                <p>Trip Date & Time</p>
                <p>
                  {moment(el.pickUpDate).format("DD-MM-YYYY")} {el.pickUpTime}
                </p>
              </div>
              <div className={el.vehicleTypeId ? styles.data : styles.noData}>
                <p>Vehicle Type</p>
                <p>{el.vehicleTypeName ? el.vehicleTypeName : "-"}</p>
              </div>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div style={{ width: "70%" }} className={styles.data}>
                <p>Pickup Location</p>
                <p>{el.pickUpLocationName ? el.pickUpLocationName : "-"}</p>
              </div>
              <div className={styles.data}>
                <p>No.of Days</p>
                <p>
                  {el.noOfDays
                    ? el.noOfDays <= 1
                      ? `${el.noOfDays} day`
                      : `${el.noOfDays} days`
                    : "-"}
                </p>
              </div>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div className={styles.data} style={{ width: "70%" }}>
                <p>Trip Notes</p>
                <p>{el.tripNotes ? el.tripNotes : "-"}</p>
              </div>
              <div className={styles.data}>
                <p>Seating Capacity</p>
                <p>
                  {el.seatingCapacity ? `${el.seatingCapacity}  Seats` : "-"}
                </p>
              </div>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div className={styles.data} style={{ width: "70%" }}>
                <p>Pick Up Date</p>
                <p>
                  {el.pickUpDate
                    ? moment(el.pickUpDate).format("DD-MM-YYYY")
                    : "-"}
                </p>
              </div>
              <div className={styles.data}>
                <p>Pick Up Time</p>
                <p>{el.pickUpTime ? el.pickUpTime : "-"}</p>
              </div>
            </div>
            {el.dropLocationName && (
              <div style={{ width: "55%" }} className={styles.data}>
                <p>Drop Location</p>
                <p>{el.dropLocationName ? el.dropLocationName : "-"}</p>
              </div>
            )}
            <div className={styles.data} style={{ width: "55%" }}>
              <p>Tour Description</p>
              <p>{el.tourDescription ? el.tourDescription : "-"}</p>
            </div>
            {el.assignedVehicleId && (
              <div className={styles.data} style={{ width: "55%" }}>
                <p>Driver Info</p>
                <div
                  className={
                    el.assignedVehicleId != null
                      ? styles.assignedTo
                      : styles.notAssignedTo
                  }
                >
                  <img
                    src={el.driverImage || Profile_Icon}
                    alt="Driver image"
                  />
                  <div>
                    <p>{el.driverFullName}</p>
                    <p>+91{el.driverContactNumber}</p>
                  </div>
                </div>
              </div>
            )}
            {el.assignedVehicleId && (
              <div className={styles.data} style={{ width: "55%" }}>
                <p>Vehicle Info</p>
                <div
                  className={
                    el.assignedVehicleId != null
                      ? styles.assignedTo
                      : styles.notAssignedTo
                  }
                >
                  <img src={el.vehicleImage || Profile_Icon} alt="" />
                  <div>
                    <p>{el.registrationNumber}</p>
                    <p>{el.vehicleTypeName}</p>
                  </div>
                </div>
              </div>
            )}
            <div
              className={styles.modalflexInnerPart}
              style={{ justifyContent: "space-between" }}
            >
              <div className={styles.bookingAmt}>
                <p>Booking Amount</p>
                <p>&#8377;{el.bookingAmount ? el.bookingAmount : "-"}*</p>
              </div>
              {el.status === "new" && (
                <div>
                  <button
                    className={styles.confirmbtn}
                    value={submit}
                    onClick={(e) => handleSubmit(e.target.value)}
                  >
                    Confirm Booking
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* --------------------- modal ends here ---------------- */}
    </>
  );
};

export default ActiveBookings;
