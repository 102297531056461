import { style } from '@mui/system';
import React from 'react'
import styles from "./Spinner.module.scss"

const Spinner = () => {
  return (
    <div className={styles.spinnerContainer}>
        <div className={`${styles.spinnerStyling} spinner-border text-warning`} role="status">
        <span className="sr-only">Loading...</span>
        </div>
    </div>
  )
}

export default Spinner;