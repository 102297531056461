import React from "react";

function BankDetails({ fetchedBankData }) {
  return (
    <form className="row g-3" autocomplete="off">
      <div className="col-md-6">
        <label htmlFor="inputEmail4" className="form-label">
          <strong>Account Number</strong>
        </label>
        <div className="col-md-6">
          {fetchedBankData.accountNumber ? fetchedBankData.accountNumber : "-"}
        </div>
      </div>
      <div className="col-md-6">
        <label htmlFor="inputEmail4" className="form-label">
          <strong>Bank Name</strong>
        </label>
        <div>{fetchedBankData.bankName ? fetchedBankData.bankName : "-"}</div>
      </div>
      <div className="col-md-6">
        <label htmlFor="inputEmail4" className="form-label">
          <strong>Bank IFSC Code</strong>
        </label>
        <div>{fetchedBankData.bankIfsc ? fetchedBankData.bankIfsc : "-"}</div>
      </div>
      <div className="col-md-6">
        <label htmlFor="inputEmail4" className="form-label">
          <strong>Account Holder Name</strong>
        </label>
        <div>
          {fetchedBankData.accountHolderName
            ? fetchedBankData.accountHolderName
            : "-"}
        </div>
      </div>
    </form>
  );
}

export default BankDetails;
