export const HOST = "https://api.taxi-sanchalak.com/v1";  // https://api.taxi-sanchalak.com/v1  //http://3.111.157.249:9000/v1

export const EMAIL_REGEX = new RegExp(
  /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
);
export const PHONE_REGEX = new RegExp(
  /^(?:(?:\+|0{0,2})91(\s*|[\-])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/
);

export const ALPHABET_ONLY_REGEX = new RegExp(
  /^[A-Za-z]+$/
);

export const FACEBOOK_REGEX = new RegExp(
  /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i
);

export const VALID_URL_REGEX = new RegExp(
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
);

export const IS_NUMBER_REGEX = /^[0-9]+$/ ;

export  const NAME_REGEX = /^[a-zA-Z ]+$/;

export const ALPHANUMERICREGEX = /^[0-9a-zA-Z ]+$/;

export const UPI_ID_REGEX = /^[a-zA-Z0-9.\-_]{2,256}@[a-zA-Z]{2,64}/;

export const WHITE_SPACES_REGEX = /^\s*$/;

export const SEATING_CAPACITY_REGEX = /^[0-9+]+$/ ;


export const API_LOGIN_USER = `${HOST}/crm/crmLogin`;
export const API_LOGIN_BY_OTP = `${HOST}/crm/sendLoginOtp`;
export const API_VALIDATE_LOGIN_OTP = `${HOST}/crm/loginValidateOtp`;
export const API_FORGET_PASSWORD = `${HOST}/crm/forgotPassword`;
export const API_GET_NOTIFICATIONS = `${HOST}/crm/getNotification`;
export const API_REGISTER = `${HOST}/crm/register`;
export const API_MYBOOKINGS = `${HOST}/crm/myBookings`;
export const API_CREATE_BOOKING = `${HOST}/crm/createBooking`;
export const API_CONFIRM_BOOKING = `${HOST}/crm/confirmBooking`;
export const API_ASSIGN_BOOKING = `${HOST}/crm/assignBooking`;
export const API_TRIP_STARTED = `${HOST}/crm/tripStarted`;
export const API_COMPLETE_TRIP = `${HOST}/crm/completedTrip`;
export const API_GET_MY_VEHICLES = `${HOST}/crm/getMyVehicles`;
export const API_ADD_VEHICLE = `${HOST}/crm/addVehicle`;
export const API_UPDATE_VEHICLE = `${HOST}/crm/updateVehicle`;
export const API_DELETE_VEHICLE = `${HOST}/crm/deleteVehicle`;
export const API_GET_MY_DRIVER = `${HOST}/crm/getMyDriver`;
export const API_ADD_DRIVER = `${HOST}/crm/addDriver`;
export const API_DELETE_DRIVER = `${HOST}/crm/deleteDriver`;
export const API_UPDATE_DRIVER = `${HOST}/crm/updateDriver`;
export const API_ADD_CUSTOMER = `${HOST}/crm/addCustomer`;
export const API_UPDATE_CUSTOMER = `${HOST}/crm/updateCustomer`;
export const API_DELETE_CUSTOMER = `${HOST}/crm/deleteCustomer`;
export const API_GET_MY_CUSTOMER = `${HOST}/crm/getMyCustomer`;
export const API_UPDATE_BANK_DETAILS = `${HOST}/crm/updateBankDetails`;
export const API_UPDATE_UPI_DETAILS = `${HOST}/crm/updateUpiDetails`;
export const API_GET_PAYMENT_DETAILS = `${HOST}/crm/getPaymentDetails`;
export const API_SEND_REVIEW_LINK = `${HOST}/crm/sendReviewLink`;
export const API_MY_CANCEL_BOOKINGS = `${HOST}/crm/myCancelBookings`;
export const API_CANCEL_BOOKING = `${HOST}/crm/cancelBooking`;
export const API_GET_VEHICLE_TYPE = `${HOST}/crm/getVehicleType`;
export const API_RESENT_OTP = `${HOST}/crm/resendOtp`;
export const API_LOG_OUT = `${HOST}/crm/logout`;
export const API_IS_BOOKING_COMPLETE = `${HOST}/crm/isCompleteBooking`;
export const API_ADD_AND_ASSIGN = `${HOST}/crm/addAndAssignVehicleDriver`;
export const API_GET_CRM_PLANS = `${HOST}/crm/getSubscriptions`;
export const API_CHANGE_PASSWORD = `${HOST}/crm/changePassword`;
export const API_GET_USER_PROFILE = `${HOST}/crm/getProfile`;
export const API_UPDATE_PROFILE = `${HOST}/crm/updateProfile`;
export const API_ISNOTIFICATIONREAD = `${HOST}/crm/isNotificationRead`;
export const API_GET_REVIEWLINKS = `${HOST}/crm/getReviewLinks`;
export const API_GET_UPDATEREVIEWLINKS = `${HOST}/crm/updateReviewLinks`;
export const API_BACK_STATE = `${HOST}/crm/backState`;
export const API_SKIP_PAYMENT = `${HOST}/crm/skipPayment`;
export const API_CHANGE_PASSWORD_VIA_EMAIL = `${HOST}/crm/changePasswordViaEmail`;

// backState
