import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import {
  errorToastMsg,
  successToastMsg,
} from "../../../../common/ToastMessage";
import {
  ALPHANUMERICREGEX,
  API_ADD_AND_ASSIGN,
  API_ADD_VEHICLE,
  API_GET_VEHICLE_TYPE,
  IS_NUMBER_REGEX,
} from "../../../../utils/consts/api";
import { BsPerson, BsXCircle } from "react-icons/bs";

import styles from "./AddVehicle.module.scss";
import { ImageCompressorHook } from "../../CommonComp/ImageCompressor/ImageCompressor";

const AddVehicleModal = ({ callbackFunc1, gettingDataFun, callbackFunc,getVehicleList }) => {
  const [show, setShow] = useState(true);
  const [manufactureErrorMessage, setManufactureErrorMessage] = useState("");
  const [ismanufacturingValid, setIsManufacturingValid] = useState(true);
  const handleClose = () => {
    setShow(false);
    callbackFunc1(false);
    // callbackFunc(false);
  };
  const [dropdownList, setDropdownList] = useState([]);
  const [dropdownValue, setdropdownValue] = useState("");
  const alphabetNumberRegex = /^[0-9a-zA-Z]+$/;
  const {imageCompressorFn,data} = ImageCompressorHook();

  const addVehicleInitialValues = {
    vehicleTypeId: "",
    registrationNumber: "",
    yearOfManufacture: "",
    rcImageFront: "rcImageFront",
    rcImageBack: "rcImageBack",
    vehicleImage1: "vehicleImage1",
    vehicleImage2: "vehicleImage2",
    vehicleImage3: "vehicleImage3",
  };

  const [addVehicleFormValues, setAddVehicleFormValues] = useState(
    addVehicleInitialValues
  );

  const vehicleImagesIntialValues = {
    rcImageFront: { preview: "", raw: "", value: "" },
    rcImageBack: { preview: "", raw: "", value: "" },
    vehicleImage1: { preview: "", raw: "", value: "" },
    vehicleImage2: { preview: "", raw: "", value: "" },
    vehicleImage3: { preview: "", raw: "", value: "" },
  };

  const [vehicleImages, setVehicleImages] = useState(vehicleImagesIntialValues);

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleType);
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleVehicleImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image,e.target.name);
      }
      setVehicleImages({
        ...vehicleImages,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const handleVehicleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "yearOfManufacture") {
      if (value.length >= 5 || (!IS_NUMBER_REGEX.test(value))) {
        setAddVehicleFormValues({
          ...addVehicleFormValues,
          [name]: value.slice(0, -1),
        });
        return;
      }
    }else if( name === "registrationNumber"){
       if(!ALPHANUMERICREGEX.test(value) || value === " "){
        setAddVehicleFormValues({
          ...addVehicleFormValues,
          [name]: value.slice(0, -1),
        });
        return;
       }
    }
    setAddVehicleFormValues({
      ...addVehicleFormValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddVehicle = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify(addVehicleFormValues));
    if (vehicleImages.rcImageFront.raw) {
      formData.append(
        "rcImageFront",
        vehicleImages.rcImageFront.raw,
        vehicleImages.rcImageFront.name
      );
    } else {
      errorToastMsg("Please Upload RC-Front Image");
      return;
    }
    if (vehicleImages.rcImageBack.raw) {
      formData.append(
        "rcImageBack",
        vehicleImages.rcImageBack.raw,
        vehicleImages.rcImageBack.name
      );
    } else {
      errorToastMsg("Please Upload RC-Back Image");
      return;
    }
    if(!vehicleImages.vehicleImage1.raw && !vehicleImages.vehicleImage2.raw && !vehicleImages.vehicleImage3.raw){
        errorToastMsg("Please upload the atlest one vehicle image");
        return;
    }
    if (vehicleImages.vehicleImage1.raw) {
      formData.append(
        "vehicleImage1",
        vehicleImages.vehicleImage1.raw,
        vehicleImages.vehicleImage1.name
      );
    } 
    if (vehicleImages.vehicleImage2.raw) {
      formData.append(
        "vehicleImage2",
        vehicleImages.vehicleImage2.raw,
        vehicleImages.vehicleImage2.name
      );
    }
    if (vehicleImages.vehicleImage3.raw) {
      formData.append(
        "vehicleImage3",
        vehicleImages.vehicleImage3.raw,
        vehicleImages.vehicleImage3.name
      );
    }

    axios
      .post(API_ADD_VEHICLE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          handleClose(false);
          getVehicleList();
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  useEffect(() => {
    getdropdownList();
  }, []);

  const changeDropdownValue = (newF) => {
    setAddVehicleFormValues({
      ...addVehicleFormValues,
      vehicleTypeId: newF,
    });
    setdropdownValue(newF);
  };

  const handleYearofManufactureMsg = () => {
    const futureYear =  new Date().getFullYear();
    if (addVehicleFormValues.yearOfManufacture) {
      if (addVehicleFormValues.yearOfManufacture < 2011 ) {
        setIsManufacturingValid(false);
        setManufactureErrorMessage(
          "Year of manufacturing should be 2011 and above"
        );
      } else if(addVehicleFormValues.yearOfManufacture > futureYear){
        setIsManufacturingValid(false);
        setManufactureErrorMessage(
          "Year of manufacturing should not be in future date"
        );
      }
      else {
        setIsManufacturingValid(true);
        setManufactureErrorMessage("");
      }
    } else {
      setIsManufacturingValid(true);
      setManufactureErrorMessage("");
    }
  };

  const handleRcImageFrontCross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      rcImageFront: { preview: "", raw: "", value: "" },
    });
  };

  const handleRcImageBackCross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      rcImageBack: { preview: "", raw: "", value: "" },
    });
  };

  const handleVehicle1Cross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      vehicleImage1: { preview: "", raw: "", value: "" },
    });
  };

  const handleVehicle2Cross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      vehicleImage2: { preview: "", raw: "", value: "" },
    });
  };

  const handleVehicle3Cross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      vehicleImage3: { preview: "", raw: "", value: "" },
    });
  };

  useEffect(()=>{
    if(data.image){
      if(data.imageName){
        setVehicleImages({
          ...vehicleImages,
          [data.imageName]:{
            preview: URL.createObjectURL(data.image),
            raw: data.image
          }
        });
      }else{
        setVehicleImages({
          ...vehicleImages,
            preview: URL.createObjectURL(data.image),
            raw: data.image
        });
      }
    }
},[data]);

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className={styles.mainModal}
        scrollable
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Add New Vehicle</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          <form onSubmit={handleAddVehicle} autocomplete="off">
            <div className={styles.selectDiv}>
              <select
                className={styles.select}
                id="exampleFormControlInput1"
                name="vehicleTypeId"
                required
                onChange={(event) => changeDropdownValue(event.target.value)}
                value={dropdownValue}
              >
                <option
                  value=""
                  className={styles.vehicleSelect}
                  style={{ color: "#9299B8", fontSize: "14px" }}
                >
                  Select Vehicle Type
                </option>
                {dropdownList.map((single) => {
                  return (
                    <option
                      key={single.vehicleTypeId}
                      value={single.vehicleTypeId}
                      className={`form-control ${styles.dropdownMenu}`}
                    >
                      {single.vehicleTypeName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-3" style={{ marginTop: "15px" }}>
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold ${styles.inputlabelText}`}
              >
                Registration Number
              </label>
              <input
                type="text"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Registration Number"
                name="registrationNumber"
                required
                value={addVehicleFormValues.registrationNumber}
                onChange={handleVehicleInput}
              />
            </div>
            <div className="mb-3" style={{ marginTop: "15px" }}>
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold`}
              >
                Year of Manufacture
              </label>
              <input
                type="number"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Year Of Manufacture"
                required
                value={addVehicleFormValues.yearOfManufacture}
                name="yearOfManufacture"
                onChange={handleVehicleInput}
                onBlur={handleYearofManufactureMsg}
              />
              {!ismanufacturingValid && (
                <div className={`text-danger ${styles.errormessage}`}>
                  {manufactureErrorMessage}
                </div>
              )}
            </div>

            <div className={`form-label fw-bold ${styles.labelFont}`}>
              <p className={styles.labelUploadFont}>Upload RC Images</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className={styles.uploadRC}>
                  <label
                    for="file-input-rc-front"
                    className="d-flex align-items-center"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadbox}>
                        {vehicleImages.rcImageFront.preview && (
                          <div className={styles.imageBox}>
                            <div
                              className={`cross ${styles.crossSign}`}
                              onClick={handleRcImageFrontCross}
                            >
                              <BsXCircle
                                color="#D7E0EE"
                                style={{
                                  backgroundColor: "#272B41",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: 2,
                                  right: 1,
                                }}
                              />
                            </div>
                            <img
                              src={vehicleImages.rcImageFront.preview}
                              alt="rcImageFront"
                              className={styles.fiRCUploadImage}
                            />
                          </div>
                        )}
                        {!vehicleImages.rcImageFront.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload RC Front
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-rc-front"
                        className="d-none"
                        name="rcImageFront"
                        value={vehicleImages.rcImageFront.value}
                        accept="image/*"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadRC}>
                  <label
                    for="file-input-rc-back"
                    className="d-flex align-items-center gap-5"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadbox}>
                        {vehicleImages.rcImageBack.preview && (
                          <div className={styles.imageBox}>
                            <div
                              className={`cross ${styles.crossSign}`}
                              onClick={handleRcImageBackCross}
                            >
                              <BsXCircle
                                color="#D7E0EE"
                                style={{
                                  backgroundColor: "#272B41",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: 2,
                                  right: 1,
                                }}
                              />
                            </div>
                            <img
                              src={vehicleImages.rcImageBack.preview}
                              alt="rcImageBack"
                              className={styles.fiRCUploadImage}
                            />
                          </div>
                        )}
                        {!vehicleImages.rcImageBack.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload RC Back
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-rc-back"
                        className="d-none"
                        accept="image/*"
                        name="rcImageBack"
                        value={vehicleImages.rcImageBack.value}
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={`form-label fw-bold ${styles.labelFont}`}>
              <p className={styles.labelUploadFont}>
                Upload actual vehicles images
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <div className={styles.uploadVImg}>
                  {" "}
                  <label
                    for="file-input-VehicleImage1"
                    className="d-flex align-items-center "
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadboxVehicleImages}>
                        {vehicleImages.vehicleImage1.preview && (
                          <div className={styles.imageBox}>
                            <div
                              className={`cross ${styles.crossSign}`}
                              onClick={handleVehicle1Cross}
                            >
                              <BsXCircle
                                color="#D7E0EE"
                                style={{
                                  backgroundColor: "#272B41",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: 2,
                                  right: 1,
                                }}
                              />
                            </div>
                            <img
                              src={vehicleImages.vehicleImage1.preview}
                              alt="vehicleImage1"
                              className={styles.fiRCUploadImage}
                            />
                          </div>
                        )}
                        {!vehicleImages.vehicleImage1.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>Upload</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-VehicleImage1"
                        className="d-none"
                        name="vehicleImage1"
                        accept="image/*"
                        value={vehicleImages.vehicleImage1.value}
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadVImg}>
                  {" "}
                  <label
                    for="file-input-vehicleImage2"
                    className="d-flex align-items-center"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadboxVehicleImages}>
                        {vehicleImages.vehicleImage2.preview && (
                          <div className={styles.imageBox}>
                            <div
                              className={`cross ${styles.crossSign}`}
                              onClick={handleVehicle2Cross}
                            >
                              <BsXCircle
                                color="#D7E0EE"
                                style={{
                                  backgroundColor: "#272B41",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: 2,
                                  right: 1,
                                }}
                              />
                            </div>
                            <img
                              src={vehicleImages.vehicleImage2.preview}
                              alt="vehicleImage2"
                              className={styles.fiRCUploadImage}
                            />
                          </div>
                        )}
                        {!vehicleImages.vehicleImage2.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>Upload</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-vehicleImage2"
                        className="d-none"
                        accept="image/*"
                        name="vehicleImage2"
                        value={vehicleImages.vehicleImage2.value}
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadVImg}>
                  {" "}
                  <label
                    for="file-input-vehicleImage3"
                    className="d-flex align-items-center"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadboxVehicleImages}>
                        {vehicleImages.vehicleImage3.preview && (
                          <div className={styles.imageBox}>
                            <div
                              className={`cross ${styles.crossSign}`}
                              onClick={handleVehicle3Cross}
                            >
                              <BsXCircle
                                color="#D7E0EE"
                                style={{
                                  backgroundColor: "#272B41",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: 2,
                                  right: 1,
                                }}
                              />
                            </div>
                            <img
                              src={vehicleImages.vehicleImage3.preview}
                              alt="vehicleImage3"
                              className={styles.fiRCUploadImage}
                            />
                          </div>
                        )}
                        {!vehicleImages.vehicleImage3.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>Upload</p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-vehicleImage3"
                        className="d-none"
                        name="vehicleImage3"
                        accept="image/*"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              className={`d-flex justify-content-start mx-3 mb-3 gap-2 ${styles.buttonDiv}`}
            >
              <button type="submit" className={styles.addAssignButton}
                      disabled={!ismanufacturingValid}
              >
                Add Vehicle
              </button>
              <Button
                variant="light"
                onClick={handleClose}
                className={styles.cancelButton}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddVehicleModal;
