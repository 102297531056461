import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import EventIcon from "@mui/icons-material/Event";
import { AiOutlinePlus } from "react-icons/ai";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./mobileView.module.scss";
import CheckedIcon from "../../../assets/images/check.png";
import { BookingTabs } from "./BookingTabs";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import AddBookingModal from "./AddBookingModal/AddBookingModal";
import { Box, Modal } from "@mui/material";
import { ALPHANUMERICREGEX, API_GET_VEHICLE_TYPE } from "../../../utils/consts/api";
import axios from "axios";
import { errorToastMsg } from "../../../common/ToastMessage";
import { flexbox } from "@mui/system";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite-rtl.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const MobileView = ({ db, getAllData, setLoader }) => {
  const [activetabIndex, setActiveTabIndex] = useState(0);

  // add new booking modal:-

  const [handleAddNewBookingStatus, setHandleAddNewBookingStatus] =
    useState(false);
  const [modalState, setModalState] = useState(false);
  const [responseMesg, setResponseMesg] = useState();
  const [open, setOpen] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [searchValue,setSearchValue] = useState("");

  const [filterObject, setFilterObject] = useState({
    Added: [],
    confirmed: [],
    completed: [],
    confirmed: [],
    paymentPending: [],
    vehicleAlloted: [],
  });

  const [filteredValues, setFilterdValues] = useState({
    searchValue: "",
    vehicleType: "",
    startDate: "",
    endDate: "",
  });

  const handlePopUpOpen = () => setOpen(true);
  const handlePopUpClose = () => setOpen(false);

  const handlesSearchFunction = (e) => {
    const { name, value } = e.target;
    if (ALPHANUMERICREGEX.test(value) || value === "") {
      setSearchValue(value);
      setFilterdValues({ ...filteredValues, [name]: value });
      mainFilterDataFunction(
        value,
        filteredValues.vehicleType,
        filteredValues.startDate,
        filteredValues.endDate
      );
    } else {
      setSearchValue(value.slice(0, -1));
    }
  };

  const changeDropdownValue = (e) => {
    const { name, value } = e.target;
    setFilterdValues({ ...filteredValues, [name]: value });
    mainFilterDataFunction(
      filteredValues.searchValue,
      value,
      filteredValues.startDate,
      filteredValues.endDate
    );
  };

  const mainFilterDataFunction = (
    searchValue,
    vehicleType,
    startDate,
    endDate
  ) => {
    const filterAdded = db.Added.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId === searchValue) &&
          item.vehicleTypeName === vehicleType
        );
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId === searchValue
        );
      } else if (vehicleType) {
        return item.vehicleTypeName === vehicleType;
      } else if (startDate && endDate) {
        return (
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      }
    });

    const filterconfirmed = db.confirmed.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId === searchValue) &&
          item.vehicleTypeName === vehicleType
        );
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId === searchValue
        );
      } else if (vehicleType) {
        return item.vehicleTypeName === vehicleType;
      } else if (startDate && endDate) {
        return (
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      }
    });

    const filterVehicleAlloted = db.vehicleAlloted.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId === searchValue) &&
          item.vehicleTypeName === vehicleType
        );
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId === searchValue
        );
      } else if (vehicleType) {
        return item.vehicleTypeName === vehicleType;
      } else if (startDate && endDate) {
        return (
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      }
    });

    const filterpaymentPending = db.paymentPending.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId === searchValue) &&
          item.vehicleTypeName === vehicleType
        );
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId === searchValue
        );
      } else if (vehicleType) {
        return item.vehicleTypeName === vehicleType;
      } else if (startDate && endDate) {
        return (
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      }
    });

    const filterCompleted = db.completed.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      } else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId === searchValue) &&
          item.vehicleTypeName === vehicleType
        );
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId === searchValue
        );
      } else if (vehicleType) {
        return item.vehicleTypeName === vehicleType;
      } else if (startDate && endDate) {
        return (
          Date.parse(moment(item.pickUpDate).format("ll")) >=
            Date.parse(startDate) &&
          Date.parse(moment(item.pickUpDate).format("ll")) <=
            Date.parse(endDate)
        );
      }
    });

    setFilterObject({
      ...db,
      Added: filterAdded,
      confirmed: filterconfirmed,
      paymentPending: filterpaymentPending,
      vehicleAlloted: filterVehicleAlloted,
      completed: filterCompleted,
    });
  };

  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleType);
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleAddNewBooking = () => {
    setHandleAddNewBookingStatus(true);
  };

  const handleBookingPosted = () => {
    handlePopUpClose();
    setModalState(false);
  };

  const handleAddNewBookingCallBack = () => {
    setHandleAddNewBookingStatus(false);
    setModalState(true);
    handlePopUpOpen();
  };

  const activatTabs = (index) => {
    setActiveTabIndex(index);
  };

  const tabsName = [
    "Added",
    "Confirmed",
    "Vehicle Alloted",
    "Payment Pending",
    "Completed",
  ];

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    getdropdownList();
  }, []);

  useEffect(() => {
    db &&
      mainFilterDataFunction(
        filteredValues.searchValue,
        filteredValues.vehicleType,
        filteredValues.startDate,
        filteredValues.endDate
      );
  }, [db]);

  const handleDate = (e) => {
    let startDate;
    let endDate;
    if (e) {
      startDate = moment(e[0]).format("ll");
      endDate = moment(e[1]).format("ll");
      setFilterdValues({
        ...filteredValues,
        startDate: startDate,
        endDate: endDate,
      });
      mainFilterDataFunction(
        filteredValues.searchValue,
        filteredValues.vehicleType,
        startDate,
        endDate
      );
    } else {
      setFilterdValues({
        ...filteredValues,
        startDate: startDate,
        endDate: endDate,
      });
      mainFilterDataFunction(
        filteredValues.searchValue,
        filteredValues.vehicleType,
        startDate,
        endDate
      );
    }
  };

  const totalBookings =
    db?.Added.length +
    db?.completed.length +
    db?.confirmed.length +
    db?.paymentPending.length +
    db?.vehicleAlloted.length;

  return (
    <>
      <div
        className={`d-flex justify-content-center align-items-center ${styles.filtersMainContainer}`}
      >
        <div className={styles.filtersDivTop}>
          <div
            className={`d-flex justify-content-between align-items-center ${styles.cancelledBookingDiv}`}
          >
            <p>My Bookings</p>
            <div className="d-flex flex-column">
              <button className="text-align-center">
                <NavLink
                  className={styles.completedNavLink}
                  to="/dashboard/completedBookings"
                >
                  View Completed Bookings
                </NavLink>
              </button>
              <button className="text-align-center">
                <NavLink
                  className={styles.cancelledNavLink}
                  to="/dashboard/cancelledBookings"
                >
                  View Cancelled Bookings
                </NavLink>
              </button>
            </div>
          </div>
          <div className={styles.search}>
            <SearchIcon className={styles.searchIcon} />
            <input
              className={styles.searchById}
              name="searchValue"
              onChange={(e) => handlesSearchFunction(e)}
              type="search"
              value={searchValue}
              placeholder="Search by ID, customer name etc..."
            />
          </div>
          <div className={styles.vehicleNDate}>
            <div className={styles.selectVehicle}>
              <DirectionsCarFilledOutlinedIcon className={styles.vehicleIcon} />
              <select
                className={styles.vehicles}
                name="vehicleType"
                onChange={(event) => changeDropdownValue(event)}
                value={filteredValues.vehicleType}
              >
                <option value=""> All vehicle</option>
                {dropdownList.map((single) => {
                  return (
                    <option
                      key={single.vehicleTypeId}
                      value={single.vehicleTypeName}
                      className={`form-control ${styles.dropdownMenu}`}
                    >
                      {single.vehicleTypeName}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            <DateRangePicker
              showOneCalendar
              placeholder="Select date range"
              block
              onChange={(e) => handleDate(e)}
            />
          </div>
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.box}>
          <div className={styles.tabs}>
            {tabsName &&
              tabsName.map((ele, index) => (
                <div
                  key={index}
                  className={
                    activetabIndex === index
                      ? `${styles.tab} ${styles.activeTab}`
                      : `${styles.tab}`
                  }
                  onClick={() => activatTabs(index)}
                >
                  {ele}
                </div>
              ))}
          </div>
        </div>
        {/* content */}
        <div className={styles.activeBookings}>
          <p>
            {totalBookings > 1
              ? `${totalBookings} Active Bookings`
              : `${totalBookings} Active Booking`}
          </p>
        </div>
        {filteredValues.searchValue ||
        filteredValues.vehicleType ||
        filteredValues.startDate ? (
          filterObject && (
            <div className={styles.contents}>
              <div
                className={
                  activetabIndex === 0
                    ? `${styles.content} ${styles.activeContent}`
                    : `${styles.content}`
                }
              >
                {filterObject &&
                  filterObject.Added.map((ele, index) => (
                    <BookingTabs
                      key={index}
                      el={ele}
                      btnValue={"Confirm"}
                      f={getAllData}
                    />
                  ))}
                <div
                  className={`d-flex justify-content-center align-items-center ${styles.addBookingDiv}`}
                  onClick={handleAddNewBooking}
                >
                  <AiOutlinePlus className={styles.addBookingIcon} />
                </div>
                {handleAddNewBookingStatus && (
                  <AddBookingModal
                    callbackFuncForError={setHandleAddNewBookingStatus}
                    callbackFunc={handleAddNewBookingCallBack}
                    mesg={setResponseMesg}
                    gettingDataFun={getAllData}
                    setLoader={setLoader}
                  />
                )}
              </div>
              <div
                className={
                  activetabIndex === 1
                    ? `${styles.content} ${styles.activeContent}`
                    : `${styles.content}`
                }
              >
                {filterObject &&
                  filterObject.confirmed.map((ele, index) => (
                    <BookingTabs
                      key={index}
                      el={ele}
                      btnValue={"Assign"}
                      gettingDataFun={getAllData}
                    />
                  ))}
              </div>
              <div
                className={
                  activetabIndex === 2
                    ? `${styles.content} ${styles.activeContent}`
                    : `${styles.content}`
                }
              >
                {filterObject &&
                  filterObject.vehicleAlloted.map((ele, index) => (
                    <BookingTabs
                      key={index}
                      el={ele}
                      btnValue={"Trip Started"}
                      gettingDataFun={getAllData}
                    />
                  ))}
              </div>
              <div
                className={
                  activetabIndex === 3
                    ? `${styles.content} ${styles.activeContent}`
                    : `${styles.content}`
                }
              >
                {filterObject &&
                  filterObject.paymentPending.map((ele, index) => (
                    <BookingTabs
                      key={index}
                      el={ele}
                      btnValue={"Complete Trip"}
                      gettingDataFun={getAllData}
                    />
                  ))}
              </div>
              <div
                className={
                  activetabIndex === 4
                    ? `${styles.content} ${styles.activeContent}`
                    : `${styles.content}`
                }
              >
                {filterObject &&
                  filterObject.completed.map((ele, index) => (
                    <BookingTabs
                      key={index}
                      el={ele}
                      btnValue={"Send Review Link"}
                      gettingDataFun={getAllData}
                    />
                  ))}
              </div>
            </div>
          )
        ) : (
          <div className={styles.contents}>
            <div
              className={
                activetabIndex === 0
                  ? `${styles.content} ${styles.activeContent}`
                  : `${styles.content}`
              }
            >
              {db &&
                db.Added.map((ele, index) => (
                  <BookingTabs
                    key={index}
                    el={ele}
                    btnValue={"Confirm"}
                    gettingDataFun={getAllData}
                  />
                ))}

              {handleAddNewBookingStatus && (
                <AddBookingModal
                  callbackFuncForError={setHandleAddNewBookingStatus}
                  callbackFunc={handleAddNewBookingCallBack}
                  mesg={setResponseMesg}
                  gettingDataFun={getAllData}
                  setLoader={setLoader}
                />
              )}
            </div>
            <div
              className={
                activetabIndex === 1
                  ? `${styles.content} ${styles.activeContent}`
                  : `${styles.content}`
              }
            >
              {db &&
                db.confirmed.map((ele, index) => (
                  <BookingTabs
                    key={index}
                    el={ele}
                    btnValue={"Assign"}
                    gettingDataFun={getAllData}
                  />
                ))}
            </div>
            <div
              className={
                activetabIndex === 2
                  ? `${styles.content} ${styles.activeContent}`
                  : `${styles.content}`
              }
            >
              {db &&
                db.vehicleAlloted.map((ele, index) => (
                  <BookingTabs
                    key={index}
                    el={ele}
                    btnValue={"Trip Started"}
                    gettingDataFun={getAllData}
                  />
                ))}
            </div>
            <div
              className={
                activetabIndex === 3
                  ? `${styles.content} ${styles.activeContent}`
                  : `${styles.content}`
              }
            >
              {db &&
                db.paymentPending.map((ele, index) => (
                  <BookingTabs
                    key={index}
                    el={ele}
                    btnValue={"Complete Trip"}
                    gettingDataFun={getAllData}
                  />
                ))}
            </div>
            <div
              className={
                activetabIndex === 4
                  ? `${styles.content} ${styles.activeContent}`
                  : `${styles.content}`
              }
            >
              {db &&
                db.completed.map((ele, index) => (
                  <BookingTabs
                    key={index}
                    el={ele}
                    btnValue={"Send Review Link"}
                    gettingDataFun={getAllData}
                  />
                ))}
            </div>
          </div>
        )}
        {modalState && (
          <div>
            <Modal
              open={open}
              onClose={handlePopUpClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className={styles.popupcontent}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className={styles.imgbox}
                >
                  <img
                    src={CheckedIcon}
                    alt=""
                    className={styles.CheckedIcon}
                  />
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                  className={styles.sucessmessage}
                >
                  Booking Posted Sucessfully
                </Typography>
                <Box>
                  <Link to="/dashboard/mybooking">
                    <button
                      className={styles.button}
                      onClick={handleBookingPosted}
                    >
                      Go to Dashboard
                    </button>
                  </Link>
                </Box>
              </Box>
            </Modal>
          </div>
        )}
        <ToastContainer />
        <div
          className={`d-flex justify-content-center align-items-center ${styles.addBookingDiv}`}
          onClick={handleAddNewBooking}
        >
          <AiOutlinePlus className={styles.addBookingIcon} />
        </div>
      </div>
    </>
  );
};
