import React from "react";

function UpiDetails({ fetchedUpiData }) {
  return (
    <form autocomplete="off">
      <div className="col-md-6">
        <label htmlFor="inputEmail4" className="form-label fw-semibold">
          <strong>UPI ID</strong>
        </label>
        <div>{fetchedUpiData.upiId ? fetchedUpiData.upiId: '-' }</div>
      </div>
      <label
        style={{ marginTop: "20px" }}
        htmlFor="inputEmail4"
        className="form-label fw-semibold "
      >
        <strong>Upload QR code</strong>
      </label>

      <div className="">
        <label htmlFor="file-input">
          { fetchedUpiData.upiQrCode ?
            <img
              src={fetchedUpiData.upiQrCode}
              alt="Unable to Fetch UPI QR Code"
              height={150}
              width={150}
            /> : '-'
          }
        </label>
      </div>
    </form>
  );
}

export default UpiDetails;
