import React from "react";
import styles from "./ForgotPassword.module.scss";
import bgImage from "../../../assets/images/login_bg_image.png";
import { useState } from "react";
import { EMAIL_REGEX } from "../../../utils/consts/api";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch, useSelector } from "react-redux";
import taxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../../assets/images/Taxi-sanchalak-left-image.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { API_FORGET_PASSWORD } from "../../../utils/consts/api";
import { errorToastMsg, successToastMsg } from "../../../common/ToastMessage";
import Spinner from "../../../components/Dashboard/CommonComp/Spinner/Spinner";
// import { userthunk } from '../../redux/action/user.action';

export const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState({
    email: "",
  });
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading]=useState(false);

  const validateEmail = () => {
    if (email.email) {
      if (!EMAIL_REGEX.test(email.email)) {
        setIsValid(false);
        setErrorMsg("Invalid Email Address.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const handleEmailChange = (e) => {
    const { name, value } = e.target;
    setEmail({ ...email, email: value });
  };


  const HandleSendCode = () => {
    if (!isValid) {
      errorToastMsg("Please Enter the Valid Email");
      return;
    }
    email.email = email.email.toLowerCase();
    if (isValid) {
      setLoading(true);
      axios
        .post(API_FORGET_PASSWORD, email, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.responseCode === -1) {
            errorToastMsg(res.data.errorMsg);
            setLoading(false);
          } else {
            setLoading(false);
            successToastMsg("Instruction Send to your Email");
          }
        })
        .catch((err) => {
          errorToastMsg(err.message);
          setLoading(false);
        });
    };
  };

  return (
    <>
    {loading ? (<Spinner/>):( 
    <div className={styles.otpcomponent}>
      <div
        className={`${styles.taxiIcon}`}
        style={{ backgroundImage: `url(${taxiIcon})` }}
      ></div>
      {/* <div
        className={`d-none d-md-block ${styles.bgleftimage}`}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
      </div>
      <div className={styles.bgleftimageMediumDiv}>
        <img src={bgImage} className={styles.bgleftimageMedium} />
      </div> */}
      <div className={styles.TaxiCRMImageDiv}>
        <div className={styles.IconAndText}>
          <img src={TaxiIcon} className={styles.TaxiIconImage} />
          <p className={styles.imageText}>CRM Application</p>
        </div>
        <div className={styles.TaxiLeftImage}>
          <img src={TaxiLeftImage} width="100%" />
        </div>
      </div>
      <div className={styles.otpform}>
        <div className={styles.formBox}>
          <h4 className={`mb-4 ${styles.mobilelogin}`}>Forgot Password?</h4>
          <p className={styles.instruction}>
            Enter the email address you used when you joined and we'll send you
            instruction to reset your password
          </p>
          <div className={`form-group mb-3 ${styles.inputbox}`}>
            <label
              style={{ marginBottom: '8px' }}
              htmlFor="exampleInputEmail1"
              className={styles.inputlabel}
            >
              Email Address
            </label>
            <input
              type="text"
              className={`form-control ${styles.input}`}
              value={email.email}
              required
              onBlur={validateEmail}
              onChange={handleEmailChange}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="name@example.com"
            />
          </div>
          {!isValid && (
            <div className={`text-danger ${styles.errormessage}`}>
              {errorMsg}
            </div>
          )}
          <button
            type="submit"
            disabled={!isValid}
            className={`btn  ${styles.sendcode}`}
            onClick={HandleSendCode}
          >
            Send Reset Instructions
          </button>
          <div className={styles.returnToLogin}>
            {/* <div className={styles.returnInnerdiv}> */}
            <p>
              Return to
              <Link to="/login" className={styles.loginLink}>
                Login
              </Link>
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
    )}  
    <ToastContainer />
    </>
  );
};
