import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./ChoosePlan.module.scss";
import bgImage from "../../../assets/images/login_bg_image.png";
import taxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../../assets/images/Taxi-sanchalak-left-image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CheckedIcon from "../../../assets/images/check.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { API_GET_CRM_PLANS, API_REGISTER } from "../../../utils/consts/api";
import { useEffect } from "react";
import { BiRupee } from "react-icons/bi";
import Spinner from "../../Dashboard/CommonComp/Spinner/Spinner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ChoosePlanComponent = ({
  handlePlanBackButton,
  registrationData,
  setRegistrationData,
}) => {
  const [modalState, setModalState] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [plansData, setPlansData] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const [loading,setLoading]=useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = () => {
    let vehicleCount = 0;
    let rcFrontCount = 0;
    let rcbackCount = 0;
    const formDataValue = new FormData();
    if (!registrationData.tempVehicleInfo || !registrationData.tempDriverInfo) {
      return;
    }
    registrationData.tempVehicleInfo.forEach((element) => {
      element.rcImageFront["key"] = `${"rcImageFront"}${++rcFrontCount}`;
      element.rcImageBack["key"] = `${"rcImageBack"}${++rcbackCount}`;
      element.vehicleImage1["key"] = `${"vehicleImage"}${++vehicleCount}`;
      element.vehicleImage2["key"] = `${"vehicleImage"}${++vehicleCount}`;
      element.vehicleImage3["key"] = `${"vehicleImage"}${++vehicleCount}`;
      registrationData.vehicleInfo.push({
        vehicleTypeId: element.vehicleTypeId,
        registrationNumber: element.registrationNumber,
        yearOfManufacture: element.yearOfManufacture,
        rcImageFront: element.rcImageFront["key"],
        rcImageBack: element.rcImageBack["key"],
        vehicleImage1: element.vehicleImage1["key"],
        vehicleImage2: element.vehicleImage2["key"],
        vehicleImage3: element.vehicleImage3["key"],
      });
      if (element.rcImageFront.raw !== "") {
        formDataValue.append(
          element.rcImageFront["key"],
          element.rcImageFront.raw,
          element.rcImageFront.raw.name
        );
      } else {
        formDataValue.append(element.rcImageFront["key"], "");
      }
      if (element.rcImageBack !== "") {
        formDataValue.append(
          element.rcImageBack["key"],
          element.rcImageBack.raw,
          element.rcImageBack.raw.name
        );
      } else {
        formDataValue.append(element.rcImageBack["key"], "");
      }
      if (element.vehicleImage1.raw !== "") {
        formDataValue.append(
          element.vehicleImage1["key"],
          element.vehicleImage1.raw,
          element.vehicleImage1.raw.name
        );
      } else {
        formDataValue.append(element.vehicleImage1["key"], "");
      }
      if (element.vehicleImage2.raw !== "") {
        formDataValue.append(
          element.vehicleImage2["key"],
          element.vehicleImage2.raw,
          element.vehicleImage2.raw.name
        );
      } else {
        formDataValue.append(element.vehicleImage2["key"], "");
      }
      if (element.vehicleImage3.raw !== "") {
        formDataValue.append(
          element.vehicleImage3["key"],
          element.vehicleImage3.raw,
          element.vehicleImage3.raw.name
        );
      } else {
        formDataValue.append(element.vehicleImage3["key"], "");
      }
    });
    
    let driverCount = 0;
    let lisenceCount = 0;
    registrationData.tempDriverInfo.forEach((element) => {
      element.licenseImageFront["key"] = `${"licenseImageFront"}${++lisenceCount}`;
      element.licenseImageBack["key"] = `${"licenseImageBack"}${++lisenceCount}`;
      element.driverImage["key"] = `${"driverImage"}${++driverCount}`;
      registrationData.driverInfo.push({
        driverFullName: element.driverFullName,
        driverContactNumber: element.driverContactNumber,
        email: element.email,
        licenseImageKeyFront: element.licenseImageFront["key"],
        licenseImageKeyBack: element.licenseImageBack["key"],
        driverImageKey: element.driverImage["key"],
      });
      if (element.licenseImageFront.raw !== "") {
        formDataValue.append(
          element.licenseImageFront["key"],
          element.licenseImageFront.raw,
          element.licenseImageFront.raw.name
        );
      } else {
        formDataValue.append(element.licenseImageFront["key"], "");
      }
      if (element.licenseImageBack !== "") {
        formDataValue.append(
          element.licenseImageBack["key"],
          element.licenseImageBack.raw,
          element.licenseImageBack.raw.name
        );
      } else {
        formDataValue.append(element.licenseImageBack["key"], "");
      }
      if (element.driverImage.raw !== "") {
        formDataValue.append(
          element.driverImage["key"],
          element.driverImage.raw,
          element.driverImage.raw.name
        );
      } else {
        formDataValue.append(element.driverImage["key"], "");
      }
    });

    formDataValue.append("data", JSON.stringify(registrationData));
    setLoading(true);
    axios
      .post(API_REGISTER, formDataValue, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          setLoading(false);
          errorToastMsg(res.data.errorMsg);
          setRegistrationData({...registrationData,vehicleInfo:[],
          driverInfo:[]});
        } else if (res.data.responseCode === 0) {
          sessionStorage.setItem("userToken", res.data.response.token);
          localStorage.setItem("userToken", res.data.response.token);
          setLoading(false);
          setModalState(true);
          handleOpen();
        }
      });
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const PlansAPIcall = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
    };
    axios(API_GET_CRM_PLANS, { headers }).then((res) => {
      setPlansData(res.data.response.subscriptionList);
      setLoading(false);
    });
  };

  const handlePlanId = (planId) => {
    setRegistrationData({
      ...registrationData,
      planDetails: {
        planId: planId,
      },
    });
    setActiveIndex(planId);
  };

  useEffect(() => {
    PlansAPIcall();
  }, []);

  useEffect(() => {
    plansData?.map((singlePlan) => {
      if (singlePlan.planValue === 0) {
        setActiveIndex(singlePlan.planId);
        handlePlanId(singlePlan.planId);
      }
    });
  }, [plansData]);

  return (
    <>
    {loading ? (<Spinner/>):(
    <div>
      <div className={styles.logincomponent}>
        <div
          className={`${styles.taxiIcon}`}
          style={{ backgroundImage: `url(${taxiIcon})` }}
        ></div>
        {/* <div
          className={`d-none d-md-block ${styles.bgleftimage}`}
          style={{ backgroundImage: `url(${bgImage})` }}
        >
        </div>
        <div className={styles.bgleftimageMediumDiv}>
          <img src={bgImage} className={styles.bgleftimageMedium} />
        </div> */}
        <div className={styles.TaxiCRMImageDiv}>
          <div className={styles.IconAndText}>
            <img src={TaxiIcon} className={styles.TaxiIconImage} />
            <p className={styles.imageText}>CRM Application</p>
          </div>
          <div className={styles.TaxiLeftImage}>
            <img src={TaxiLeftImage} width="100%" />
          </div>
        </div>
        <div className={styles.bgrightloginform}>
          <div className={styles.donotaccount}>
            <p className={styles.alreadyaccount}>
              Already have an account?
              <span className={styles.signin}>
                <Link to="/login">Sign in</Link>
              </span>
            </p>
          </div>
          <div className={styles.registerYourAccount}>
            <h4 className={`mb-8 ${styles.registeraccount}`}>
              Register your account
            </h4>
            <div
              className={`d-flex justify-content-between ${styles.pricingplandiv}`}
            >
              <p className={styles.pricingplan}>
                Select a Pricing plan for your account
              </p>
              <h6 className={styles.step5}>Step 5 of 5</h6>
            </div>
            <div className={styles.pricing}>
              {plansData.map((singlePlan) => {
                return (
                  <div
                    className={styles.plan1}
                    onClick={() => handlePlanId(singlePlan.planId)}
                    style={{
                      backgroundColor:
                        singlePlan.planId === activeIndex ? "#e8f9f4" : null,
                    }}
                  >
                    <div className={styles.pricinglabel}>
                      {singlePlan.planTitle === "free"
                        ? "Trial Plan"
                        : singlePlan.planTitle}
                    </div>
                    <div className={styles.price}>
                      <h6 className={styles.discountprice}>
                        {singlePlan.planValue !== 0 ? (
                          <BiRupee className={styles.rupeeIcon} />
                        ) : (
                          ""
                        )}
                        <sapn className={styles.originalprice}>
                          {" "}
                          {singlePlan.planValue === 0
                            ? "Free"
                            : singlePlan.planValue}
                        </sapn>
                        {singlePlan.planValue !== 0 ? (
                          <span
                            style={{
                              color: "#9299B8",
                              fontWeight: "400",
                              fontSize: "13px",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            Per month{" "}
                          </span>
                        ) : (
                          ""
                        )}
                      </h6>
                    </div>
                    <ul className={styles.features}>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheck} style={{marginRight:'2px'}}/>
                        </span>{" "}
                        {singlePlan.feature1 ? singlePlan.feature1 : 
                        'Manage all your taxi bookings at one place.'}
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheck} style={{marginRight:'2px'}}/>
                        </span>{" "}
                        {singlePlan.feature2 ? singlePlan.feature2 : 
                        'Easily send booking notifications and payment link to customers.'}
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheck} style={{marginRight:'2px'}}/>
                        </span>{" "}
                        {singlePlan.feature3 ? singlePlan.feature3 :
                        'Get notified for future bookings.'}
                      </li>
                      <li>
                        <span>
                          <FontAwesomeIcon icon={faCheck} style={{marginRight:'2px'}}/>
                        </span>{" "}
                        {singlePlan.feature4 ? singlePlan.feature4 :
                         'Manage all your vehicles,drivers and customers at one place.'}
                      </li>
                    </ul>
                    <button
                      className={
                        singlePlan.planId === activeIndex
                          ? styles.activeplan
                          : styles.getstartedIst
                      }
                    >
                      {singlePlan.planValue === 0
                        ? "Current Plan"
                        : "Get Started"}
                    </button>
                  </div>
                );
              })}
              {/* <div className={styles.plan2}>
                <div className={styles.title}>
                  <div className={styles.pricinglabel}>Professional</div>
                  <div className={styles.discountoff}> -40% </div>
                </div>
                <h6 className={styles.discountprice}>
                  <span className={styles.pricestyling}>$99 </span>
                  <sapn className={styles.originalprice}> 59 </sapn>
                  <span
                    style={{
                      color: "#9299B8",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    Per month{" "}
                  </span>
                </h6>
                <ul className={styles.features}>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    Unlimited Websites
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    1 User
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    100MB Space/website
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    Continuous deployment
                  </li>
                </ul>
                <button className={styles.getstarted2nd}> Get Started </button>
              </div>
              <div className={styles.plan3}>
                <div className={styles.title}>
                  <div className={styles.pricinglabel}>Business</div>
                  <div className={styles.discountoff}> -40% </div>
                </div>
                <h6 className={styles.discountprice}>
                  <span className={styles.pricestyling}>$99 </span>
                  <sapn className={styles.originalprice}> 59 </sapn>
                  <span
                    style={{
                      color: "#9299B8",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    {" "}
                    Per month{" "}
                  </span>
                </h6>
                <ul className={styles.features}>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    Unlimited Websites
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    Unlimited Users
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    Unlimited Space/website
                  </li>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={faCheck} />
                    </span>{" "}
                    Continuous deployment
                  </li>
                </ul>
                <button className={styles.getstarted3rd}> Get Started </button>
              </div> */}
            </div>
            <div className={`${styles.buttons}`}>
              <button
                type="button"
                className={`btn btn-outline-secondary ${styles.back}`}
                onClick={handlePlanBackButton}
              >
                Back
              </button>
              <button
                type="submit"
                className={`btn ${styles.submit}`}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      
      {modalState && (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className={styles.popupcontent}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.imgbox}
              >
                <img src={CheckedIcon} alt="" className={styles.CheckedIcon} />
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={styles.sucessmessage}
              >
                Account Created Sucessfully.
              </Typography>
              <Typography className={styles.dashboard}>
                Explore Dashboard.
              </Typography>
              <Box>
                <Link to="/dashboard/mybooking">
                  <button className={styles.button}>Go to Dashboard</button>
                </Link>
              </Box>
            </Box>
          </Modal>
        </div>
      )}
      </div>
      )}
    <ToastContainer />
    </>
  );
};

export default ChoosePlanComponent;
