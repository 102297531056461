import axios from "axios";
import { errorToastMsg } from "../../common/ToastMessage";
import { API_LOGIN_USER } from "../../utils/consts/api";
export const USER_LOGIN = "USER_LOGIN";
export const DELETE_USER = "DELETE_USER";

export const userlogin = (payload) => {
  return {
    type: USER_LOGIN,
    payload,
  };
};

export const deleteUser = () => {
  return {
    type: DELETE_USER,
  };
};

export const userthunk = (loginInputs) => (dispatch) => {
  axios
    .post(API_LOGIN_USER, loginInputs.inputs)
    .then((res) => {
      dispatch(userlogin({userData:res.data,loggedInStatus:loginInputs.checkedValue}));
    })
    .catch((error)=>{
      errorToastMsg(error.message);
    })
};
