import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import {
  errorToastMsg,
  successToastMsg,
} from "../../../../common/ToastMessage";
import {
  API_ADD_AND_ASSIGN,
  API_ADD_DRIVER,
  API_ADD_VEHICLE,
  API_GET_VEHICLE_TYPE,
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
} from "../../../../utils/consts/api";
import { BsPerson, BsXCircle } from "react-icons/bs";
import styles from "./AddDriver.module.scss";
import { ImageCompressorHook } from "../../CommonComp/ImageCompressor/ImageCompressor";

const AddDriverModal = ({
  callbackFunc1,
  callbackFunc,
  getDriverDropdownList,
}) => {
  const [show, setShow] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const isNum = /^[0-9]*$/;
  const {imageCompressorFn,data} = ImageCompressorHook();

  const handleClose = () => {
    setShow(false);
    setIsValid(true);
    setIsPhoneValid(true);
    callbackFunc1(false);
    // callbackFunc(false);
  };

  const addDriverInitialValues = {
    driverFullName: "",
    driverContactNumber: "",
    email: "",
    driverImageKey: "driverImage",
    licenseImageKeyFront: "lisenceFrontImage",
    licenseImageKeyBack: "lisenceBackImage",
  };

  const [addDriverFormValues, setDriverFormValues] = useState(
    addDriverInitialValues
  );

  const [driverImages, setDriverImages] = useState({
    driverImage: { preview: "", raw: "", value: "" },
    lisenceFrontImage: { preview: "", raw: "", value: "" },
    lisenceBackImage: { preview: "", raw: "", value: "" },
  });

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const handleDriverInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "driverContactNumber") {
      if (isNum.test(value) && value.length <= 10) {
        setDriverFormValues({
          ...addDriverFormValues,
          [name]: value,
        });
      } else {
        setDriverFormValues({
          ...addDriverFormValues,
          [name]: value.slice(0, -1),
        });
      }
    } else if (name === "driverFullName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setDriverFormValues({
          ...addDriverFormValues,
          [name]: value,
        });
      } else {
        setDriverFormValues({
          ...addDriverFormValues,
          [name]: value.slice(0, -1),
        });
      }
    } else if (name === "email"){
      if (value !== " ") {
        setDriverFormValues({
          ...addDriverFormValues,
          [name]: value.toLowerCase(),
        });
      } else {
        setDriverFormValues({
          ...addDriverFormValues,
          [name]: value.slice(0, -1),
        });
      }
    }
    else {
      setDriverFormValues({
        ...addDriverFormValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleAddDriver = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify(addDriverFormValues));

    if (!driverImages.driverImage.raw) {
      errorToastMsg("Please Upload the Profile-Picture");
      return;
    } else {
      formData.append(
        "driverImage",
        driverImages.driverImage.raw,
        driverImages.driverImage.raw.name
      );
    }

    if (!driverImages.lisenceFrontImage.raw) {
      errorToastMsg("Please upload the Lisence front image");
      return;
    } else {
      formData.append(
        "lisenceFrontImage",
        driverImages.lisenceFrontImage.raw,
        driverImages.lisenceFrontImage.raw.name
      );
    }

    if (!driverImages.lisenceBackImage.raw) {
      errorToastMsg("Please upload the Lisence back image");
      return;
    } else {
      formData.append(
        "lisenceBackImage",
        driverImages.lisenceBackImage.raw,
        driverImages.lisenceBackImage.raw.name
      );
    }

    axios
      .post(API_ADD_DRIVER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          handleClose(false);
          getDriverDropdownList();
          setDriverImages({
            driverImage: { preview: "", raw: "" },
            lisenceFrontImage: { preview: "", raw: "" },
            lisenceBackImage: { preview: "", raw: "" },
          });
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleDriverImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image,e.target.name);
      }
      setDriverImages({
        ...driverImages,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const validateEmail = () => {
    const { email } = addDriverFormValues;
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsValid(false);
        setErrorMsg("Invalid Email Address.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    const { driverContactNumber } = addDriverFormValues;
    if (driverContactNumber) {
      if (!PHONE_REGEX.test(driverContactNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const handleAddDriverCrosss = (e) => {
    e.preventDefault();
    setDriverImages({
      ...driverImages,
      driverImage: { raw: "", preview: "", value: "" },
    });
  };

  const handleAddLisenceFrontCrosss = (e) => {
    e.preventDefault();
    setDriverImages({
      ...driverImages,
      lisenceFrontImage: { raw: "", preview: "", value: "" },
    });
  };

  const handleAddLisenceBackCrosss = (e) => {
    e.preventDefault();
    setDriverImages({
      ...driverImages,
      lisenceBackImage: { raw: "", preview: "", value: "" },
    });
  };

  useEffect(()=>{
      if(data.image){
        if(data.imageName){
          setDriverImages({
            ...driverImages,
            [data.imageName]:{
              preview: URL.createObjectURL(data.image),
              raw: data.image
            }
          });
        }else{
          setDriverImages({
            ...driverImages,
              preview: URL.createObjectURL(data.image),
              raw: data.image
          });
        }
      }
  },[data]);

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className={styles.mainModal}
        scrollable
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Add Driver</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          <div>
            <form onSubmit={handleAddDriver} autocomplete="off">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold`}
                >
                  Driver Name
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Driver Name"
                  name="driverFullName"
                  required
                  value={addDriverFormValues.driverFullName}
                  onChange={handleDriverInput}
                />
              </div>
              <div className="d-flex align-items-center gap-4">
                {driverImages.driverImage.preview ? (
                  <div>
                    <div
                      className={`cross ${styles.crossSign}`}
                      onClick={handleAddDriverCrosss}
                    >
                      <BsXCircle
                        color="#D7E0EE"
                        style={{
                          backgroundColor: "#272B41",
                          borderRadius: "50%",
                          position: "absolute",
                          top: 2,
                          right: 1,
                        }}
                      />
                    </div>
                    <img
                      src={driverImages.driverImage.preview}
                      alt="driverImage"
                      className={styles.profileIconImage}
                    />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <BsPerson className={styles.driverProfileIcon} />
                  </div>
                )}
                <div style={{ width:'50%' }}>
                  <label htmlFor="file-input" className={styles.uploadImage}>
                    {driverImages.driverImage.preview
                      ? driverImages.driverImage.raw.name
                      : "Upload Picture "}
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    className="d-none"
                    name="driverImage"
                    value={driverImages.driverImage.value}
                    onChange={handleDriverImages}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold`}
                >
                  Mobile Number
                </label>
                <input
                  type="number"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Mobile Number"
                  name="driverContactNumber"
                  required
                  onBlur={validatePhoneNumber}
                  value={addDriverFormValues.driverContactNumber}
                  onChange={handleDriverInput}
                />
              </div>
              {!isPhoneValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "3px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {phoneErrorMsg}
                </div>
              )}
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold`}
                >
                  Email (Optional)
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Email"
                  name="email"
                  value={addDriverFormValues.email}
                  onBlur={validateEmail}
                  onChange={handleDriverInput}
                />
              </div>
              {!isValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "7px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {errorMsg}
                </div>
              )}
              <div className={`form-label fw-bold ${styles.labelFont}`}>
                <p className={styles.labelUploadFont}>
                  {" "}
                  Upload Lisence Images{" "}
                </p>
                <div className="d-flex gap-4">
                  <div className={styles.uploadLisence}>
                    {" "}
                    <label
                      for="file-input-lisence-front"
                      className="d-flex align-items-center "
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {driverImages.lisenceFrontImage.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleAddLisenceFrontCrosss}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={driverImages.lisenceFrontImage.preview}
                                alt="lisenceFrontImage"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          )}
                          {!driverImages.lisenceFrontImage.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload Lisence Front
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-lisence-front"
                          className="d-none"
                          name="lisenceFrontImage"
                          value={driverImages.lisenceFrontImage.value}
                          accept="image/*"
                          onChange={handleDriverImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadLisence}>
                    {" "}
                    <label
                      for="file-input-lisence-back"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {driverImages.lisenceBackImage.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleAddLisenceBackCrosss}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={driverImages.lisenceBackImage.preview}
                                alt="lisenceBackImage"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          )}
                          {!driverImages.lisenceBackImage.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload Lisence Back
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-lisence-back"
                          className="d-none"
                          name="lisenceBackImage"
                          value={driverImages.lisenceBackImage.value}
                          accept="image/*"
                          onChange={handleDriverImages}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-start mx-3 mb-3 gap-2 ${styles.buttonDiv}`}
              >
                <button
                  type="submit"
                  className={styles.addAssignButton}
                  disabled={!isPhoneValid || !isValid}
                >
                  Add Driver
                </button>
                <Button
                  variant="light"
                  onClick={handleClose}
                  className={styles.cancelButton}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDriverModal;
