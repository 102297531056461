import React from "react";
import { useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { Navbar } from "../../Navbar/Navbar";
import MobileSideBar from "../Sidebar/MobileSideBar";

export const PopOver = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div>
        <Navbar handleShow={handleShow} />
      </div>
      <div>
        <div className="container">
          <Offcanvas
            show={show}
            onHide={handleClose}
            style={{
              width: "65%",
              // height: "100%",
            }}
          >
            <Offcanvas.Header>
              {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
              <div style={{ margin: "15px 10px 0px 15px" }}>
                <img src="/taxi-sanchalak-logo.png" alt="taxi-sanchalak-logo" />
              </div>
            </Offcanvas.Header>

            <Offcanvas.Body>
              <MobileSideBar />
            </Offcanvas.Body>
          </Offcanvas>
        </div>
      </div>
    </div>
  );
};
