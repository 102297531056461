import { Popover } from "react-bootstrap";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import moment from "moment";
import {
  API_GET_NOTIFICATIONS,
  API_ISNOTIFICATIONREAD,
} from "../../utils/consts/api";
import { errorToastMsg } from "../../common/ToastMessage";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import bellicon from "../../assets/images/Bell-icon.png";
import styles from "./notificationPopUp.module.scss";
import { Link } from "react-router-dom";
import { thunkDispatchNotification } from "../../redux/action/notification.action";
import { useDispatch, useSelector } from "react-redux";
import Profile_Icon from "../../assets/images/driver_placeholder.jpg";
import { BsDot } from "react-icons/bs";

function NotificationPopUp() {
  const [isClose, setIsClose] = useState(false);
  const [newNotificationState, setNewNotificationState] = useState(false);
  const dispatch = useDispatch();

  const reduxNotifications = useSelector(
    (state) => state.notificationReducer.notificationData
  );

  let pageInfo = {
    page: 0,
    size: 100,
  };

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const crmAgentId =
    localStorage.getItem("crmAgentId") || sessionStorage.getItem("crmAgentId");

  const notificationsArrayOfFive = reduxNotifications.slice(0, 5);

  const checkNewNotification = () => {
    reduxNotifications.map((singleNotification) => {
      if (singleNotification.readFlag === 0) {
        setNewNotificationState(true);
        return;
      }
    });
  };

  const handleNotification = () => {
    if(!isClose){
      axios
        .post(API_ISNOTIFICATIONREAD,{crmAgentId: crmAgentId}, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.responseCode === 0) {
          } else {
            errorToastMsg(res.data.errorMsg);
          }
        })
        .catch((err) => {
          errorToastMsg(err.message);
        });
    }else{
      dispatch(thunkDispatchNotification());
    }
    setIsClose(!isClose);
    setNewNotificationState(false);
  };

  const getAllNotifications = () => {
    dispatch(thunkDispatchNotification());
  };

  useEffect(() => {
    getAllNotifications();
  }, []);

  useEffect(()=>{
    checkNewNotification();
  },[reduxNotifications]);

  const OverlayTriggerClose = ()=>{
    dispatch(thunkDispatchNotification());
  }

  return (
    <div className={styles.popUpScroller} onClick={handleNotification}>
      <OverlayTrigger
        rootClose={true}
        onExit={OverlayTriggerClose}
        className={styles.popUpOuterDiv}
        trigger="click"
        defaultShow={isClose}
        placement="bottom-end"
        overlay={
          <Popover style={{ border: "none" }}>
            <Popover.Body
              onClick={() => handleNotification}
              className={styles.popOverStyle}
            >
              <ListGroup className={styles.ListGroup}>
                {notificationsArrayOfFive.map((noti) => {
                  return (
                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-start border1"
                      key={noti.notificationId}
                    >
                      <div className="profileImageDiv">
                        <img
                          className="profileImage"
                          src={
                            noti.fromUserImage
                              ? noti.fromUserImage
                              : Profile_Icon
                          }
                          alt="N/A"
                        />
                      </div>
                      <div className="ms-2 me-auto infoDiv">
                        <div>
                          <strong className="orangeText">
                            {noti.fromUserName}{" "}
                          </strong>
                          {noti.message}.
                        </div>
                        <div className="TimeStamp">
                          {moment(noti.createTs).fromNow()}
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
              <div className={styles.buttonArea}>
                {reduxNotifications.length === 0 ? (
                  <h2 className={styles.nothingToShow}>Nothing to show</h2>
                ) : (
                  <Link className="navbar-brand" to="/dashboard/notification">
                    <button
                      className={styles.viewNotificationbutton}
                      onClick={() => setIsClose(true)}
                    >
                      View All Notifications
                    </button>
                  </Link>
                )}
              </div>
            </Popover.Body>
            {/* <Popover.Body className={styles.modalcancle}>Cancel Booking</Popover.Body> */}
          </Popover>
        }
      >
        <Link className="navbar-brand">
          {newNotificationState ? (
            <div className={styles.bellIconDiv}>
              <BsDot style={{ color: "red" }} size={19} />
            </div>
          ) : null}
          <div className={styles.bellIconNotification}>
            <img
              src={bellicon}
              alt="Logo"
              width="20"
              height="20"
              className={`d-inline-block align-text-top ${styles.bellicon}`}
            />
          </div>
        </Link>
      </OverlayTrigger>
    </div>
  );
}

export default NotificationPopUp;
