import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
// import session from "redux-persist/lib/storage/session";
import { errorToastMsg, successToastMsg } from "../../../../common/ToastMessage";
import { API_IS_BOOKING_COMPLETE } from "../../../../utils/consts/api";
import styles from "./MarkBookingCompleteModal.module.scss";
import { IS_NUMBER_REGEX } from "../../../../utils/consts/api";

const MarkBookigCompleteModal = ({ callbackFunc, crmBookingId, gettingDataFun, sucessfullCallbackFunction,tripCompletedStatus }) => {

    const [marginEarnedAmount, setMarginEarnedAmount] = useState();
    const [show, setShow] = useState(true);
    const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

    const handleClose = () => {
        setShow(false);
        callbackFunc(false);
    };

    const handlePaymentMode = (isBookingCompleteStatus) => {
        if(!tripCompletedStatus){
            if(isBookingCompleteStatus === 1){
                if (!marginEarnedAmount) {
                    errorToastMsg("Please fill the Margin amount first");
                    return;
                };
            };
        }
        axios
            .post(
                API_IS_BOOKING_COMPLETE,
                {
                    crmBookingId,
                    paymentReceivedStatus:isBookingCompleteStatus,
                    marginEarned: +marginEarnedAmount
                },
                {
                    headers: {
                    
                        Authorization: token,
                    },
                }
            )
            .then((res) => {
                if (res.data.responseCode === -1) {
                    errorToastMsg(res.data.errorMsg);
                } else if (res.data.responseCode === 0) {
                    sucessfullCallbackFunction();
                    successToastMsg(res.data.successMsg);
                }
            }).catch((error) => {
                errorToastMsg(error.message);
            });
            handleClose();
    };

    const handleMarginEarned = (e) => {
        const { name,value } = e.target;
        if(IS_NUMBER_REGEX.test(value)){
            setMarginEarnedAmount(value);
        }else{
            setMarginEarnedAmount(value.slice(0,-1));
        }
    };

    return (
        <>
            {/* Modal Related to the Margin Earned Method. */}
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
            >
                <div className={styles.modalDiv}>
                    <div className={styles.modalTitle}>Mark Booking As Complete</div>
                    <div className={styles.modalCross} onClick={handleClose}>
                        x
                    </div>
                </div>
                <hr className="m-0" />
                <Modal.Body>
                    {/* <div className={styles.gridModal}> */}
                    <div className="mb-3">
                        <label
                            htmlFor="exampleFormControlInput1"
                            className={`form-label fw-bold`}
                        >
                            Total Margin Earned
                        </label>
                        <input
                            type="number"
                            className="form-control"
                            id="exampleFormControlInput1"
                            placeholder="Enter margin amount"
                            value={marginEarnedAmount}
                            onChange={handleMarginEarned}
                            name="marginEarnedAmount"
                        />
                    </div>
                    <div
                        className={`d-flex justify-content-start mx-3 mb-3 gap-2 ${styles.buttonDiv}`}
                    >
                        <Button
                            variant="tranparent"
                            onClick={() => handlePaymentMode(1)}
                            className={`${styles.sendLink} border`}
                        >
                            Completed
                        </Button>

                        <Button
                            variant="light"
                            onClick={() => handlePaymentMode(0)}
                            className={`${styles.sendCash} border`}
                        >
                            Not Complete
                        </Button>

                        <Button
                            variant="light"
                            onClick={() => handleClose()}
                            className="border"
                        >
                            Cancel
                        </Button>
                    </div>
                    {/* </div> */}
                </Modal.Body>
            </Modal>    
        </>
    );
};
export default MarkBookigCompleteModal;

