import logo from "./logo.svg";
import "./App.css";
import { useRoutes, useLocation } from "react-router-dom";
import { AllRoutes } from "./routing/index";

// import { Navbar } from "./components/Navbar/Navbar";

function App() {
  // const location = useLocation();
  // const { pathname } = location;

  // const noNav = ["/login", "/register", "/login/forgot", "/", "*"];

  return (
    <div className="App">
      {/* {noNav.includes(pathname) ? null : <Navbar />} */}
      <AllRoutes/>
    </div>
  );
}

export default App;
