import React from "react";
import { useState } from "react";
import styles from "./index.module.scss";
// import bgImage from "../../assets/images/login_bg_image.png";
import taxiIcon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiIcon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../assets/images/Taxi-sanchalak-left-image.png";
import {
  ALPHANUMERICREGEX,
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
} from "../../utils/consts/api";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import AddVechileComponent from "./AddVechiles/addvechile";
import { Link } from "react-router-dom";
import { IS_NUMBER_REGEX } from "../../utils/consts/api";

const RegistrationComponent = () => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [addToVechileState, setAddToVechileState] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showPassword2, setshowPassword2] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);

  const [registrationData, setRegistrationData] = useState({
    fullName: "",
    companyName: "",
    email: "",
    mobileNumber: "",
    password: "",
    confirmPassword: "",
    tempVehicleInfo: [],
    vehicleInfo: [],
    tempDriverInfo: [],
    vehicleInfo: [],
    driverInfo: [],
    reviewLinks: [],
    planDetails: {
      planId: "",
    },
  });

  const {
    email,
    mobileNumber,
    fullName,
    companyName,
    password,
    confirmPassword,
  } = registrationData;

  const validateEmail = () => {
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsEmailValid(false);
        setEmailErrorMsg("Invalid Email Address.");
      } else {
        setIsEmailValid(true);
        setEmailErrorMsg("");
      }
    } else {
      setIsEmailValid(true);
      setEmailErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    if (mobileNumber) {
      if (!PHONE_REGEX.test(mobileNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobileNumber") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 10) {
        setRegistrationData({
          ...registrationData,
          [name]: value,
        });
      } else {
        setRegistrationData({
          ...registrationData,
          [name]: value.slice(0, -1),
        });
      }
    } else if (name === "companyName") {
      if (ALPHANUMERICREGEX.test(value) && value !== " ") {
        setRegistrationData({
          ...registrationData,
          [name]: value,
        });
      } else {
        setRegistrationData({
          ...registrationData,
          [name]: value.slice(0, -1),
        });
      }
    } else if (name === "fullName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setRegistrationData({
          ...registrationData,
          [name]: value,
        });
      } else {
        setRegistrationData({
          ...registrationData,
          [name]: value.slice(0, -1),
        });
      }
    } else {
      setRegistrationData({
        ...registrationData,
        [name]: value,
      });
    }
  };

  const handleAddVechileBackButton = () => {
    setAddToVechileState(false);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();

    registrationData.email = registrationData.email.toLowerCase();
    if (registrationData.password !== registrationData.confirmPassword) {
      errorToastMsg("Password and Confirm Password are not matching");
      return;
    }
    if (isEmailValid && isPhoneValid) {
      setAddToVechileState(true);
    }
  };

  const handlePassword = () => {
    setshowPassword(!showPassword);
  };

  const handlePassword2 = () => {
    setshowPassword2(!showPassword2);
  };

  // {--------> Toastify Messsage Starts Here ----------> }

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // {--------> Toastify Messsage Ends Here ----------> }

  const handleCheckedFunction = (e) => {
    if (e.target.checked) {
      setCheckedValue(true);
    } else {
      setCheckedValue(false);
    }
  };

  const password_validate = () => {
    const password_Checker = {
      capital: /[A-Z]/,
      digit: /[0-9]/,
      full: /^[@#][A-Za-z0-9]{7,13}$/,
    };

    if (password) {
      if (!password_Checker.capital.test(password)) {
        setIsPasswordValid(false);
        setPasswordErrorMsg(
          "Password should have atlest one capital character"
        );
      } else if (!password_Checker.digit.test(password)) {
        setIsPasswordValid(false);
        setPasswordErrorMsg("Password should have atleat one digit");
      } else if (password.length < 7) {
        setIsPasswordValid(false);
        setPasswordErrorMsg("Password length should be greater than 7");
      } else {
        setIsPasswordValid(true);
        setPasswordErrorMsg("");
      }
    } else {
      setIsPasswordValid(true);
      setPasswordErrorMsg("");
    }
  };

  return (
    <>
      {!addToVechileState && (
        <div className={styles.registrationcomponent}>
          <div
            className={`${styles.taxiIcon}`}
            style={{ backgroundImage: `url(${taxiIcon})` }}
          ></div>
          <div className={styles.TaxiCRMImageDiv}>
            <div className={styles.IconAndText}>
              <img src={TaxiIcon} className={styles.TaxiIconImage} alt="Icon" />
              <p className={styles.imageText}>CRM Application</p>
            </div>
            <div className={styles.TaxiLeftImage}>
              <img src={TaxiLeftImage} width="100%" alt="" />
            </div>
          </div>
          {/* <div
            className={`d-none d-md-block ${styles.bgleftimage}`}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
          </div>
          <div className={styles.bgleftimageMediumDiv}>
            <img src={bgImage} className={styles.bgleftimageMedium} />
          </div> */}
          <div className={styles.bgrightloginform}>
            <div className={styles.donotaccount}>
              <p>
                Already have an account?
                <span className={styles.registernow}>
                  <Link to="/login">Sign in</Link>
                </span>
              </p>
            </div>
            <form autocomplete="off"
              className={`row g-4 ${styles.registrationForm}`}
              onSubmit={handleFormSubmission}
            >
              <h3 className={styles.registeraccount}>Register your account</h3>
              <div
                className={`d-flex justify-content-between ${styles.tellbusinessdiv}`}
              >
                <p
                  className={`fw-semibold text-black-50 ${styles.tellbusiness}`}
                >
                  Tell us bit about your business
                </p>
                <h6 className={styles.step1}>Step 1 of 5</h6>
              </div>
              <div className={`${styles.inputbox}`}>
                <label
                  for="inputEmail4"
                  className={`form-label ${styles.inputlabel}`}
                >
                  Name
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.input}`}
                  id="inputEmail4"
                  placeholder="Full Name"
                  value={fullName}
                  required
                  name="fullName"
                  onChange={handleChange}
                />
              </div>
              <div className={`${styles.inputbox}`}>
                <label
                  for="inputPassword4"
                  className={`form-label ${styles.inputlabel}`}
                >
                  Company Name (Optional)
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.input}`}
                  id="inputPassword4"
                  placeholder="Company Name (Optional)"
                  value={companyName}
                  name="companyName"
                  onChange={handleChange}
                />
              </div>
              <div className={`${styles.inputbox}`}>
                <label
                  for="inputAddress"
                  className={`form-label ${styles.inputlabel}`}
                >
                  Official Email Address
                </label>
                <input
                  type="text"
                  className={`form-control ${styles.input}`}
                  id="inputAddress"
                  placeholder="name@example.com"
                  value={email}
                  required
                  onBlur={validateEmail}
                  name="email"
                  onChange={handleChange}
                />
                {!isEmailValid && (
                  <div style={{marginTop:'5px',marginBottom:'-10px'}} className={`text-danger ${styles.errormessage}`}>
                    {emailErrorMsg}
                  </div>
                )}
              </div>
              <div className={`${styles.inputbox}`}>
                <label
                  for="inputAddress2"
                  className={`form-label ${styles.inputlabel}`}
                >
                  Phone Number
                </label>
                <input
                  type="number"
                  className={`form-control ${styles.input}`}
                  id="inputAddress2"
                  placeholder="+91 123 1232 123"
                  value={mobileNumber}
                  required
                  onBlur={validatePhoneNumber}
                  name="mobileNumber"
                  onChange={handleChange}
                />
                {!isPhoneValid && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    {phoneErrorMsg}
                  </div>
                )}
              </div>
              <div className={`${styles.inputbox}`}>
                <label
                  for="inputCity"
                  className={`form-label ${styles.inputlabel}`}
                >
                  Password
                </label>
                <div style={{ display: "flex" }}>
                  <input
                    type={showPassword ? "text" : "password"}
                    className={`form-control ${styles.input}`}
                    id="inputCity"
                    placeholder="Password"
                    value={password}
                    required
                    name="password"
                    onBlur={password_validate}
                    onChange={handleChange}
                  />
                  {!showPassword && (
                    <span className={`input-group-text ${styles.eyeStyle}`}>
                      <i
                        className="far fa-eye-slash"
                        onClick={handlePassword}
                      ></i>
                    </span>
                  )}
                  {showPassword && (
                    <span className={`input-group-text ${styles.eyeStyle}`}>
                      <i className="far fa-eye" onClick={handlePassword}></i>
                    </span>
                  )}
                </div>
              </div>
              <div className={`${styles.inputbox}`}>
                <label
                  for="inputZip"
                  className={`form-label ${styles.inputlabel}`}
                >
                  Confirm Password
                </label>
                <div style={{ display: "flex" }}>
                  <input
                    type={showPassword2 ? "text" : "password"}
                    className={`form-control ${styles.input}`}
                    id="inputZip"
                    placeholder="Password"
                    value={confirmPassword}
                    required
                    name="confirmPassword"
                    onChange={handleChange}
                  />
                  {!showPassword2 && (
                    <span className={`input-group-text ${styles.eyeStyle}`}>
                      <i
                        className="far fa-eye-slash"
                        onClick={handlePassword2}
                      ></i>
                    </span>
                  )}
                  {showPassword2 && (
                    <span className={`input-group-text ${styles.eyeStyle}`}>
                      <i className="far fa-eye" onClick={handlePassword2}></i>
                    </span>
                  )}
                </div>
              </div>
              {!isPasswordValid && (
                <div className={`text-danger ${styles.errormessage}`}>
                  {passwordErrorMsg}
                </div>
              )}
              <div className={`col-12 ${styles.termpolicydiv}`}>
                <div className={`form-check ${styles.termpolicy}`}>
                  <input
                    className={`form-check-input`}
                    onChange={(e) => handleCheckedFunction(e)}
                    type="checkbox"
                    id="gridCheck"
                  />
                  <label
                    className={`form-check-label ${styles.termpolicylabel}`}
                    for="gridCheck"
                  >
                    Creating an account means you're okay with our
                    <span className={`form control ${styles.termpolicyspan}`}>
                      Terms of Service
                    </span>
                    and{" "}
                    <span
                      style={{ paddingLeft: "0px" }}
                      className={`form control ${styles.termpolicyspan}`}
                    >
                      Privacy Policy
                    </span>
                  </label>
                </div>
              </div>
              <div className={`col-12 ${styles.continue}`}>
                <button
                  type="submit"
                  className={`btn ${styles.continuebutton}`}
                  disabled={
                    !checkedValue ||
                    !isPasswordValid ||
                    !isEmailValid ||
                    !isPhoneValid
                  }
                >
                  Continue
                </button>
              </div>
            </form>
            <ToastContainer />
          </div>
        </div>
      )}
      {addToVechileState && (
        <AddVechileComponent
          registrationData={registrationData}
          setRegistrationData={setRegistrationData}
          handleAddVechileBackButton={handleAddVechileBackButton}
        />
      )}
    </>
  );
};

export default RegistrationComponent;
