import React, { useEffect } from "react";
// import { Link } from "react-router-dom";
import styles from "./bankaccount.module.scss";
import { FiUpload } from "react-icons/fi";
// import bgImage from "../../../assets/images/login_bg_image.png";
import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { errorToastMsg, successToastMsg } from "./ToastMessage";
import BankDetails from "./bank/BankDetails";
import UpiDetails from "./upi/UpiDetails";
import { FiEdit } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import { ModelTraining } from "@mui/icons-material";
import {
  RiCheckboxBlankCircleLine,
  RiQrCodeFill,
  RiRecordCircleFill,
} from "react-icons/ri";
import {
  ALPHABET_ONLY_REGEX,
  ALPHANUMERICREGEX,
  API_GET_PAYMENT_DETAILS,
  API_UPDATE_BANK_DETAILS,
  API_UPDATE_UPI_DETAILS,
  NAME_REGEX,
  UPI_ID_REGEX,
  WHITE_SPACES_REGEX,
} from "../../../utils/consts/api";
import { BsXCircle } from "react-icons/bs";
import Spinner from "../CommonComp/Spinner/Spinner";
import { ImageCompressorHook } from "../CommonComp/ImageCompressor/ImageCompressor";

const BankAccountDetails = () => {
  const [isShow, setIsshow] = useState(false);
  let initialBankValues = {
    accountNumber: "",
    confirmAccountNumber: "",
    bankName: "",
    bankIFSCCode: "",
    accountHolderName: "",
  };
  let initialUpiValues = {
    upiId: "",
    upiImage: "upiImage",
    flag: "false",
  };

  const isNum = /^[0-9]*$/;
  const [isUpiIDValid, setIsUpiIDValid] = useState(true);
  const [upiErrorMsg, setUpiErrorMsg] = useState("");
  const [isUpdateUpiIDValid, setIsUpdateUpiIDValid] = useState(true);
  const [UpdateUpiErrorMsg, setUpdateUpiErrorMsg] = useState("");

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  let authToken = {
    headers: {
      Authorization: `${token}`,
    },
  };
  const [accountDetails, setAccountDetails] = useState(initialBankValues);
  const [upiDetails, setupiDetails] = useState(initialUpiValues);
  const [upiImage, setUpiImage] = useState({ preview: "", raw: "", value: "" });
  const [fetchedBankData, setFetchedBankData] = useState({});
  const [fetchedUpiData, setFetchedUpiData] = useState({
    upiId: "",
    upiQrCode: "",
    flag: "false",
    upiImage: "upiImage",
  });
  const [isFetchedBankData, setIsFetchedBankData] = useState(false);
  const [isFetchedUpiData, setIsFetchedUpiData] = useState(false);
  const [updateModelBank, setUpdateModelBank] = useState(false);
  const [updateModelUpi, setUpdateModelUpi] = useState(false);
  const [UpdateBank, setUpdateBank] = useState({});
  const [UpdateUpi, setUpdateUpi] = useState({});
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateUPIimageState, setUpdateUPIimageState] = useState(false);
  const {imageCompressorFn,data} = ImageCompressorHook();

  const handleUpdateModelBankShow = (obj) => {
    setUpdateModelBank(true);
    setUpdateBank(obj);
  };
  const handleUpdateModelUpiShow = (obj) => {
    setUpdateModelUpi(true);
    setUpdateUpi(obj);
  };

  const handleUpdateModelBankClose = () => {
    setUpdateModelBank(false);
    setUpdateBank({});
    setUpiImage({ raw: "", preview: "" });
  };

  const handleUpdateModelUpiClose = () => {
    setUpdateModelUpi(false);
    setUpdateUpi({});
    setUpiImage({ preview: "", raw: "" });
    setUpdateUPIimageState(false);
    setIsUpdateUpiIDValid(true);
    setUpdateUpiErrorMsg("");
  };

  const getAllData = () => {
    setLoading(true);
    axios
      .get(API_GET_PAYMENT_DETAILS, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.response.bankDetails.length > 0) {
          setFetchedBankData(res.data.response.bankDetails[0]);
          setIsFetchedBankData(true);
        }
        if (res.data.response.upiDetails.length > 0) {
          setFetchedUpiData(res.data.response.upiDetails[0]);
          setIsFetchedUpiData(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "accountNumber" || name === "confirmAccountNumber") {
      if (isNum.test(value) && value.length <= 16) {
        setAccountDetails({
          ...accountDetails,
          [e.target.name]: e.target.value,
        });
      } else {
        setAccountDetails({ ...accountDetails, name: value.slice(0, -1) });
      }
      return;
    } else if (name === "bankName") {
      if (NAME_REGEX.test(value)) {
        setAccountDetails({
          ...accountDetails,
          [e.target.name]: e.target.value,
        });
      } else {
        setAccountDetails({ ...accountDetails, [name]: value.slice(0, -1) });
      }
    } else if (name === "bankIFSCCode") {
      if (ALPHANUMERICREGEX.test(value) && value.length <= 11) {
        setAccountDetails({
          ...accountDetails,
          [e.target.name]: e.target.value,
        });
      } else {
        setAccountDetails({ ...accountDetails, [name]: value.slice(0, -1) });
      }
    } else if (name === "accountHolderName") {
      if (NAME_REGEX.test(value)) {
        setAccountDetails({
          ...accountDetails,
          [e.target.name]: e.target.value,
        });
      } else {
        setAccountDetails({ ...accountDetails, [name]: value.slice(0, -1) });
      }
    } else {
      setAccountDetails({ ...accountDetails, [e.target.name]: e.target.value });
    }
  };

  const handleUpdate = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "accountNumber" || name === "confirmAccountNumber") {
      if (isNum.test(value) && value.length <= 16) {
        setUpdateBank({ ...UpdateBank, [e.target.name]: e.target.value });
      } else {
        setUpdateBank({ ...UpdateBank, [name]: value.slice(0, -1) });
      }
    } else if (name === "bankName") {
      if (NAME_REGEX.test(value)) {
        setUpdateBank({ ...UpdateBank, [e.target.name]: e.target.value });
      } else {
        setUpdateBank({ ...UpdateBank, [name]: value.slice(0, -1) });
      }
    } else if (name === "bankIFSCCode") {
      if (ALPHANUMERICREGEX.test(value)&& value.length <= 11) {
        setUpdateBank({ ...UpdateBank, [e.target.name]: e.target.value });
      } else {
        setUpdateBank({ ...UpdateBank, [name]: value.slice(0, -1) });
      }
    } else if (name === "accountHolderName") {
      if (NAME_REGEX.test(value)) {
        setUpdateBank({ ...UpdateBank, [e.target.name]: e.target.value });
      } else {
        setUpdateBank({ ...UpdateBank, [name]: value.slice(0, -1) });
      }
    } else {
      setUpdateBank({ ...UpdateBank, [e.target.name]: e.target.value });
    }
  };

  const handleUPIInput = (e) => {
    e.persist();
    setupiDetails({ ...upiDetails, [e.target.name]: e.target.value });
  };

  const handleUpiUpdate = (e) => {
    e.persist();
    setUpdateUpi({ ...UpdateUpi, [e.target.name]: e.target.value });
  };

  const handleUpdateImage = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image);
      }
      setUpdateUpi({ ...UpdateUpi, flag: "true" });
    }
  };

  const handleImage = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image);
      }
      setupiDetails({ ...upiDetails, flag: "true" });
    }
  };

  const handleRadio = () => {
    setIsshow(!isShow);
    isShow ? setIsshow(false) : setIsshow(true);
  };

  const handleBankDetailsSubmit = (event) => {
    event.preventDefault();
    if (!UpdateBank.accountNumber) {
      errorToastMsg("Please enter bank account number");
      return;
    }

    if (!UpdateBank.confirmAccountNumber) {
      errorToastMsg("Please enter Confirm bank account number");
      return;
    }

    if (!UpdateBank.bankName || UpdateBank.bankName.match(WHITE_SPACES_REGEX)) {
      errorToastMsg("Please enter your bank name");
      return;
    }

    if (!UpdateBank.bankIFSCCode || UpdateBank.bankIFSCCode.match(WHITE_SPACES_REGEX) ) {
      errorToastMsg("Please enter IFSC code");
      return;
    }

    if (!UpdateBank.accountHolderName ||
       UpdateBank.accountHolderName.match(WHITE_SPACES_REGEX) ) {
      errorToastMsg("Please enter Bank account holder name");
      return;
    }

    if (UpdateBank.accountNumber !== UpdateBank.confirmAccountNumber) {
      errorToastMsg(
        "Account number and Confirm account number is not matching"
      );
      return;
    }
    setLoading(true);
    axios
      .post(API_UPDATE_BANK_DETAILS, UpdateBank, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
          getAllData();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
    handleUpdateModelBankClose();
    setAccountDetails(initialBankValues);
  };

  const handleUpdateUpi = (e) => {
    e.preventDefault();
    
    if (!UpdateUpi.upiId) {
      errorToastMsg("Please Upload the UPI ID.");
      return;
    }

    if(!UpdateUpi?.upiQrCode && !upiImage.raw){
      errorToastMsg("Please upload the QR code");
      return;
    }
    
    if(updateUPIimageState){
      if(!upiImage.raw){
          errorToastMsg("Please upload the QR code");
          return;
       };
    }
    delete UpdateUpi.upiQrCode;
    const formData = new FormData();
    formData.append("data", JSON.stringify(UpdateUpi));

    if (upiImage.raw) {
      formData.append("upiImage", upiImage.raw, upiImage.raw.name);
    }
    setLoading(true);
    axios
      .post(API_UPDATE_UPI_DETAILS, formData, {
        headers: {
          // "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
          handleUpdateModelUpiClose();
        } else {
          successToastMsg(res.data.successMsg);
          handleUpdateModelUpiClose();
          getAllData();
        }
        setupiDetails(initialUpiValues);
        setUpdateModelUpi(false);
        setUpiImage({ preview: "", raw: "" });
      })
      .catch((err) => {
        errorToastMsg(err.message);
        handleUpdateModelUpiClose();
        setLoading(false);
      });
    handleUpdateModelUpiClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isShow === false) {
      if (!accountDetails.accountNumber) {
        errorToastMsg("Please enter bank account number");
        return;
      }

      if (!accountDetails.confirmAccountNumber) {
        errorToastMsg("Please enter Confirm bank account number");
        return;
      }

      if (!accountDetails.bankName || 
        accountDetails.bankName.match(WHITE_SPACES_REGEX)
        ) {
        errorToastMsg("Please enter your bank name");
        return;
      }

      if (!accountDetails.bankIFSCCode ||
          accountDetails.bankIFSCCode.match(WHITE_SPACES_REGEX)) {
        errorToastMsg("Please enter IFSC code");
        return;
      }

      if (!accountDetails.accountHolderName ||
           accountDetails.accountHolderName.match(WHITE_SPACES_REGEX)) {
        errorToastMsg("Please enter account holder name");
        return;
      }

      if (
        accountDetails.accountNumber !== accountDetails.confirmAccountNumber
      ) {
        errorToastMsg(
          "Account number and Confirm account number is not matching"
        );
        return;
      }
      setLoading(true);
      axios
        .post(API_UPDATE_BANK_DETAILS, accountDetails, {
          headers: {
            // "content-type": "multipart/form-data",
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.responseCode === -1) {
            errorToastMsg(res.data.errorMsg);
            setLoading(false);
          } else {
            successToastMsg(res.data.successMsg);
            setAccountDetails(initialBankValues);
            getAllData();
          }
        })
        .catch((err) => {
          errorToastMsg(err.msg);
          setLoading(false);
        });
    } else {
      const formData = new FormData();
      formData.append("data", JSON.stringify(upiDetails));
      if (!upiDetails.upiId) {
        errorToastMsg("Please Enter the UPI ID.");
        return;
      }
      if (upiImage.raw) {
        formData.append("upiImage", upiImage.raw, upiImage.raw.name);
      } else {
        errorToastMsg("Please Upload the QR code.");
        return;
      }
      setLoading(true);
      axios
        .post(API_UPDATE_UPI_DETAILS, formData, {
          headers: {
            // "content-type": "multipart/form-data",
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.responseCode === -1) {
            errorToastMsg(res.data.errorMsg);
            setLoading(false);
          } else {
            successToastMsg(res.data.successMsg);
            getAllData();
          }
        })
        .catch((err) => {
          errorToastMsg(err.msg);
          setLoading(false);
        });
      setupiDetails(initialUpiValues);
      setUpiImage({ preview: "", raw: "" });
    }
  };

  // const handleUpiDetailsSubmit = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append("data", JSON.stringify(upiDetails));
  //   if (!upiDetails.upiId) {
  //     errorToastMsg("Please Upload the UPI ID.");
  //     return;
  //   }
  //   if (upiImage.raw) {
  //     formData.append("upiImage", upiImage.raw, upiImage.raw.name);
  //   } else {
  //     errorToastMsg("Please Upload the QR code.");
  //     return;
  //   }
  //   axios
  //     .post(
  //       "http://3.111.157.249:9000/v1/crm/updateUpiDetails",
  //       formData,
  //       authToken
  //     )
  //     .then((res) => {
  //       if (res.data.responseCode === -1) {
  //         errorToastMsg(res.data.errorMsg);
  //       } else {
  //         successToastMsg(res.data.successMsg);
  //         setupiDetails(initialUpiValues);
  //         getAllData();
  //       }
  //     })
  //     .catch((err) => {
  //       errorToastMsg(err.message);
  //     });
  // };

  const validateBankName = () => {
    if (accountDetails.bankName) {
      if (!ALPHABET_ONLY_REGEX.test(accountDetails.bankName)) {
        setIsValid(false);
        setErrorMsg("Please enter only alphabets");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const handleUpadateUPICross = (e) => {
    e.preventDefault();
    setUpiImage({ raw: "", preview: "", value: "" });
    setUpdateUPIimageState(true);
    setUpdateUpi({ ...UpdateUpi, flag: "false" });
  };

  const validateUpiID = () => {
    const { upiId } = upiDetails;
    if (upiId) {
      if (!UPI_ID_REGEX.test(upiId)) {
        setIsUpiIDValid(false);
        setUpiErrorMsg("Please enter valid upi id.");
      } else {
        setIsUpiIDValid(true);
        setUpiErrorMsg("");
      }
    } else {
      setIsUpiIDValid(true);
      setUpiErrorMsg("");
    }
  };

  const validateUpdateUpiID = () => {
    const { upiId } = UpdateUpi;
    if (upiId) {
      if (!UPI_ID_REGEX.test(upiId)) {
        setIsUpdateUpiIDValid(false);
        setUpdateUpiErrorMsg("Please enter valid upi id.");
      } else {
        setIsUpdateUpiIDValid(true);
        setUpdateUpiErrorMsg("");
      }
    } else {
      setIsUpdateUpiIDValid(true);
      setUpdateUpiErrorMsg("");
    }
  };

  useEffect(()=>{
      if(data.image){
          setUpiImage({
            ...upiImage,
              preview: URL.createObjectURL(data.image),
              raw: data.image
          });
    }
  },[data]);

  return (
    <>
      <div className={styles.mainContainer}>
        <Sidebar />
        {loading ? (
          <Spinner />
        ) : (
          <div className={styles.logincomponent}>
            <p className={styles.paymentDetails}>Payment Details</p>
            <div className={styles.paymentDetailsComponent}>
              {(isFetchedBankData || isFetchedUpiData) && (
                <>
                  <div className={styles.BankAccountDetails}>
                    <div className={styles.bankDetailsHeaders}>
                      <p className={styles.bankDetails}>Bank Details</p>
                      <FiEdit
                        className={styles.iconStyle}
                        onClick={() =>
                          handleUpdateModelBankShow({
                            accountHolderName:
                              fetchedBankData.accountHolderName,
                            accountNumber: fetchedBankData.accountNumber,
                            confirmAccountNumber: fetchedBankData.accountNumber,
                            bankIFSCCode: fetchedBankData.bankIfsc,
                            bankName: fetchedBankData.bankName,
                          })
                        }
                      />
                    </div>
                    <BankDetails fetchedBankData={fetchedBankData} />
                  </div>
                  <div className={styles.BankAccountDetails}>
                    <hr />
                    <div className={styles.bankDetailsHeaders}>
                      <p className={styles.upiDetails}>UPI Details</p>
                      <FiEdit
                        className={styles.iconStyle}
                        onClick={() =>
                          handleUpdateModelUpiShow({
                            upiId: fetchedUpiData.upiId,
                            upiQrCode: fetchedUpiData.upiQrCode,
                            flag: "false",
                            upiImage: "upiImage",
                          })
                        }
                      />
                    </div>
                    <UpiDetails fetchedUpiData={fetchedUpiData} />
                  </div>
                </>
              )}
            </div>
            {!isFetchedUpiData && !isFetchedBankData && (
              <div className={styles.bgrightloginform}>
                <div className={`${styles.registerYourAccount}`}>
                  <div
                    className="col-xs-6 d-flex"
                    style={{ marginBottom: "10px" }}
                  >
                    {!isShow ? (
                      <RiRecordCircleFill
                        className={styles.circleButton}
                        style={{ color: "blue" }}
                      />
                    ) : (
                      <RiCheckboxBlankCircleLine
                        className={styles.circleButton}
                      />
                    )}
                    <div className={styles.bankAndUPIDiv}>
                      {isShow ? (
                        <div
                          style={{ paddingTop: "10px" }}
                          className={styles.withOutline}
                          onClick={handleRadio}
                          placeholder="Bank Account"
                        >
                          {" "}
                          Bank Account
                        </div>
                      ) : (
                        <div
                          className={styles.noOutline}
                          placeholder="Bank Account"
                        >
                          {" "}
                          Bank Account
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={isShow ? "d-none" : ""}>
                    <div className={styles.BankAccountDetails}>
                      <form className="row g-3" autocomplete="off">
                        <div className="col-md-6">
                          <label
                            htmlFor="inputEmail4"
                            className={`form-label ${styles.inputLabel}`}
                          >
                            Account Number
                          </label>
                          <input
                            type="number"
                            className={`form-control ${styles.inputBox}`}
                            id="inputEmail4"
                            placeholder="6547-8702-6987-2527"
                            name="accountNumber"
                            onChange={handleInput}
                            value={accountDetails.accountNumber}
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputEmail4"
                            className={`form-label ${styles.inputLabel}`}
                          >
                            Re-enter Account Number
                          </label>
                          <input
                            type="number"
                            className={`form-control ${styles.inputBox}`}
                            id="inputEmail4"
                            placeholder="6547-8702-6987-2527"
                            name="confirmAccountNumber"
                            onChange={handleInput}
                            value={accountDetails.confirmAccountNumber}
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputEmail4"
                            className={`form-label ${styles.inputLabel}`}
                          >
                            Bank Name
                          </label>
                          <input
                            type="text"
                            className={`form-control ${styles.inputBox}`}
                            id="inputEmail4"
                            placeholder="Bank Name"
                            name="bankName"
                            onChange={handleInput}
                            onBlur={validateBankName}
                            value={accountDetails.bankName}
                          />
                          {!isValid && (
                            <div
                              className={`text-danger ${styles.errormessage}`}
                            >
                              {errorMsg}
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputEmail4"
                            className={`form-label ${styles.inputLabel}`}
                          >
                            Bank IFSC Code
                          </label>
                          <input
                            type="text"
                            className={`form-control ${styles.inputBox}`}
                            id="inputEmail4"
                            placeholder="Bank IFSC Code"
                            name="bankIFSCCode"
                            onChange={handleInput}
                            value={accountDetails.bankIFSCCode}
                          />
                        </div>
                        <div className="col-md-6">
                          <label
                            htmlFor="inputEmail4"
                            className={`form-label ${styles.inputLabel}`}
                          >
                            Account Holder Name
                          </label>
                          <input
                            type="text"
                            className={`form-control ${styles.inputBox}`}
                            id="inputEmail4"
                            placeholder="Account Holder Name"
                            name="accountHolderName"
                            onChange={handleInput}
                            value={accountDetails.accountHolderName}
                          />
                        </div>
                      </form>
                    </div>
                  </div>

                  <div className={`col-xs-6 my-1 ${styles.UpiDetailsDiv}`}>
                    <label>
                      {/* <input
                     name="Bank Account UPI QR Code"
                     defaultChecked={isShow}
                     type="radio"
                     onChange={handleRadio}
                   /> */}
                      {isShow ? (
                        <RiRecordCircleFill style={{ color: "blue" }} />
                      ) : (
                        <RiCheckboxBlankCircleLine />
                      )}

                      <label className={styles.bankAndUPIDiv}>
                        {!isShow ? (
                          <div
                            onClick={handleRadio}
                            className={`d-flex justify-content-between ${styles.withOutline}`}
                            placeholder="Bank Account"
                          >
                            <p>UPI QR Code</p>
                            <RiQrCodeFill size={23} />
                          </div>
                        ) : (
                          <div
                            className={styles.noOutline}
                            placeholder="Bank Account"
                          >
                            {" "}
                            UPI QR Code
                          </div>
                        )}
                      </label>
                    </label>
                  </div>
                  <div className={isShow === false ? "d-none" : ""}>
                    <div className={styles.BankAccountDetails}>
                      <form autocomplete="off">
                        <div>
                          <label
                            htmlFor="inputEmail4"
                            className={`form-label ${styles.inputLabel}`}
                          >
                            Enter UPI ID
                          </label>
                          <input
                            type="text"
                            className={`form-control w-75 ${styles.inputBox}`}
                            id="inputEmail4"
                            placeholder="Enter UPI ID"
                            name="upiId"
                            onBlur={validateUpiID}
                            value={upiDetails.upiId}
                            onChange={handleUPIInput}
                          />
                        </div>
                        {!isUpiIDValid && (
                          <div
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                            className={`text-danger ${styles.errormessage}`}
                          >
                            {upiErrorMsg}
                          </div>
                        )}
                        <label
                          style={{ marginTop: "20px" }}
                          htmlFor="inputEmail4"
                          className="form-label fw-semibold "
                        >
                          Upload QR code
                        </label>

                        <div className="">
                          <label
                            htmlFor="file-input"
                            className={styles.uploadImage}
                          >
                            {upiImage.preview ? (
                              <div className={styles.imageBox}>
                                <div
                                  className={`cross ${styles.crossSign}`}
                                  onClick={handleUpadateUPICross}
                                >
                                  <BsXCircle
                                    color="#D7E0EE"
                                    style={{
                                      backgroundColor: "#272B41",
                                      borderRadius: "50%",
                                      position: "absolute",
                                      top: 2,
                                      right: 1,
                                    }}
                                  />
                                </div>
                                <img
                                  src={upiImage.preview}
                                  alt="upiImage"
                                  className={styles.uploadIconDiv}
                                />
                              </div>
                            ) : (
                              <div className={styles.uploadIconDiv}>
                                <FiUpload className={styles.uploadIcon} />
                                <p>Upload</p>
                              </div>
                            )}
                          </label>
                          <input
                            type="file"
                            id="file-input"
                            className="d-none"
                            value={upiImage.value}
                            onChange={handleImage}
                            accept="image/*"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className={styles.updateButtonDiv}>
                    <button
                      type="submit"
                      className={`btn customButtonActive ${styles.updateButton}`}
                      onClick={handleSubmit}
                      disabled={!isValid || !isUpiIDValid}
                    >
                      Update
                    </button>
                  </div>
                </div>
                {/* <div className={styles.updateButtonDiv}>
                 <button type="submit" className={`btn ${styles.updateButton}`}>
                   Update
                 </button>
               </div> */}
              </div>
            )}

            {/* Bank Details update model */}
            <Modal
              size="lg"
              centered
              show={updateModelBank}
              onHide={handleUpdateModelBankClose}
              scrollable
              className={styles.paymentModel}
            >
              <div className={styles.updateModelUpper}>
                <h5 className={`${styles.updateModalTitle}`}>
                  Update Bank Details
                </h5>
                <div
                  className={styles.updateModalCross}
                  onClick={handleUpdateModelBankClose}
                >
                  x
                </div>
              </div>
              <hr className="m-0" />
              <Modal.Body>
                <form onSubmit={handleBankDetailsSubmit} autocomplete="off">
                  <div className={styles.bgrightloginform}>
                    <div className={`${styles.registerYourAccount}`}>
                      <div className={""}>
                        <div className={styles.BankAccountDetails}>
                          <form className="row g-3" autocomplete="off">
                            <div className="col-md-6">
                              <label
                                htmlFor="inputEmail4"
                                className="form-label fw-semibold"
                              >
                                Account Number
                              </label>
                              <input
                                type="number"
                                className={`form-control text-secondary 
                                fw-normal ${styles.UpdateinputBox}`}
                                id="inputEmail4"
                                required
                                placeholder="6547-8702-6987-2527"
                                name="accountNumber"
                                onChange={handleUpdate}
                                value={UpdateBank?.accountNumber}
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="inputEmail4"
                                className="form-label fw-semibold"
                              >
                                Re-enter Account Number
                              </label>
                              <input
                                type="number"
                                className={`form-control text-secondary 
                                fw-normal ${styles.UpdateinputBox}`}
                                id="inputEmail4"
                                placeholder="6547-8702-6987-2527"
                                name="confirmAccountNumber"
                                required
                                onChange={handleUpdate}
                                value={UpdateBank?.confirmAccountNumber}
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="inputEmail4"
                                className="form-label fw-semibold"
                              >
                                Bank Name
                              </label>
                              <input
                                type="text"
                                className={`form-control text-secondary 
                                fw-normal ${styles.UpdateinputBox}`}
                                id="inputEmail4"
                                placeholder="Bank Name"
                                required
                                name="bankName"
                                onChange={handleUpdate}
                                value={UpdateBank?.bankName}
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="inputEmail4"
                                className="form-label fw-semibold"
                              >
                                Bank IFSC Code
                              </label>
                              <input
                                type="text"
                                className={`form-control text-secondary 
                                fw-normal ${styles.UpdateinputBox}`}
                                id="inputEmail4"
                                required
                                placeholder="Bank IFSC Code"
                                name="bankIFSCCode"
                                onChange={handleUpdate}
                                value={UpdateBank?.bankIFSCCode}
                              />
                            </div>
                            <div className="col-md-6">
                              <label
                                htmlFor="inputEmail4"
                                className="form-label fw-semibold"
                              >
                                Account Holder Name
                              </label>
                              <input
                                type="text"
                                className={`form-control text-secondary 
                                fw-normal ${styles.UpdateinputBox}`}
                                id="inputEmail4"
                                required
                                placeholder="Account Holder Name"
                                name="accountHolderName"
                                onChange={handleUpdate}
                                value={UpdateBank?.accountHolderName}
                              />
                            </div>
                          </form>
                          <div className={styles.updateButtonDiv}>
                            <button
                              type="submit"
                              className={`btn customButtonActive ${styles.updateButton}`}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>

            {/* UPI update model */}
            <Modal
              size="md"
              centered
              show={updateModelUpi}
              onHide={handleUpdateModelUpiClose}
              scrollable
              className={styles.paymentModel}
            >
              <div className={styles.updateModelUpper}>
                <h5 className={`${styles.updateModalTitle}`}>
                  Update UPI Details
                </h5>
                <div
                  className={styles.updateModalCross}
                  onClick={handleUpdateModelUpiClose}
                >
                  x
                </div>
              </div>
              <hr className="m-0" />
              <Modal.Body>
                <form autocomplete="off">
                  <div className={styles.bgrightloginform}>
                    <div className={`${styles.registerYourAccount}`}>
                      <div className={""}>
                        <div className={styles.BankAccountDetails}>
                          <div className="col-md-6">
                            <label
                              htmlFor="inputEmail4"
                              className="form-label fw-semibold"
                            >
                              Enter UPI ID
                            </label>
                            <input
                              type="email"
                              className={`form-control text-secondary 
                              fw-normal ${styles.UpdateinputBox}`}
                              id="inputEmail4"
                              placeholder="Enter UPI ID"
                              name="upiId"
                              onBlur={validateUpdateUpiID}
                              value={UpdateUpi?.upiId}
                              onChange={handleUpiUpdate}
                            />
                          </div>
                          {!isUpdateUpiIDValid && (
                          <div
                            style={{ marginTop: "5px", marginBottom: "5px" }}
                            className={`text-danger ${styles.errormessage}`}
                          >
                            {UpdateUpiErrorMsg}
                          </div>
                        )}
                          <label
                            style={{ marginTop: "20px" }}
                            htmlFor="inputEmail4"
                            className="form-label fw-semibold "
                          >
                            Upload QR code
                          </label>

                          <div className="">
                            <label
                              htmlFor="file-input"
                              className={styles.uploadImage}
                            >
                              {upiImage.preview ? (
                                <div className={styles.imageBox}>
                                  <div
                                    className={`cross ${styles.crossSign}`}
                                    onClick={handleUpadateUPICross}
                                  >
                                    <BsXCircle
                                      color="#D7E0EE"
                                      style={{
                                        backgroundColor: "#272B41",
                                        borderRadius: "50%",
                                        position: "absolute",
                                        top: 2,
                                        right: 1,
                                      }}
                                    />
                                  </div>
                                  <img
                                    src={upiImage.preview}
                                    alt="upiImage"
                                    className={styles.uploadIconDiv}
                                    height={150}
                                    width={150}
                                  />
                                </div>
                              ) : UpdateUpi?.upiQrCode &&
                                !updateUPIimageState ? (
                                <img
                                  src={UpdateUpi?.upiQrCode}
                                  alt="upiImage"
                                  className={styles.uploadIconDiv}
                                  height={150}
                                  width={150}
                                />
                              ) : (
                                <div className={styles.uploadIconDiv}>
                                  <FiUpload className={styles.uploadIcon} />
                                  <p>Upload</p>
                                </div>
                              )}
                            </label>
                            <input
                              type="file"
                              id="file-input"
                              className="d-none"
                              value={upiImage.value}
                              onChange={handleUpdateImage}
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles.updateButtonDiv}>
                        <button
                          type="submit"
                          className={`btn customButtonActive ${styles.updateButton}`}
                          onClick={handleUpdateUpi}
                          disabled={!isUpdateUpiIDValid}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default BankAccountDetails;
