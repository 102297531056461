import { USER_LOGIN } from "../action/user.action";
import { DELETE_USER } from "../action/user.action";

const intialState = {
    userdata:[]
};

export const userReducer = (state=intialState,{type,payload})=>{
      switch(type){
         case USER_LOGIN:
            return {...state,userdata:payload}
         case DELETE_USER:
            return {...state,userdata:[]}
         default:
            return state
      }
};
