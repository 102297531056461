import styles from "./Navbar.module.scss";
import navbaricon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import taxiLogo from "../../assets/images/mobile-view -logo.png";
import profileicon from "../../assets/images/Profile-icon.svg";
import settingicon from "../../assets/images/Setting-icon.png";
import { AiOutlineUser } from "react-icons/ai";
import bellicon from "../../assets/images/Bell-icon.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { IoMdLogOut } from "react-icons/io";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useState } from "react";
import MobileSideBar from "../Dashboard/Sidebar/MobileSideBar";
import { deleteUser } from "../../redux/action/user.action";
import { useDispatch } from "react-redux";
import { Dropdown, Popover, Tooltip } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationPopUp from "../Notification/NotificationPopUp";
import axios from "axios";
import { errorToastMsg, successToastMsg } from "../../common/ToastMessage";
import { API_LOG_OUT } from "../../utils/consts/api";
export const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // for offcanvas:-

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");
  const crmAgentId = localStorage.getItem("crmAgentId") || sessionStorage.getItem("crmAgentId");
  
  const handleLogout = () => {
    
    axios
      .post(API_LOG_OUT,{crmAgentId: crmAgentId}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          sessionStorage.removeItem("userToken");
          localStorage.removeItem("userToken");
          sessionStorage.removeItem("crmAgentId");
          localStorage.removeItem("crmAgentId");
          dispatch(deleteUser());
          navigate("/login");
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleGetProfile = ()=>{
     navigate("/getProfile");
  }

  return (
    <>
      <div>
        <nav className={`navbar ${styles.navbarComponent}`}>
          <div className="container-fluid">
            <div className={`${styles.navbarBrandleft}`}>
              <div className={`${styles.navbarMenu}`} >
                <FontAwesomeIcon
                  onClick={handleShow}
                  icon={faBars}
                  size="xl"
                  style={{
                    color: "#929ab8",
                  }}
                />
              </div>

              <Link className="navbar-brand" to="/dashboard/mybooking">
                <img
                  src={taxiLogo}
                  alt="taxi-Logo"
                  className={`inline-block align-text-top ${styles.navbaricon} `}
                />
                <img
                  src={navbaricon}
                  alt="taxi-logo"
                  className={`inline-block align-text-top ${styles.desktopIcon} `}
                />
              </Link>
            </div>
            <div className={`d-flex ${styles.iconsDiv}`}>
              <div style={{ cursor: "pointer" }}>
                <NotificationPopUp />
              </div>
              <Link style={{ marginTop: "5px" }}>
                <img
                  src={settingicon}
                  alt="Logo"
                  width="20"
                  height="20"
                  className={`d-inline-block align-text-top ${styles.settingicon}`}
                />
              </Link>
              <div className={styles.dropdownbtn}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" style={{ background: 'none', color: 'currentColor', borderColor: 'transparent' }}>
                    <AiOutlineUser className={styles.profileIcon}></AiOutlineUser>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={handleGetProfile}>
                       My Profile
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout} >
                      <Link>Sign Out</Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="container">
            <Offcanvas
              show={show}
              onHide={handleClose}
              style={{
                maxWidth: "260px",
                width: '60%',
                minWidth: '220px'
              }}
            >
              <Offcanvas.Header>
                <div style={{ margin: "15px 10px 0px 15px" }}>
                  <img
                    src="/taxi-sanchalak-logo.png"
                    alt="taxi-sanchalak-logo"
                  />
                </div>
              </Offcanvas.Header>

              <Offcanvas.Body>
                <MobileSideBar />
              </Offcanvas.Body>
            </Offcanvas>
          </div>
        </nav>
      </div>
    </>
  );
};
