import LoginComponent from "../../views/auth/login";
import { Navbar } from "../Navbar/Navbar";

export const withAuthCheck = (Component) => {
  return function Comp() {
    const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");
    if (token) {
      return (
        <>
        <div style={{position:"fixed",top:0,left:0,width:"100vw", zIndex:1000,background:"white"}}>
         <Navbar />
        </div>
        <div style={{marginTop:"60px"}}>
         <Component />
        </div>
        </>
      )
    } else {
      window.open('/login', '_self')
      return <LoginComponent />;
    }
  }
};