import React, { useEffect, useState } from "react";
import { RiDeleteBin6Line } from "react-icons/ri";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteuserComponent from "./DeleteVehicleModal/Delete-vehicle-modal";
import styles from "./showDriver.module.scss";

function ShowDriver({ addDriverArray, handleDriverDelete }) {
  const [modalShow, setModalShow] = useState(false);
  const [deletePlanID, setDeletePlanID] = useState("");

  const handleDeleteOpen = (id) => {
    setDeletePlanID(id);
    setModalShow(true);
  };

  const handleDeleteclose = () => {
    setModalShow(false);
  };

  const handleDeleteaccept = ()=>{
    handleDriverDelete(deletePlanID);
    setModalShow(false);
    setDeletePlanID('');
  }

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      {addDriverArray && addDriverArray.length > 0 && (
        <div className={` ${styles.driversGrid}`}>
          {addDriverArray &&
            addDriverArray?.map((DriverInfo) => (
              <div key={DriverInfo.unique_id}>
                <div className={`card g-0 ${styles.cardDiv}`}>
                  <div className={`card-body ${styles.cardInnerDiv}`}>
                    <div className={`d-flex flex-row gap-3 ${styles.cardInfo}`}>
                      <div className={`d-flex flex-row gap-3`}>
                        <div>
                          <img
                            className={styles.carImage}
                            src={DriverInfo.driverImage.preview}
                            alt="Driver Image"
                          />
                        </div>
                        <div className={styles.carDescription}>
                          <div className={`p-0 ${styles.carNumber}`}>
                            {DriverInfo.driverFullName}
                          </div>
                          <div className="p-0">
                            {DriverInfo.driverContactNumber}
                          </div>
                          <div className="p-0">{DriverInfo.email}</div>
                        </div>
                      </div>
                      <div className={styles.carDelete}>
                        <div className="p-0">
                          <RiDeleteBin6Line
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleDeleteOpen(DriverInfo.unique_id)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      {/* Delete Modal Component */}

      <DeleteuserComponent
        show={modalShow}
        onHide={handleDeleteclose}
        onAccept={handleDeleteaccept}
      />
    </>
  );
}

export default ShowDriver;
