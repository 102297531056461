import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./SocialURL.module.scss";
import bgImage from "../../../assets/images/login_bg_image.png";
import TaxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../../assets/images/Taxi-sanchalak-left-image.png";
import taxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import tripadvisor from "../../../assets/images/tripadvisor.png";
import JustDialIcon from "../../../assets/images/just-dial-Icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FACEBOOK_REGEX, VALID_URL_REGEX } from "../../../utils/consts/api";

import { useState } from "react";
import ChoosePlanComponent from "../ChoosePlan/ChoosePlan";
import { ToastContainer } from "react-toastify";
import { errorToastMsg } from "../../../common/ToastMessage";

const SocialURLComponent = ({
  handleSocialURLBackButton,
  registrationData,
  setRegistrationData,
}) => {
  const [planState, setPlanState] = useState(false);
  const [isValidFacebook, setIsValidFacebook] = useState(true);
  const [facebokErrorMsg, setFacebokErrorMsg] = useState("");
  const [isValidTripAdvisor, setIsValidTripAdvisor] = useState(true);
  const [tripAdvisorErrorMsg, setTripAdvisorErrorMsg] = useState("");
  const [isValidGoogle, setIsValidGoogle] = useState(true);
  const [googleErrorMsg, setGoogleErrorMsg] = useState("");
  const [isValidJustDial, setIsValidJustDial] = useState(true);
  const [justDialErrorMsg, setJustDialErrorMsg] = useState("");
  const [reviewLinksData, setReviewLinksData] = useState({
    fbUrl: "",
    justDialUrl: "",
    googleUrl: "",
    tripAdvisorUrl: "",
  });

  const handlePlanBackButton = () => {
    setPlanState(false);
  };

  const handleReviewLinks = (e) => {
    const { name, value } = e.target;
    setReviewLinksData({
      ...reviewLinksData,
      [name]: value,
    });
  };

  const handleAddReviewSubmit = (e) => {
    e.preventDefault();
    const { fbUrl, justDialUrl, tripAdvisorUrl, googleUrl } = reviewLinksData;
    if (!fbUrl && !justDialUrl && !tripAdvisorUrl && !googleUrl) {
      errorToastMsg("Please submit atleast one url");
    } else {
      setPlanState(true);
      setRegistrationData({
        ...registrationData,
        reviewLinks: [reviewLinksData],
      });
    }
  };

  const handleDoItLater = (e) => {
    e.preventDefault();
    setPlanState(true);
    setRegistrationData({
      ...registrationData,
      reviewLinks: [],
    });
  };

  const validateFacebookURL = () => {
    if (reviewLinksData.fbUrl) {
      if (!FACEBOOK_REGEX.test(reviewLinksData.fbUrl)) {
        setIsValidFacebook(false);
        setFacebokErrorMsg("Invalid facebook url.");
      } else {
        setIsValidFacebook(true);
        setFacebokErrorMsg("");
      }
    } else {
      setIsValidFacebook(true);
      setFacebokErrorMsg("");
    }
  };

  const validateGoogleURL = () => {
    if (reviewLinksData.googleUrl) {
      if (!VALID_URL_REGEX.test(reviewLinksData.googleUrl)) {
        setIsValidGoogle(false);
        setGoogleErrorMsg("Invalid google url.");
      } else {
        setIsValidGoogle(true);
        setGoogleErrorMsg("");
      }
    } else {
      setIsValidGoogle(true);
      setGoogleErrorMsg("");
    }
  };

  const validateJustDial = () => {
    if (reviewLinksData.justDialUrl) {
      if (!VALID_URL_REGEX.test(reviewLinksData.justDialUrl)) {
        setIsValidJustDial(false);
        setJustDialErrorMsg("Invalid just dial url.");
      } else {
        setIsValidJustDial(true);
        setJustDialErrorMsg("");
      }
    } else {
      setIsValidJustDial(true);
      setJustDialErrorMsg("");
    }
  };

  const validateTripAdvisorURL = () => {
    if (reviewLinksData.tripAdvisorUrl) {
      if (!VALID_URL_REGEX.test(reviewLinksData.tripAdvisorUrl)) {
        setIsValidTripAdvisor(false);
        setTripAdvisorErrorMsg("Invalid Trip advisor url.");
      } else {
        setIsValidTripAdvisor(true);
        setTripAdvisorErrorMsg("");
      }
    } else {
      setIsValidTripAdvisor(true);
      setTripAdvisorErrorMsg("");
    }
  };

  useEffect(()=>{
     if(registrationData.reviewLinks[0]){
       setReviewLinksData(registrationData.reviewLinks[0]);
     }
  },[])

  return (
    <>
      {!planState && (
        <div>
          <div className={styles.logincomponent}>
            <div
              className={`${styles.taxiIcon}`}
              style={{ backgroundImage: `url(${taxiIcon})` }}
            ></div>
            {/* <div
              className={`d-none d-md-block ${styles.bgleftimage}`}
              style={{ backgroundImage: `url(${bgImage})` }}
            >
            </div>
            <div className={styles.bgleftimageMediumDiv}>
              <img src={bgImage} className={styles.bgleftimageMedium} />
            </div> */}
            <div className={styles.TaxiCRMImageDiv}>
              <div className={styles.IconAndText}>
                <img src={TaxiIcon} className={styles.TaxiIconImage} />
                <p className={styles.imageText}>CRM Application</p>
              </div>
              <div className={styles.TaxiLeftImage}>
                <img src={TaxiLeftImage} width="100%" />
              </div>
            </div>
            <div className={styles.bgrightloginform}>
              <div className={styles.donotaccount}>
                <p className={styles.alreadyaccount}>
                  Already have an account?
                  <span className={styles.signin}>
                    <Link to="/login">Sign in</Link>
                  </span>
                </p>
              </div>
              <div className={styles.registerYourAccount}>
                <h4 className={`mb-8 ${styles.registeraccount}`}>
                  Register your account
                </h4>
                <div
                  className={`d-flex justify-content-between ${styles.addreviewdiv}`}
                >
                  <p
                    className={`fw-semibold text-black-50 ${styles.addreview}`}
                  >
                    Add Review link for better credibility{" "}
                  </p>
                  <h6 className={styles.step4}>Step 4 of 5</h6>
                </div>
                <div className={styles.URLsDiv}>
                  <form
                    onSubmit={handleAddReviewSubmit}
                    style={{ marginTop: "25px" }}
                  >
                    <div className={styles.icondiv}>
                      <label for="facebook" className="form-label ">
                        Facebook
                      </label>
                      <div className="input-group mb-3">
                        <span className={styles.facebook}>
                          <FontAwesomeIcon icon={faFacebookF} />
                        </span>
                        <input
                          type="text"
                          className={`form-control ${styles.iconinput}`}
                          id="facebook"
                          placeholder="Url"
                          value={registrationData.reviewLinks[0]?.fbUrl ? registrationData.reviewLinks[0].fbUrl : reviewLinksData.fbUrl}
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="fbUrl"
                          onBlur={validateFacebookURL}
                          onChange={handleReviewLinks}
                        />
                      </div>
                      {!isValidFacebook && (
                        <div className={`text-danger ${styles.errormessage}`}>
                          {facebokErrorMsg}
                        </div>
                      )}
                    </div>
                    <div className={styles.icondiv}>
                      <label for="facebook" className="form-label fw-semibold">
                        JustDial
                      </label>
                      <div className="input-group mb-3">
                        <span className={styles.justDial}>
                          <img src={JustDialIcon} height="100%" width="100%" />
                        </span>
                        <input
                          style={{ borderLeft: "1px solid #FA8B0C" }}
                          type="text"
                          className="form-control"
                          id="facebook"
                          placeholder="Url"
                          aria-label="Username"
                          value={registrationData.reviewLinks[0]?.justDialUrl ? registrationData.reviewLinks[0].justDialUrl : reviewLinksData.justDialUrl}
                          aria-describedby="basic-addon1"
                          name="justDialUrl"
                          onBlur={validateJustDial}
                          onChange={handleReviewLinks}
                        />
                      </div>
                    </div>
                    {!isValidJustDial && (
                      <div className={`text-danger ${styles.errormessage}`}>
                        {justDialErrorMsg}
                      </div>
                    )}
                    <div className={styles.icondiv}>
                      <label for="facebook" className="form-label fw-semibold">
                        Google
                      </label>
                      <div className="input-group mb-3">
                        <span className={styles.google}>
                          <FontAwesomeIcon icon={faGoogle} />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          id="facebook"
                          placeholder="Url"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name="googleUrl"
                          onBlur={validateGoogleURL}
                          onChange={handleReviewLinks}
                          value={registrationData.reviewLinks[0]?.googleUrl ? registrationData.reviewLinks[0].googleUrl : reviewLinksData.googleUrl}
                        />
                      </div>
                    </div>
                    {!isValidGoogle && (
                      <div className={`text-danger ${styles.errormessage}`}>
                        {googleErrorMsg}
                      </div>
                    )}
                    <div className={styles.icondiv}>
                      <label for="facebook" className="form-label fw-semibold">
                        Tripadvisor
                      </label>
                      <div className="input-group mb-3">
                        <img className={styles.tripadvisor} src={tripadvisor} />
                        <input
                          type="text"
                          className="form-control"
                          id="facebook"
                          placeholder="Url"
                          aria-label="Username"
                          value={registrationData.reviewLinks[0]?.tripAdvisorUrl ? registrationData.reviewLinks[0].tripAdvisorUrl : reviewLinksData.tripAdvisorUrl}
                          aria-describedby="basic-addon1"
                          name="tripAdvisorUrl"
                          onBlur={validateTripAdvisorURL}
                          onChange={handleReviewLinks}
                        />
                      </div>
                    </div>
                    {!isValidTripAdvisor && (
                      <div className={`text-danger ${styles.errormessage}`}>
                        {tripAdvisorErrorMsg}
                      </div>
                    )}
                    <div className={`class mb-2 ${styles.buttons}`}>
                      <button
                        type="button"
                        className={`btn btn-outline-secondary ${styles.back}`}
                        style={{ height: "50px" }}
                        onClick={handleSocialURLBackButton}
                      >
                        Back
                      </button>
                      <button
                        className={`btn ${styles.back}`}
                        onClick={handleDoItLater}
                      >
                        Do it later
                      </button>
                      <button
                        type="submit"
                        className={`btn ${styles.dolater}`}
                        disabled={
                          !isValidFacebook ||
                          !isValidGoogle ||
                          !isValidJustDial ||
                          !isValidTripAdvisor
                        }
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {planState && (
        <ChoosePlanComponent
          handlePlanBackButton={handlePlanBackButton}
          registrationData={registrationData}
          setRegistrationData={setRegistrationData}
        />
      )}
      <ToastContainer />
    </>
  );
};

export default SocialURLComponent;
