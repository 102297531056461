import React, { useEffect, useRef } from "react";
import styles from "./addDriver.module.scss";
import bgImage from "../../../assets/images/login_bg_image.png";
import TaxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../../assets/images/Taxi-sanchalak-left-image.png";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import ShowDriver from "./ShowDriver/ShowDriver";
import SocialURLComponent from "../SocialURL/SocialURL";
import taxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import {
  IS_NUMBER_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
  NAME_REGEX,
} from "../../../utils/consts/api";
import { BsPerson, BsXCircle } from "react-icons/bs";
import { ImageCompressorHook } from "../../Dashboard/CommonComp/ImageCompressor/ImageCompressor";

export const AddDriverComponent = ({
  registrationData,
  handleAddDriverBackButton,
  setRegistrationData,
}) => {
  const [reviewLinkState, setReviewLinkState] = useState(false);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const {imageCompressorFn,data} = ImageCompressorHook();

  const popUpIntialValues = {
    unique_id: "",
    driverFullName: "",
    driverContactNumber: "",
    email: "",
    driverImage: { preview: "", raw: "", value: "" },
    licenseImageFront: { preview: "", raw: "", value: "" },
    licenseImageBack: { preview: "", raw: "", value: "" },
  };

  const [popUpValues, setPopUpValues] = useState(popUpIntialValues);

  const [addDriverArray, SetAddDriverArray] = useState(
    registrationData.tempDriverInfo
  );

  const [show, setShow] = useState(false);

  const handleDriverImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image,e.target.name);
      }
      setPopUpValues({
        ...popUpValues,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const handleFormData = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "driverContactNumber") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 10) {
        setPopUpValues({ ...popUpValues, [name]: value });
      } else {
        setPopUpValues({ ...popUpValues, [name]: value.slice(0, -1) });
      }
    } else if (name === "driverFullName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setPopUpValues({ ...popUpValues, [name]: value });
      } else {
        setPopUpValues({ ...popUpValues, [name]: value.slice(0, -1) });
      }
    } else {
      setPopUpValues({ ...popUpValues, [e.target.name]: e.target.value });
    }
  };

  const handleClose = () => {
    setShow(false);
    setPopUpValues(popUpIntialValues);
    setIsEmailValid(true);
    setIsPhoneValid(true);
    setPhoneErrorMsg("");
    setEmailErrorMsg("");
  };
  const handleShow = () => {
    const unique_id = uuid();
    setPopUpValues({ ...popUpValues, unique_id: unique_id });
    setShow(true);
  };

  const handleModalSbmission = (e) => {
    e.preventDefault();
    popUpValues.email = popUpValues.email.toLowerCase();
    if (!popUpValues.driverImage.preview) {
      errorToastMsg("Please upload Profile picture");
      return;
    } else if (!popUpValues.licenseImageFront.preview) {
      errorToastMsg("Please upload Lisence front Image");
      return;
    } else if (!popUpValues.licenseImageBack.preview) {
      errorToastMsg("Please upload Lisence back Image");
      return;
    }
    SetAddDriverArray([...addDriverArray, popUpValues]);
    setRegistrationData({
      ...registrationData,
      tempDriverInfo: addDriverArray,
    });
    setPopUpValues(popUpIntialValues);
    handleClose();
  };

  const handleSocialURLBackButton = () => {
    setReviewLinkState(false);
  };

  const handleAddDriverSubmit = (e) => {
    e.preventDefault();
    setReviewLinkState(true);
  };

  const handleDriverDelete = (unique_id) => {
    const filterArray = addDriverArray.filter(
      (driverInfo) => driverInfo.unique_id != unique_id
    );
    SetAddDriverArray(filterArray);
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const validateMobileNumber = () => {
    const { driverContactNumber } = popUpValues;
    if (driverContactNumber) {
      if (!PHONE_REGEX.test(driverContactNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const validateEmail = () => {
    const { email } = popUpValues;
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsEmailValid(false);
        setEmailErrorMsg("Invalid Email Address.");
      } else {
        setIsEmailValid(true);
        setEmailErrorMsg("");
      }
    } else {
      setIsEmailValid(true);
      setEmailErrorMsg("");
    }
  };

  const handleCrossDriverFunction = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      driverImage: { preview: "", raw: "", value: "" },
    });
  };

  const handleCrossLisenceImage = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      licenseImageFront: { preview: "", raw: "", value: "" },
    });
  };

  const handleCrossLisenceBackImage = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      licenseImageBack: { preview: "", raw: "", value: "" },
    });
  };

  useEffect(() => {
    setRegistrationData({
      ...registrationData,
      tempDriverInfo: addDriverArray,
    });
  }, [addDriverArray]);

//   useEffect(()=>{
//     if(data.image){
//       if(data.imageName){
//         setDriverImages({
//           ...driverImages,
//           [data.imageName]:{
//             preview: URL.createObjectURL(data.image),
//             raw: data.image
//           }
//         });
//       }else{
//         setDriverImages({
//           ...driverImages,
//             preview: URL.createObjectURL(data.image),
//             raw: data.image
//         });
//       }
//     }
// },[data]);

useEffect(()=>{
  if(data.image){
    if(data.imageName){
      setPopUpValues({
        ...popUpValues,
        [data.imageName]:{
          preview: URL.createObjectURL(data.image),
          raw: data.image
        }
      });
    }else{
      setPopUpValues({
        ...popUpValues,
          preview: URL.createObjectURL(data.image),
          raw: data.image
      });
    }
  }
},[data]);

  return (
    <>
      {!reviewLinkState && (
        <div className={styles.logincomponent}>
          <div
            className={`${styles.taxiIcon}`}
            style={{ backgroundImage: `url(${taxiIcon})` }}
          >
            {" "}
          </div>
          <div className={styles.TaxiCRMImageDiv}>
            <div className={styles.IconAndText}>
              <img src={TaxiIcon} className={styles.TaxiIconImage} />
              <p className={styles.imageText}>CRM Application</p>
            </div>
            <div className={styles.TaxiLeftImage}>
              <img src={TaxiLeftImage} width="100%" />
            </div>
          </div>
          <div className={styles.bgrightloginform}>
            <div className={styles.donotaccount}>
              <p>
                Already have an account?
                <span className={styles.registernow}>
                  <Link to="/login">Sign in</Link>
                </span>
              </p>
            </div>
            <div className={styles.registerYourAccount}>
              <h4 className={`mb-8 ${styles.registerText}`}>
                Register your account
              </h4>
              <div className="d-flex justify-content-between mt-4 mb-4">
                <p className={styles.addVehicleFont}>
                  Please add your Driver here
                </p>
                <h6 className={styles.stepText}>
                  <span>3 Step of 5</span>
                </h6>
              </div>
              <div>
                <ShowDriver
                  addDriverArray={registrationData.tempDriverInfo}
                  handleDriverDelete={handleDriverDelete}
                />
              </div>
              <div className={styles.addVechile1}>
                <button
                  type="button"
                  className={styles.addVechile}
                  onClick={handleShow}
                >
                  {" "}
                  + Add Driver
                </button>
              </div>
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
              >
                <div className={styles.modalMainDiv}>
                  <div className={styles.modalDiv}>
                    <div className={`${styles.modalTitle}`}>Add Driver</div>
                    <div
                      className={`${styles.closeButton}`}
                      onClick={handleClose}
                    >
                      x
                    </div>
                  </div>
                  <Modal.Body className={`${styles.mainBodyDiv}`}>
                    <form onSubmit={handleModalSbmission} autocomplete="off">
                      <div
                        className="d-flex align-items-center gap-4"
                        style={{ marginBottom: "20px" }}
                      >
                        {popUpValues?.driverImage.preview ? (
                          <div>
                            <div
                              className={`cross ${styles.crossSign}`}
                              onClick={handleCrossDriverFunction}
                            >
                              <BsXCircle
                                color="#D7E0EE"
                                style={{
                                  backgroundColor: "#272B41",
                                  borderRadius: "50%",
                                  position: "absolute",
                                  top: 2,
                                  right: 1,
                                }}
                              />
                            </div>
                            <img
                              src={popUpValues.driverImage.preview}
                              alt="userImage"
                              className={styles.profileIconImage}
                            />
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <BsPerson className={styles.icons} />
                          </div>
                        )}
                        <div style={{width:'50%'}}>
                          <label
                            htmlFor="file-input"
                            className={styles.uploadImage}
                          >
                            {popUpValues.driverImage.preview
                              ? popUpValues.driverImage.raw.name
                              : "Upload Picture "}
                          </label>
                          <input
                            type="file"
                            id="file-input"
                            className="d-none"
                            name="driverImage"
                            value={popUpValues.driverImage.value}
                            onChange={handleDriverImages}
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          required
                          className={`form-control ${styles.driverName}`}
                          id="exampleFormControlInput1"
                          placeholder="Driver Name"
                          name="driverFullName"
                          value={popUpValues.driverFullName}
                          onChange={handleFormData}
                        />
                      </div>
                      <div className={"mb-3"}>
                        <input
                          type="number"
                          required
                          className={`form-control ${styles.driverContactNumber}`}
                          id="exampleFormControlInput1"
                          placeholder="Driver Contact Number"
                          name="driverContactNumber"
                          onBlur={validateMobileNumber}
                          value={popUpValues.driverContactNumber}
                          onChange={handleFormData}
                        />
                      </div>
                      {!isPhoneValid && (
                        <div style={{ marginBottom:'5px',marginTop:'0px' }} className={`text-danger ${styles.errormessage}`}>
                          {phoneErrorMsg}
                        </div>
                      )}
                      <div className="mb-2">
                        <input
                          type="text"
                          required
                          className={`form-control ${styles.driverName}`}
                          id="exampleFormControlInput1"
                          placeholder="Driver Email"
                          name="email"
                          value={popUpValues.email}
                          onBlur={validateEmail}
                          onChange={handleFormData}
                        />
                      </div>
                      {!isEmailValid && (
                        <div
                          style={{ marginBottom: "5px" }}
                          className={`text-danger ${styles.errormessage}`}
                        >
                          {emailErrorMsg}
                        </div>
                      )}
                      <div className={`form-label fw-bold ${styles.labelFont}`}>
                        <p className={`mb-2 ${styles.uploadRCText}`}>
                          Upload Licence Images
                        </p>
                        <div
                          className={`form-label fw-bold ${styles.uploadRCDiv}`}
                        >
                          <label
                            htmlFor="file-lisencefront"
                            className="form-label px-2 py-1 d-flex gap-2"
                          >
                            <div className={styles.vehicleUpload}>
                              <div className={styles.rCImageDiv}>
                                {popUpValues.licenseImageFront.preview && (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossLisenceImage}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={
                                        popUpValues.licenseImageFront.preview
                                      }
                                      alt="lisenceImageFront"
                                      className={styles.fiRCUploadImage}
                                    />
                                  </div>
                                )}
                                {!popUpValues.licenseImageFront.preview && (
                                  <div className={styles.fileUploadDiv}>
                                    <div>
                                      <FiUpload className={styles.fiRCUpload} />
                                    </div>
                                    <h6 className={styles.RCfontFront}>
                                      Lisence Front
                                    </h6>
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                id="file-lisencefront"
                                className="d-none"
                                name="licenseImageFront"
                                value={popUpValues.licenseImageFront.value}
                                onChange={handleDriverImages}
                                accept="image/*"
                                placeholder="Lisence Front"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-lisencefront"
                            className="d-none"
                            name="licenseImageFront"
                            onChange={handleDriverImages}
                          />
                          <label
                            htmlFor="file-lisenceBack"
                            className="form-label px-2 py-1 d-flex gap-2"
                          >
                            <div className={styles.vehicleUpload}>
                              <div className={styles.rCImageDiv}>
                                {popUpValues.licenseImageBack.preview && (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossLisenceBackImage}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={popUpValues.licenseImageBack.preview}
                                      alt="lisenceImageBack"
                                      className={styles.fiRCUploadImage}
                                    />
                                  </div>
                                )}
                                {!popUpValues.licenseImageBack.preview && (
                                  <div className={styles.fileUploadDiv}>
                                    <div>
                                      <FiUpload className={styles.fiRCUpload} />
                                    </div>
                                    <h6 className={styles.RCfontFront}>
                                      Lisence Back
                                    </h6>
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                id="file-lisenceBack"
                                className="d-none"
                                name="licenseImageBack"
                                value={popUpIntialValues.licenseImageBack.value}
                                onChange={handleDriverImages}
                                accept="image/*"
                                placeholder="Lisence Back"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-lisenceBack"
                            className="d-none"
                            name="licenseImageBack"
                            onChange={handleDriverImages}
                          />
                        </div>
                      </div>
                      <div
                        className={styles.modalButton}
                        style={{ marginTop: "10px" }}
                      >
                        <Button
                          onClick={handleClose}
                          className={styles.modalCancelButton}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          disabled={emailErrorMsg || phoneErrorMsg}
                          className={styles.modalAddButton}
                        >
                          Add
                        </Button>
                      </div>
                    </form>
                  </Modal.Body>
                </div>
              </Modal>
              <div className={`d-flex ${styles.buttonDiv}`}>
                <button
                  type="button"
                  className={`btn btn-outline-secondary ${styles.backbutton}`}
                  style={{ height: "50px", width: "80px" }}
                  onClick={handleAddDriverBackButton}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className={`${styles.submitButton}`}
                  onClick={handleAddDriverSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
      {reviewLinkState && (
        <SocialURLComponent
          handleSocialURLBackButton={handleSocialURLBackButton}
          setRegistrationData={setRegistrationData}
          registrationData={registrationData}
        />
      )}
    </>
  );
};

export default AddDriverComponent;
