import React from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styles from "./payment.module.scss";

const PaymentErrorComponent = ({ callbackFunc }) => {
  const [show, setShow] = useState(true);

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    callbackFunc(false);
  };

  return (
    <Modal
      size="md"
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      keyboard={false}
      className={styles.deleteModal}
    >
      <Modal.Header closeButton className={styles.deleteModalHeader}>
        <Modal.Title style={{ color: "#FF4D4F" }}>
          Incomplete UPI Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ fontSize:'18px', fontWeight:'600' }}>
          {" "}
          If you want to complete the UPI details, then click on below
          button
        </p>
      </Modal.Body>
      <Modal.Footer style={{ borderTop: "none", display:'flex', justifyContent:'center' }}>
        <div style={{ display:'flex', alignContent:'center'  }}>
          <button
            className={`btn ${styles.paymentDeatilsButton}`}
            onClick={() => navigate("/dashboard/bankAccount")}
          >
            Go to Payment Details
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentErrorComponent;
