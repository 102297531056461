import React from "react";
import { useState } from "react";
import styles from "./otp.module.scss";
import bgImage from "../../../assets/images/login_bg_image.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useDispatch, useSelector } from "react-redux";
import taxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../../assets/images/Taxi-sanchalak-left-image.png";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { API_RESENT_OTP, API_VALIDATE_LOGIN_OTP } from "../../../utils/consts/api";
import { useEffect } from "react";
import  Spinner  from "../../../components/Dashboard/CommonComp/Spinner/Spinner";

export const OtpComponent = ({ loginHandle, HandleMobileBackButton, handlemobileNumber, }) => {
  const [mobileData, setMobileData] = useState({
    mobileNumber: handlemobileNumber.mobileNumber,
    otpNumber: "",
  });

  const { mobileNumber, otpNumber } = mobileData;
  const navigate = useNavigate();
  const [resentOTPState, setResentOTPState] = useState(true);   // Toggle state between Resent Button and Timer in UI.
  const [isresentTimerON, setIsResentTimerON] = useState(true);  // State for Timer.
  const [resentOTPTimer, setResentOTPTimer] = useState(30);      // Timer State
  const [loading,setLoading]=useState(false);

  const handleMobileOTPChange = (e) => {
    setMobileData({ ...mobileData, otpNumber: e.target.value });
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleVerifyOTP = () => {
    if (!otpNumber) {
      errorToastMsg("Please Enter the OTP");
      return;
    }
    setLoading(true);
    axios
      .post(API_VALIDATE_LOGIN_OTP, mobileData)
      .then(({ data }) => {
        if (data.responseCode === -1) {
          errorToastMsg(data.errorMsg);
          setLoading(false);
        } else if (data.responseCode === 0) {
          setLoading(false);
          successToastMsg("Sucessfully Verified the OTP");
          sessionStorage.setItem("userToken", data.response.token);
          navigate("/dashboard/mybooking");
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
  };

  const handleResendOTP = () => {
    setLoading(true);
    const headers = {
      "Content-Type": "application/json",
    }
    axios.post(API_RESENT_OTP, { mobileNumber }, { headers }).then(({ data }) => {
      if (data.responseCode === -1) {
        errorToastMsg(data.errorMsg);
        setLoading(false);
      } else if (data.responseCode === 0) {
        setLoading(false);
        successToastMsg(data.successMsg);
        setResentOTPState(true);
        setResentOTPTimer(30);
        setIsResentTimerON(true);
      }
    })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      })
  };

  useEffect(() => {
    let otpTimer;
    if (isresentTimerON) {
      otpTimer = setInterval(() => {
        setResentOTPTimer((previousState) => {
          if (previousState >= 1) {
            return (
              previousState - 1
            )
          } else {
            setIsResentTimerON(false);
            setResentOTPState(false);
            clearInterval(otpTimer);
          }
        });
      }, 1000)
    } else {
      clearInterval(otpTimer);
    }
    return () => clearInterval(otpTimer);
  }, [isresentTimerON, resentOTPState]);


  return (
    <>
    {loading ? (<Spinner/>):(
    <div className={styles.otpcomponent}>
      <div
        className={`${styles.taxiIcon}`}
        style={{ backgroundImage: `url(${taxiIcon})` }}
      ></div>
      <div className={styles.TaxiCRMImageDiv}>
        <div className={styles.IconAndText}>
          <img src={TaxiIcon} className={styles.TaxiIconImage} />
          <p className={styles.imageText}>CRM Application</p>
        </div>
        <div className={styles.TaxiLeftImage}>
          <img src={TaxiLeftImage} width="100%" />
        </div>
      </div>
      {/* <div
        className={`d-none d-md-block ${styles.bgleftimage}`}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
      </div>
      <div className={styles.bgleftimageMediumDiv}>
        <img src={bgImage} className={styles.bgleftimageMedium} />
      </div> */}
      <div className={styles.otpform}>
        <div className={styles.formBox}>
          <h4
            className={`mb-4 ${styles.back}`}
            onClick={HandleMobileBackButton}
          >{`${"<"} Back`}</h4>
          <h4 className={`mb-4 ${styles.mobilelogin}`}>Verify Number</h4>
          <div className={styles.sentOTPDiv} >
            <p className={styles.sentOTP} >We have sent OTP to</p>
            <span className={styles.sentOTPMobileNumber}>+91-{handlemobileNumber.mobileNumber}</span>
          </div>
          <div className={`form-group mb-3 ${styles.inputbox}`}>
            <label htmlFor="exampleInputEmail1" className={styles.inputlabel} style={{ marginBottom: '10px' }}>
              Enter OTP
            </label>
            <input
              type="text"
              className={`form-control ${styles.input}`}
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter OTP Here"
              onChange={handleMobileOTPChange}
              value={otpNumber}
            />
          </div>
          <div className={styles.sendVerifyOTPDiv}>
            <button
              type="submit"
              className={`btn  ${styles.sendcode}`}
              onClick={handleVerifyOTP}
            >
              Verify OTP
            </button>
            {resentOTPState ? <div className={styles.resendOTPDiv}>
              <p className={styles.ResentOTPText}>Resend OTP ({resentOTPTimer}s)</p>
            </div>
              : <button
                type="submit"
                className={`btn  ${styles.sendcode}`}
                onClick={handleResendOTP}
              >
                ReSend OTP
              </button>
            }
          </div>
          <p className={styles.or}>
            <span>Or</span>
          </p>
          <button
            type="submit"
            className={styles.signinwithmobile}
            onClick={loginHandle}
          >
            Sign in with Email & Password
          </button>
        </div>
        <div className={styles.donotaccount}>
          <p>
            Don't have an account?
            <Link to="/register" className={styles.registernow}>
              Register Now
            </Link>
          </p>
        </div>
      </div>
      
    </div>
    )}
    <ToastContainer />
    </>
  );
};
