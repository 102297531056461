import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
// import session from "redux-persist/lib/storage/session";
import {
  errorToastMsg,
  successToastMsg,
} from "../../../../common/ToastMessage";
import { API_COMPLETE_TRIP, API_SKIP_PAYMENT } from "../../../../utils/consts/api";
import styles from "./CompleteTripBookingModal.module.scss";
import PaymentErrorComponent from "./PaymentErrorModal/payment-error-modal";
import { thunkDispatchNotification } from "../../../../redux/action/notification.action";
import { useDispatch, useSelector } from "react-redux";

const CompleteTripBookigmodal = ({
  callbackFunc,
  crmBookingId,
  gettingDataFun,
  SecessfullcallbackFunc,
  setTripCompletedStatus
}) => {
  const [payableAmount, setPayAbleAmount] = useState();
  const [show, setShow] = useState(true);
  const [paymentErrorShow, setPaymentErrorShow] = useState(false);
  const dispatch = useDispatch();

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const handleClose = () => {
    setShow(false);
    callbackFunc(false);
  };

  const handlePaymentMode = (paymentMode) => {
    if (!payableAmount) {
      errorToastMsg("Please fill the Amount First");
      return;
    }

    axios
      .post(
        API_COMPLETE_TRIP,
        {
          crmBookingId,
          paymentMode,
          amount: +payableAmount,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          setShow(false);
          setPaymentErrorShow(true);
        } else if (res.data.responseCode === 0) {
          successToastMsg(res.data.successMsg);
          dispatch(thunkDispatchNotification());
          SecessfullcallbackFunc();
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
        handleClose();
      });
  };

  const handlePayableAmount = (e) => {
    setPayAbleAmount(e.target.value);
  };

  const handlePaymentErrorClose = () => {
    setPaymentErrorShow(false);
    callbackFunc(false);
  };

  const handleSkipMethod = () => {
    axios
      .post(
        API_SKIP_PAYMENT,
        {
          crmBookingId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          setShow(false);
        } else if (res.data.responseCode === 0) {
          successToastMsg(res.data.successMsg);
          dispatch(thunkDispatchNotification());
          SecessfullcallbackFunc();
          setTripCompletedStatus(true);
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
        handleClose();
      });
  };

  return (
    <>
      {/* Modal Related to the Payment Method. */}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Update Trip</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          {/* <div className={styles.gridModal}> */}
          <div className="mb-3">
            <label
              htmlFor="exampleFormControlInput1"
              className={`form-label fw-bold`}
            >
              Request pending payment from client
            </label>
            <input
              type="number"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="payable amount"
              value={payableAmount}
              onChange={handlePayableAmount}
              name="payableAmount"
            />
          </div>
          <div
            className={`d-flex justify-content-start mx-3 mb-3 gap-2 ${styles.buttonDiv}`}
          >
            <Button
              variant="tranparent"
              onClick={() => handlePaymentMode("sendLink")}
              className={`${styles.sendLink} border`}
            >
              Send Link
            </Button>

            <Button
              variant="light"
              onClick={() => handlePaymentMode("cash")}
              className={`${styles.sendCash} border`}
            >
              Cash
            </Button>

            <Button
              variant="light"
              onClick={() => handleSkipMethod()}
              className={`${styles.tripCompleted} border`}
            >
              Trip Completed
            </Button>

            <Button
              variant="light"
              onClick={() => handleClose()}
              className="border"
            >
              Cancel
            </Button>
          </div>
          {/* </div> */}
        </Modal.Body>
      </Modal>

      {paymentErrorShow && (
        <PaymentErrorComponent callbackFunc={handlePaymentErrorClose} />
      )}
    </>
  );
};

export default CompleteTripBookigmodal;
