import axios from "axios";
import { errorToastMsg } from "../../common/ToastMessage";
import { API_GET_NOTIFICATIONS } from "../../utils/consts/api";
export const GETNOTIFICATIONDATA = "GETNOTIFICATIONDATA";

const pageInfo = {
  page: 0,
  size: 100,
};


export const dispatchNotification = (payload)=>{
    return{
        type:GETNOTIFICATIONDATA,
        payload
     }
};


export const thunkDispatchNotification = ()=>(dispatch)=>{
    const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");
    axios
    .post(API_GET_NOTIFICATIONS, pageInfo, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    })
    .then((res) => {
       dispatch(dispatchNotification(res.data.response.notificationList));
    })
    .catch((error) => {
      errorToastMsg(error.msg);
    });
};