import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  errorToastMsg,
  successToastMsg,
} from "../../../../common/ToastMessage";
import {
  API_ASSIGN_BOOKING,
  API_GET_MY_DRIVER,
  API_GET_MY_VEHICLES,
} from "../../../../utils/consts/api";
import AddDriverAndVehicle from "./AddDriverAndVehicle";
import AddDriverModal from "./AddDriver";
import styles from "./AssignBookingModal.module.scss";
import AddVehicleModal from "./AddVehicle";
import { useDispatch, useSelector } from "react-redux";
import { thunkDispatchNotification } from "../../../../redux/action/notification.action";
import AssignOtherDriverAndVehicle from "./AssignOtherDriver";

const AssignBookingModal = ({ callbackFunc, crmBookingId, gettingDataFun }) => {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    callbackFunc(false);
  };
  // const handleShow = () => setShow(true);
  const [dropdownList, setDropdownList] = useState([]);
  const [dropdownValue, setdropdownValue] = useState("");
  const dispatch = useDispatch();

  const [driverDropdownList, setDriverDropdownList] = useState([]);
  const [driverdropdownValue, setDriverdropdownValue] = useState("");

  const [AddVehicleAndDriverModal, setAddVehicleAndDriverModal] =
    useState(false);

  const [AddDriverModalState, setAddDriverModalState] = useState(false);
  const [AddVehicleModalState, setAdVehicleModalState] = useState(false);
  const [otherDriverVehicleModalState,setOtherDriverVehicleModalState] = useState(false);

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const getdropdownList = () => {
    axios
      .get(API_GET_MY_VEHICLES, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleList);
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };
  const getDriverDropdownList = () => {
    axios
      .get(API_GET_MY_DRIVER, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setDriverDropdownList(res.data.response.driverList);
      });
  };

  useEffect(() => {
    getdropdownList();
    getDriverDropdownList();
  }, []);

  const changeDropdownValue = (newF) => {
    setdropdownValue(newF);
  };

  const changeDriverDropdownValue = (newF) => {
    setDriverdropdownValue(newF);
  };

  const AddVehicleAndDriver = () => {
    setAddVehicleAndDriverModal(true);
  };

  const AddDriver = () => {
    setAddDriverModalState(true);
  };

  const addVehicle = () => {
    setAdVehicleModalState(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        API_ASSIGN_BOOKING,
        {
          crmBookingId: crmBookingId,
          assignedVehicleId: dropdownValue,
          assignedDriverId: driverdropdownValue,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          handleClose();
          gettingDataFun();
          dispatch(thunkDispatchNotification());
        }
      });
  };

  const handleOtherDriverstate = ()=>{
    setOtherDriverVehicleModalState(true);
  }

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Assign Vehicle & Driver</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          <div className={styles.gridModal}>
            <form onSubmit={handleSubmit} autocomplete="off">
              <div className="mb-3"></div>
              <div className="mb-3 ">
                <select
                  className={styles.select}
                  id="exampleFormControlInput1"
                  required
                  onChange={(event) => changeDropdownValue(event.target.value)}
                  value={dropdownValue}
                >
                  <option value=""> Assign Vehicle </option>
                  {dropdownList.map((single) => {
                    return (
                      <option
                        key={single.agentVehicleId}
                        value={single.agentVehicleId}
                        className={`form-control ${styles.dropdownMenu}`}
                      >
                        {single.registrationNumber}
                      </option>
                    );
                  })}
                  ;
                </select>
              </div>
              <div className="mb-3 ">
                <select
                  className={styles.select}
                  id="exampleFormControlInput1"
                  required
                  onChange={(event) =>
                    changeDriverDropdownValue(event.target.value)
                  }
                  value={driverdropdownValue}
                >
                  <option value=""> Assign Driver </option>
                  {driverDropdownList.map((single) => {
                    return (
                      <option
                        key={single.driverId}
                        value={single.driverId}
                        className={`form-control ${styles.dropdownMenu}`}
                      >
                        {single.driverFullName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={`d-flex gap-3 ${styles.buttonDiv}`}>
                <Button
                  variant="warning"
                  type="submit"
                  style={{ color: "white" }}
                >
                  Assign
                </Button>
                <Button
                  variant="light"
                  onClick={handleClose}
                  className="border"
                >
                  Cancel
                </Button>
                <Button
                  variant="secondary"
                  className={` ml-auto p-2`}
                  onClick={addVehicle}
                  style={{ color: "white" }}
                >
                  + Add New Vehicle
                </Button>
                <Button
                  variant="secondary"
                  className={` ml-auto p-2`}
                  onClick={AddDriver}
                  style={{ color: "white" }}
                >
                  + Add New Driver
                </Button>
                <Button
                  variant="secondary"
                  className={` ml-auto p-2`}
                  onClick={handleOtherDriverstate}
                  style={{ color: "white" }}
                >
                  + Assign Other Vehicle/Driver
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {AddVehicleAndDriverModal && (
        <AddDriverAndVehicle
          callbackFunc1={setAddVehicleAndDriverModal}
          crmBookingId={crmBookingId}
          gettingDataFun={gettingDataFun}
          callbackFunc={callbackFunc}
        />
      )}
      {AddDriverModalState && (
        <AddDriverModal
          callbackFunc1={setAddDriverModalState}
          callbackFunc={callbackFunc}
          getDriverDropdownList={getDriverDropdownList}
        />
      )}
      {AddVehicleModalState && (
        <AddVehicleModal
          callbackFunc1={setAdVehicleModalState}
          callbackFunc={callbackFunc}
          getVehicleList={getdropdownList}
        />
      )}
      {otherDriverVehicleModalState && (
        <AssignOtherDriverAndVehicle
          gettingDataFun={gettingDataFun}
          crmBookingId = {crmBookingId}
          callbackFunc1 = {setOtherDriverVehicleModalState}
          callbackFunc = {handleClose}
        />
      )}
    </>
  );
};

export default AssignBookingModal;
