import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./ConfirmBookingModal.module.scss";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_CONFIRM_BOOKING } from "../../../../utils/consts/api";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import { thunkDispatchNotification } from "../../../../redux/action/notification.action";
import { TextField, ThemeProvider, createTheme } from "@mui/material";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ConfirmBookingModal = ({
  element,
  callbackFunc,
  crmBookingId,
  gettingDataFun,
}) => {
  const [show, setShow] = useState(true);
  const [notifyData, setNotifyData] = useState({
    crmBookingId: crmBookingId,
    notificationDate: "",
    notificationTime: "",
  });
  const [minDate, setMinDate] = useState("");
  const [min, setMin] = useState("00:00");
  const max = "23:59";
  const dispatch = useDispatch();
  const [selectedTime, setSelectedTime] = useState(null);

  const disableDates = () => {
    let today;
    today = new Date();
    const todayDate = moment(today).format("YYYY-MM-DD");
    setMinDate(todayDate);
  };

  const disableTime = (value) => {
    let minTime;

    let d = new Date();
    let todayDate = moment(d).format("YYYY-MM-DD");
    let hrs = d.getHours();
    let mins = d.getMinutes();
    if (hrs < 10) {
      hrs = "0" + hrs;
    }
    if (mins < 10) {
      mins = "0" + mins;
    }
    if (todayDate === value) {
      minTime = `${hrs}:${mins}`;
    } else {
      minTime = "00:00";
    }

    setMin(minTime);
  };
  useEffect(() => {
    disableDates();
  }, []);

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const handleClose = () => {
    setShow(false);
    callbackFunc(false);
  };

  const handleInput = (e) => {
    if (e.target.name === "notificationDate") {
      disableTime(e.target.value);
    }
    setNotifyData({ ...notifyData, [e.target.name]: e.target.value });
  };

  const handleBookingSumission = (e) => {
    e.preventDefault();
    if (!notifyData.notificationTime) {
      errorToastMsg("Please select notifiaction time");
      return;
    }

    if (
      notifyData.notificationDate &&
      new Date(
        notifyData.notificationDate.concat(" ", notifyData.notificationTime)
      ).getTime() <=
        new Date(moment(new Date()).format("YYYY-MM-DD hh:mm A")).getTime()
    ) {
      errorToastMsg(
        "Your booking date and time both should be greater than current "
      );
      return;
    }

    if (
      notifyData.notificationDate &&
      new Date (moment(element.pickUpDate)
        .format("YYYY-MM-DD")
        .concat(" ", element.pickUpTime))
        .getTime() <
      new Date (notifyData.notificationDate.concat(" ", notifyData.notificationTime)).getTime()
    ) {
      errorToastMsg("Notification date and time should be less than Booking date and time");
      return;
    };

    axios
      .post(API_CONFIRM_BOOKING, notifyData, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) errorToastMsg(res.data.errorMsg);
        else {
          successToastMsg(res.data.successMsg);
          setNotifyData({
            crmBookingId: crmBookingId,
            notificationDate: "",
            notificationTime: "",
          });
          dispatch(thunkDispatchNotification());
          gettingDataFun();
          handleClose();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
      });
  };

  const handleNotificationTime = (newTime) => {
    setSelectedTime(newTime);
    const dateTime = newTime.format("YYYY-MM-DD hh:mm A").trim().split(" ");
    const timeToSet = `${dateTime[1]} ${dateTime[2]}`;
    setNotifyData({ ...notifyData, notificationTime: timeToSet });
  };

  // Theme for Rendering clock

  const theme = createTheme({
    components: {
      MuiDesktopTimePicker: {
        defaultProps: {
          viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          },
        },
      },
      MuiDesktopDateTimePicker: {
        defaultProps: {
          viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          },
        },
      },
    },
  });

  //  Toastify Message

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Notify Me To Assign Vehicle</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          <form onSubmit={handleBookingSumission} autocomplete="off">
            <div className={styles.gridModal}>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Notification Date
                </label>
                <input
                  type="Date"
                  min={minDate}
                  className={`${styles.inputFont}`}
                  id="exampleFormControlInput1"
                  placeholder="mm/dd/yyyy"
                  name="notificationDate"
                  onChange={handleInput}
                  // value={formValues.customerName}
                  // className="input-group input-group-sm"
                  required
                />
              </div>
              <div className={`mb-0 ${styles.pickUpTime}`}>
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold `}
                >
                  Notification Time
                </label>
                {/* <input
                type="time"
                className={` ${styles.inputFont}`}
                id="exampleFormControlInput1"
                placeholder="Mm/hh"
                name="notificationTime"
                onChange={handleInput}
                min={min}
                max={max}
                //   value={formValues.mobileNumber}
                required
              /> */}
                <LocalizationProvider
                  style={{ height: "34px" }}
                  dateAdapter={AdapterDayjs}
                >
                  <ThemeProvider style={{ height: "34px" }} theme={theme}>
                    <TimePicker
                      className={styles.timePickerInput}
                      style={{ height: "34px" }}
                      onChange={handleNotificationTime}
                      value={selectedTime}
                      // minTime={
                      //   notifyData.notificationDate ===
                      //   moment(new Date()).format("YYYY-MM-DD")
                      //     ? moment(new Date())
                      //     : null
                      // }
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </ThemeProvider>
                </LocalizationProvider>
              </div>
            </div>

            <div
              className={`justify-content-start mt-2 gap-3 ${styles.buttonDesktopDiv}`}
            >
              <Button
                type="submit"
                variant="warning"
                style={{ color: "white" }}
              >
                Notify me
              </Button>
              <Button variant="light" onClick={handleClose} className="border">
                Cancel
              </Button>
            </div>
            <div
              className={`justify-content-start mt-2 gap-3 ${styles.buttonMobileDiv}`}
            >
              <Button variant="light" onClick={handleClose} className="border">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="warning"
                style={{ color: "white" }}
              >
                Notify me
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default ConfirmBookingModal;
