import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "./AddBookingModal.module.scss";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import axios from "axios";
import moment from "moment";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { errorToastMsg } from "../../../../common/ToastMessage";
import {
  API_CREATE_BOOKING,
  API_GET_VEHICLE_TYPE,
  EMAIL_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
  SEATING_CAPACITY_REGEX,
} from "../../../../utils/consts/api";
import CheckedIcon from "../../../../assets/images/check.png";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker, renderTimeViewClock } from "@mui/x-date-pickers";
import { TextField, ThemeProvider, createTheme } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AddBookingModal = ({
  callbackFunc,
  mesg,
  gettingDataFun,
  callbackFuncForError,
  setLoader,
}) => {
  const [show, setShow] = useState(true);
  const [oneWay, setOneWay] = useState(true);
  const [roundTrip, setRoundTrip] = useState(false);
  const [vehicleDropDown, setVehicleDropDown] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [minDate, setMinDate] = useState("");
  const handlePopUpOpen = () => setOpen(true);
  const handlePopUpClose = () => setOpen(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [min, setMin] = useState("00:00");
  const [selectedTime, setSelectedTime] = useState(null);
  const [oneWayselectedTime, SetOneWaySelectedTime] = useState(null);
  const [timeError, setTimeError] = useState(false);
  const max = "23:59";

  const handleClose = () => {
    setShow(false);
    callbackFuncForError();
    setIsValid(true);
    setIsPhoneValid(true);
  };
  const handleShow = () => setShow(true);

  const handleOneWay = () => {
    setOneWay(true);
    setRoundTrip(false);
  };
  const handleRound = () => {
    setOneWay(false);
    setRoundTrip(true);
  };

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  //one way pick up and drop location:-
  const [addressPickUp, setAddressPickUp] = useState("");

  const [addressDrop, setAddressDrop] = useState("");
  const isNum = /^[0-9]*$/;

  const handleSelectPickUp = async (value) => {
    let latLngPickUp;
    const results = await geocodeByAddress(value);
    latLngPickUp = await getLatLng(results[0]);

    setAddressPickUp(value);
    setOneWayValue({
      ...oneWayValue,
      pickupLocationLat: latLngPickUp.lat,
      pickupLocationLng: latLngPickUp.lng,
      pickupLocationName: value,
    });
  };
  const handleSelectDrop = async (value) => {
    const results = await geocodeByAddress(value);
    const latLngDrop = await getLatLng(results[0]);
    setAddressDrop(value);
    setOneWayValue({
      ...oneWayValue,
      dropLocationLat: latLngDrop.lat,
      dropLocationLng: latLngDrop.lng,
      dropLocationName: value,
    });
  };

  const [oneWayValue, setOneWayValue] = useState({
    customerName: "",
    customerContactNumber: "",
    customerEmail: "",
    bookingType: "oneWay",
    vehicleTypeId: "",
    pickupDate: "",
    pickupTime: "",
    pickupLocationLat: "",
    pickupLocationLng: "",
    pickupLocationName: "",
    dropLocationLat: "",
    dropLocationLng: "",
    dropLocationName: "",
    bookingAmount: "",
    noOfDays: "",
    tripNotes: "",
    seatingCapacity: "",
    tourDescription: "",
  });

  const handleOneWayInput = (e) => {
    e.persist();

    const { name, value } = e.target;
    if (name === "pickupDate") {
      disableTime(value);
      setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
    } else if (name === "customerName") {
      if (value === " " || !NAME_REGEX.test(value)) {
        setOneWayValue({
          ...oneWayValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      } else {
        setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
      }
    } else if (name === "customerEmail" || name === "tourDescription") {
      if (value === " ") {
        setOneWayValue({
          ...oneWayValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      } else {
        setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
      }
    } else if (name === "customerContactNumber") {
      if (isNum.test(value) && value.length <= 10) {
        setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
      } else {
        setOneWayValue({
          ...oneWayValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "seatingCapacity") {
      const numericSeatCapacity = value.split("+");
      if (SEATING_CAPACITY_REGEX.test(value) && numericSeatCapacity[0] <= 52) {
        if (numericSeatCapacity[1]) {
          if (numericSeatCapacity[1] <= 9) {
            setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
          } else {
            setOneWayValue({
              ...oneWayValue,
              [e.target.name]: e.target.value.slice(0, -1),
            });
          }
        } else {
          setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
        }
      } else {
        setOneWayValue({
          ...oneWayValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "bookingAmount" && value?.length > 0) {
      if (isNum.test(value) && value > 0) {
        setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
      } else {
        setOneWayValue({
          ...oneWayValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "noOfDays") {
      if (isNum.test(value) && value <= 9) {
        setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
      } else {
        setOneWayValue({ ...oneWayValue, name: value.slice(0, -1) });
      }
    } else {
      setOneWayValue({ ...oneWayValue, [e.target.name]: e.target.value });
    }
  };

  const handleOneWayadd = (e) => {
    e.preventDefault();
    oneWayValue.customerEmail = oneWayValue.customerEmail.toLocaleLowerCase();
    if (oneWay) {
      if(!oneWayValue.pickupTime){
          errorToastMsg('Please select booking time');
          return;
      }
      setLoader(true);
  
      if ( oneWayValue.pickupDate &&
        new Date(oneWayValue.pickupDate.concat(" ", oneWayValue.pickupTime)).getTime() <=
        new Date(moment(new Date()).format("YYYY-MM-DD hh:mm A")).getTime()
      ) {
        errorToastMsg(
          "Your booking date and time both should be greater than current "
        );
        return;
      }
      axios
        .post(API_CREATE_BOOKING, oneWayValue, {
          headers: {
            "Content-Type": "Application/json",
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          if (res.data.responseCode === -1) {
            errorToastMsg(res.data.errorMsg);
            callbackFuncForError(false);
            setLoader(false);
          } else if (res.data.responseCode === 0) {
            callbackFunc();
            setModalState(true);
            handlePopUpOpen();
            gettingDataFun();
            setLoader(false);
          }
        })
        .catch((error) => {
          errorToastMsg(error.message);
          setLoader(false);
        });
    } else {
      if(!roundTripValue.pickupTime){
         errorToastMsg('Please select the booking time');
         return;
      }
      setLoader(true);

      if ( roundTripValue.pickupDate &&
        new Date(roundTripValue.pickupDate.concat(" ", roundTripValue.pickupTime)).getTime() <=
        new Date(moment(new Date()).format("YYYY-MM-DD hh:mm A")).getTime()
      ) {
        errorToastMsg(
          "Your booking date and time both should be greater than current "
        );
        return;
      }
      axios
        .post(
          API_CREATE_BOOKING,
          { ...oneWayValue, ...roundTripValue },
          {
            headers: {
              "Content-Type": "Application/json",
              Authorization: `${token}`,
            },
          }
        )
        .then(({ data }) => {
          if (data.responseCode === -1) {
            errorToastMsg(data.errorMsg);
            setLoader(false);
          } else if (data.responseCode === 0) {
            callbackFunc();
            gettingDataFun();
            setLoader(false);
          }
        })
        .catch((err) => {
          errorToastMsg(err.message);
          setLoader(false);
        });
    }
  };

  // roundTrip pick and drop:-
  const [roundTripPickUp, setRoundTripPickUp] = useState("");

  const handleRoundPickUp = async (value) => {
    const results = await geocodeByAddress(value);
    const latLngPickUp = await getLatLng(results[0]);
    setRoundTripPickUp(value);
    setRoundTripValue({
      ...roundTripValue,
      pickupLocationLat: latLngPickUp.lat,
      pickupLocationLng: latLngPickUp.lng,
      pickupLocationName: value,
    });
  };

  const [roundTripValue, setRoundTripValue] = useState({
    bookingType: "roundTrip",
    vehicleTypeId: "",
    noOfDays: "",
    tripNotes: "",
    seatingCapacity: "",
    pickupDate: "",
    pickupTime: "",
    pickupLocationLat: "",
    pickupLocationLng: "",
    pickupLocationName: "",
    tourDescription: "",
    bookingAmount: "",
  });

  const handleRoundTripValue = (e) => {
    const { name, value } = e.target;
    if (name === "pickupDate") {
      disableTime(value);
      setRoundTripValue({ ...roundTripValue, [e.target.name]: e.target.value });
    } else if (name === "tourDescription") {
      if (value === " ") {
        setRoundTripValue({ ...roundTripValue, [name]: value.slice(0, -1) });
        return;
      } else {
        setRoundTripValue({ ...roundTripValue, [name]: value });
        return;
      }
    } else if (name === "seatingCapacity") {
      const numericSeatCapacity = value.split("+");
      if (SEATING_CAPACITY_REGEX.test(value) && numericSeatCapacity[0] <= 52) {
        if (numericSeatCapacity[1]) {
          if (numericSeatCapacity[1] <= 9) {
            setRoundTripValue({
              ...roundTripValue,
              [e.target.name]: e.target.value,
            });
          } else {
            setRoundTripValue({
              ...roundTripValue,
              [e.target.name]: e.target.value.slice(0, -1),
            });
          }
        } else {
          setRoundTripValue({
            ...roundTripValue,
            [e.target.name]: e.target.value,
          });
        }
      } else {
        setRoundTripValue({
          ...roundTripValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "bookingAmount") {
      if (isNum.test(value) && value > 0) {
        setRoundTripValue({
          ...roundTripValue,
          [e.target.name]: e.target.value,
        });
      } else {
        setRoundTripValue({
          ...roundTripValue,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "noOfDays") {
      if (isNum.test(value) && value <= 99) {
        setRoundTripValue({
          ...roundTripValue,
          [e.target.name]: e.target.value,
        });
      } else {
        setRoundTripValue({ ...roundTripValue, name: value.slice(0, -1) });
      }
    } else {
      setRoundTripValue({ ...roundTripValue, [e.target.name]: e.target.value });
    }
  };

  const handleRoundTripTimePicker = (newTime) => {
    setSelectedTime(newTime);
    const dateTime = newTime.format("YYYY-MM-DD hh:mm A").trim().split(" ");
    const timeToSet = `${dateTime[1]} ${dateTime[2]}`;
    setRoundTripValue({ ...roundTripValue, pickupTime: timeToSet });
  };

  const handleOneWayTimePicker = (newTime) => {
    SetOneWaySelectedTime(newTime);
    const dateTime = newTime.format("YYYY-MM-DD hh:mm A").trim().split(" ");
    const timeToSet = `${dateTime[1]} ${dateTime[2]}`;
    setOneWayValue({ ...oneWayValue, pickupTime: timeToSet });
  };

  const handleTimeError = (newError) => {
    if (newError) {
      setTimeError(true);
    } else {
      setTimeError(false);
    }
  };

  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setVehicleDropDown(res.data.response.vehicleType);
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  useEffect(() => {
    getdropdownList();
  }, []);

  const searchOptions = {
    componentRestrictions: { country: ["ind"] },
  };

  const disableDates = () => {
    let today;
    today = new Date();
    const todayDate = moment(today).format("YYYY-MM-DD");
    setMinDate(todayDate);
  };

  const disableTime = (value) => {
    let minTime;

    let d = new Date();
    let todayDate = moment(d).format("YYYY-MM-DD");
    let hrs = d.getHours();
    let mins = d.getMinutes();
    if (hrs < 10) {
      hrs = "0" + hrs;
    }
    if (mins < 10) {
      mins = "0" + mins;
    }
    if (todayDate === value) {
      minTime = `${hrs}:${mins}`;
    } else {
      minTime = "00:00";
    }

    setMin(minTime);
  };
  useEffect(() => {
    disableDates();
  }, []);

  const validateEmail = () => {
    const { customerEmail } = oneWayValue;
    if (customerEmail) {
      if (!EMAIL_REGEX.test(customerEmail)) {
        setIsValid(false);
        setErrorMsg("Invalid Email Address.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    const { customerContactNumber } = oneWayValue;
    if (customerContactNumber) {
      if (!PHONE_REGEX.test(customerContactNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const theme = createTheme({
    components: {
      MuiDesktopTimePicker: {
        defaultProps: {
          viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          },
        },
      },
      MuiDesktopDateTimePicker: {
        defaultProps: {
          viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          },
        },
      },
    },
  });

  // const isTimeValid = () => {
  //   if (!selectedTime) {
  //     return false;
  //   }

  //   const selectedMoment = moment(selectedTime);
  //   return selectedMoment.isValid();
  // };

  return (
    <div className={styles.AddBookingModalMain}>
      {/* ---------------------  modal ----------------------- */}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={true}
        scrollable
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Add New Booking</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body className={`${styles.modalBody}`}>
          <form onSubmit={handleOneWayadd} autoComplete="chrome-off">
            <div className="mb-2">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold my-1`}
              >
                Customer Name
              </label>
              <input
                type="text"
                className={`input-group input-group-sm testing ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Customer name"
                required
                autoComplete="false"
                name="customerName"
                onChange={handleOneWayInput}
                value={oneWayValue.customerName}
              />
            </div>
            <div className="mb-2">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold my-1`}
              >
                Customer Mobile Number
              </label>
              <input
                type="number"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Customer Mobile Number"
                required
                name="customerContactNumber"
                onBlur={validatePhoneNumber}
                onChange={handleOneWayInput}
                value={oneWayValue.customerContactNumber}
              />
            </div>
            {!isPhoneValid && (
              <div className={`text-danger ${styles.errormessage}`}>
                {phoneErrorMsg}
              </div>
            )}
            <div className="mb-2">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold my-1`}
              >
                Customer Email(Optional)
              </label>
              <input
                type="text"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Customer Email"
                onBlur={validateEmail}
                name="customerEmail"
                value={oneWayValue.customerEmail}
                onChange={handleOneWayInput}
              />
            </div>
            {!isValid && (
              <div className={`text-danger ${styles.errormessage}`}>
                {errorMsg}
              </div>
            )}
            <div className={`d-flex justify-content-start mb-3 gap-2 `}>
              <div className="mt-3">
                <span className={styles.oneWayRoundWayButtonDiv}>
                  <button
                    className={styles.btnOne}
                    disabled={oneWay}
                    onClick={handleOneWay}
                    style={{
                      backgroundColor: oneWay ? "#F7B217" : "",
                      color: oneWay ? "white" : "#9299B8",
                    }}
                  >
                    One Way
                  </button>
                  <button
                    className={styles.btnRound}
                    disabled={roundTrip}
                    onClick={handleRound}
                    style={{
                      backgroundColor: roundTrip ? "#F7B217" : "",
                      color: roundTrip ? "white" : "#9299B8",
                    }}
                  >
                    {" "}
                    Round Trip{" "}
                  </button>
                </span>
              </div>
            </div>
            {roundTrip ? null : (
              <>
                <div className={`${styles.gridModal} pb-0`}>
                  <div className={`${styles.selectOneWay}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Vehicle Type
                    </label>
                    <div className={`mb-2 ${styles.selectDiv}`}>
                      <select
                        className={`${styles.select}`}
                        name="vehicleTypeId"
                        required
                        onChange={(event) => handleOneWayInput(event)}
                        value={oneWayValue.vehicleTypeId}
                      >
                        <option value="">Select Vehicle Type</option>
                        {vehicleDropDown.map((single) => {
                          return (
                            <option
                              key={single.vehicleTypeId}
                              value={single.vehicleTypeId}
                              className={`form-control ${styles.dropdownMenu}`}
                            >
                              {single.vehicleTypeName}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className={`${styles.selectOneWay}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Seating Capacity
                    </label>
                    <input
                      className={`${styles.input}`}
                      placeholder="Seating Capacity"
                      type="text"
                      value={oneWayValue.seatingCapacity}
                      name="seatingCapacity"
                      onChange={handleOneWayInput}
                    />
                  </div>
                </div>

                <div className={`${styles.gridModal} pb-0`}>
                  <div className={`${styles.selectOneWay}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      No of Days
                    </label>
                    <div className={`mb-2 ${styles.selectDiv}`}>
                      <input
                        className={`${styles.input}`}
                        placeholder="No of Days"
                        type="number"
                        value={oneWayValue.noOfDays}
                        name="noOfDays"
                        onChange={handleOneWayInput}
                      />
                    </div>
                  </div>
                  <div className={`${styles.selectOneWay}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Trip Notes
                    </label>
                    <input
                      className={`${styles.input}`}
                      placeholder="Enter Trip Notes"
                      type="text"
                      name="tripNotes"
                      id=""
                      onChange={handleOneWayInput}
                    />
                  </div>
                </div>
                <div className={`${styles.gridModal} pb-0`}>
                  <div className="mb-0">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Pick Up Date
                    </label>
                    <input
                      type="date"
                      min={minDate}
                      className={`form-control ${styles.pickUpDate}`}
                      id="exampleFormControlInput1"
                      required
                      name="pickupDate"
                      onChange={handleOneWayInput}
                    />
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["DatePicker"]}>
                        <DatePicker label="Basic date picker" />
                      </DemoContainer>
                    </LocalizationProvider> */}
                  </div>
                  <div className={`mb-0 ${styles.pickUpTime}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1 ${styles.pickUpTime}`}
                    >
                      Pick Up Time
                    </label>
                    {/* <input
                      type="time"
                      className={`form-control ${styles.pickUpTime}`}
                      id="exampleFormControlInput1"
                      name="pickupTime"
                      onChange={handleOneWayInput}
                      min={min}
                      max={max}
                      required
                    /> */}
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                    >
                      <ThemeProvider theme={theme}>
                        <TimePicker
                          required={true}
                          onError={handleTimeError}
                          className={styles.timePickerInput}
                          style={{ height: "34px" }}
                          onChange={handleOneWayTimePicker}
                          value={oneWayselectedTime}
                          // minTime={
                          //   oneWayValue.pickupDate ===
                          //   moment(new Date()).format("YYYY-MM-DD")
                          //     ? moment(new Date())
                          //     : null
                          // }
                          renderInput={(params) => (
                            <TextField
                             style={{ height: "0px" }}
                            {...params} onError={handleTimeError} />
                          )}
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </div>
                </div>
                <div
                  className={`${styles.gridModal} ${styles.suggestionContainer} pb-0`}
                >
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Pick Up Location
                    </label>

                    <PlacesAutocomplete
                      value={addressPickUp}
                      onChange={(address) =>
                        address !== " " ? setAddressPickUp(address) : null
                      }
                      searchOptions={searchOptions}
                      required
                      onSelect={handleSelectPickUp}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Select Pickup Location",
                              className: `${styles.input}`,
                            })}
                            required
                          />
                          <div
                            className={styles.addressSearchOnWay}
                            style={{
                              border:
                                suggestions.length > 0
                                  ? "1px solid lightgray"
                                  : "",
                              paddingTop: suggestions.length > 0 ? "10px" : "",
                              paddingBottom:
                                suggestions.length > 0 ? "10px" : "",
                            }}
                          >
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#F7B217"
                                  : "",
                                color: suggestion.active ? "#fff" : "black",
                                cursor: "pointer",
                                borderRadius: "5px",
                                padding: "10px 0px",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  <div
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      color: "white",
                                    }}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Drop Location
                    </label>
                    <PlacesAutocomplete
                      value={addressDrop}
                      required
                      searchOptions={searchOptions}
                      onChange={(address) =>
                        address !== " " ? setAddressDrop(address) : null
                      }
                      onSelect={handleSelectDrop}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            {...getInputProps({
                              placeholder: "Type address",
                              className: `${styles.input}`,
                            })}
                            required
                          />

                          <div
                            className={styles.addressSearchOnWay}
                            style={{
                              border:
                                suggestions.length > 0
                                  ? "1px solid lightgray"
                                  : "",
                              paddingTop: suggestions.length > 0 ? "10px" : "",
                              paddingBottom:
                                suggestions.length > 0 ? "10px" : "",
                            }}
                          >
                            {suggestions.map((suggestion) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#F7B217"
                                  : "",
                                color: suggestion.active ? "#fff" : "black",
                                cursor: "pointer",
                                borderRadius: "5px",
                                padding: "5px",
                              };

                              return (
                                <div
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  <div
                                    style={{
                                      paddingLeft: "10px",
                                      paddingRight: "10px",
                                      color: "white",
                                    }}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className={`form-label fw-bold my-1`}
                  >
                    Enter Tour Description
                  </label>
                  <input
                    type="text"
                    className={`input-group input-group-sm ${styles.input}`}
                    id="exampleFormControlInput1"
                    placeholder="Enter Tour Description"
                    required
                    name="tourDescription"
                    value={oneWayValue.tourDescription}
                    onChange={handleOneWayInput}
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className={`form-label fw-bold my-1`}
                  >
                    Total Booking Amount
                  </label>
                  <input
                    type="number"
                    className={`input-group input-group-sm ${styles.input}`}
                    id="exampleFormControlInput1"
                    placeholder="Enter Total Booking Amount"
                    required
                    name="bookingAmount"
                    onChange={handleOneWayInput}
                    value={oneWayValue.bookingAmount}
                  />
                </div>
              </>
            )}

            {/* ************************************************* */}

            {roundTrip ? (
              <>
                <div className={` mb-1 ${styles.rountTripVehicleNDays}`}>
                  <div className={styles.vehiclTypeDiv}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Vehicle Type
                    </label>
                    <select
                      name="vehicleTypeId"
                      onChange={handleRoundTripValue}
                      className={styles.vehilceSelect}
                      required
                    >
                      <option value="" disabled selected hidden>
                        Select Vehicle Type
                      </option>
                      {vehicleDropDown.map((single) => {
                        return (
                          <option
                            key={single.vehicleTypeId}
                            value={single.vehicleTypeId}
                            className={`form-control ${styles.dropdownMenu}`}
                          >
                            {single.vehicleTypeName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className={styles.noOfDays}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Seating Capacity
                    </label>
                    <input
                      type="text"
                      className={`input-group input-group-sm ${styles.noOfDaysInput}`}
                      id="exampleFormControlInput1"
                      placeholder="Select capacity"
                      required
                      name="seatingCapacity"
                      value={roundTripValue.seatingCapacity}
                      onChange={handleRoundTripValue}
                    />
                  </div>
                </div>

                <div className={`${styles.gridModal} pb-0`}>
                  <div className={`${styles.selectOneWay}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      No of Days
                    </label>
                    <div className={`mb-2 ${styles.selectDiv}`}>
                      <input
                        type="number"
                        className={`${styles.input}`}
                        id="exampleFormControlInput1"
                        placeholder="Enter Days"
                        required
                        value={roundTripValue.noOfDays}
                        name="noOfDays"
                        onChange={handleRoundTripValue}
                      />
                    </div>
                  </div>
                  <div className={`${styles.selectOneWay}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Trip Notes
                    </label>
                    <input
                      className={`${styles.input}`}
                      required
                      placeholder="Enter Trip Notes"
                      type="text"
                      name="tripNotes"
                      onChange={handleRoundTripValue}
                    />
                  </div>
                </div>

                {/* <div className={styles.rountTripVehicleNDays}>
                  <div className={styles.noOfDays}>
                      <label
                        htmlFor="exampleFormControlInput1"
                        className={`form-label fw-bold my-1`}
                      >
                        No Of Days
                      </label>
                      <input
                        type="text"
                        className={`input-group input-group-sm ${styles.noOfDaysInput}`}
                        id="exampleFormControlInput1"
                        placeholder="Enter Days"
                        required
                        name="noOfDays"
                        onChange={handleRoundTripValue}
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="exampleFormControlInput1"
                        className={`form-label fw-bold my-1`}
                      >
                        Trip Not
                      </label>
                      <input
                        type="text"
                        className={`input-group input-group-sm ${styles.input}`}
                        id="exampleFormControlInput1"
                        placeholder="Enter Trip Notes"
                        required
                        name="tripNotes"
                        onChange={handleRoundTripValue}
                      />
                    </div>
                </div> */}
                {/* <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className={`form-label fw-bold my-1`}
                  >
                    Seating Capacity
                  </label>
                  <input
                    type="text"
                    className={`input-group input-group-sm ${styles.input}`}
                    id="exampleFormControlInput1"
                    placeholder="Select capacity"
                    required
                    name="seatingCapacity"
                    value={roundTripValue.seatingCapacity}
                    onChange={handleRoundTripValue}
                  />
                </div> */}
                <div className={`${styles.gridModal} pb-0`}>
                  <div className="mb-0">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1`}
                    >
                      Pick Up Date
                    </label>
                    <input
                      type="date"
                      min={minDate}
                      className={`form-control ${styles.pickUpDate}`}
                      id="exampleFormControlInput1"
                      placeholder="Pick up date"
                      name="pickupDate"
                      onChange={handleRoundTripValue}
                      required
                    />
                  </div>
                  <div className={`mb-0 ${styles.pickUpTime}`}>
                    <label
                      htmlFor="exampleFormControlInput1"
                      className={`form-label fw-bold my-1 ${styles.pickUpTime}`}
                    >
                      Pick Up Time
                    </label>
                    {/* <input
                      type="time"
                      className={`form-control ${styles.pickUpTime}`}
                      id="exampleFormControlInput1"
                      placeholder="Pick up time"
                      name="pickupTime"
                      onChange={handleRoundTripValue}
                      min={min}
                      max={max}
                      required
                    /> */}
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                    >
                      <ThemeProvider theme={theme}>
                        <TimePicker
                          required
                          inputProps={{ required: true }}
                          onError={handleTimeError}
                          className={styles.timePickerInput}
                          style={{ height: "34px" }}
                          onChange={handleRoundTripTimePicker}
                          value={selectedTime}
                          // minTime={
                          //   roundTripValue.pickupDate ===
                          //   moment(new Date()).format("YYYY-MM-DD")
                          //     ? moment(new Date())
                          //     : null
                          // }
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              onError={handleTimeError}
                            />
                          )}
                        />
                      </ThemeProvider>
                    </LocalizationProvider>
                  </div>
                </div>
                <div className={`${styles.suggestionContainer} mb-2`}>
                  <label
                    htmlFor="exampleFormControlInput1"
                    className={`form-label fw-bold my-1`}
                  >
                    Pick Up Location
                  </label>
                  <PlacesAutocomplete
                    searchOptions={searchOptions}
                    value={roundTripPickUp}
                    onChange={(address) =>
                      address !== " " ? setRoundTripPickUp(address) : null
                    }
                    onSelect={handleRoundPickUp}
                    required
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <input
                          {...getInputProps({
                            placeholder: "Select Pickup Location",
                            className: `${styles.input}`,
                          })}
                          required
                        />

                        <div
                          className={styles.addressSearchRoundTrip}
                          style={{
                            border:
                              suggestions.length > 0
                                ? "1px solid lightgray"
                                : "",
                            paddingTop: suggestions.length > 0 ? "10px" : "",
                            paddingBottom: suggestions.length > 0 ? "10px" : "",
                          }}
                        >
                          {suggestions.map((suggestion) => {
                            const style = {
                              backgroundColor: suggestion.active
                                ? "#F7B217"
                                : "",
                              color: suggestion.active ? "#fff" : "black",
                              cursor: "pointer",
                              borderRadius: "5px",
                              padding: "5px",
                            };

                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  style,
                                })}
                              >
                                <div
                                  style={{
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                    color: "white",
                                  }}
                                >
                                  <span>{suggestion.description}</span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className={`form-label fw-bold my-1`}
                  >
                    Enter Tour Description
                  </label>
                  <input
                    type="text"
                    className={`input-group input-group-sm ${styles.input}`}
                    id="exampleFormControlInput1"
                    placeholder="Enter Tour Description"
                    required
                    name="tourDescription"
                    value={roundTripValue.tourDescription}
                    onChange={handleRoundTripValue}
                  />
                </div>
                <div className="mb-2">
                  <label
                    htmlFor="exampleFormControlInput1"
                    className={`form-label fw-bold`}
                  >
                    Total Booking Amount
                  </label>
                  <input
                    type="number"
                    className={`input-group input-group-sm ${styles.input}`}
                    id="exampleFormControlInput1"
                    placeholder="Enter Total Booking Amount"
                    required
                    name="bookingAmount"
                    onChange={handleRoundTripValue}
                    value={roundTripValue.bookingAmount}
                  />
                </div>
              </>
            ) : null}

            <div
              className={`d-flex justify-content-start mt-3  mb-3 gap-2 ${styles.buttonDiv}`}
            >
              <Button
                type="submit"
                variant="warning"
                className={`${styles.sendLink} border`}
                disabled={!isPhoneValid || !isValid || timeError}
              >
                Add Booking
              </Button>
              <Button variant="light" onClick={handleClose} className="border">
                Cancel
              </Button>
            </div>
          </form>
          {/* ******************************************************* */}
        </Modal.Body>
      </Modal>
      {modalState && (
        <div>
          <Modal
            style={{ border: "none" }}
            open={open}
            size="sm"
            onClose={handlePopUpClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} className={styles.popupcontent}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={styles.imgbox}
              >
                <img src={CheckedIcon} alt="" className={styles.CheckedIcon} />
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={styles.sucessmessage}
              >
                Account Created Sucessfully
              </Typography>
              <Typography className={styles.dashboard}>
                Explore Dashboard
              </Typography>
              <Box>
                <Link to="/dashboard/mybooking">
                  <button className={styles.button}>Go to Dashboard</button>
                </Link>
              </Box>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default AddBookingModal;
