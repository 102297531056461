import axios from "axios";
import React, { useState, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import "./notification.scss";
import moment from "moment";
import Sidebar from "../Dashboard/Sidebar/Sidebar";
import { API_GET_NOTIFICATIONS } from "../../utils/consts/api";
import { errorToastMsg } from "../../common/ToastMessage";
import styles from "./Notification.module.scss";
import { thunkDispatchNotification } from "../../redux/action/notification.action";
import { useDispatch, useSelector } from "react-redux";
import Profile_Icon from "../../assets/images/driver_placeholder.jpg";

function Notification() {
  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const dispatch = useDispatch();
  const [notifications, setNotifications] = useState([]);

  const reduxNotifications = useSelector(
    (state) => state.notificationReducer.notificationData
  );

  const getAllNotifications = () => {
    dispatch(thunkDispatchNotification());
  };

  useEffect(() => {
    getAllNotifications();
    setNotifications(reduxNotifications);
  }, []);

  return (
    <>
      <div className="d-flex">
        <Sidebar />
        <div className={styles.myBooking}>
          <div className={styles.myBookingContainer}>
            <div className={styles.myBookingFilter}>
              <p className={styles.type}>Notifications</p>
            </div>
          </div>
          <div className={styles.container}>
            <div>
              <ListGroup>
                {reduxNotifications.map((noti) => {
                  return (
                    <ListGroup.Item
                      className="d-flex justify-content-between align-items-start border1"
                      key={noti.notificationId}
                    >
                      <div className="profileImageDiv">
                        <img
                          className="profileImage"
                          src={
                            noti.fromUserImage
                              ? noti.fromUserImage
                              : Profile_Icon
                          }
                          alt="N/A"
                        />
                      </div>
                      <div className="ms-2 me-auto infoDiv">
                        <div>
                          <strong className="orangeText">
                            {noti.fromUserName}{" "}
                          </strong>
                          {noti.message}.
                        </div>
                        <div className="TimeStamp">
                          {moment(noti.createTs).fromNow()}
                        </div>
                      </div>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
