import axios from "axios";
import { useEffect, useState } from "react";
import { errorToastMsg, successToastMsg } from "../../../common/ToastMessage";
import {
  ALPHANUMERICREGEX,
  API_GET_USER_PROFILE,
  API_UPDATE_PROFILE,
  EMAIL_REGEX,
  IS_NUMBER_REGEX,
  NAME_REGEX,
  PHONE_REGEX,
} from "../../../utils/consts/api";
import Spinner from "../../Dashboard/CommonComp/Spinner/Spinner";
import styles from "./UpdateInformation.module.scss";
import { ToastContainer, toast } from "react-toastify";

export const UpdateInformation = ({getProfileData,profileData,setProfileData}) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const { fullName, emailId, mobileNumber } = profileData;

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const handleProfileInput = (e) => {
    const { name, value } = e.target;
    if (name === "mobileNumber") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 10) {
        setProfileData({ ...profileData, [name]: value });
      } else {
        setProfileData({ ...profileData, [name]: value.slice(0, -1) });
      }
    } else if (name === "fullName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setProfileData({ ...profileData, [name]: value });
      } else {
        setProfileData({ ...profileData, [name]: value.slice(0, -1) });
      }
    } else if (name === "companyName"){
      if (ALPHANUMERICREGEX.test(value) && value!== " ") {
        setProfileData({ ...profileData, [name]: value });
      } else {
        setProfileData({ ...profileData, [name]: value.slice(0, -1) });
      }
    }
    else {
      setProfileData({ ...profileData, [name]: value });
    }
  };

  const validateEmail = () => {
    if (emailId) {
      if (!EMAIL_REGEX.test(emailId)) {
        setIsEmailValid(false);
        setEmailErrorMsg("Invalid Email Address.");
      } else {
        setIsEmailValid(true);
        setEmailErrorMsg("");
      }
    } else {
      setIsEmailValid(true);
      setEmailErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    if (mobileNumber) {
      if (!PHONE_REGEX.test(mobileNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const handleProfileUpdate = (e) => {
    e.preventDefault();
    profileData.emailId = profileData.emailId.toLowerCase();
    setLoading(true);
    axios
      .post(API_UPDATE_PROFILE, profileData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          successToastMsg(res.data.successMsg);
          getProfileData();
          setLoading(false);
        } else if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div>
          <p className={styles.editProfileText}>Edit Profile</p>
          <p className={styles.peronalInformationText}>
            Set up your peronal Information
          </p>
        </div>
        <div className={styles.formDiv}>
          {loading ? (
            <Spinner />
          ) : (
            <div className={styles.formInnerDiv}>
              <form onSubmit={handleProfileUpdate}>
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="exampleInputName"
                      className={styles.inputlabel}
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}`}
                      id="exampleInputName"
                      placeholder="Enter Name"
                      value={profileData.fullName}
                      name="fullName"
                      onChange={handleProfileInput}
                      required
                    />
                  </div>
                </div>
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="exampleInputPhoneNumber"
                      className={styles.inputlabel}
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}`}
                      id="exampleInputPhoneNumber"
                      placeholder="Enter phone number"
                      // name="mobileNumber"
                      readOnly
                      disabled
                      value={profileData.mobileNumber}
                      // onChange={handleProfileInput}
                      required
                      // onBlur={validatePhoneNumber}
                    />
                    {!isPhoneValid && (
                      <div
                        style={{ marginTop: "8px" }}
                        className={`text-danger ${styles.errormessage}`}
                      >
                        {phoneErrorMsg}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="exampleInputCompany"
                      className={styles.inputlabel}
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}`}
                      id="exampleInputCompany"
                      placeholder="Enter company name"
                      value={profileData.companyName}
                      name="companyName"
                      onChange={handleProfileInput}
                      required
                    />
                  </div>
                </div>
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="officialEmail"
                      className={styles.inputlabel}
                    >
                      Official Email Address
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}`}
                      id="officialEmail"
                      placeholder="example@gmail.com"
                      value={profileData.emailId}
                      name="emailId"
                      onChange={handleProfileInput}
                      required
                      onBlur={validateEmail}
                    />
                    {!isEmailValid && (
                      <div
                        style={{ marginTop: "8px" }}
                        className={`text-danger ${styles.errormessage}`}
                      >
                        {emailErrorMsg}
                      </div>
                    )}
                  </div>
                </div>
                <div className={styles.buttonDiv}>
                  <button
                    disabled={!isEmailValid || !isPhoneValid}
                    type="submit"
                    className={styles.updateButton}
                  >
                    Update Profile
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
