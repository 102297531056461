import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PaginNew } from "../CommonComp/PaginNew";
import { errorToastMsg } from "../../../common/ToastMessage";

import moment from "moment";

import styles from "./CancelledBookings.module.scss";
import { MobileView } from "./MobileView";
import Sidebar from "../Sidebar/Sidebar";
import { API_MY_CANCEL_BOOKINGS } from "../../../utils/consts/api";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";
import Spinner from "../CommonComp/Spinner/Spinner";

export const CancelledBookings = () => {
  const [cancelledBookings, setCancelledBookings] = useState([]);
  const [total, setTotal] = useState();
  const [postPerPage, setPostPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = fetch(API_MY_CANCEL_BOOKINGS, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          setCancelledBookings(data.response.cancelBookingList);
          setTotal(data.response.cancelBookingList.length);
          setLoading(false);
        });
    } catch (error) {
      errorToastMsg(error.message);
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const indexOfLastPage = page * postPerPage;
  const indexOfFirstpage = indexOfLastPage - postPerPage;
  const currentPost =
    cancelledBookings &&
    cancelledBookings.slice(indexOfFirstpage, indexOfLastPage);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(()=>{
    setPage(1);
  },[postPerPage])

  return (
    <div className="d-flex">
      <Sidebar />
      <div className={`${styles.mainContainer}`}>
        <div>
          <Link to="/dashboard/mybooking">
            <p className={styles.backBtn}>{"<"} Back</p>
          </Link>

          <p className={styles.cancelledHeading}>Cancelled Bookings</p>
        </div>

        {/* table starts from here:- */}

        {loading ? (
          <Spinner/>
        ):(
        <div className={styles.tableDiv}>
          {cancelledBookings.length > 0 ? (
            <div>
              <table className="table table-borderless ">
                <thead className={styles.tableHead}>
                  <tr className={styles.tableRow}>
                    <th style={{ width: "5%" }} scope="col">
                      {" "}
                      ID
                    </th>
                    <th style={{ width: "16%" }} scope="col">
                      Customer
                    </th>
                    <th scope="col">Number</th>
                    <th scope="col">Trip Date & Time</th>
                    <th style={{ width: "16%" }} scope="col">
                      Address
                    </th>
                    <th scope="col">Booking Amount</th>
                    <th style={{ width: "10%" }} scope="col">
                      Status
                    </th>
                  </tr>
                </thead>
                {currentPost &&
                  currentPost.map((ele, index) => (
                    <tbody key={index}>
                      <tr className={styles.tableData}>
                        <td>ID{ele.crmBookingId}</td>
                        <td>{ele.customerName}</td>
                        <td>+91 9876543210</td>

                        {ele.pickUpDate ? (
                          <td>
                            {moment(
                              `${ele.pickUpDate.slice(0, 10)} ${ele.pickUpTime}`
                            ).format("DD MMM  YYYY h:mm A")}
                          </td>
                        ) : (
                          ""
                        )}

                        <td>
                          {ele.pickUpLocationName},{ele.cityName}
                        </td>
                        <td>₹{ele.bookingAmount}</td>
                        <td>
                          <span
                            className={`badge text-uppercase  ${
                              ele.bookingType === "roundTrip"
                                ? styles.statusRoundTrip
                                : styles.statusOneWay
                            }`}
                          >
                            {ele.bookingType}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  ))}
              </table>

              <div className={styles.paginationDiv}>
                <PaginNew
                  post={cancelledBookings}
                  total={total}
                  handlePageClick={handlePageClick}
                  postPerPage={postPerPage}
                  setPostPerPage={setPostPerPage}
                  page={page}
                />
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </div>
        )}
        <MobileView
          cancelledBookings={cancelledBookings && cancelledBookings}
        />
      </div>
    </div>
  );
};
