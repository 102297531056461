import React, { useEffect, useState } from "react";
import styles from "./MyBooking.module.scss";
import Sidebar from "../Sidebar/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import EventIcon from "@mui/icons-material/Event";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import BookingStatus from "./BookingStatus";
import AddBookingModal from "./AddBookingModal/AddBookingModal";
import axios from "axios";
import { MobileView } from "./MobileView";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CheckedIcon from "../../../assets/images/check.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ALPHANUMERICREGEX, API_GET_VEHICLE_TYPE, API_MYBOOKINGS } from "../../../utils/consts/api";
import Modal from "@mui/material/Modal";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite-rtl.css';
import moment from "moment/moment";
import Spinner from "../CommonComp/Spinner/Spinner";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const MyBooking = () => {
  const [handleAddNewBookingStatus, setHandleAddNewBookingStatus] =
    useState(false);
  const [responseMesg, setResponseMesg] = useState();
  const [db, setDb] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalState, setModalState] = useState(false);
  const [searchValue,setSearchValue] = useState("");
  const [filterObject, setFilterObject] = useState({
    Added: [],
    confirmed: [],
    completed: [],
    confirmed: [],
    paymentPending: [],
    vehicleAlloted: []
  });

  const [dropdownList, setDropdownList] = useState([]);
  const [filteredValues, setFilterdValues] = useState({
    searchValue: '',
    vehicleType: '',
    startDate: '',
    endDate: ''
  });

  const handlePopUpOpen = () => setOpen(true);
  const handlePopUpClose = () => setOpen(false);

  const handleAddNewBooking = () => {
    setHandleAddNewBookingStatus(true);
  };

  const handleAddNewBookingCallBack = () => {
    setHandleAddNewBookingStatus(false);
    setModalState(true);
    handlePopUpOpen();
  };

  const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  if (responseMesg !== undefined) {
    if (responseMesg?.data.responseCode === -1) {
      errorToastMsg(responseMesg.data.errorMsg);
      setResponseMesg(undefined);
    } else {
      successToastMsg(responseMesg?.data.successMsg);
      setResponseMesg(undefined);
    }
  }

  const getAllData = async () => {
    setLoading(true);
    axios
      .get(API_MYBOOKINGS, config)
      .then((response) => {
        setDb(response.data.response);
      })
      .catch((err) => errorToastMsg(err.message))
      .finally(() => setLoading(false));
  };

  const handleBookingPosted = () => {
    handlePopUpClose();
    setModalState(false);
  };

  const mainFilterDataFunction = (searchValue, vehicleType, startDate, endDate) => {

    const filterAdded = db.Added.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
      else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType
        )
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId == searchValue
        )
      } else if (vehicleType) {
        return (
          item.vehicleTypeName == vehicleType
        )
      } else if (startDate && endDate) {
        return (
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
    }
    );

    const filterconfirmed = db.confirmed.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
      else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType
        )
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId == searchValue
        )
      } else if (vehicleType) {
        return (
          item.vehicleTypeName == vehicleType
        )
      } else if (startDate && endDate) {
        return (
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
    }
    );

    const filterVehicleAlloted = db.vehicleAlloted.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
      else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType
        )
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId == searchValue
        )
      } else if (vehicleType) {
        return (
          item.vehicleTypeName == vehicleType
        )
      } else if (startDate && endDate) {
        return (
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
    }
    );

    const filterpaymentPending = db.paymentPending.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
      else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType
        )
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId == searchValue
        )
      } else if (vehicleType) {
        return (
          item.vehicleTypeName == vehicleType
        )
      } else if (startDate && endDate) {
        return (
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
    }
    );

    const filterCompleted = db.completed.filter((item) => {
      if (searchValue && vehicleType && startDate && endDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (searchValue && startDate) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      } else if (vehicleType && startDate) {
        return (
          item.vehicleTypeName == vehicleType &&
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
      else if (searchValue && vehicleType) {
        return (
          (item.customerName.toLowerCase().includes(searchValue) ||
            item.crmBookingId == searchValue) &&
          item.vehicleTypeName == vehicleType
        )
      } else if (searchValue) {
        return (
          item.customerName.toLowerCase().includes(searchValue) ||
          item.crmBookingId == searchValue
        )
      } else if (vehicleType) {
        return (
          item.vehicleTypeName == vehicleType
        )
      } else if (startDate && endDate) {
        return (
          (Date.parse(moment(item.pickUpDate).format('ll')) >= Date.parse(startDate) &&
            Date.parse(moment(item.pickUpDate).format('ll')) <= Date.parse(endDate))
        )
      }
    }
    );

    setFilterObject({
      ...db, Added: filterAdded, confirmed: filterconfirmed, paymentPending: filterpaymentPending,
      vehicleAlloted: filterVehicleAlloted, completed: filterCompleted
    });
  };

  const handlesSearchFunction = (e) => {
    const { name, value } = e.target;
    if(ALPHANUMERICREGEX.test(value) || value === ""){
      setSearchValue(value);
      setFilterdValues({ ...filteredValues, [name]: value });
      mainFilterDataFunction(value, filteredValues.vehicleType, filteredValues.startDate, filteredValues.endDate);
    }else{
       setSearchValue(value.slice(0,-1));
    }
  };

  const changeDropdownValue = (e) => {
    const { name, value } = e.target;
    setFilterdValues({ ...filteredValues, [name]: value });
    mainFilterDataFunction(filteredValues.searchValue, value, filteredValues.startDate, filteredValues.endDate);
  };


  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleType);
      }).catch((error) => {
        errorToastMsg(error.message);
      });
  };


  useEffect(() => {
    if(window.innerWidth >= 1024){
      getAllData();
    }
  }, []);

  useEffect(() => {
    getdropdownList();
  }, []);

  useEffect(() => {
    db && mainFilterDataFunction(filteredValues.searchValue, filteredValues.vehicleType,
      filteredValues.startDate, filteredValues.endDate);
  }, [db]);

  const handleDate = (e) => {
    let startDate;
    let endDate;
    if (e) {
      startDate = moment(e[0]).format('ll');
      endDate = moment(e[1]).format('ll');
      setFilterdValues({ ...filteredValues, startDate: startDate, endDate: endDate });
      mainFilterDataFunction(filteredValues.searchValue, filteredValues.vehicleType, startDate, endDate);
    } else {
      setFilterdValues({ ...filteredValues, startDate: startDate, endDate: endDate });
      mainFilterDataFunction(filteredValues.searchValue, filteredValues.vehicleType, startDate, endDate);
    }
  };

  const totalBookings =
    db?.Added.length +
    db?.completed.length +
    db?.confirmed.length +
    db?.paymentPending.length +
    db?.vehicleAlloted.length;

    const handleMobileGetAllData = ()=>{
       if(window.innerWidth<1024){
         getAllData();
       }
    };

  return (
    <>
      <div className={styles.main}>
        <Sidebar />
        <div className={styles.myBooking}>
          <div className={styles.myBookingContainer}>
            <div className={styles.myBookingFilter}>
              <p className={styles.type}>My Bookings</p>
              <div className={styles.filters}>
                <div className={styles.search}>
                  <SearchIcon className={styles.searchIcon} />
                  <input
                    className={styles.searchById}
                    name="searchValue"
                    onChange={(e) => handlesSearchFunction(e)}
                    value={searchValue}
                    type="search"
                    placeholder="Search by ID, customer name etc..."
                  />
                </div>
                <div className={styles.selectVehicle}>
                  <DirectionsCarFilledOutlinedIcon
                    className={styles.vehicleIcon}
                  />
                  <select className={styles.vehicles}
                    name="vehicleType"
                    onChange={(event) => changeDropdownValue(event)}
                    value={filteredValues.vehicleType}>
                    <option value=""> All vehicle</option>
                    {dropdownList.map((single) => {
                      return (
                        <option
                          key={single.vehicleTypeId}
                          value={single.vehicleTypeName}
                          className={`form-control ${styles.dropdownMenu}`}
                        >
                          {single.vehicleTypeName}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div >
                  <DateRangePicker style={{ color:'yellow' }} appearance="default" placeholder="Select date range" showOneCalendar onChange={(e) => handleDate(e)} />
                </div>
                <div
                  className={styles.addNewBooking}
                  onClick={handleAddNewBooking}
                >
                  <AddIcon className={styles.addNewIcon} /> Add New Booking
                </div>
                {handleAddNewBookingStatus && (
                  <AddBookingModal
                    callbackFuncForError={setHandleAddNewBookingStatus}
                    callbackFunc={handleAddNewBookingCallBack}
                    mesg={setResponseMesg}
                    gettingDataFun={getAllData}
                    setLoader={setLoading}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={styles.container}>
            <div className={styles.topCont}>
              <p>
                {totalBookings}{" "}
                {totalBookings > 1 ? "Active Bookings" : "Active Booking"}
              </p>
              <div className={styles.bookingButtons}>
              <button className={styles.completedBookingButton}>
                <Link to="/dashboard/completedBookings">
                  <strong style={{"color":"#007ae9","textDecoration":"none"}}>View Completed Bookings</strong>
                </Link>
              </button>
              <button className={styles.cancelBookingButton}>
                <Link to="/dashboard/cancelledBookings">
                  <strong style={{"color":"#FF4D4F","textDecoration":"none"}}>View Cancelled Bookings</strong>
                </Link>
              </button>
              </div>
              
            </div>
            {loading ? (<Spinner/>):(
            <div>
            {filteredValues.searchValue || filteredValues.vehicleType || filteredValues.startDate ? filterObject && (
              <div className={styles.bookings}>
                <BookingStatus
                  status="Added"
                  customerData={filterObject.Added}
                  gettingDataFun={getAllData}
                  color="#272b41"
                  submit="Confirm"
                />
                <BookingStatus
                  status="Confirmed"
                  customerData={filterObject.confirmed}
                  gettingDataFun={getAllData}
                  color="#f7b217"
                  submit="Assign"
                />
                <BookingStatus
                  status="Vehicle Alloted"
                  customerData={filterObject.vehicleAlloted}
                  gettingDataFun={getAllData}
                  color="#272b41"
                  submit="Trip Started"
                />
                <BookingStatus
                  status="Payment Pending"
                  customerData={filterObject.paymentPending}
                  gettingDataFun={getAllData}
                  color="#20c997"
                  submit="Complete Trip"
                />
                <BookingStatus
                  status="Completed"
                  customerData={filterObject.completed}
                  gettingDataFun={getAllData}
                  color="#272b41"
                  submit="Send Review Link"
                />
              </div>
            ) : db ? (
              <div className={styles.bookings}>
                <BookingStatus
                  status="Added"
                  customerData={db.Added}
                  gettingDataFun={getAllData}
                  color="#272b41"
                  submit="Confirm"
                />
                <BookingStatus
                  status="Confirmed"
                  customerData={db.confirmed}
                  gettingDataFun={getAllData}
                  color="#f7b217"
                  submit="Assign"
                />
                <BookingStatus
                  status="Vehicle Alloted"
                  customerData={db.vehicleAlloted}
                  gettingDataFun={getAllData}
                  color="#272b41"
                  submit="Trip Started"
                />
                <BookingStatus
                  status="Payment Pending"
                  customerData={db.paymentPending}
                  gettingDataFun={getAllData}
                  color="#20c997"
                  submit="Complete Trip"
                />
                <BookingStatus
                  status="Completed"
                  customerData={db.completed}
                  gettingDataFun={getAllData}
                  color="#272b41"
                  submit="Send Review Link"
                />
              </div>
            ):(<NoDataFound/>)}
             </div>
            )}
          </div>
          <ToastContainer />
          {modalState && (
            <div>
              <Modal
                open={open}
                onClose={handlePopUpClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style} className={styles.popupcontent}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={styles.imgbox}
                  >
                    <img
                      src={CheckedIcon}
                      alt=""
                      className={styles.CheckedIcon}
                    />
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    className={styles.sucessmessage}
                  >
                    Booking Posted Sucessfully
                  </Typography>
                  <Box>
                    <Link to="/dashboard/mybooking">
                      <button
                        className={styles.button}
                        onClick={handleBookingPosted}
                      >
                        Go to Dashboard
                      </button>
                    </Link>
                  </Box>
                </Box>
              </Modal>
            </div>
          )}
        </div>
      </div>
      <MobileView db={db} getAllData={handleMobileGetAllData}  setLoader={setLoading}/>
    </>
  )
};


export default MyBooking;
