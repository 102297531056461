import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { PaginNew } from "../CommonComp/PaginNew";
import { errorToastMsg } from "../../../common/ToastMessage";

import moment from "moment";

import styles from "./CompletedBookings.module.scss";
import { MobileView } from "../CancelledBookings/MobileView";
import Sidebar from "../Sidebar/Sidebar";
import { API_MYBOOKINGS } from "../../../utils/consts/api";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";
import Spinner from "../CommonComp/Spinner/Spinner";
import Profile_Icon from "../../../assets/images/driver_placeholder.jpg";



export const CompletedBookings = () => {

  const [completedBookings, setCompletedBookings] = useState([]);
  const [total, setTotal] = useState();
  const [postPerPage, setPostPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = fetch(API_MYBOOKINGS, {
        method: "GET",
        headers: {
          "Content-type": "application/json",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
            setCompletedBookings(data.response.completed);
          setTotal(data.response.completed.length);
          setLoading(false);
        });
    } catch (error) {
      errorToastMsg(error.message);
      setLoading(false);
    }
  };

  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };

  const indexOfLastPage = page * postPerPage;
  const indexOfFirstpage = indexOfLastPage - postPerPage;
  const currentPost =
  completedBookings &&
  completedBookings.slice(indexOfFirstpage, indexOfLastPage);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(()=>{
    setPage(1);
  },[postPerPage])

  return (
    <div className="d-flex">
    <Sidebar />
    <div className={`${styles.mainContainer}`}>
      <div>
        <Link to="/dashboard/mybooking">
          <p className={styles.backBtn}>{"<"} Back</p>
        </Link>

        <p className={styles.cancelledHeading}>Completed Bookings</p>
      </div>

      {/* table starts from here:- */}
      {loading ? (
        <Spinner/>
      ):(
      <div className={styles.tableDiv}>
        {completedBookings.length > 0  ? (
          <div>
          <div className={styles.tableContainer}>
            <table className={` table table-borderless `}>
              <thead className={styles.tableHead}>
                <tr className={styles.tableRow}>
                  <th scope="col">
                    {" "}
                    ID
                  </th>
                  <th scope="col">
                    Customer
                  </th>
                  <th scope="col">Number</th>
                  <th scope="col">Trip Date & Time</th>
                  <th scope="col">Trip Notes</th>
                  <th scope="col">Tour Description</th>
                  <th scope="col">
                    Address
                  </th>
                  <th scope="col">Margin Earned</th>
                  <th scope="col">Booking Amount</th>
                  <th scope="col">Driver Info</th>
                  <th scope="col">Vehicle Info</th>
                  <th scope="col">
                    Status
                  </th>
                </tr>
              </thead>
              {currentPost &&
                currentPost.map((ele, index) => (
                  <tbody key={index}>
                    <tr className={styles.tableData}>
                      <td>ID{ele.crmBookingId}</td>
                      <td>{ele.customerName}</td>
                      <td>+91 {ele.customerContactNumber}</td>

                      {ele.pickUpDate ? (
                        <td>
                          {moment(
                            `${ele.pickUpDate.slice(0, 10)} ${ele.pickUpTime}`
                          ).format("DD MMM  YYYY h:mm A")}
                        </td>
                      ) : (
                        ""
                      )}
                      <td>{ele.tripNotes ? ele.tripNotes: '-'}</td>
                      <td>{ele.tourDescription ? ele.tourDescription:'-'}</td>
                      <td>
                        {ele.pickUpLocationName},{ele.cityName}
                      </td>
                      <td>{ele.marginEarned ? `₹ ${ele.marginEarned}` : '-'}</td>
                      <td>₹{ele.bookingAmount}</td>
                      <td>
                      <div
                            className={
                            ele.assignedDriverId != null
                                ? styles.assignedTo
                                : styles.notAssignedTo
                            }
                        >
                            <img
                            src={ele.driverImage || Profile_Icon }
                            alt="Driver image"
                            />
                            <div>
                            <p>{ele.driverFullName}</p>
                            <p>+91{ele.driverContactNumber}</p>
                            </div>
                        </div>
                      </td>
                      <td>
                        <div
                            className={
                            ele.assignedVehicleId != null
                                ? styles.assignedTo
                                : styles.notAssignedTo
                            }
                        >
                            <img
                            src={ele.vehicleImage || Profile_Icon}
                            alt=""
                            />
                            <div>
                            <p>{ele.registrationNumber}</p>
                            <p>{ele.vehicleTypeName}</p>
                            </div>
                        </div>
                      </td>
                      <td>
                        <span
                          className={`badge text-uppercase  ${
                            ele.bookingType === "roundTrip"
                              ? styles.statusRoundTrip
                              : styles.statusOneWay
                          }`}
                        >
                          {ele.bookingType}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
            </div>
            <div className={styles.paginationDiv}>
              <PaginNew
                post={completedBookings}
                total={total}
                handlePageClick={handlePageClick}
                postPerPage={postPerPage}
                setPostPerPage={setPostPerPage}
                page={page}
              />
            </div>
          </div>
        ) : (
          <NoDataFound/>
        )}
      </div>
      )}
      <MobileView
        cancelledBookings={completedBookings && completedBookings}
      />
    </div>
  </div>
  )
}

