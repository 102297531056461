import React from "react";
import styles from "./NoDataFound.module.scss";
import no_data from "../../../../assets/images/no_data.jpg";

const NoDataFound = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <img className={styles.noDataFoundImage} src={no_data} alt="no-data" />
    </div>
  );
};

export default NoDataFound;
