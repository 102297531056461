import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  errorToastMsg,
  successToastMsg,
} from "../../../../common/ToastMessage";
import {
  API_ADD_VEHICLE, API_ASSIGN_BOOKING,
} from "../../../../utils/consts/api";

import styles from "./AddVehicle.module.scss";

const AssignOtherDriverAndVehicle = ({ callbackFunc1, callbackFunc, gettingDataFun, crmBookingId }) => {
  const [show, setShow] = useState(true);
  
  const handleClose = () => {
    setShow(false);
    callbackFunc1(false);
    callbackFunc(false);
  };

  const addVehicleDriverInitialValues = {
    crmBookingId:crmBookingId,
    registrationNumber:"",
    driverFullName: "",
    driverContactNumber: ""
  };

  const [addFormValues, setAddFormValues] = useState(
    addVehicleDriverInitialValues
  );

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");


  const handleVehicleInput = (e) => {
    e.persist();
    const { name, value } = e.target;

    let newValue;
    if(name === "registrationNumber"){
      newValue = value.replace(/[^a-zA-Z0-9\-]/g, '');
      setAddFormValues({
        ...addFormValues,
        [e.target.name]: newValue,
      });
    }else if(name === "driverContactNumber"){
      if(value.length <= 10){
        setAddFormValues({
          ...addFormValues,
          [e.target.name]: value,
        });
      }
    }else{
      setAddFormValues({
        ...addFormValues,
        [e.target.name]: value,
      });
    }

  };

  const handleAddVehicle = (e) => {
    e.preventDefault();

    axios
      .post(API_ASSIGN_BOOKING, addFormValues, {
        headers: {
          "Content-Type": "application/json",
           Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          handleClose(false);
          gettingDataFun();
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className={styles.mainModal}
        scrollable
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Assign Other Vehicle and Driver </div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          <form onSubmit={handleAddVehicle} autocomplete="off">
            <div className="mb-3" style={{ marginTop: "15px" }}>
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold ${styles.inputlabelText}`}
              >
                Vehicle Registration Number
              </label>
              <input
                type="text"
                pattern="[a-zA-Z0-9\-._]+"
                title="Please enter valid registration number"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Registration Number"
                name="registrationNumber"
                required
                value={addFormValues.registrationNumber}
                onChange={handleVehicleInput}
              />
            </div>
            <div className="mb-3" style={{ marginTop: "15px" }}>
              <label
                htmlFor="exampleFormControlContactNumber1"
                className={`form-label fw-bold`}
              >
                Driver Contact Number
              </label>
              <input
                type="number"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlContactNumber1"
                placeholder="Driver Contact Number"
                required
                value={addFormValues.driverContactNumber}
                name="driverContactNumber"
                onChange={handleVehicleInput}
              />
            </div>
            <div className="mb-3" style={{ marginTop: "15px" }}>
              <label
                htmlFor="exampleFormControlDriverName1"
                className={`form-label fw-bold`}
              >
                Driver Full Name
              </label>
              <input
                type="text"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlDriverName1"
                placeholder="Enter driver name"
                required
                value={addFormValues.driverFullName}
                name="driverFullName"
                onChange={handleVehicleInput}
              />
            </div>
            <div
              className={`d-flex justify-content-start mx-3 mb-3 gap-2 ${styles.buttonDiv}`}
            >
              <button type="submit" className={styles.addAssignButton}>
                Assign
              </button>
              <Button
                variant="light"
                onClick={handleClose}
                className={styles.cancelButton}
              >
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssignOtherDriverAndVehicle;
