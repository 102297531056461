import React, { useEffect, useRef } from "react";
import styles from "./addvechile.module.scss";
import bgImage from "../../../assets/images/login_bg_image.png";
import TaxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../../assets/images/Taxi-sanchalak-left-image.png";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import axios from "axios";
import ShowVehicles from "./ShowVechiles/ShowVehicles";
import SocialURLComponent from "../SocialURL/SocialURL";
import taxiIcon from "../../../assets/images/Taxi-sanchalak-icon-image.png";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuid } from "uuid";
import {
  ALPHANUMERICREGEX,
  API_GET_VEHICLE_TYPE,
  IS_NUMBER_REGEX,
} from "../../../utils/consts/api";
import { AddDriverComponent } from "../AddDriver/addDriver";
import { BsXCircle } from "react-icons/bs";
import { ImageCompressorHook } from "../../Dashboard/CommonComp/ImageCompressor/ImageCompressor";

export const AddVechileComponent = ({
  registrationData,
  handleAddVechileBackButton,
  setRegistrationData,
}) => {
  const [dropdownList, setDropdownList] = useState([]);
  const [addDriverState, setAddDriverState] = useState(false);
  const updateAllVechiles = useRef();
  const [isYearOfManufactureValid, setIsYearOfManufactureValid] =
    useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const {imageCompressorFn,data} = ImageCompressorHook();

  const popUpIntialValues = {
    vehicleTypeId: "",
    vehicleTypeName: "",
    unique_id: "",
    registrationNumber: "",
    yearOfManufacture: "",
    rcImageFront: { preview: "", raw: "", value: "" },
    rcImageBack: { preview: "", raw: "", value: "" },
    vehicleImage1: { preview: "", raw: "", value: "" },
    vehicleImage2: { preview: "", raw: "", value: "" },
    vehicleImage3: { preview: "", raw: "", value: "" },
  };
  const [popUpValues, setPopUpValues] = useState(popUpIntialValues);

  const [addVechileArray, SetAddVechileArray] = useState(
    registrationData.tempVehicleInfo
  );

  const [show, setShow] = useState(false);

  //  DropDown API calls

  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleType);
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  useEffect(() => {
    getdropdownList();
  }, []);

  const changeDropdownValue = (newF) => {
    const FilterValue = dropdownList.filter(
      (vehicleObject) => vehicleObject.vehicleTypeId == newF
    );
    const { vehicleTypeName } = FilterValue[0];
    setPopUpValues({
      ...popUpValues,
      vehicleTypeId: newF,
      vehicleTypeName: vehicleTypeName,
    });
  };

  const handleVehicleImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image,e.target.name);
      }
      setPopUpValues({
        ...popUpValues,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const handleFormData = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "registrationNumber") {
      if (ALPHANUMERICREGEX.test(value) && value !== " ") {
        setPopUpValues({ ...popUpValues, [name]: value });
      } else {
        setPopUpValues({ ...popUpValues, [name]: value.slice(0, -1) });
      }
    } else if (name === "yearOfManufacture") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 4) {
        setPopUpValues({ ...popUpValues, [name]: value });
      } else {
        setPopUpValues({ ...popUpValues, [name]: value.slice(0, -1) });
      }
    } else {
      setPopUpValues({ ...popUpValues, [name]: value });
    }
  };

  const handleClose = () => {
    setPopUpValues(popUpIntialValues);
    setShow(false);
    setIsYearOfManufactureValid(true);
    setErrorMsg("");
  };

  const handleShow = () => {
    const unique_id = uuid();
    setPopUpValues({ ...popUpValues, unique_id: unique_id });
    setShow(true);
  };

  const handleModalSbmission = (e) => {
    e.preventDefault();
    if (!popUpValues.rcImageFront.preview) {
      errorToastMsg("Please upload RC-Front Image");
      return;
    } else if (!popUpValues.rcImageBack.preview) {
      errorToastMsg("Please upload RC-Back Image");
      return;
    } else if (
      !popUpValues.vehicleImage1.preview &&
      !popUpValues.vehicleImage2.preview &&
      !popUpValues.vehicleImage3.preview
    ) {
      errorToastMsg("Please upload atleast one Vehicle Image");
      return;
    }
    SetAddVechileArray([...addVechileArray, popUpValues]);
    setPopUpValues(popUpIntialValues);
    handleClose();
  };

  const handleAddDriverBackButton = () => {
    setAddDriverState(false);
  };

  const handleAddVechileSubmit = (e) => {
    e.preventDefault();
    setAddDriverState(true);
    setRegistrationData({
      ...registrationData,
      tempVehicleInfo: addVechileArray,
    });
  };

  const handleVehicleDelete = (unique_id) => {
    const filterArray = addVechileArray.filter(
      (vehicleInfo) => vehicleInfo.unique_id != unique_id
    );
    SetAddVechileArray(filterArray);
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const validateYearOfManufacture = () => {
    const futureYear =  new Date().getFullYear();
    const { yearOfManufacture } = popUpValues;
    if (yearOfManufacture) {
      if (yearOfManufacture < 2011) {
        setIsYearOfManufactureValid(false);
        setErrorMsg("Year of manufacture should be 2011 and above.");
      } else if (yearOfManufacture > futureYear){
        setIsYearOfManufactureValid(false);
        setErrorMsg("Year of manufacture should not be in the future date.");
      }
      else {
        setIsYearOfManufactureValid(true);
        setErrorMsg("");
      }
    } else {
      setIsYearOfManufactureValid(true);
      setErrorMsg("");
    }
  };

  const handleCrossrcImageFront = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      rcImageFront: { preview: "", raw: "", value: "" },
    });
  };

  const handleCrossrcImageBack = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      rcImageBack: { preview: "", raw: "", value: "" },
    });
  };

  const handleCrossVehicle1 = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      vehicleImage1: { preview: "", raw: "", value: "" },
    });
  };

  const handleCrossVehicle2 = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      vehicleImage2: { preview: "", raw: "", value: "" },
    });
  };

  const handleCrossVehicle3 = (event) => {
    event.preventDefault();
    setPopUpValues({
      ...popUpValues,
      vehicleImage3: { preview: "", raw: "", value: "" },
    });
  };

  useEffect(() => {
    setRegistrationData({
      ...registrationData,
      tempVehicleInfo: addVechileArray,
    });
  }, [addVechileArray]);

  useEffect(()=>{
    if(data.image){
      if(data.imageName){
        setPopUpValues({
          ...popUpValues,
          [data.imageName]:{
            preview: URL.createObjectURL(data.image),
            raw: data.image
          }
        });
      }else{
        setPopUpValues({
          ...popUpValues,
            preview: URL.createObjectURL(data.image),
            raw: data.image
        });
      }
    }
},[data]);

  return (
    <>
      {!addDriverState && (
        <div className={styles.logincomponent}>
          <div
            className={`${styles.taxiIcon}`}
            style={{ backgroundImage: `url(${taxiIcon})` }}
          >
            {" "}
          </div>
          <div className={styles.TaxiCRMImageDiv}>
            <div className={styles.IconAndText}>
              <img src={TaxiIcon} className={styles.TaxiIconImage} />
              <p className={styles.imageText}>CRM Application</p>
            </div>
            <div className={styles.TaxiLeftImage}>
              <img src={TaxiLeftImage} width="100%" />
            </div>
          </div>
          <div className={styles.bgrightloginform}>
            <div className={styles.donotaccount}>
              <p>
                Already have an account?
                <span className={styles.registernow}>
                  <Link to="/login">Sign in</Link>
                </span>
              </p>
            </div>
            <div className={styles.registerYourAccount}>
              <h4 className={`mb-8 ${styles.registerText}`}>
                Register your account
              </h4>
              <div className="d-flex justify-content-between mt-4 mb-4">
                <p className={styles.addVehicleFont}>
                  Please add your vehicles here
                </p>
                <h6 className={styles.stepText}>
                  <span>2 Step of 5</span>
                </h6>
              </div>
              <div>
                <ShowVehicles
                  updateAllVechiles={updateAllVechiles}
                  addVechileArray={registrationData.tempVehicleInfo}
                  handleVehicleDelete={handleVehicleDelete}
                />
              </div>
              <div className={styles.addVechile1}>
                <button
                  type="button"
                  className={styles.addVechile}
                  onClick={handleShow}
                >
                  {" "}
                  + Add Vehicle
                </button>
              </div>
              <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
              >
                <div className={styles.modalMainDiv}>
                  <div className={styles.modalDiv}>
                    <div className={`${styles.modalTitle}`}>Add Vehicles</div>
                    <div
                      className={`${styles.closeButton}`}
                      onClick={handleClose}
                    >
                      x
                    </div>
                  </div>
                  <Modal.Body className={`${styles.mainBodyDiv}`}>
                    <form onSubmit={handleModalSbmission} autocomplete="off">
                      <div className={`mb-3 ${styles.selectTagDiv}`}>
                        <select
                          className={`${styles.selectVechileTag}`}
                          name="vehicleTypeId"
                          required
                          id="exampleFormControlInput1"
                          onChange={(event) =>
                            changeDropdownValue(event.target.value)
                          }
                          value={popUpValues.vehicleTypeId}
                        >
                          <option value="">Select Vehicle Type</option>
                          {dropdownList.map((single) => {
                            return (
                              <option
                                key={single.vehicleTypeId}
                                value={single.vehicleTypeId}
                                className={`form-control ${styles.dropdownMenu}`}
                              >
                                {single.vehicleTypeName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          required
                          className={`form-control ${styles.registrationNumber}`}
                          id="exampleFormControlInput1"
                          placeholder="Registration Number"
                          name="registrationNumber"
                          value={popUpValues.registrationNumber}
                          onChange={handleFormData}
                        />
                      </div>
                      <div className={"mb-2"}>
                        <input
                          type="number"
                          required
                          className={`form-control ${styles.yearOfManufacturing}`}
                          id="exampleFormControlInput1"
                          placeholder="Year Of Manufacture"
                          name="yearOfManufacture"
                          onBlur={validateYearOfManufacture}
                          value={popUpValues.yearOfManufacture}
                          onChange={handleFormData}
                        />
                      </div>
                      {!isYearOfManufactureValid && (
                        <div style={{marginBottom:'5px'}} className={`text-danger ${styles.errormessage}`}>
                          {errorMsg}
                        </div>
                      )}
                      <div className={`form-label fw-bold ${styles.labelFont}`}>
                        <p className={`styles.uploadRCText mb-2`}>Upload RC Images</p>
                        <div
                          className={`form-label fw-bold ${styles.uploadRCDiv}`}
                        >
                          <label
                            htmlFor="file-rcfront"
                            className="form-label px-2 py-1 d-flex gap-2"
                          >
                            <div className={styles.vehicleUpload}>
                              <div className={styles.rCImageDiv}>
                                {popUpValues.rcImageFront.preview && (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossrcImageFront}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={popUpValues.rcImageFront.preview}
                                      alt="rcImageFront"
                                      className={styles.fiRCUploadImage}
                                    />
                                  </div>
                                )}
                                {!popUpValues.rcImageFront.preview && (
                                  <div className={styles.fileUploadDiv}>
                                    <div>
                                      <FiUpload className={styles.fiRCUpload} />
                                    </div>
                                    <h6 className={styles.RCfontFront}>
                                      Upload RC Front
                                    </h6>
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                id="file-front"
                                className="d-none"
                                name="rcImageBack"
                                onChange={handleVehicleImages}
                                accept="image/*"
                                placeholder="RC Front"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-rcfront"
                            value={popUpValues.rcImageFront.value}
                            className="d-none"
                            name="rcImageFront"
                            accept="image/*"
                            onChange={handleVehicleImages}
                          />
                          <label
                            htmlFor="file-rcback"
                            className="form-label px-2 py-1 d-flex gap-2"
                          >
                            <div className={styles.vehicleUpload}>
                              <div className={styles.rCImageDiv}>
                                {popUpValues.rcImageBack.preview && (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossrcImageBack}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={popUpValues.rcImageBack.preview}
                                      alt="rcImageBack"
                                      className={styles.fiRCUploadImage}
                                    />
                                  </div>
                                )}
                                {!popUpValues.rcImageBack.preview && (
                                  <div className={styles.fileUploadDiv}>
                                    <div>
                                      <FiUpload className={styles.fiRCUpload} />
                                    </div>
                                    <h6 className={styles.RCfontFront}>
                                      Upload RC Back
                                    </h6>
                                  </div>
                                )}
                              </div>
                              <input
                                type="file"
                                id="file-front"
                                className="d-none"
                                name="rcImageBack"
                                onChange={handleVehicleImages}
                                accept="image/*"
                                placeholder="RC Front"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-rcback"
                            value={popUpValues.rcImageBack.value}
                            className="d-none"
                            name="rcImageBack"
                            accept="image/*"
                            onChange={handleVehicleImages}
                          />
                        </div>
                      </div>
                      <div className={styles.actualImagesDiv}>
                        <h6 className={styles.actualImagesTitle}>
                          Upload actual vehicles images{" "}
                        </h6>
                        <br />
                        <div
                          className={`form-label fw-bold ${styles.actualImagesInnerDiv}`}
                        >
                          <label
                            htmlFor="file-rc2"
                            className="form-label px-2 py- d-flex gap-1"
                          >
                            <div className={styles.vehicleUpload}>
                              <div>
                                {popUpValues.vehicleImage1.preview ? (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossVehicle1}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={popUpValues.vehicleImage1.preview}
                                      alt="dummy"
                                      className={styles.fiUpload2}
                                      accept="image/*"
                                      multiple
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <div className={styles.fiUpload2}>
                                      <div>
                                        <FiUpload
                                          className={styles.fileUploadSymbol}
                                        />
                                      </div>
                                      <p className={styles.fileUploadText}>
                                        Upload
                                      </p>
                                    </div>
                                  </>
                                )}{" "}
                              </div>
                              <input
                                type="file"
                                id="file-input2"
                                className="d-none"
                                name="vehicleImage1"
                                onChange={handleVehicleImages}
                                accept="image/*"
                                multiple
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-rc2"
                            className="d-none"
                            name="vehicleImage1"
                            accept="image/*"
                            value={popUpValues.vehicleImage1.value}
                            onChange={handleVehicleImages}
                          />
                          <label
                            htmlFor="file-rc3"
                            className="form-label px-2 d-flex gap-1"
                          >
                            <div className={styles.vehicleUpload}>
                              <div>
                                {popUpValues.vehicleImage2.preview ? (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossVehicle2}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={popUpValues.vehicleImage2.preview}
                                      alt="dummy"
                                      className={styles.fiUpload2}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <div className={styles.fiUpload2}>
                                      <div>
                                        <FiUpload
                                          className={styles.fileUploadSymbol}
                                        />
                                      </div>
                                      <p className={styles.fileUploadText}>
                                        Upload
                                      </p>
                                    </div>
                                  </>
                                )}{" "}
                              </div>
                              <input
                                type="file"
                                id="file-input3"
                                className="d-none"
                                name="vehicleImage2"
                                onChange={handleVehicleImages}
                                accept="image/*"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-rc3"
                            className="d-none"
                            value={popUpValues.vehicleImage2.value}
                            name="vehicleImage2"
                            accept="image/*"
                            onChange={handleVehicleImages}
                          />
                          <label
                            htmlFor="file-rc4"
                            className="form-label px-2 d-flex gap-1"
                          >
                            <div className={styles.vehicleUpload}>
                              <div>
                                {popUpValues.vehicleImage3.preview ? (
                                  <div className={styles.imageBox}>
                                    <div
                                      className={`cross ${styles.crossSign}`}
                                      onClick={handleCrossVehicle3}
                                    >
                                      <BsXCircle
                                        color="#D7E0EE"
                                        style={{
                                          backgroundColor: "#272B41",
                                          borderRadius: "50%",
                                          position: "absolute",
                                          top: 2,
                                          right: 1,
                                        }}
                                      />
                                    </div>
                                    <img
                                      src={popUpValues.vehicleImage3.preview}
                                      alt="dummy"
                                      className={styles.fiUpload2}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    <div className={styles.fiUpload2}>
                                      <div>
                                        <FiUpload
                                          className={styles.fileUploadSymbol}
                                        />
                                      </div>
                                      <p className={styles.fileUploadText}>
                                        Upload
                                      </p>
                                    </div>
                                  </>
                                )}{" "}
                              </div>
                              <input
                                type="file"
                                id="file-input4"
                                className="d-none"
                                name="vehicleImage3"
                                onChange={handleVehicleImages}
                                accept="image/*"
                              />
                            </div>
                          </label>
                          <input
                            type="file"
                            id="file-rc4"
                            className="d-none"
                            value={popUpValues.vehicleImage3.value}
                            name="vehicleImage3"
                            accept="image/*"
                            onChange={handleVehicleImages}
                          />
                        </div>
                      </div>
                      <div className={styles.modalButton}>
                        <Button
                          onClick={handleClose}
                          className={styles.modalCancelButton}
                        >
                          Cancel
                        </Button>
                        <Button type="submit" className={styles.modalAddButton}
                                disabled={!isYearOfManufactureValid}

                        >
                          Add
                        </Button>
                      </div>
                    </form>
                  </Modal.Body>
                </div>
              </Modal>
              <div className={`d-flex ${styles.buttonDiv}`}>
                <button
                  type="button"
                  className={`btn btn-outline-secondary ${styles.backbutton}`}
                  style={{ height: "50px", width: "80px" }}
                  onClick={handleAddVechileBackButton}
                >
                  Back
                </button>
                <button
                  type="submit"
                  className={`${styles.submitButton}`}
                  onClick={handleAddVechileSubmit}
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
      {addDriverState && (
        <AddDriverComponent
          handleAddDriverBackButton={handleAddDriverBackButton}
          setRegistrationData={setRegistrationData}
          registrationData={registrationData}
        />
      )}
    </>
  );
};

export default AddVechileComponent;
