import Sidebar from "../Dashboard/Sidebar/Sidebar";
import ProfileCover from "../../assets/images/Cover.png";
import mobileCover from "../../assets/images/MobileCover.png";
import styles from "./userProfile.module.scss";
import userProfile from "../../assets/images/UserProfile.png";
import profileIcon from "../../assets/images/userIcon.png";
import activeProfileIcon from "../../assets/images/ActiveProfileIcon.svg";
import changePassword from "../../assets/images/changePassword.png";
import transparentKey from "../../assets/images/Key.svg";
import { UpdateInformation } from "./UpdateInformation/UpdateInfomation";
import { BankInformation } from "./UpdatePassword/UpdatePassword";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_GET_USER_PROFILE } from "../../utils/consts/api";
import { errorToastMsg } from "../../common/ToastMessage";
import { ToastContainer, toast } from "react-toastify";

export const UserProfile = () => {
  const [passwordProfileState, setPasswordProfileState] = useState(true);
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    fullName: "",
    emailId: "",
    mobileNumber: "",
    companyName: "",
  });
  const [apiProfileData,setapiProfileData]=useState({
     fullName:''
  });

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const getProfileData = () => {
    setLoading(true);
    profileData.emailId = profileData.emailId.toLowerCase();
    axios
      .get(API_GET_USER_PROFILE, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setProfileData(res.data.response);
          setapiProfileData(res.data.response);
          setLoading(false);
        } else {
          setProfileData({});
          setLoading(false);
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <div className={` d-flex ${styles.mainContainer}`}>
        <Sidebar />
        <div className={styles.mainContainerInnerDiv}>
          <p className={styles.myProfile}>My Profile</p>
          <div className={styles.EditInformationMainDiv}>
            <div className={styles.editLeftDiv}>
              <div className={styles.userInformation}>
                <img src={userProfile} alt="userImage" />
                {<p className={styles.userName}>{apiProfileData.fullName ? apiProfileData.fullName : ''}</p>}
              </div>
              <hr
                style={{
                  borderTop: "2px solid rgb(216 216 223)",
                  width: "105%",
                }}
              ></hr>
              <div>
                {passwordProfileState ? (
                  <div className={styles.activeTab}>
                    <img
                      width="12px"
                      height="14px"
                      src={activeProfileIcon}
                      alt="profileIcon"
                    />
                    <p
                      onClick={() => setPasswordProfileState(true)}
                      className={styles.activetabText}
                    >
                      Edit Profile
                    </p>
                  </div>
                ) : (
                  <div className={styles.tabDiv}>
                    <img
                      width="12px"
                      height="14px"
                      src={profileIcon}
                      alt="profileIcon"
                    />
                    <p
                      onClick={() => setPasswordProfileState(true)}
                      className={styles.tabDivText}
                    >
                      Edit Profile
                    </p>
                  </div>
                )}
                {!passwordProfileState ? (
                  <div className={styles.activeTab}>
                    <img
                      width="12px"
                      height="14px"
                      src={changePassword}
                      alt="profileIcon"
                    />
                    <p
                      onClick={() => setPasswordProfileState(false)}
                      className={styles.activetabText}
                    >
                      Change Password
                    </p>
                  </div>
                ) : (
                  <div className={styles.tabDiv}>
                    <img
                      width="12px"
                      height="14px"
                      src={transparentKey}
                      alt="profileIcon"
                    />
                    <p
                      onClick={() => setPasswordProfileState(false)}
                      className={styles.tabDivText}
                    >
                      Change Password
                    </p>
                  </div>
                )}
                <div></div>
              </div>
            </div>
            <div>
              <div className={styles.editRightDiv}>
                <div>
                  <img
                    className={styles.backGroundImageStyle}
                    width="100%"
                    src={ProfileCover}
                    alt="Background Cover"
                  />
                  <img
                    className={styles.mobilebackGroundImageStyle}
                    width="100%"
                    src={mobileCover}
                    alt="Background Cover"
                  />
                </div>
                <div className={styles.rightDiv}>
                  {passwordProfileState && (
                    <UpdateInformation
                      getProfileData={getProfileData}
                      profileData={profileData}
                      setProfileData={setProfileData}
                    />
                  )}
                  {!passwordProfileState && <BankInformation />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
