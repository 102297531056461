import React, { useEffect } from 'react';
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiEdit, FiUpload } from "react-icons/fi";
import { RiDeleteBin6Line } from 'react-icons/ri';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteuserComponent from './DeleteVehicleModal/Delete-vehicle-modal';
import styles from "./showVehicle.module.scss";
function ShowVehicles({ updateAllVechiles, addVechileArray, handleVehicleDelete }) {
  
  const [modalShow, setModalShow] = useState(false);
  const [deletePlanID, setDeletePlanID] = useState("");
  
  const handleDeleteOpen = (id) => {
    setDeletePlanID(id);
    setModalShow(true);
  };

  const handleDeleteclose = () => {
    setModalShow(false);
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleDeleteaccept = ()=>{
    handleVehicleDelete(deletePlanID);
    setModalShow(false);
    setDeletePlanID('');
  }

  updateAllVechiles.current = addVechileArray;

  return (
    <>
      {addVechileArray && addVechileArray.length > 0 && <div className={` ${styles.driversGrid}`}>
        {addVechileArray && addVechileArray?.map((vechileInfo) => (
          <div key={vechileInfo.unique_id}>
            <div className={`card g-0 ${styles.cardDiv}`}>
              <div className={`card-body ${styles.cardInnerDiv}`}>
                <div className={`d-flex flex-row gap-3 ${styles.cardInfo}`}>
                  <div className={`d-flex flex-row gap-5`}>
                    <div>
                      <img className={styles.carImage} src={vechileInfo.vehicleImage1.preview || vechileInfo.vehicleImage2.preview || vechileInfo.vehicleImage3.preview} />
                    </div>
                    <div className={styles.carDescription}>
                      <div className={`p-0 ${styles.carNumber}`}>
                        {vechileInfo.registrationNumber}
                      </div>
                      <div className="p-0">
                        {vechileInfo.yearOfManufacture}
                      </div>
                      <div className="p-0">
                        {vechileInfo.vehicleTypeName}
                      </div>
                    </div>
                  </div>
                  <div className={styles.carDelete}>
                    <div className="p-0"
                    >
                      <RiDeleteBin6Line onClick={() => handleDeleteOpen(vechileInfo.unique_id)} style={{ cursor:'pointer' }}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      }
       {/* Delete Modal Component */}

       <DeleteuserComponent
          show={modalShow}
          onHide={handleDeleteclose}
          onAccept={handleDeleteaccept}
        />
    </>
  )
}

export default ShowVehicles