import React, { useState, useEffect } from "react";
import { FiUpload, FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import styles from "./vehicles.module.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PaginNew } from "../CommonComp/PaginNew";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  API_ADD_VEHICLE,
  API_GET_MY_VEHICLES,
  API_DELETE_VEHICLE,
  API_UPDATE_VEHICLE,
  API_GET_VEHICLE_TYPE,
  ALPHANUMERICREGEX,
  IS_NUMBER_REGEX,
} from "../../../utils/consts/api";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";
import { AiOutlinePlus } from "react-icons/ai";
import { BsXCircle } from "react-icons/bs";
import DeleteuserComponent from "./DeleteVehicleModal/Delete-vehicle-modal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spinner from "../CommonComp/Spinner/Spinner";
import { ImageCompressorHook } from "../CommonComp/ImageCompressor/ImageCompressor";

export const Vehicles = () => {
  const [items, setItems] = useState([]);

  // change the value 50 as per the total data received from the api:-

  const [total, setTotal] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [postPerPage, setPostPerPage] = useState(9);
  const [deletePlanID, setDeletePlanID] = useState("");
  const [page, setPage] = useState(1);
  const handlePageClick = (data) => {
    setPage(data.selected + 1);
  };
  const [dropdownValue, setdropdownValue] = useState("");
  const [dropdownList, setDropdownList] = useState([]);
  const [loading, setLoading] = useState(false);
  const alphabetNumberRegex = /^[0-9a-zA-Z]+$/;
  const isNum = /^[0-9]*$/;

  const vehicleImagesIntialValues = {
    rcImageFront: { preview: "", raw: "", value: "" },
    rcImageBack: { preview: "", raw: "", value: "" },
    vehicleImage1: { preview: "", raw: "", value: "" },
    vehicleImage2: { preview: "", raw: "", value: "" },
    vehicleImage3: { preview: "", raw: "", value: "" },
  };
  const [vehicleImages, setVehicleImages] = useState(vehicleImagesIntialValues);

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const indexOfLastPage = page * postPerPage;
  const indexOfFirstpage = indexOfLastPage - postPerPage;
  const currentPost = items.slice(indexOfFirstpage, indexOfLastPage);

  const [show, setShow] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);
  const [update, setUpdate] = useState({});
  const handleUpdateModelshow = (obj) => {
    setUpdateModel(true);
    setUpdate(obj);
  };
  const {imageCompressorFn,data} = ImageCompressorHook();

  const initialValues = {
    vehicleTypeId: "",
    registrationNumber: "",
    yearOfManufacture: "",
    rcImageFront: "rcImageFront",
    rcImageBack: "rcImageBack",
    vehicleImage1: "vehicleImage1",
    vehicleImage2: "vehicleImage2",
    vehicleImage3: "vehicleImage3",
  };
  const [formValues, setFormValues] = useState(initialValues);

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "registrationNumber") {
      if (ALPHANUMERICREGEX.test(value)) {
        setFormValues({ ...formValues, [name]: value });
      } else {
        setFormValues({ ...formValues, [name]: value.slice(0, -1) });
      }
    } else if (name === "yearOfManufacture") {
      if (isNum.test(value)) {
        setFormValues({ ...formValues, [name]: value });
      } else {
        setFormValues({ ...formValues, [name]: value.slice(0, -1) });
      }
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleVehicleImages = (e) => {
    if (e.target.files.length) {
      const image = e.target.files[0];
      if(image){
        imageCompressorFn(image,e.target.name,'Add');
      }
      setVehicleImages({
        ...vehicleImages,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const [isYearOfManufactureValid, setIsYearOfManufactureValid] =
    useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isUpdateYearOfManufactureValid, setIsUpdateYearOfManufactureValid] =
    useState(true);
  const [updateErrorMsg, setUpdateErrorMsg] = useState("");

  // VehicleAdd API Call

  const handleAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify(formValues));
    if (vehicleImages.rcImageFront.raw) {
      formData.append(
        "rcImageFront",
        vehicleImages.rcImageFront.raw,
        vehicleImages.rcImageFront.name
      );
    } else {
      errorToastMsg("Please Upload RC-Front Image");
      return;
    }
    if (vehicleImages.rcImageBack.raw) {
      formData.append(
        "rcImageBack",
        vehicleImages.rcImageBack.raw,
        vehicleImages.rcImageBack.name
      );
    } else {
      errorToastMsg("Please Upload RC-Back Image");
      return;
    }

    if (
      !vehicleImages.vehicleImage1.raw &&
      !vehicleImages.vehicleImage2.raw &&
      !vehicleImages.vehicleImage3.raw
    ) {
      errorToastMsg("Please upload atleast one image");
      return;
    }

    if (vehicleImages.vehicleImage1.raw) {
      formData.append(
        "vehicleImage1",
        vehicleImages.vehicleImage1.raw,
        vehicleImages.vehicleImage1.name
      );
    }
    if (vehicleImages.vehicleImage2.raw) {
      formData.append(
        "vehicleImage2",
        vehicleImages.vehicleImage2.raw,
        vehicleImages.vehicleImage2.name
      );
    }
    if (vehicleImages.vehicleImage3.raw) {
      formData.append(
        "vehicleImage3",
        vehicleImages.vehicleImage3.raw,
        vehicleImages.vehicleImage3.name
      );
    }
    setLoading(true);
    axios
      .post(API_ADD_VEHICLE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
          getData();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
    handleClose(false);
    setdropdownValue("");
    setFormValues(initialValues);
    setVehicleImages(vehicleImagesIntialValues);
  };

  // DropDown API calls

  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleType);
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  useEffect(() => {
    getdropdownList();
  }, []);

  const changeDropdownValue = (newF) => {
    setFormValues({ ...formValues, vehicleTypeId: newF });
    setdropdownValue(newF);
  };

  const changeUpdateDropdownValue = (newF) => {
    setUpdate({ ...update, vehicleTypeId: newF });
    setdropdownValue(newF);
  };

  const handleUpdateModelsClose = () => {
    setUpdateModel(false);
    setUpdate({
      agentVehicleId: "",
      vehicleTypeId: "",
      registrationNumber: "",
      yearOfManufacture: "",
    });
    setUpdateVehicleImages(UpdateVehicleIntialValues);
    setIsUpdateYearOfManufactureValid(true);
    setUpdateImageState(UpdateVehicleImageStateIntialValues);
    setdropdownValue("");
  };

  const UpdateVehicleIntialValues = {
    rcFrontImage: { raw: "", preview: "", value: "" },
    rcImageBack: { raw: "", preview: "", value: "" },
    vehicleImage: { raw: "", preview: "", value: "" },
    vehicleImage2: { raw: "", preview: "", value: "" },
    vehicleImage3: { raw: "", preview: "", value: "" },
  };
  const [updateVehicleImages, setUpdateVehicleImages] = useState(
    UpdateVehicleIntialValues
  );

  const UpdateVehicleImageStateIntialValues = {
    rcFrontImage: false,
    rcImageBack: false,
    vehicleImage: false,
    vehicleImage2: false,
    vehicleImage3: false,
  };
  const [updateImageState, setUpdateImageState] = useState(
    UpdateVehicleImageStateIntialValues
  );

  const handleClose = () => {
    setShow(false);
    setIsYearOfManufactureValid(true);
    setVehicleImages(vehicleImagesIntialValues);
    setFormValues(initialValues);
    setdropdownValue("");
  };

  const handleShow = () => setShow(true);

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify(update));

    if (updateVehicleImages.rcFrontImage.raw) {
      formData.append(
        "rcImageFront",
        updateVehicleImages.rcFrontImage.raw,
        updateVehicleImages.rcFrontImage.name
      );
    } else if (updateImageState.rcFrontImage) {
      errorToastMsg("Please upload rcFront Image");
      return;
    }

    if (updateVehicleImages.rcImageBack.raw) {
      formData.append(
        "rcImageBack",
        updateVehicleImages.rcImageBack.raw,
        updateVehicleImages.rcImageBack.name
      );
    } else if (updateImageState.rcImageBack) {
      errorToastMsg("Please upload rcBack Image");
      return;
    }

    if (
      updateImageState.vehicleImage ||
      updateImageState.vehicleImage2 ||
      updateImageState.vehicleImage3
    ) {
      if (
        !updateVehicleImages.vehicleImage.raw &&
        !updateVehicleImages.vehicleImage2.raw &&
        !updateVehicleImages.vehicleImage3.raw
      ) {
        if (update.vehicleImageToShow && !updateImageState.vehicleImage) {
        } else if (
          update.vehicleImage2ToShow &&
          !updateImageState.vehicleImage2
        ) {
        } else if (
          update.vehicleImage3ToShow &&
          !updateImageState.vehicleImage3
        ) {
        } else {
          errorToastMsg("Please upload atleast one vehicle Image");
          return;
        }
      }
    }

    if (updateVehicleImages.vehicleImage.raw) {
      formData.append(
        "vehicleImage1",
        updateVehicleImages.vehicleImage.raw,
        updateVehicleImages.vehicleImage.name
      );
    }

    if (updateVehicleImages.vehicleImage2.raw) {
      formData.append(
        "vehicleImage2",
        updateVehicleImages.vehicleImage2.raw,
        updateVehicleImages.vehicleImage2.name
      );
    }
    if (updateVehicleImages.vehicleImage3.raw) {
      formData.append(
        "vehicleImage3",
        updateVehicleImages.vehicleImage3.raw,
        updateVehicleImages.vehicleImage3.name
      );
    }
    setLoading(true);
    axios
      .post(API_UPDATE_VEHICLE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
          handleUpdateModelsClose();
          getData();
        }
      })
      .catch((error) => {
        errorToastMsg("Internal Server Error");
        setLoading(false);
      });
    handleUpdateModelsClose();
    setdropdownValue("");
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await fetch(API_GET_MY_VEHICLES, {
        method: "GET",
        headers: {
          Authorization: `${token}`,
        },
      });
      const data = await res.json();
      setItems(data.response.vehicleList);
      setTotal(data.response.vehicleList.length);
      setLoading(false);
    } catch (error) {
      errorToastMsg(error.message);
      setLoading(false);
    }
  };

  const handleDeleteaccept = () => {
    if (!deletePlanID) {
      errorToastMsg("Please try again");
      return;
    }
    setModalShow(false);
    setLoading(true);
    axios
      .post(
        API_DELETE_VEHICLE,
        { agentVehicleId: deletePlanID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) errorToastMsg(res.data.errorMsg);
        else {
          successToastMsg(res.data.successMsg);
          getData();
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
  };

  const handleDeleteclose = () => {
    setModalShow(false);
  };

  const handleUpdateVehicleImages = (e) => {
    if(e.target.files.length){
      const image = e.target.files[0];
      imageCompressorFn(image,e.target.name,'Update');
      setUpdateVehicleImages({
        ...updateVehicleImages,
        [e.target.name]: {
          value: e.target.value,
        },
      });
    }
  };

  const handleVehicleUpdateData = (e) => {
    const { name, value } = e.target;
    if (name === "registrationNumber") {
      if (ALPHANUMERICREGEX.test(value)) {
        setUpdate({ ...update, [name]: value });
      } else {
        setUpdate({ ...update, [name]: value.slice(0, -1) });
      }
    } else if (name === "yearOfManufacture") {
      if (IS_NUMBER_REGEX.test(value) && value.length <= 4) {
        setUpdate({ ...update, [name]: value });
      } else {
        setUpdate({ ...update, [name]: value.slice(0, -1) });
      }
    } else {
      setUpdate({ ...update, [name]: value });
    }
  };

  const handleDeleteOpen = (id) => {
    setDeletePlanID(id);
    setModalShow(true);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [postPerPage]);

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleRcImageFrontCross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      rcImageFront: { preview: "", raw: "", value: "" },
    });
  };

  const handleRcImageBackCross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      rcImageBack: { preview: "", raw: "", value: "" },
    });
  };

  const handleVehicle1Cross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      vehicleImage1: { preview: "", raw: "", value: "" },
    });
  };

  const handleVehicle2Cross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      vehicleImage2: { preview: "", raw: "", value: "" },
    });
  };

  const handleVehicle3Cross = (event) => {
    event.preventDefault();
    setVehicleImages({
      ...vehicleImages,
      vehicleImage3: { preview: "", raw: "", value: "" },
    });
  };

  const handleUpdateRcImageFrontCross = (event) => {
    event.preventDefault();
    setUpdateVehicleImages({
      ...updateVehicleImages,
      rcFrontImage: { preview: "", raw: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, rcFrontImage: true });
  };

  const handleUpdateRcImageBackCross = (event) => {
    event.preventDefault();
    setUpdateVehicleImages({
      ...updateVehicleImages,
      rcImageBack: { preview: "", raw: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, rcImageBack: true });
  };

  const handleUpdateVehicle1Cross = (event) => {
    event.preventDefault();
    setUpdateVehicleImages({
      ...updateVehicleImages,
      vehicleImage: { preview: "", raw: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, vehicleImage: true });
  };

  const handleUpdateVehicle2Cross = (event) => {
    event.preventDefault();
    setUpdateVehicleImages({
      ...updateVehicleImages,
      vehicleImage2: { preview: "", raw: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, vehicleImage2: true });
  };

  const handleUpdateVehicle3Cross = (event) => {
    event.preventDefault();
    setUpdateVehicleImages({
      ...updateVehicleImages,
      vehicleImage3: { preview: "", raw: "", value: "" },
    });
    setUpdateImageState({ ...updateImageState, vehicleImage3: true });
  };

  const validateYearOfManufacture = () => {
    const futureYear =  new Date().getFullYear();
    const { yearOfManufacture } = formValues;
    if (yearOfManufacture) {
      if (yearOfManufacture < 2011) {
        setIsYearOfManufactureValid(false);
        setErrorMsg("Year of manufacture should be 2011 and above.");
      } else if( yearOfManufacture > futureYear){
        setIsYearOfManufactureValid(false);
        setErrorMsg("Year of manufacture should not be in the future date."); 
      }
      else {
        setIsYearOfManufactureValid(true);
        setErrorMsg("");
      }
    } else {
      setIsYearOfManufactureValid(true);
      setErrorMsg("");
    }
  };

  const validateUpdateYearOfManufacture = () => {
    const futureYear =  new Date().getFullYear();
    const { yearOfManufacture } = update;
    if (yearOfManufacture) {
      if (yearOfManufacture < 2011) {
        setIsUpdateYearOfManufactureValid(false);
        setUpdateErrorMsg("Year of manufacture should be 2011 and above.");
      } else if ( yearOfManufacture > futureYear){
        setIsUpdateYearOfManufactureValid(false);
        setUpdateErrorMsg("Year of manufacture should not be in the future date.");
      }
      else {
        setIsUpdateYearOfManufactureValid(true);
        setUpdateErrorMsg("");
      }
    } else {
      setIsUpdateYearOfManufactureValid(true);
      setUpdateErrorMsg("");
    }
  };

  useEffect(()=>{
    if(data.type === 'Add'){
      if(data.image){
        if(data.imageName){
          setVehicleImages({
            ...vehicleImages,
            [data.imageName]:{
              preview: URL.createObjectURL(data.image),
              raw: data.image
            }
          });
        }
      }
    }if(data.type === 'Update'){
      if(data.image){
        if(data.imageName){
          setUpdateVehicleImages({
            ...updateVehicleImages,
            [data.imageName]:{
              preview: URL.createObjectURL(data.image),
              raw: data.image
            }
          });
        }
      }
    }
  },[data]);

  return (
    <div className="d-flex">
      <Sidebar />
      <div className={`bg-secondary bg-opacity-10  ${styles.mainContainer} `}>
        <div className="d-flex justify-content-between align-items-center my-3  mx-2">
          <div className={styles.pageTitle}>
            <h4>My Vehicles</h4>
          </div>
          {/* Modal Starts here */}
          <div className={`${styles.addDriverBtnDiv}`}>
            <Button
              variant="warning"
              onClick={handleShow}
              className={`${styles.addDriverBtn}`}
            >
              + Add Vehicle
            </Button>
          </div>
        </div>

        {/* Add-Vehicle Modal Here */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          scrollable
        >
          <div className={styles.modalDiv}>
            <h5 className={`${styles.modalTitle}`}>Add Vehicle</h5>
            <div className={styles.modalCross} onClick={handleClose}>
              x
            </div>
          </div>

          <Modal.Body>
            <form onSubmit={handleAdd} autocomplete="off">
              <div className={`mb-3 ${styles.selectBox}`}>
                <select
                  className={styles.select}
                  name="vehicleTypeId"
                  required
                  onChange={(event) => changeDropdownValue(event.target.value)}
                  value={dropdownValue}
                >
                  <option value="">Select Vehicle Type</option>
                  {dropdownList.map((single) => {
                    return (
                      <option
                        key={single.vehicleTypeId}
                        value={single.vehicleTypeId}
                        className={`form-control ${styles.dropdownMenu}`}
                      >
                        {single.vehicleTypeName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Registration Number"
                  required
                  name="registrationNumber"
                  accept="image/*"
                  value={formValues.registrationNumber}
                  onChange={handleInput}
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Year Of Manufacture"
                  onBlur={validateYearOfManufacture}
                  required
                  value={formValues.yearOfManufacture}
                  name="yearOfManufacture"
                  onChange={handleInput}
                />
              </div>
              {!isYearOfManufactureValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "5px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {errorMsg}
                </div>
              )}
              <div className={`form-label fw-bold ${styles.labelFont}`}>
                Upload RC Images
                <div className="d-flex justify-content-between align-items-center">
                  <div className={styles.uploadRC}>
                    <label
                      for="file-input-rc-front"
                      className="d-flex align-items-center"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {vehicleImages.rcImageFront.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleRcImageFrontCross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <div className={styles.uploadbox}>
                                <img
                                  src={vehicleImages.rcImageFront.preview}
                                  alt="rcImageFront"
                                  accept="image/*"
                                  className={styles.fiRCUploadImage}
                                />
                              </div>
                            </div>
                          )}
                          {!vehicleImages.rcImageFront.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload RC Front
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-rc-front"
                          className="d-none"
                          name="rcImageFront"
                          value={vehicleImages.rcImageFront.value}
                          accept="image/*"
                          onChange={handleVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadRC}>
                    <label
                      for="file-input-rc-back"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {vehicleImages.rcImageBack.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleRcImageBackCross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <div className={styles.uploadbox}>
                                <img
                                  src={vehicleImages.rcImageBack.preview}
                                  alt="rcImageBack"
                                  className={styles.fiRCUploadImage}
                                />
                              </div>
                            </div>
                          )}
                          {!vehicleImages.rcImageBack.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload RC Back
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-rc-back"
                          className="d-none"
                          value={vehicleImages.rcImageBack.value}
                          name="rcImageBack"
                          accept="image/*"
                          onChange={handleVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className={`form-label fw-bold ${styles.labelFont}`}>
                Upload actual vehicles images
                <div className="d-flex justify-content-between align-items-center">
                  <div className={styles.uploadVImg}>
                    {" "}
                    <label
                      for="file-input-VehicleImage1"
                      className="d-flex align-items-center "
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadboxVehicleImages}>
                          {vehicleImages.vehicleImage1.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleVehicle1Cross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <div className={styles.uploadbox}>
                                <img
                                  src={vehicleImages.vehicleImage1.preview}
                                  alt="vehicle image"
                                  className={styles.fiRCUploadImage}
                                />
                              </div>
                            </div>
                          )}
                          {!vehicleImages.vehicleImage1.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>Upload</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-VehicleImage1"
                          className="d-none"
                          value={vehicleImages.vehicleImage1.value}
                          name="vehicleImage1"
                          accept="image/*"
                          onChange={handleVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadVImg}>
                    {" "}
                    <label
                      for="file-input-vehicleImage2"
                      className="d-flex align-items-center"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadboxVehicleImages}>
                          {vehicleImages.vehicleImage2.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleVehicle2Cross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <div className={styles.uploadbox}>
                                <img
                                  src={vehicleImages.vehicleImage2.preview}
                                  alt="vehicle image"
                                  className={styles.fiRCUploadImage}
                                />
                              </div>
                            </div>
                          )}
                          {!vehicleImages.vehicleImage2.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>Upload</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-vehicleImage2"
                          className="d-none"
                          value={vehicleImages.vehicleImage2.value}
                          name="vehicleImage2"
                          accept="image/*"
                          onChange={handleVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadVImg}>
                    {" "}
                    <label
                      for="file-input-vehicleImage3"
                      className="d-flex align-items-center"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadboxVehicleImages}>
                          {vehicleImages.vehicleImage3.preview && (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleVehicle3Cross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <div className={styles.uploadbox}>
                                <img
                                  src={vehicleImages.vehicleImage3.preview}
                                  alt="vehicle image"
                                  className={styles.fiRCUploadImage}
                                />
                              </div>
                            </div>
                          )}
                          {!vehicleImages.vehicleImage3.preview && (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>Upload</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-vehicleImage3"
                          className="d-none"
                          value={vehicleImages.vehicleImage3.value}
                          name="vehicleImage3"
                          accept="image/*"
                          onChange={handleVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row-reverse justify-content-start mb-1 mt-3 gap-4">
                <Button
                  type="submit"
                  variant="warning"
                  style={{ color: "white" }}
                  disabled={!isYearOfManufactureValid}
                >
                  Add
                </Button>
                <button onClick={handleClose} className={styles.closeBtn}>
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* ------------------ Update Modal hare -------------------- */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateModel}
          onHide={handleUpdateModelsClose}
          scrollable
        >
          <div className={styles.modalDiv}>
            <h5 className={`${styles.modalTitle}`}>Update Vehicles</h5>
            <div
              className={styles.modalCross}
              onClick={handleUpdateModelsClose}
            >
              x
            </div>
          </div>

          <Modal.Body>
            <form onSubmit={handleUpdate} autocomplete="off">
              <div className={`mb - 3 ${styles.selectBox}`}>
                <select
                  className={styles.select}
                  name="vehicleTypeId"
                  defaultValue={update.vehicleTypeId}
                  onChange={(event) =>
                    changeUpdateDropdownValue(event.target.value)
                  }
                  value={dropdownValue}
                >
                  <option value="">
                    {update.vehicleTypeName
                      ? update.vehicleTypeName
                      : "Select Vehicle Type"}
                  </option>
                  {dropdownList.map((single) => {
                    return (
                      <option
                        key={single.vehicleTypeId}
                        value={single.vehicleTypeId}
                        className={`form-control ${styles.dropdownMenu}`}
                      >
                        {single.vehicleTypeName}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Registration Number"
                  name="registrationNumber"
                  value={update.registrationNumber}
                  onChange={(e) => handleVehicleUpdateData(e)}
                  required
                />
              </div>
              <div className="mb-3">
                <input
                  type="number"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Year Of Manufacture"
                  name="yearOfManufacture"
                  value={update.yearOfManufacture}
                  onBlur={validateUpdateYearOfManufacture}
                  onChange={(e) => handleVehicleUpdateData(e)}
                  required
                />
              </div>
              {!isUpdateYearOfManufactureValid && (
                <div
                  style={{ marginBottom: "5px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {updateErrorMsg}
                </div>
              )}
              <div className={`form-label fw-bold ${styles.labelFont}`}>
                Upload RC
                <div className="d-flex gap-5">
                  <div className={styles.uploadRC}>
                    <label
                      for="file-input-rc-front"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {updateVehicleImages.rcFrontImage.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateRcImageFrontCross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>

                              <img
                                src={updateVehicleImages.rcFrontImage.preview}
                                alt="rcImageFront"
                                className={styles.fiRCUploadImage}
                              />
                            </div>
                          ) : update.rcFrontImageToShow &&
                            !updateImageState.rcFrontImage ? (
                            <img
                              src={update.rcFrontImageToShow}
                              alt="rcFrontImage"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload RC Front
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <input
                        type="file"
                        id="file-input-rc-front"
                        className="d-none"
                        name="rcFrontImage"
                        accept="image/*"
                        value={updateVehicleImages.rcFrontImage.value}
                        onChange={handleUpdateVehicleImages}
                      />
                    </label>
                  </div>
                  <div className={styles.uploadRC}>
                    <label
                      for="file-input-rc-back"
                      className="d-flex align-items-center gap-5"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadbox}>
                          {updateVehicleImages.rcImageBack.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateRcImageBackCross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <div className={styles.uploadbox}>
                                <img
                                  src={updateVehicleImages.rcImageBack.preview}
                                  alt="rcImageBack"
                                  accept="image/*"
                                  className={styles.fiRCUploadImage}
                                />
                              </div>
                            </div>
                          ) : update.rcImageBackToShow &&
                            !updateImageState.rcImageBack ? (
                            <img
                              src={update.rcImageBackToShow}
                              alt="rcImageBack"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div className={styles.fileUploadDiv}>
                              <div className={styles.uploadbox}>
                                <FiUpload className={`${styles.uploadIcon}`} />
                                <p className={`${styles.uploadText}`}>
                                  Upload RC Back
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-rc-back"
                          className="d-none"
                          accept="image/*"
                          name="rcImageBack"
                          value={updateVehicleImages.rcImageBack.value}
                          onChange={handleUpdateVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>

              <div className={`form-label fw-bold ${styles.labelFont}`}>
                Upload actual vehicles images
                <label
                  htmlFor="file-rc"
                  className="d-flex justify-content-between align-items-center"
                >
                  <div className={styles.uploadVImg}>
                    {" "}
                    <label
                      for="file-input-VImg1"
                      className="d-flex align-items-center gap-4"
                    >
                      <div className={` ${styles.upload}`}>
                        <div className={styles.uploadSmall}>
                          {updateVehicleImages.vehicleImage.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateVehicle1Cross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={updateVehicleImages.vehicleImage.preview}
                                alt="Vehicle Image"
                                className={styles.uploadIconSmall}
                              />
                            </div>
                          ) : update.vehicleImageToShow &&
                            !updateImageState.vehicleImage ? (
                            <img
                              src={update.vehicleImageToShow}
                              alt="Vehicle Image"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div>
                              <div className={styles.uploadSmall}>
                                <FiUpload
                                  className={`${styles.uploadIconSymbol}`}
                                />
                                <p className={`${styles.uploadText}`}>Upload</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-VImg1"
                          className="d-none"
                          value={updateVehicleImages.vehicleImage.value}
                          name="vehicleImage"
                          accept="image/*"
                          onChange={handleUpdateVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadVImg}>
                    {" "}
                    <label
                      for="file-input-VImg2"
                      className="d-flex align-items-center gap-4"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadSmall}>
                          {updateVehicleImages.vehicleImage2.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateVehicle2Cross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={updateVehicleImages.vehicleImage2.preview}
                                alt="Vehicle image"
                                className={styles.uploadIconSmall}
                              />
                            </div>
                          ) : update.vehicleImage2ToShow &&
                            !updateImageState.vehicleImage2 ? (
                            <img
                              src={update.vehicleImage2ToShow}
                              alt="Vehicle image"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div>
                              <div className={styles.uploadSmall}>
                                <FiUpload
                                  className={`${styles.uploadIconSymbol}`}
                                />
                                <p className={`${styles.uploadText}`}>Upload</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-VImg2"
                          className="d-none"
                          value={handleUpdateVehicle2Cross}
                          name="vehicleImage2"
                          accept="image/*"
                          onChange={handleUpdateVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                  <div className={styles.uploadVImg}>
                    <label
                      for="file-input-VImg3"
                      className="d-flex align-items-center"
                    >
                      <div className={`${styles.upload}`}>
                        <div className={styles.uploadSmall}>
                          {updateVehicleImages.vehicleImage3.preview ? (
                            <div className={styles.imageBox}>
                              <div
                                className={`cross ${styles.crossSign}`}
                                onClick={handleUpdateVehicle3Cross}
                              >
                                <BsXCircle
                                  color="#D7E0EE"
                                  style={{
                                    backgroundColor: "#272B41",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    top: 2,
                                    right: 1,
                                  }}
                                />
                              </div>
                              <img
                                src={updateVehicleImages.vehicleImage3.preview}
                                alt="vehicle image"
                                className={styles.uploadIconSmall}
                              />
                            </div>
                          ) : update.vehicleImage3ToShow &&
                            !updateImageState.vehicleImage3 ? (
                            <img
                              src={update.vehicleImage3ToShow}
                              alt="vehicle image"
                              className={styles.fiRCUploadImage}
                            />
                          ) : (
                            <div>
                              <div className={styles.uploadSmall}>
                                <FiUpload
                                  className={`${styles.uploadIconSymbol}`}
                                />
                                <p className={`${styles.uploadText}`}>Upload</p>
                              </div>
                            </div>
                          )}
                        </div>
                        <input
                          type="file"
                          id="file-input-VImg3"
                          className="d-none"
                          name="vehicleImage3"
                          accept="image/*"
                          value={updateVehicleImages.vehicleImage3.value}
                          onChange={handleUpdateVehicleImages}
                        />
                      </div>
                    </label>
                  </div>
                </label>
              </div>
              <div
                className={`d-flex flex-row-reverse justify-content-start mx-3 mb-2 mt-4 gap-4 ${styles.buttonBox}`}
              >
                <Button
                  type="submit"
                  className={styles.addDriver}
                  disabled={!isUpdateYearOfManufactureValid}
                >
                  Update Vehicle
                </Button>
                <button
                  className={styles.closeBtn}
                  onClick={handleUpdateModelsClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* -------------modal ends here----------------- */}
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {currentPost.length > 0 ? (
              <div className={`row mx-0 ${styles.driversGrid}`}>
                {currentPost.map((ele) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12  mx-0 p-2"
                    key={ele.agentVehicleId}
                  >
                    <div
                      className={styles.contentDiv}
                      style={{ padding: "25px 15px" }}
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className={styles.nameTitle}>
                            {ele.registrationNumber}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between gap-2">
                          {ele.status === 1 ? (
                            <span className={`${styles.badge}`}>Active</span>
                          ) : (
                            <span className={`${styles.deactiveBadge}`}>
                              Deactive
                            </span>
                          )}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Click to Edit
                              </Tooltip>
                            }
                          >
                            <i
                              className={`${styles.iconStyle} fas fa-edit me-2`}
                              onClick={() =>
                                handleUpdateModelshow({
                                  agentVehicleId: ele.agentVehicleId,
                                  registrationNumber: ele.registrationNumber,
                                  yearOfManufacture: ele.yearOfManufacture,
                                  vehicleTypeId: ele.vehicleTypeId,
                                  rcFrontImageToShow: ele.rcImage,
                                  rcImageBackToShow: ele.rcImageBack,
                                  vehicleImageToShow: ele.vehicleImage,
                                  vehicleImage2ToShow: ele.vehicleImage2,
                                  vehicleImage3ToShow: ele.vehicleImage3,
                                  rcImageFront: "rcImageFront",
                                  rcImageBack: "rcImageBack",
                                  vehicleImage1: "vehicleImage1",
                                  vehicleImage2: "vehicleImage2",
                                  vehicleImage3: "vehicleImage3",
                                  vehicleTypeName: ele.vehicleTypeName,
                                })
                              }
                            ></i>
                            {/* <FiEdit /> */}
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Click to Delete
                              </Tooltip>
                            }
                          >
                            <i
                              style={{ opacity: "0.8" }}
                              className={`${styles.iconStyle} fa fa-trash-alt`}
                              onClick={() =>
                                handleDeleteOpen(ele.agentVehicleId)
                              }
                            ></i>
                            {/* <RiDeleteBin6Line /> */}
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div
                        className={`d-flex justify-content-between  ${styles.detailsSection}`}
                      >
                        <div>
                          <div>
                            <p
                              className={styles.otherContentTitle}
                              style={{ marginTop: "10px" }}
                            >
                              Year Of Manufacturing
                            </p>
                            <p
                              className={styles.otherContentValue}
                              style={{ marginTop: "5px" }}
                            >
                              {ele.yearOfManufacture}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div>
                            <p
                              className={styles.otherContentTitle}
                              style={{ marginTop: "10px" }}
                            >
                              {" "}
                              Type
                            </p>
                            <p
                              className={styles.otherContentValue}
                              style={{ marginTop: "5px" }}
                            >
                              {ele.vehicleTypeName}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
        <PaginNew
          post={items}
          total={total}
          handlePageClick={handlePageClick}
          postPerPage={postPerPage}
          setPostPerPage={setPostPerPage}
          page={page}
        />
        <ToastContainer />
        <div
          className={`d-flex justify-content-center align-items-center ${styles.addBookingDiv}`}
          onClick={handleShow}
        >
          <AiOutlinePlus className={styles.addBookingIcon} />
        </div>

        {/* Delete Modal Component */}

        <DeleteuserComponent
          show={modalShow}
          onHide={handleDeleteclose}
          onAccept={handleDeleteaccept}
        />
      </div>
    </div>
  );
};
