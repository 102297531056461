import React from "react";
import styles from "./MyBooking.module.scss";
import ActiveBookings from "./ActiveBookings";

const BookingStatus = ({
  status,
  customerData,
  color,
  submit,
  gettingDataFun,
}) => {
  let actualData;
  if(status==="Completed") {
    actualData=customerData?.slice(0,10)
  }
  else{
    actualData=customerData;
  }

  return (
    <div className={styles.applicationStatus}>
      <div className={styles.status}>
        <p>{status}</p>
      </div>
      <div className={styles.bookingInfo}>
        {actualData !== undefined && 
          actualData.map((el, index) => {
            return (
              <ActiveBookings
                key={index}
                el={el}
                color={color}
                status={status}
                submit={submit}
                gettingDataFun={gettingDataFun}
              />
            );
          })}
      </div>
    </div>
  );
};

export default BookingStatus;
