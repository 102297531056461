import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./login.module.scss";
import bgImage from "../../assets/images/login_bg_image.png";
import TaxiIcon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../assets/images/Taxi-sanchalak-left-image.png";
import LeftBackGround from "../../assets/images/Left-BackGround.png";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_LOGIN_BY_OTP, EMAIL_REGEX, IS_NUMBER_REGEX, PHONE_REGEX } from "../../utils/consts/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import taxiIcon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import { userthunk } from "../../redux/action/user.action";
import { OtpComponent, otpComponent } from "./otpComponent/otp";
import axios from "axios";
import { API_LOGIN_USER } from "../../utils/consts/api";
import Spinner from "../../components/Dashboard/CommonComp/Spinner/Spinner";


const LoginComponent = () => {
  const [mobileLogin, setMobileLogin] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [mobileSubmitted, setMobileSubmitted] = useState(false);
  const [forgotpasswordState, setForgotpasswordState] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [checkedValue, setCheckedValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [mobileNumber, setMobileNumber] = useState({
    mobileNumber: "",
  });

  const dispatch = useDispatch();

  const state = useSelector((state) => state.userReducer.userdata);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const { email, password } = inputs;

  const changeloginstate = () => {
    if (mobileSubmitted) {
      setMobileSubmitted(false);
    } else {
      {
        mobileLogin ? setMobileLogin(false) : setMobileLogin(true);
      }
    }
  };

  const handlePassword = () => {
    setshowPassword(!showPassword);
  };

  const handleMobileSubmission = (e) => {
    e.preventDefault();
    setLoading(true);
    if (isPhoneValid) {
      axios
        .post(API_LOGIN_BY_OTP, mobileNumber)
        .then(({ data }) => {
          if (data.responseCode === -1) {
            errorToastMsg(data.errorMsg);
            setLoading(false);
          } else if (data.responseCode === 0) {
            setLoading(false);
            successToastMsg(data.successMsg);
            setMobileSubmitted(true);
            setMobileLogin(false);
          }
        })
        .catch((err) => {
          errorToastMsg(err.message);
          setLoading(false);
        });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
    if (!email) {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const validateEmail = () => {
    if (email) {
      if (!EMAIL_REGEX.test(email)) {
        setIsValid(false);
        setErrorMsg("Invalid Email Address.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    if (mobileNumber.mobileNumber) {
      if (!PHONE_REGEX.test(mobileNumber.mobileNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  useEffect(() => {
    if (state.userData) {
      if (state.userData.responseCode === -1) {
        errorToastMsg(state.userData.errorMsg);
      } else if (state.userData.responseCode === 0) {
        successToastMsg("Sucessfully Verified the Customre");
        sessionStorage.setItem("userToken", state.userData.response.token);
        sessionStorage.setItem("crmAgentId", state.userData.response.crmAgentId);
        if (state.loggedInStatus) {
          localStorage.setItem("userToken", state.userData.response.token);
          localStorage.setItem("crmAgentId", state.userData.response.crmAgentId);
        }
        const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");
        if (token) {
          navigate("/dashboard/mybooking");
        }
      }
    }
  }, [state]);

  const HandleMobileBackButton = () => {
    setMobileSubmitted(false);
    setMobileLogin(true);
  };

  const handleMobileNumber = (e) => {
    const {name,value} = e.target;
    if(IS_NUMBER_REGEX.test(value) && value.length<=10 ){
      setMobileNumber({ mobileNumber: value });       
    }else{
      setMobileNumber({ mobileNumber: value.slice(0,-1) });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (email && password) {
      inputs.email = inputs.email.toLocaleLowerCase();
      Number(password);
      dispatch(userthunk({ inputs, checkedValue }));
    }
  };

  return (
    <>
    {loading ? (<Spinner/>):(
    <div>
      {!mobileLogin && !mobileSubmitted && (
        <div className={styles.logincomponent}>
          <div
            className={`${styles.taxiIcon}`}
            style={{ backgroundImage: `url(${taxiIcon})` }}
          >
          </div>
          {/* <div
            className={`d-none d-md-block ${styles.bgleftimage}`}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
          </div> */}
          <div className={styles.TaxiCRMImageDiv}>
            <div className={styles.IconAndText}>
              <img src={TaxiIcon} className={styles.TaxiIconImage} />
              <p className={styles.imageText}>CRM Application</p>
            </div>
            <div className={styles.TaxiLeftImage}>
              <img src={TaxiLeftImage} width="100%" />
            </div>
          </div>
          {/* <div className={styles.bgleftimageMediumDiv}>
            <img src={bgImage} className={styles.bgleftimageMedium} />
          </div> */}
          <div className={styles.bgrightloginform}>
            <div className={styles.formBox}>
              <h4 className={`mb-4 ${styles.loginCRM}`}>Login to CRM</h4>
              <form onSubmit={handleSubmit} autocomplete="off"
              style={{ marginTop: '35px' }}>
                <div className={`form-group mb-3 ${styles.inputbox}`}>
                  <label
                    className={`form-label fw-bold ${styles.inputlabel}`}
                    htmlFor="exampleInputEmail1"
                  >
                    Email address
                  </label>
                  <input
                    type="text"
                    className={`form-control ${styles.input}`}
                    autoComplete="false"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="name@example.com"
                    value={email}
                    required
                    onBlur={validateEmail}
                    onChange={handleChange}
                    name="email"
                  />
                </div>
                {submitted && !email && !errorMsg && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    Email is required
                  </div>
                )}
                {!isValid && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    {errorMsg}
                  </div>
                )}
                <div className={` ${styles.inputbox}`}>
                  <label
                    className={`form-label ${styles.inputlabel}`}
                    htmlFor="exampleInputPassword1"
                  >
                    Password
                  </label>
                  <div className={`input-group`}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className={`form-control ${styles.input}`}
                      id="exampleInputPassword1"
                      placeholder="Password"
                      required
                      onChange={handleChange}
                      name="password"
                    />
                    {!showPassword && (
                      <span className={`input-group-text ${styles.eyeStyle}`}>
                        <i
                          className="far fa-eye-slash"
                          onClick={handlePassword}
                        ></i>
                      </span>
                    )}
                    {showPassword && (
                      <span className={`input-group-text ${styles.eyeStyle}`}>
                        <i className="far fa-eye" onClick={handlePassword}></i>
                      </span>
                    )}
                  </div>
                </div>
                {submitted && !password && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    Password is required
                  </div>
                )}
                <div className={`form-group mb-4 ${styles.keeplogged}`}>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={() => setCheckedValue(!checkedValue)}
                      id="exampleCheck1"
                      value="loggedIn"
                    />
                    <label className="form-check-label" htmlFor="exampleCheck1">
                      Keep me logged in
                    </label>
                  </div>
                  <div className={styles.forgotpassword}>
                    <Link to="/login/forgot">Forgot Password ?</Link>
                  </div>
                </div>
                <button
                  type="submit"
                  disabled={!isValid}
                  className={`btn ${styles.signinbutton}`}
                >
                  Sign In
                </button>
              </form>
              <p className={styles.or}>
                <span>Or</span>
              </p>
              <button
                type="submit"
                className={styles.signinwithmobile}
                onClick={changeloginstate}
              >
                Sign in with Mobile Number & OTP
              </button>
            </div>
            <div className={styles.donotaccount}>
              <p>
                Don't have an account?
                <Link to="/register" className={styles.registernow}>
                  Register Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
      {mobileLogin && !mobileSubmitted && (
        <div className={styles.logincomponent}>
          <div
            className={`${styles.taxiIcon}`}
            style={{ backgroundImage: `url(${taxiIcon})` }}
          ></div>
          <div className={styles.TaxiCRMImageDiv}>
            <div className={styles.IconAndText}>
              <img src={TaxiIcon} className={styles.TaxiIconImage} />
              <p className={styles.imageText}>CRM Application</p>
            </div>
            <div className={styles.TaxiLeftImage}>
              <img src={TaxiLeftImage} width="100%" />
            </div>
          </div>
          {/* <div
            className={`d-none d-md-block ${styles.bgleftimage}`}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
          </div>
          <div className={styles.bgleftimageMediumDiv}>
            <img src={bgImage} className={styles.bgleftimageMedium} />
          </div> */}
          <div
            className={styles.bgrightloginform}
          >
            <div className={styles.formBox}>
              <form onSubmit={handleMobileSubmission} autocomplete="off">
                <h4 className={`mb-6 ${styles.mobilelogin}`}>
                  Login to CRM
                </h4>
                <div className={`form-group mb-3 ${styles.inputbox}`}>
                  <label
                    style={{ marginBottom: '10px' }}
                    htmlFor="exampleInputEmail1"
                    className={styles.inputlabel}
                  >
                    Mobile Number
                  </label>
                  <input
                    type="number"
                    className={`form-control ${styles.input}`}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Enter registerd mobile number"
                    value={mobileNumber.mobileNumber}
                    onChange={handleMobileNumber}
                    required
                    onBlur={validatePhoneNumber}
                  />
                </div>
                {!isPhoneValid && (
                  <div className={`text-danger ${styles.errormessage}`} style={{ marginTop:'' }}>
                    {phoneErrorMsg}
                  </div>
                )}
                <button
                  type="submit"
                  className={`btn  ${styles.sendcode}`}
                  style={{ marginTop: "20px" }}
                >
                  Send Code
                </button>
                <p className={styles.or}>
                  <span>Or</span>
                </p>
                <button
                  type="submit"
                  className={styles.signinwithmobile}
                  onClick={changeloginstate}
                >
                  Sign in with Email & Password
                </button>
              </form>
            </div>
            <div className={styles.donotaccount}>
              <p>
                Don't have an account?
                <Link to="/register" className={styles.registernow}>
                  Register Now
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
      {mobileSubmitted && (
        <OtpComponent
          loginHandle={changeloginstate}
          HandleMobileBackButton={HandleMobileBackButton}
          handlemobileNumber={mobileNumber}
        />
      )}
      </div>
      )}
      <ToastContainer />
    </>
  );
};

export default LoginComponent;








