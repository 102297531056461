import React, { useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import EventIcon from "@mui/icons-material/Event";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { NavLink } from "react-router-dom";
import { IoMdCard } from "react-icons/io";
import { style } from "@mui/system";
import { RiLinksFill } from "react-icons/ri";

const Sidebar = () => {
  const [LinkColor, setLinkColor] = useState("");

  const navLinkStyles = ({ isActive }) => {
    return {
      backgroundColor: isActive ? "#F7B217" : "",
      color: isActive ? "white" : "black",
    };
  };

  useEffect(() => {
    setLinkColor(window.location.pathname);
  },[]);

  return (
    <div className={styles.sidebar}>
      <NavLink
        to="/dashboard/mybooking"
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.sidebarElements
        }
      >
        <EventIcon className={styles.sidebarIcon} />

        <p> My Bookings</p>
      </NavLink>
      <NavLink
        to="/dashboard/customer"
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.sidebarElements
        }
      >
        <PeopleOutlineIcon className={styles.sidebarIcon} />

        <p>My Customers</p>
      </NavLink>
      <NavLink
        to="/dashboard/vehicles"
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.sidebarElements
        }
      >
        <DirectionsCarFilledOutlinedIcon className={styles.sidebarIcon} />

        <p>My Vehicles</p>
      </NavLink>
      <NavLink
        to="/dashboard/drivers"
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.sidebarElements
        }
      >
        <PersonOutlineOutlinedIcon className={styles.sidebarIcon} />

        <p>My Drivers</p>
      </NavLink>
      <NavLink
        to="/dashboard/bankaccount"
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.sidebarElements
        }
      >
        <IoMdCard className={styles.sidebarIcon} />

        <p>Payment Details</p>
      </NavLink>
      <NavLink
        to="/dashboard/reviewLinks"
        className={({ isActive }) =>
          isActive ? styles.activeLink : styles.sidebarElements
        }
      >
        <RiLinksFill className={styles.sidebarIcon} />

        <p>Review Links</p>
      </NavLink>
    </div>
  );
};

export default Sidebar;
