import axios from "axios";
import { useState } from "react";
import { errorToastMsg, successToastMsg } from "../../../common/ToastMessage";
import { API_CHANGE_PASSWORD } from "../../../utils/consts/api";
import styles from "./UpdatePassword.module.scss";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../Dashboard/CommonComp/Spinner/Spinner";

export const BankInformation = () => {
  const [showPassword, setshowPassword] = useState(false);
  const [showPassword2, setshowPassword2] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");

  const passwordIntialValues = {
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  };

  const [passwordData, setPasswordData] = useState(passwordIntialValues);
  const [loading, setLoading] = useState(false);
  const { oldPassword, newPassword, confirmNewPassword } = passwordData;

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const handlePassword = () => {
    setshowPassword(!showPassword);
  };

  const handlePassword2 = () => {
    setshowPassword2(!showPassword2);
  };

  const handlePasswordInput = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const password_validate = () => {
    const password_Checker = {
      capital: /[A-Z]/,
      digit: /[0-9]/,
      full: /^[@#][A-Za-z0-9]{7,13}$/,
    };

    if (newPassword) {
      if (!password_Checker.capital.test(newPassword)) {
        setIsPasswordValid(false);
        setPasswordErrorMsg(
          "Password should have atlest one capital character"
        );
      } else if (!password_Checker.digit.test(newPassword)) {
        setIsPasswordValid(false);
        setPasswordErrorMsg("Password should have atleat one digit");
      } else if (newPassword.length < 7) {
        setIsPasswordValid(false);
        setPasswordErrorMsg("Password length should be greater than 7");
      } else {
        setIsPasswordValid(true);
        setPasswordErrorMsg("");
      }
    } else {
      setIsPasswordValid(true);
      setPasswordErrorMsg("");
    }
  };

  const handlePasswordSubmission = (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      errorToastMsg("NewPassword and ConfirmPassword are not matching");
      return;
    }

    setLoading(true);

    axios
      .post(API_CHANGE_PASSWORD, passwordData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === 0) {
          setLoading(false);
          successToastMsg(res.data.successMsg);
          setPasswordData(passwordIntialValues);
        } else if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div>
          <p className={styles.editProfileText}>Change Password</p>
          <p className={styles.peronalInformationText}>
            Change or reset your account password
          </p>
        </div>
        <hr></hr>

        {loading ? (
          <Spinner />
        ) : (
          <div className={styles.formDiv}>
            <div className={styles.formInnerDiv}>
              <form onSubmit={handlePasswordSubmission} autoComplete="off">
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="exampleInputName"
                      className={styles.inputlabel}
                    >
                      Old Password
                    </label>
                    <input
                      type="text"
                      className={`form-control ${styles.input}`}
                      name="oldPassword"
                      id="exampleInputName"
                      value={passwordData.oldPassword}
                      placeholder="Enter password"
                      onChange={handlePasswordInput}
                      required
                    />
                  </div>
                </div>
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="exampleInputPhoneNumber"
                      className={styles.inputlabel}
                    >
                      New Password
                    </label>
                    <div style={{ display: "flex" }}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${styles.input}`}
                        id="Enter new password"
                        placeholder="Enter new password"
                        value={passwordData.newPassword}
                        name="newPassword"
                        onChange={handlePasswordInput}
                        required
                        onBlur={password_validate}
                      />
                      {!showPassword && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye-slash"
                            onClick={handlePassword}
                          ></i>
                        </span>
                      )}
                      {showPassword && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye"
                            onClick={handlePassword}
                          ></i>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {!isPasswordValid && (
                  <div
                    style={{ marginBottom: "10px" }}
                    className={`text-danger ${styles.errormessage}`}
                  >
                    {passwordErrorMsg}
                  </div>
                )}
                <div className={styles.userInformationDiv}>
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      style={{ marginBottom: "7px" }}
                      htmlFor="exampleInputCompany"
                      className={styles.inputlabel}
                    >
                      Confirm Password
                    </label>
                    <div style={{ display: "flex" }}>
                      <input
                        type={showPassword2 ? "text" : "password"}
                        className={`form-control ${styles.input}`}
                        id="exampleInputCompany"
                        placeholder="Confirm password"
                        name="confirmNewPassword"
                        value={passwordData.confirmNewPassword}
                        onChange={handlePasswordInput}
                        required
                      />
                      {!showPassword2 && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye-slash"
                            onClick={handlePassword2}
                          ></i>
                        </span>
                      )}
                      {showPassword2 && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye"
                            onClick={handlePassword2}
                          ></i>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.buttonDiv}>
                  <button
                    type="submit"
                    disabled={!isPasswordValid}
                    className={styles.updateButton}
                  >
                    Confirm Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
