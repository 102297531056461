import React, { useState } from "react";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";
import MarkBookigCompleteModal from "./MarkBookingCompleteModal/MarkBookingCompleteModal";
import { errorToastMsg, successToastMsg } from "../../../common/ToastMessage";
import styles from "./bookingTabs.module.scss";
import moment from "moment";
import ConfirmBookingModal from "./ConfirmBookingModal/ConfirmBookingModal";
import {
  API_BACK_STATE,
  API_CANCEL_BOOKING,
  API_TRIP_STARTED,
} from "../../../utils/consts/api";
import axios from "axios";
import Profile_Icon from "../../../assets/images/driver_placeholder.jpg";
import AssignBookingModal from "./AssignBookingModal/AssignBookingModal";
import CompleteTripBookigmodal from "./CompleteTripBookigModal/CompleteTripBookingmodal";
import SendReviewLinkModal from "./SendReviewLink/SendReviewLinkModal";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Modal, Tooltip } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";

export const BookingTabs = ({ el, btnValue, gettingDataFun }) => {
  const [confirmBookigStatus, setconfirmBookigStatus] = useState(false);
  const [assignBookingStatus, setassignBookingStatus] = useState(false);
  const [MarginEarnedBookigStatus, setMarginEarnedBookigStatus] =
    useState(false);
  const [CompleteTripBookigStatus, setCompleteTripBookigStatus] =
    useState(false);
  const [show, setShow] = useState(false);
  const [sendReviewShow, setSendReviewShow] = useState(false);
  const [tripCompletedStatus, setTripCompletedStatus] = useState(false);

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const clickTripStarted = () => {
    axios
      .post(
        API_TRIP_STARTED,
        {
          crmBookingId: el.crmBookingId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          gettingDataFun();
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleshow = (el) => {
    setShow(true);
  };

  const handleCancelBooking = (id) => {
    const cancelBookingData = {
      crmBookingId: id,
    };

    axios
      .post(API_CANCEL_BOOKING, cancelBookingData, {
        headers: {
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) errorToastMsg(res.data.errorMsg);
        else {
          successToastMsg(res.data.successMsg);
          gettingDataFun();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
      });
  };

  const handleSucessfullCallBackFunction = () => {
    setMarginEarnedBookigStatus(true);
    setCompleteTripBookigStatus(false);
  };

  const handleSubmit = (e) => {
    if (e.btnValue === "Confirm") {
      setconfirmBookigStatus(true);
    } else if (e.btnValue === "Assign") {
      setassignBookingStatus(true);
    } else if (e.btnValue === "Trip Started") {
      clickTripStarted();
    } else if (e.btnValue === "Complete Trip") {
      setCompleteTripBookigStatus(true);
    } else if (e.btnValue === "Send Review Link") {
      setSendReviewShow(true);
    }
  };

  const handlePopUpSubmit = ()=>{
    setconfirmBookigStatus(true);
    setShow(false);
  }

  const handleSucessfullMarkBooking = () => {
    setMarginEarnedBookigStatus(false);
    setSendReviewShow(true);
  };

  const handleBackFunction = (BookingId,event) => {
    event.stopPropagation();
    axios
      .post(
        API_BACK_STATE,
        {
          crmBookingId: BookingId,
        },
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          gettingDataFun();
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
      });
  };

  return (
    <>
      <div>
        <div className="card my-3">
          <div className="card-body border" onClick={() => handleshow(el)}>
            <div>
              {btnValue === "Assign" ? (
                <div className={`${styles.backStateDiv}`}>
                  <OverlayTrigger
                    placement="right"
                    style={{ height: "40%" }}
                    overlay={
                      <Tooltip
                        id={`tooltip-top`}
                        style={{ marginTop: "1px", marginLeft: "5px" }}
                      >
                        Move to Added
                      </Tooltip>
                    }
                  >
                    <i onClick={(e) => handleBackFunction(el.crmBookingId,e)}>
                      <IoMdArrowBack style={{ fontWeight: "bolder" }} />
                    </i>
                  </OverlayTrigger>
                </div>
              ) : btnValue === "Trip Started" ? (
                <div className={`${styles.backStateDiv}`}>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-top`}>Move to Confirmed</Tooltip>
                    }
                  >
                    <i onClick={(e) => handleBackFunction(el.crmBookingId,e)}>
                      <IoMdArrowBack />
                    </i>
                  </OverlayTrigger>
                </div>
              ) : btnValue === "Complete Trip" ? (
                <div className={`${styles.backStateDiv}`}>
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        Move to Vehicle Alloted
                      </Tooltip>
                    }
                  >
                    <i onClick={(e) => handleBackFunction(el.crmBookingId,e)}>
                      <IoMdArrowBack />
                    </i>
                  </OverlayTrigger>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className={styles.id}>ID {el.crmBookingId}</p>
              <div className="d-flex align-items-center">
                <span
                  className={
                    el.bookingType == "oneWay"
                      ? `${styles.oneWayBadge}`
                      : `${styles.rountTripBadge}`
                  }
                >
                  {el.bookingType}
                </span>
                {el.status === "completed" || el.status === "inProgress" ? (
                  ""
                ) : (
                  <div style={{ cursor: "pointer" }}>
                    <OverlayTrigger
                      delay={{ show: 250, hide: 800 }}
                      placement="bottom"
                      overlay={
                        <Popover>
                          <Popover.Body
                            onClick={() => handleCancelBooking(el.crmBookingId)}
                            style={{ padding: "4px", cursor: "pointer" }}
                            className={styles.modalcancle}
                          >
                            Cancel Booking
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <MoreVertIcon className={styles.moreSettings} />
                    </OverlayTrigger>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-2">
              <p className={styles.customerName}>{el.customerName}</p>
              <h5
                className={styles.mobileNumber}
              >{`+91 ${el.customerContactNumber}`}</h5>
            </div>
            <div className="mb-2">
              <p className={styles.contentHeadings}>Trip Date & Time</p>
              <h5 className={styles.contentValue}>
                {moment(
                  `${el.pickUpDate.slice(0, 10)} ${el.pickUpTime}`
                ).format("DD MMM  YYYY h:mm A")}
              </h5>
            </div>
            {btnValue === "Send Review Link" && (
              <div className="mb-2">
                <p className={styles.contentHeadings}>Trip Notes</p>
                <h5 className={styles.contentValue}>
                  {el.tripNotes ? el.tripNotes : "-"}
                </h5>
              </div>
            )}
            {btnValue === "Send Review Link" && (
              <div className="mb-2">
                <p className={styles.contentHeadings}>Tour Description</p>
                <h5 className={styles.contentValue}>
                  {el.tourDescription ? el.tourDescription : "-"}
                </h5>
              </div>
            )}
            {el.noOfDays ? (
              <div className="mb-2">
                <p className={styles.contentHeadings}>No. Of Days</p>
                <h5 className={styles.contentValue}>
                  {el.noOfDays > 1
                    ? `${el.noOfDays} days`
                    : `${el.noOfDays} day`}
                </h5>
              </div>
            ) : (
              ""
            )}
            <div className="mb-2">
              <p className={styles.contentHeadings}>Pickup Location</p>
              <h5 className={styles.contentValue}>{el.pickUpLocationName}</h5>
            </div>
            {el.marginEarned && (
              <div className="mb-2">
                <p className={styles.contentHeadings}>Margin Earned</p>{" "}
                <h5
                  style={{ color: "#20C997" }}
                  className={styles.contentValue}
                >
                  {el.marginEarned ? `₹ ${el.marginEarned}` : "-"}
                </h5>
              </div>
            )}
            <div className="mb-2">
              <p className={styles.contentHeadings}>Booking Amount</p>{" "}
              <h5 style={{ color: "#20C997" }} className={styles.contentValue}>
                ₹{el.bookingAmount}
              </h5>
            </div>
          </div>
          <hr className="mt-0" />
          <div className={styles.btnDiv}>
            <button
              className={
                btnValue === "Confirm"
                  ? ` ${styles.confirmBtn}`
                  : btnValue === "Assign"
                  ? `${styles.assignBtn}`
                  : btnValue === "Trip Started"
                  ? `${styles.tripStartedBtn}`
                  : btnValue === "Complete Trip"
                  ? `${styles.completeTripBtn}`
                  : `${styles.reviewLinkBtn}`
              }
              onClick={() => handleSubmit({ btnValue })}
            >
              {btnValue}
            </button>
          </div>
          {confirmBookigStatus && (
            <ConfirmBookingModal
              element={el}
              callbackFunc={setconfirmBookigStatus}
              crmBookingId={el.crmBookingId}
              gettingDataFun={gettingDataFun}
            />
          )}
          {assignBookingStatus && (
            <AssignBookingModal
              callbackFunc={setassignBookingStatus}
              crmBookingId={el.crmBookingId}
              gettingDataFun={gettingDataFun}
            />
          )}
          {CompleteTripBookigStatus && (
            <CompleteTripBookigmodal
              setTripCompletedStatus={setCompleteTripBookigStatus}
              SecessfullcallbackFunc={handleSucessfullCallBackFunction}
              callbackFunc={setCompleteTripBookigStatus}
              crmBookingId={el.crmBookingId}
              gettingDataFun={gettingDataFun}
            />
          )}
          {sendReviewShow && (
            <SendReviewLinkModal
              callbackFunc={setSendReviewShow}
              crmBookingId={el.crmBookingId}
              gettingDataFun={gettingDataFun}
            />
          )}
          {MarginEarnedBookigStatus && (
            <MarkBookigCompleteModal
              tripCompletedStatus
              callbackFunc={setMarginEarnedBookigStatus}
              sucessfullCallbackFunction={handleSucessfullMarkBooking}
              crmBookingId={el.crmBookingId}
              gettingDataFun={gettingDataFun}
            />
          )}
        </div>
      </div>

      {/* --------------------- modal here ---------------- */}

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        show={show}
        onHide={handleClose}
      >
        <div className={styles.modalDiv}>
          <p className={styles.idNumber}>
            <strong>ID {el.crmBookingId}</strong>
          </p>
          <div className={styles.modalflex}>
            <p
              className={
                el.bookingType === "oneWay" ? styles.oneWay : styles.roundTrip
              }
            >
              {el.bookingType}
            </p>
            <div className={styles.modalCross} onClick={handleClose}>
              x
            </div>
          </div>
        </div>
        <hr
          className={styles.borderLine}
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            borderTop: "1px solid #575757",
          }}
        />
        <Modal.Body>
          <div className={styles.midSection} style={{ padding: "0px 10px" }}>
            <div
              className={
                el.customerName && el.customerContactNumber
                  ? styles.name
                  : styles.noData
              }
            >
              <p>{el.customerName}</p>
              <p>
                +91 {el.customerContactNumber}{" "}
                {el.customerEmail ? ` , ${el.customerEmail} ` : ""}
              </p>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div className={styles.data} style={{ width: "70%" }}>
                <p>Trip Date & Time</p>
                <p>
                  {moment(el.pickUpDate).format("DD-MM-YYYY")} {el.pickUpTime}
                </p>
              </div>
              <div className={el.vehicleTypeId ? styles.data : styles.noData}>
                <p>Vehicle Type</p>
                <p>{el.vehicleTypeName ? el.vehicleTypeName : "-"}</p>
              </div>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div style={{ width: "70%" }} className={styles.data}>
                <p>Pickup Location</p>
                <p>{el.pickUpLocationName ? el.pickUpLocationName : "-"}</p>
              </div>
              <div className={styles.data}>
                <p>No.of Days</p>
                <p>
                  {el.noOfDays
                    ? el.noOfDays <= 1
                      ? `${el.noOfDays} day`
                      : `${el.noOfDays} days`
                    : "-"}
                </p>
              </div>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div className={styles.data} style={{ width: "70%" }}>
                <p>Trip Notes</p>
                <p>{el.tripNotes ? el.tripNotes : "-"}</p>
              </div>
              <div className={styles.data}>
                <p>Seating Capacity</p>
                <p>
                  {el.seatingCapacity ? `${el.seatingCapacity}  Seats` : "-"}
                </p>
              </div>
            </div>
            <div className={styles.modalflexInnerPart}>
              <div className={styles.data} style={{ width: "70%" }}>
                <p>Pick Up Date</p>
                <p>
                  {el.pickUpDate
                    ? moment(el.pickUpDate).format("DD-MM-YYYY")
                    : "-"}
                </p>
              </div>
              <div className={styles.data}>
                <p>Pick Up Time</p>
                <p>{el.pickUpTime ? el.pickUpTime : "-"}</p>
              </div>
            </div>
            {el.dropLocationName && (
              <div style={{ width: "55%" }} className={styles.data}>
                <p>Drop Location</p>
                <p>{el.dropLocationName ? el.dropLocationName : "-"}</p>
              </div>
            )}
            <div className={styles.data} style={{ width: "55%" }}>
              <p>Tour Description</p>
              <p>{el.tourDescription ? el.tourDescription : "-"}</p>
            </div>
            {el.assignedVehicleId && (
              <div className={styles.data} style={{ width: "55%" }}>
                <p>Driver Info</p>
                <div
                  className={
                    el.assignedVehicleId != null
                      ? styles.assignedTo
                      : styles.notAssignedTo
                  }
                >
                  <img
                    src={el.driverImage || Profile_Icon}
                    alt="Driver image"
                  />
                  <div>
                    <p>{el.driverFullName}</p>
                    <p>+91{el.driverContactNumber}</p>
                  </div>
                </div>
              </div>
            )}
            {el.assignedVehicleId && (
              <div className={styles.data} style={{ width: "55%" }}>
                <p>Vehicle Info</p>
                <div
                  className={
                    el.assignedVehicleId != null
                      ? styles.assignedTo
                      : styles.notAssignedTo
                  }
                >
                  <img src={el.vehicleImage || Profile_Icon} alt="" />
                  <div>
                    <p>{el.registrationNumber}</p>
                    <p>{el.vehicleTypeName}</p>
                  </div>
                </div>
              </div>
            )}
            <div
              className={styles.modalflexInnerPart}
              style={{ justifyContent: "space-between" }}
            >
              <div className={styles.bookingAmt}>
                <p>Booking Amount</p>
                <p>&#8377;{el.bookingAmount ? el.bookingAmount : "-"}*</p>
              </div>
              {el.status === "new" && (
                <div>
                  <button
                    className={styles.confirmbtn}
                    value={btnValue}
                    onClick={(e) => handlePopUpSubmit()}
                  >
                    Confirm Booking
                  </button>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* --------------------- modal ends here ---------------- */}
    </>
  );
};
