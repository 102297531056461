import React, { useEffect } from "react";
import styles from "./MyReviewLinks.module.scss";
import tripadvisor from "../../../assets/images/tripadvisor.png";
import JustDialIcon from "../../../assets/images/just-dial-Icon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faGoogle } from "@fortawesome/free-brands-svg-icons";
import { API_GET_REVIEWLINKS, API_GET_UPDATEREVIEWLINKS, FACEBOOK_REGEX, VALID_URL_REGEX } from "../../../utils/consts/api";

import { useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import {
  errorToastMsg,
  successToastMsg,
} from "../BankAccountDetails/ToastMessage";
import { ToastContainer } from "react-toastify";
import Spinner from "../CommonComp/Spinner/Spinner";

const ReviewLinksComponent = () => {
  const [isValidFacebook, setIsValidFacebook] = useState(true);
  const [facebokErrorMsg, setFacebokErrorMsg] = useState("");
  const [isValidTripAdvisor, setIsValidTripAdvisor] = useState(true);
  const [tripAdvisorErrorMsg, setTripAdvisorErrorMsg] = useState("");
  const [isValidGoogle, setIsValidGoogle] = useState(true);
  const [googleErrorMsg, setGoogleErrorMsg] = useState("");
  const [isValidJustDial, setIsValidJustDial] = useState(true);
  const [justDialErrorMsg, setJustDialErrorMsg] = useState("");
  const [reviewLinksData, setReviewLinksData] = useState({
    fbUrl: "",
    googleUrl: "",
    justDialUrl: "",
    tripAdvisorUrl: "",
  });
  const [loading, setLoading] = useState(false);
  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const handleReviewLinks = (e) => {
    const { name, value } = e.target;
    setReviewLinksData({
      ...reviewLinksData,
      [name]: value,
    });
  };

  const validateFacebookURL = () => {
    if (reviewLinksData.fbUrl) {
      if (!FACEBOOK_REGEX.test(reviewLinksData.fbUrl)) {
        setIsValidFacebook(false);
        setFacebokErrorMsg("Invalid facebook url.");
      } else {
        setIsValidFacebook(true);
        setFacebokErrorMsg("");
      }
    } else {
      setIsValidFacebook(true);
      setFacebokErrorMsg("");
    }
  };

  const validateGoogleURL = () => {
    if (reviewLinksData.googleUrl) {
      if (!VALID_URL_REGEX.test(reviewLinksData.googleUrl)) {
        setIsValidGoogle(false);
        setGoogleErrorMsg("Invalid google url.");
      } else {
        setIsValidGoogle(true);
        setGoogleErrorMsg("");
      }
    } else {
      setIsValidGoogle(true);
      setGoogleErrorMsg("");
    }
  };

  const validateJustDial = () => {
    if (reviewLinksData.justDialUrl) {
      if (!VALID_URL_REGEX.test(reviewLinksData.justDialUrl)) {
        setIsValidJustDial(false);
        setJustDialErrorMsg("Invalid just dial url.");
      } else {
        setIsValidJustDial(true);
        setJustDialErrorMsg("");
      }
    } else {
      setIsValidJustDial(true);
      setJustDialErrorMsg("");
    }
  };

  const validateTripAdvisorURL = () => {
    if (reviewLinksData.tripAdvisorUrl) {
      if (!VALID_URL_REGEX.test(reviewLinksData.tripAdvisorUrl)) {
        setIsValidTripAdvisor(false);
        setTripAdvisorErrorMsg("Invalid Trip advisor url.");
      } else {
        setIsValidTripAdvisor(true);
        setTripAdvisorErrorMsg("");
      }
    } else {
      setIsValidTripAdvisor(true);
      setTripAdvisorErrorMsg("");
    }
  };

  const getReviewLinks = () => {
    setLoading(true);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    };
    axios
      .get(API_GET_REVIEWLINKS, config)
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          if(res.data.response){
            setReviewLinksData(res.data.response);
          }
        }
        setLoading(false);
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getReviewLinks();
  }, []);

  const handleUpdateReviewLinks = (e) => {
    e.preventDefault();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token}`,
      },
    };
    axios
      .post(
        API_GET_UPDATEREVIEWLINKS,
        reviewLinksData,
        config
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          getReviewLinks();
          successToastMsg("Review Links are saved sucessfully");
        }
      })
      .catch((err) => {
        errorToastMsg(err.message);
      });
  };


  return (
    <>
      <div>
        <div className={styles.logincomponent}>
          <Sidebar />
          <div className={styles.bgrightloginform}>
            <div className={styles.registerYourAccount}>
              <div
                className={`d-flex justify-content-between col-md-10 ${styles.addreviewdiv}`}
              >
                <p className={`${styles.addreview}`}>
                  Add Review link for better credibility{" "}
                </p>
              </div>
              {loading? (<Spinner/>):(
              <form
                style={{ marginTop: "35px" }}
                onSubmit={handleUpdateReviewLinks}
              >
                <div className={styles.icondiv}>
                  <label htmlFor="facebook" className="form-label ">
                    Facebook
                  </label>
                  <div className="input-group mb-3">
                    <span className={styles.facebook}>
                      <FontAwesomeIcon icon={faFacebookF} />
                    </span>
                    <input
                      type="text"
                      className={`form-control ${styles.iconinput}`}
                      id="facebook"
                      placeholder="Url"
                      value={reviewLinksData.fbUrl ? reviewLinksData.fbUrl : ''}
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="fbUrl"
                      onBlur={validateFacebookURL}
                      onChange={handleReviewLinks}
                    />
                  </div>
                  {!isValidFacebook && (
                    <div className={`text-danger ${styles.errormessage}`}>
                      {facebokErrorMsg}
                    </div>
                  )}
                </div>
                <div className={styles.icondiv}>
                  <label htmlFor="justDial" className="form-label fw-semibold">
                    JustDial
                  </label>
                  <div className="input-group mb-3">
                    <span className={styles.justDial}>
                      <img src={JustDialIcon} height="100%" width="100%" />
                    </span>
                    <input
                      style={{ borderLeft: "1px solid #FA8B0C" }}
                      type="text"
                      className="form-control"
                      id="justDial"
                      placeholder="Url"
                      aria-label="Username"
                      value={reviewLinksData.justDialUrl ? reviewLinksData.justDialUrl : ''}
                      aria-describedby="basic-addon1"
                      name="justDialUrl"
                      onBlur={validateJustDial}
                      onChange={handleReviewLinks}
                    />
                  </div>
                </div>
                {!isValidJustDial && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    {justDialErrorMsg}
                  </div>
                )}
                <div className={styles.icondiv}>
                  <label htmlFor="google" className="form-label fw-semibold">
                    Google
                  </label>
                  <div className="input-group mb-3">
                    <span className={styles.google}>
                      <FontAwesomeIcon icon={faGoogle} />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="google"
                      placeholder="Url"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      name="googleUrl"
                      onBlur={validateGoogleURL}
                      onChange={handleReviewLinks}
                      value={reviewLinksData.googleUrl ? reviewLinksData.googleUrl : ''}
                    />
                  </div>
                </div>
                {!isValidGoogle && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    {googleErrorMsg}
                  </div>
                )}
                <div className={styles.icondiv}>
                  <label htmlFor="tripadvisor" className="form-label fw-semibold">
                    Tripadvisor
                  </label>
                  <div className="input-group mb-3">
                    <img className={styles.tripadvisor} src={tripadvisor} />
                    <input
                      type="text"
                      className="form-control"
                      id="tripadvisor"
                      placeholder="Url"
                      aria-label="Username"
                      value={reviewLinksData.tripAdvisorUrl ? reviewLinksData.tripAdvisorUrl : ''}
                      aria-describedby="basic-addon1"
                      name="tripAdvisorUrl"
                      onBlur={validateTripAdvisorURL}
                      onChange={handleReviewLinks}
                    />
                  </div>
                </div>
                {!isValidTripAdvisor && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    {tripAdvisorErrorMsg}
                  </div>
                )}
                <div className={`d-flex class ${styles.buttons}`}>
                  <button
                    type="submit"
                    className={`btn customButtonActive ${styles.dolater}`}
                    disabled={
                      !isValidFacebook ||
                      !isValidGoogle ||
                      !isValidJustDial ||
                      !isValidTripAdvisor
                    }
                  >
                    Update
                  </button>
                </div>
              </form>
               )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ReviewLinksComponent;
