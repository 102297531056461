import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import LoginComponent from "../views/auth/login";
import RegistrationComponent from "../components/Registeration";
import { Customer } from "../components/Dashboard/MyCustomer/Customer";
import { Vehicles } from "../components/Dashboard/MyVehicles/Vehicles";
import { Drivers } from "../components/Dashboard/MyDrivers/Drivers";
import MyBooking from "../components/Dashboard/MyBooking/MyBooking";
import { CancelledBookings } from "../components/Dashboard/CancelledBookings/CancelledBookings";
import { CompletedBookings } from "../components/Dashboard/CompletedBookings/CompletedBookings";
import { ForgotPasswordComponent } from "../views/auth/ForgotPassword/ForgotPassword";
import Notification from "../components/Notification/Notification";
import BankAccountDetails from "../components/Dashboard/BankAccountDetails/bankaccount";
import { PopOver } from "../components/Dashboard/samples/PopOver";
import { Error404 } from "../components/404Error/Error404";
import { withAuthCheck } from "../components/HighOrderComponentAuth/HighOrderComponentAuth";
import ChoosePlanComponent from "../components/Registeration/ChoosePlan/ChoosePlan";
import AddVechileComponent from "../components/Registeration/AddVechiles/addvechile";
import SocialURLComponent from "../components/Registeration/SocialURL/SocialURL";
import ReviewLinksComponent from "../components/Dashboard/MyReviewLink/MyReviewLinks";
import { UserProfile } from "../components/UserProfile/userProfile";
import ChangePasswordComponent from "../components/Update-Password/ChangePassword";

const CheckAuthWithCustomer = withAuthCheck(Customer);
const CheckAuthWithDriver = withAuthCheck(Drivers);
const CheckAuthWithVehicles = withAuthCheck(Vehicles);
const CheckAuthWithMyBooking = withAuthCheck(MyBooking);
const CheckAuthWithCancelledBookings = withAuthCheck(CancelledBookings);
const CheckAuthWithCompletedBookings = withAuthCheck(CompletedBookings);
const CheckAuthWithNotification = withAuthCheck(Notification);
const CheckAuthWithBankAccountDetails = withAuthCheck(BankAccountDetails);
const CheckAuthWithPopOver = withAuthCheck(PopOver);
const CheckAuthWithReviewLinks = withAuthCheck(ReviewLinksComponent);
const CheckAuthWithUserProfile = withAuthCheck(UserProfile);


const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

export const AllRoutes = ()=>{
    
    return(
      <div>
         <Routes>
            { token ? <Route path="/" element= {<CheckAuthWithMyBooking />} /> : <Route path="/" element= {<LoginComponent />} />}
            <Route path="/login" element= {<LoginComponent />} />
            <Route path="/register" element= {<RegistrationComponent />} />
            <Route path="/login/forgot" element= {<ForgotPasswordComponent />} />
            <Route path="/dashboard/customer" element= {<CheckAuthWithCustomer />} />
            <Route path="/dashboard/vehicles" element= { <CheckAuthWithVehicles/> } />
            <Route path="/dashboard/drivers" element= { <CheckAuthWithDriver /> } />
            <Route path="/dashboard/mybooking" element= {<CheckAuthWithMyBooking />} />
            <Route path="/dashboard/bankAccount" element= {<CheckAuthWithBankAccountDetails />} />
            <Route path="/dashboard/cancelledBookings" element= {<CheckAuthWithCancelledBookings />} />
            <Route path="/dashboard/completedBookings" element= {<CheckAuthWithCompletedBookings />} />
            <Route path="/dashboard/notification" element= {<CheckAuthWithNotification />} />
            <Route path="/dashboard/popover" element= {<CheckAuthWithPopOver />} />
            <Route path="/dashboard/reviewlinks" element= {<CheckAuthWithReviewLinks />} />
            <Route path="/getProfile" element={ <CheckAuthWithUserProfile/>} />
            <Route path="/passwordChange" element={ <ChangePasswordComponent/>} />
            <Route path="*" element= {<Error404 />} />
         </Routes>
      </div>
    )
};


// ForgotPasswordComponent