import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from "./deleteVehicle.module.scss";

const DeleteuserComponent = ({ show, onHide, onAccept }) => {
    return (
            <Modal
                size='md'
                show={show}
                onHide={onHide}
                centered
                backdrop="static"
                keyboard={false}
                style={{ border: '2px solid red' }}
                className={styles.deleteModal}
            >
                <Modal.Header closeButton className={styles.deleteModalHeader}>
                    <Modal.Title>
                        Delete Vehicle
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5> Are you sure you want to delete the vehicle ?</h5>
                </Modal.Body>
                <Modal.Footer style={{ borderTop:'none' }}>
                    <button className='btn' style={{ border: '1px solid #e3e6ef', color: '#9299b8', height: '40px', width: '80px' }} onClick={onHide}>No</button>
                    <button className={`btn ${styles.yesButton}`} onClick={onAccept}>Yes</button>
                </Modal.Footer>
            </Modal>
    );
};


export default DeleteuserComponent;