import { GETNOTIFICATIONDATA } from "../action/notification.action";

const notificationIntialState = {
  notificationData: [],
};

export const notificationReducer = (
  state = notificationIntialState,
  { type, payload }
) => {
  switch (type) {
    case GETNOTIFICATIONDATA:
      return { notificationData: payload };

    default:
      return state;
  }
};
