import React, { useState, useEffect } from "react";
import { BsPerson, BsXCircle } from "react-icons/bs";
import styles from "./customer.module.scss";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { PaginNew } from "../CommonComp/PaginNew";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Sidebar/Sidebar";
import { withAuthCheck } from "../../HighOrderComponentAuth/HighOrderComponentAuth";
import { AiOutlinePlus } from "react-icons/ai";
import {
  API_ADD_CUSTOMER,
  API_UPDATE_CUSTOMER,
  API_GET_MY_CUSTOMER,
  API_DELETE_CUSTOMER,
  EMAIL_REGEX,
  PHONE_REGEX,
  NAME_REGEX,
} from "../../../utils/consts/api";
import NoDataFound from "../CommonComp/noDataFound/NoDataFound";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DeleteuserComponent from "./DeleteVehicleModal/Delete-vehicle-modal";
import Spinner from "../CommonComp/Spinner/Spinner";
import Compressor from 'compressorjs';
import { ImageCompressorHook } from "../CommonComp/ImageCompressor/ImageCompressor";

export const Customer = () => {
  const [total, setTotal] = useState();
  const [postPerPage, setPostPerPage] = useState(9);
  const [page, setPage] = useState(1);
  const isNum = /^[0-9]*$/;

  let initialValues = {
    customerName: "",
    contactNumber: "",
    emailId: "",
    location: "",
    image: "customerImage",
  };

  const [userImage, setUserImage] = useState({
    preview: "",
    raw: "",
    value: "",
  });
  const [formValues, setFormValues] = useState(initialValues);
  const [show, setShow] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);
  const [Update, setUpdate] = useState({});
  const [updatedImage, setUpdatedImage] = useState({
    preview: "",
    raw: "",
    value: "",
  });

  const handleClose = () => {
    setShow(false);
    setUserImage({ preview: "", raw: "", value: "" });
    setFormValues(initialValues);
    setIsPhoneValid(true);
    setPhoneErrorMsg("");
    setIsValid(true);
    setErrorMsg("");
  };
  const [modalShow, setModalShow] = useState(false);
  const [deletePlanID, setDeletePlanID] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneErrorMsg, setPhoneErrorMsg] = useState("");
  const [isUpdateValid, setIsUpdateValid] = useState(true);
  const [updateErrorMsg, setUpdateErrorMsg] = useState("");
  const [isUpdatePhoneValid, setIsUpdatePhoneValid] = useState(true);
  const [phoneUpdateErrorMsg, setUpdatePhoneErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const {imageCompressorFn,data} = ImageCompressorHook();

  const handleShow = () => setShow(true);
  const handleUserClose = () => setShowUser(false);
  const handleUserShow = (data) => {
    setShowUser(true);
    setUpdate(data);
  };
  const [customerData, setCustomerData] = useState([]);

  const handleUpdateModelshow = (obj) => {
    setUpdateModel(true);
    setUpdate(obj);
  };

  const handleUpdateModelsClose = () => {
    setUpdateModel(false);
    setUpdate({});
    setIsUpdatePhoneValid(true);
    setIsUpdateValid(true);
    setUpdatedImage({ preview: "", raw: "", value: "" });
  };

  const token =
    localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token}`,
    },
  };

  const body = {
    page: page - 1,
    size: postPerPage,
  };

  const getAllData = () => {
    setLoading(true);
    axios
      .post(API_GET_MY_CUSTOMER, body, config)
      .then((response) => {
        setCustomerData(response.data.response.customerList);
        setTotal(response.data.response.customerList.length);
        setLoading(false);
      })
      .catch((err) => {
        errorToastMsg(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    setPage(1);
  }, [postPerPage]);
  // pagination Part:-
  const indexOfLastPage = page * postPerPage;
  const indexOfFirstpage = indexOfLastPage - postPerPage;
  const currentPost = customerData.slice(indexOfFirstpage, indexOfLastPage);

  const handlePageClick = async (data) => {
    setPage(data.selected + 1);
  };

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleInput = (e) => {
    e.persist();
    const { name, value } = e.target;
    if (name === "contactNumber") {
      if (isNum.test(value) && value.length <= 10) {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      } else {
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else if (name === "customerName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
      } else {
        setFormValues({
          ...formValues,
          [e.target.name]: e.target.value.slice(0, -1),
        });
      }
    } else {
      setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }
  };

  const handleUpdateInput = (e) => {
    e.persist();
    const { name, value } = e.target;

    if (name === "contactNumber") {
      if (isNum.test(value) && value.length <= 10) {
        setUpdate({ ...Update, [e.target.name]: e.target.value });
      } else {
        setUpdate({ ...Update, [e.target.name]: e.target.value.slice(0, -1) });
      }
    } else if (name === "customerName") {
      if (NAME_REGEX.test(value) && value !== " ") {
        setUpdate({ ...Update, [e.target.name]: e.target.value });
      } else {
        setUpdate({ ...Update, [e.target.name]: e.target.value.slice(0, -1) });
      }
    } else {
      setUpdate({ ...Update, [e.target.name]: e.target.value });
    }
  };

  const handleImage = async (e) => {
    const image = e.target.files[0];
    if (e.target.files.length) {
      imageCompressorFn(image,e.target.name);     
        }
  };

  const handleUpdateImage = (e) => {
    if (e.target.files.length) {
      setUpdatedImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
        value: e.target.value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    formValues.emailId = formValues.emailId.toLowerCase();
    const formData = new FormData();
    formData.append("data", JSON.stringify(formValues));
    if (userImage.raw) {
      formData.append("customerImage", userImage.raw, userImage.raw.name);
    }
    setLoading(true);
    axios
      .post(API_ADD_CUSTOMER, formData, {
        headers: {
          // "content-type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
        }
        getAllData();
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
    handleClose();
    setFormValues(initialValues);
    setUserImage("");
  };

  const handleDeleteaccept = (id) => {
    if (!deletePlanID) {
      errorToastMsg("Please try again");
      return;
    }
    setLoading(true);
    setModalShow(false);
    axios
      .post(
        API_DELETE_CUSTOMER,
        { customerId: deletePlanID },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${token}`,
          },
        }
      )
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
        }
        getAllData();
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    delete Update.imageToShow;
    Update.emailId = Update.emailId.toLowerCase();
    const formData = new FormData();
    formData.append("data", JSON.stringify(Update));
    if (updatedImage.raw) {
      formData.append("customerImage", updatedImage.raw, updatedImage.raw.name);
    }
    setLoading(true);
    axios
      .post(API_UPDATE_CUSTOMER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
          setLoading(false);
        } else {
          successToastMsg(res.data.successMsg);
        }
        getAllData();
      })
      .catch((error) => {
        errorToastMsg(error.message);
        setLoading(false);
      });
    handleUserClose();
    setUpdateModel(false);
    setUpdatedImage({ preview: "", raw: "" });
  };

  const handleDeleteOpen = (id) => {
    setDeletePlanID(id);
    setModalShow(true);
  };

  const handleDeleteclose = () => {
    setModalShow(false);
  };

  const validateEmail = () => {
    const { emailId } = formValues;
    if (emailId) {
      if (!EMAIL_REGEX.test(emailId)) {
        setIsValid(false);
        setErrorMsg("Invalid Email Address.");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  const validatePhoneNumber = () => {
    const { contactNumber } = formValues;
    if (contactNumber) {
      if (!PHONE_REGEX.test(contactNumber)) {
        setIsPhoneValid(false);
        setPhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsPhoneValid(true);
        setPhoneErrorMsg("");
      }
    } else {
      setIsPhoneValid(true);
      setPhoneErrorMsg("");
    }
  };

  const updateValidateEmail = () => {
    const { emailId } = Update;
    if (emailId) {
      if (!EMAIL_REGEX.test(emailId)) {
        setIsUpdateValid(false);
        setUpdateErrorMsg("Invalid Email Address.");
      } else {
        setIsUpdateValid(true);
        setUpdateErrorMsg("");
      }
    } else {
      setIsUpdateValid(true);
      setUpdateErrorMsg("");
    }
  };

  const validateUpdatePhoneNumber = () => {
    const { contactNumber } = Update;
    if (contactNumber) {
      if (!PHONE_REGEX.test(contactNumber)) {
        setIsUpdatePhoneValid(false);
        setUpdatePhoneErrorMsg("Invalid Phone Number.");
      } else {
        setIsUpdatePhoneValid(true);
        setUpdatePhoneErrorMsg("");
      }
    } else {
      setIsUpdatePhoneValid(true);
      setUpdatePhoneErrorMsg("");
    }
  };

  useEffect(()=>{
    if(data.image){
      if(data.imageName){
        setUserImage({
          ...userImage,
          [data.imageName]:{
            preview: URL.createObjectURL(data.image),
            raw: data.image
          }
        });
      }else{
        setUserImage({
          ...userImage,
            preview: URL.createObjectURL(data.image),
            raw: data.image
        });
      }
    }
  },[data]);

  return (
    <div className="d-flex">
      <Sidebar />
      <div className={`bg-secondary bg-opacity-10  ${styles.mainContainer} `}>
        <div className="d-flex justify-content-between align-items-center my-3  mx-2">
          <div className={styles.pageTitle}>
            <h4>My Customers</h4>
          </div>
          <div className={`${styles.addDriverBtnDiv}`}>
            <Button
              variant="warning"
              onClick={handleShow}
              className={`${styles.addDriverBtn}`}
            >
              + Add Customer
            </Button>
          </div>
        </div>

        {/*AddCustomer Modal Starts here */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
          scrollable
        >
          <div className={styles.modalDiv}>
            <h5 className={`${styles.modalTitle}`}>Add New Customer</h5>
            <div className={styles.modalCross} onClick={handleClose}>
              x
            </div>
          </div>
          <hr className="m-0" />
          <Modal.Body>
            <form onSubmit={handleSubmit} autocomplete="off">
              <div className={styles.profileImage}>
                {userImage.preview ? (
                  <div>
                    <div
                      className={`cross ${styles.crossSign}`}
                      onClick={() =>
                        setUserImage({ preview: "", raw: "", value: "" })
                      }
                    >
                      <BsXCircle
                        color="#D7E0EE"
                        style={{
                          backgroundColor: "#272B41",
                          borderRadius: "50%",
                          position: "absolute",
                          top: 2,
                          right: 1,
                        }}
                      />
                    </div>
                    <img
                      src={userImage.preview}
                      alt="userImage"
                      className={styles.profileIcons}
                    />
                  </div>
                ) : (
                  <div>
                    {" "}
                    <BsPerson className={styles.icons} />
                  </div>
                )}

                <div className={styles.uploadFile}>
                  <label htmlFor="file-input" className={styles.uploadImage}>
                    {userImage.preview
                      ? userImage.raw.name
                      : "Upload Picture(Optional)"}
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    className="d-none"
                    value={userImage.value}
                    onChange={handleImage}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Name
                </label>
                <input
                  type="text"
                  required
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter customer name"
                  name="customerName"
                  onChange={handleInput}
                  value={formValues.customerName}
                  // className="input-group input-group-sm"
                />
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  c
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Mobile Number
                </label>
                <input
                  type="number"
                  required
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Customer Mobile Number"
                  name="contactNumber"
                  onBlur={validatePhoneNumber}
                  onInput={handleInput}
                  value={formValues.contactNumber}
                />
              </div>
              {!isPhoneValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "3px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {phoneErrorMsg}
                </div>
              )}
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Email (Optional)
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  name="emailId"
                  placeholder="Enter Customer Email"
                  onBlur={validateEmail}
                  value={formValues.emailId}
                  onChange={handleInput}
                />
              </div>
              {!isValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "3px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {errorMsg}
                </div>
              )}
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Location (Optional)
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter location"
                  name="location"
                  value={formValues.location}
                  onChange={handleInput}
                />
              </div>
              <div
                className="d-flex justify-content-start mb-3 gap-4"
                style={{ marginTop: "15px" }}
              >
                <Button
                  type="submit"
                  variant="warning"
                  style={{ color: "white" }}
                  disabled={!isPhoneValid || !isValid}
                >
                  Add Customer
                </Button>
                <button className={styles.closeBtn} onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* UpdateCustomer Modal Here */}

        <Modal
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={updateModel}
          onHide={handleUpdateModelsClose}
          scrollable
        >
          <div className={styles.modalDiv}>
            <h5 className={`${styles.modalTitle}`}>Update Customer</h5>
            <div
              className={styles.modalCross}
              onClick={handleUpdateModelsClose}
            >
              x
            </div>
          </div>
          <hr className="m-0" />
          <Modal.Body>
            <form onSubmit={handleUpdate} autocomplete="off">
              <div className="d-flex align-items-center gap-4">
                {updatedImage.preview ? (
                  <div>
                    <div
                      className={`cross ${styles.crossSign}`}
                      onClick={() =>
                        setUpdatedImage({ preview: "", raw: "", value: "" })
                      }
                    >
                      <BsXCircle
                        color="#D7E0EE"
                        style={{
                          backgroundColor: "#272B41",
                          borderRadius: "50%",
                          position: "absolute",
                          top: 2,
                          right: 1,
                        }}
                      />
                    </div>
                    <img
                      src={updatedImage.preview}
                      alt="userImage"
                      className={styles.profileIcons}
                    />
                  </div>
                ) : Update.imageToShow ? (
                  <img
                    src={Update.imageToShow}
                    alt="userImage"
                    className={styles.profileIcons}
                  />
                ) : (
                  <div>
                    {" "}
                    <BsPerson className={styles.icons} />
                  </div>
                )}

                <div className={styles.uploadFile}>
                  <label htmlFor="file-input" className={styles.uploadImage}>
                    {updatedImage.preview
                      ? updatedImage.raw.name
                      : "update picture"}
                  </label>
                  <input
                    type="file"
                    id="file-input"
                    className="d-none"
                    value={updatedImage.value}
                    onChange={handleUpdateImage}
                    accept="image/*"
                  />
                </div>
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                  hidden
                >
                  Customer ID
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter customer id"
                  name="customerName"
                  onChange={handleUpdateInput}
                  defaultValue={Update.customerId}
                  value={Update.customerId}
                  // className="input-group input-group-sm"
                  hidden
                />
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Name
                </label>
                <input
                  type="text"
                  required
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter customer name"
                  name="customerName"
                  onChange={handleUpdateInput}
                  defaultValue={Update.customerName}
                  value={Update.customerName}
                  // className={`input-group input-group-sm ${styles.input}`}
                />
              </div>
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  c
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Mobile Number
                </label>
                <input
                  type="number"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter Customer Mobile Number"
                  name="contactNumber"
                  onBlur={validateUpdatePhoneNumber}
                  onChange={handleUpdateInput}
                  defaultValue={Update.contactNumber}
                  value={Update.contactNumber}
                  required
                />
              </div>
              {!isUpdatePhoneValid && (
                <>
                  <div
                    style={{ marginTop: "0px", marginBottom: "7px" }}
                    className={`text-danger ${styles.errormessage}`}
                  >
                    {phoneUpdateErrorMsg}
                  </div>
                </>
              )}
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Customer Email (Optional)
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  name="emailId"
                  placeholder="Enter Customer Email"
                  value={Update.emailId}
                  onBlur={updateValidateEmail}
                  defaultValue={Update.emailId}
                  onChange={handleUpdateInput}
                />
              </div>
              {!isUpdateValid && (
                <div
                  style={{ marginTop: "0px", marginBottom: "7px" }}
                  className={`text-danger ${styles.errormessage}`}
                >
                  {updateErrorMsg}
                </div>
              )}
              <div className="mb-1">
                <label
                  htmlFor="exampleFormControlInput1"
                  className={`form-label fw-bold ${styles.labelFont}`}
                >
                  Location (Optional)
                </label>
                <input
                  type="text"
                  className={`input-group input-group-sm ${styles.input}`}
                  id="exampleFormControlInput1"
                  placeholder="Enter location"
                  name="location"
                  defaultValue={Update.location}
                  value={Update.location}
                  onChange={handleUpdateInput}
                />
              </div>
              <div className="d-flex justify-content-start mt-3 gap-5">
                <Button
                  type="submit"
                  variant="warning"
                  style={{ color: "white" }}
                  disabled={!isUpdateValid || !isUpdatePhoneValid}
                >
                  Update Customer
                </Button>
                <button
                  className={styles.closeBtn}
                  onClick={handleUpdateModelsClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        {/* -------------modal ends here----------------- */}
        {loading ? (
          <Spinner />
        ) : (
          <div>
            {currentPost.length > 0 ? (
              <div className="row mx-0">
                {currentPost.map((ele, index) => (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mx-0 p-2"
                    key={index}
                  >
                    <div className={styles.contentDiv}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p className={styles.nameTitle}>{ele.customerName}</p>
                        </div>
                        <div className="d-flex justify-content-between gap-2 align-items-center">
                          {ele.status === "active" ? (
                            <span className={` ${styles.badge}`}>Active</span>
                          ) : (
                            <span className={` ${styles.deactiveBadge}`}>
                              Deactive
                            </span>
                          )}
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Click to Edit
                              </Tooltip>
                            }
                          >
                            <i
                              className={`${styles.iconStyle} fas fa-edit me-2`}
                              onClick={() =>
                                handleUpdateModelshow({
                                  customerId: ele.customerId,
                                  customerName: ele.customerName,
                                  image: "customerImage",
                                  imageToShow: ele.image,
                                  contactNumber: ele.contactNumber,
                                  location: ele.location,
                                  emailId: ele.emailId,
                                })
                              }
                            ></i>
                            {/* <FiEdit /> */}
                          </OverlayTrigger>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-top`}>
                                Click to Delete
                              </Tooltip>
                            }
                          >
                            <i
                              style={{ opacity: "0.8" }}
                              className={`${styles.iconStyle} fa fa-trash-alt`}
                              onClick={() => handleDeleteOpen(ele.customerId)}
                            ></i>
                            {/* <RiDeleteBin6Line /> */}
                          </OverlayTrigger>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "3px" }}
                        className={`d-flex justify-content-between  ${styles.detailsSection}`}
                      >
                        <div>
                          <div>
                            <p
                              className={styles.otherContentTitle}
                              style={{ marginTop: "5px" }}
                            >
                              Email
                            </p>
                            <p
                              className={styles.otherContentValue}
                              style={{ marginTop: "0px" }}
                            >
                              {ele.emailId ? ele.emailId : "-"}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div>
                            <p
                              className={styles.otherContentTitle}
                              style={{ marginTop: "5px" }}
                            >
                              Phone Number
                            </p>
                            <p
                              className={styles.otherContentValue}
                              style={{ marginTop: "0px" }}
                            >
                              {ele.contactNumber ? ele.contactNumber : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "3px" }}
                        className={`d-flex justify-content-between  ${styles.detailsSection}`}
                      >
                        <div>
                          <div>
                            <p
                              className={styles.otherContentTitle}
                              style={{ marginTop: "5px" }}
                            >
                              Location
                            </p>
                            <p
                              className={styles.otherContentValue}
                              style={{ marginTop: "0px" }}
                            >
                              {ele.location ? ele.location : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <NoDataFound />
            )}
          </div>
        )}
        <PaginNew
          post={customerData}
          total={total}
          handlePageClick={handlePageClick}
          postPerPage={postPerPage}
          setPostPerPage={setPostPerPage}
          page={page}
        />
        <ToastContainer />

        <DeleteuserComponent
          show={modalShow}
          onHide={handleDeleteclose}
          onAccept={handleDeleteaccept}
        />

        <div
          className={`d-flex justify-content-center align-items-center ${styles.addBookingDiv}`}
          onClick={handleShow}
        >
          <AiOutlinePlus className={styles.addBookingIcon} />
        </div>
      </div>
    </div>
  );
};
