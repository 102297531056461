import { createStore, applyMiddleware, compose,combineReducers } from 'redux';
// import { persistStore, persistCombineReducers } from 'redux-persist';
// import storage from 'redux-persist/es/storage';
import thunk from 'redux-thunk';
import reducers from './reducer/index';

// Redux Persist config
// const config = {
//     key: 'root',
//     storage
//   };

// const reducer = persistCombineReducers(config,reducers);
const rootReducer = combineReducers(reducers);
const middleware = [thunk];


     const Store = createStore(rootReducer,
        compose(
          applyMiddleware(...middleware))
     );

    // const persistor = persistStore(
    //   store,
    //   null,
    //   () => { store.getState(); },
    // );

    // return { persistor, store };
//   };

export default Store;
