import moment from "moment";
import React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import styles from "./mobileView.module.scss";
import Profile_Icon from "../../../assets/images/driver_placeholder.jpg";

export const MobileView = ({ cancelledBookings }) => {
  return (
    <div className={styles.container}>
      {cancelledBookings &&
        cancelledBookings.map((ele, index) => (
          <div className={styles.contentCard} key={index}>
            <div
              className={`d-flex justify-content-between align-items-center  px-3 mt-3 `}
            >
              <p className={styles.id}>ID {ele.crmBookingId}</p>
              <div className="d-flex align-items-center">
                <span
                  className={`badge text-uppercase  ${
                    ele.bookingType === "roundTrip"
                      ? styles.statusRoundTrip
                      : styles.statusOneWay
                  }`}
                >
                  {ele.bookingType}
                </span>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.customerName}>{ele.customerName}</p>
              </div>
              <div>
                <p className={styles.mobileNumber}>
                  +91 - {ele.customerContactNumber}
                </p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.dateNTimeHeading}>Trip Date & Time</p>
              </div>
              <div>
                {" "}
                <p className={styles.dateNTime}>
                  {moment(
                    `${ele.pickUpDate.slice(0, 10)} ${ele.pickUpTime}`
                  ).format("DD MMM  YYYY h:mm A")}
                </p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.vehicleHeading}>Trip Notes</p>
              </div>
              <div>
                <p className={styles.vehicle}>{ele.tripNotes || "-"}</p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.vehicleHeading}>Tour Description</p>
              </div>
              <div>
                <p className={styles.vehicle}>{ele.tourDescription || "-"}</p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.vehicleHeading}>Vehicle Type</p>
              </div>
              <div>
                <p className={styles.vehicle}>{ele.vehicleTypeName || "-"}</p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.locationHeading}>Pickup Location</p>
              </div>
              <div>
                <p className={styles.location}>{ele.pickUpLocationName}</p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.amountHeading}>Total Margin Earned</p>
              </div>
              <div>
                <p className={styles.amount}>
                  ₹{ele.marginEarned}
                  <sup>*</sup>
                </p>
              </div>
            </div>
            <div className="px-3 py-2">
              <div>
                <p className={styles.amountHeading}>Booking Amount</p>
              </div>
              <div>
                <p className={styles.amount}>
                  ₹{ele.bookingAmount}
                  <sup>*</sup>
                </p>
              </div>
            </div>
            {ele.driverFullName && (
              <div className={`${styles.driverDetail} px-3 py-2`}>
                <p>Driver Info</p>
                <div>
                  <img
                    src={ele.driverImage || Profile_Icon}
                    alt="Driver image"
                  />
                  <div>
                    <p>{ele.driverFullName}</p>
                    <p>+91{ele.driverContactNumber}</p>
                  </div>
                </div>
              </div>
            )}
            {ele.registrationNumber && (
              <div className={`${styles.vehicleDetail} px-3 py-2`}>
                <p>Vehicle Info</p>
                <div>
                  <img src={ele.vehicleImage || Profile_Icon} alt="" />
                  <div>
                    <p>{ele.registrationNumber}</p>
                    <p>{ele.vehicleTypeName}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};
