import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./ChangePasssword.module.scss";
import TaxiIcon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import TaxiLeftImage from "../../assets/images/Taxi-sanchalak-left-image.png";
import { useState } from "react";
import { API_CHANGE_PASSWORD_VIA_EMAIL, EMAIL_REGEX } from "../../utils/consts/api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import taxiIcon from "../../assets/images/Taxi-sanchalak-icon-image.png";
import Spinner from "../Dashboard/CommonComp/Spinner/Spinner";
import axios from "axios";

const ChangePasswordComponent = () => {
  const [isValid, setIsValid] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [showPassword, setshowPassword] = useState(false);
  const [showChangePassword, setshowChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryParameters = new URLSearchParams(window.location.search)
  const Token = queryParameters.get("token")
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    newPassword: "",
    confirmNewPassword: "",
    token:Token
  });

  const { newPassword, confirmNewPassword } = inputs;

  const handlePassword = () => {
    setshowPassword(!showPassword);
  };

  const handleChangePassword = () => {
    setshowChangePassword(!showChangePassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const password_validate = () => {
    const password_Checker = {
      capital: /[A-Z]/,
      digit: /[0-9]/,
      full: /^[@#][A-Za-z0-9]{7,13}$/,
    };

    if (newPassword) {
      if (!password_Checker.capital.test(newPassword)) {
        setIsValid(false);
        setErrorMsg("Password should have atlest one capital character");
      } else if (!password_Checker.digit.test(newPassword)) {
        setIsValid(false);
        setErrorMsg("Password should have atleat one digit");
      } else if (newPassword.length < 7) {
        setIsValid(false);
        setErrorMsg("Password length should be greater than 7");
      } else {
        setIsValid(true);
        setErrorMsg("");
      }
    } else {
      setIsValid(true);
      setErrorMsg("");
    }
  };

  // Toast Messages starts

  const errorToastMsg = (msg) => {
    return toast.error(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const successToastMsg = (msg) => {
    return toast.success(msg, {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  // Toast Message Ends

  // Form submission Starts for Change-Password

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if(newPassword !== confirmNewPassword){
       errorToastMsg("Password and Confirm password are not matching");
       return;
      };

    setLoading(true);
    axios.post(API_CHANGE_PASSWORD_VIA_EMAIL,inputs)
    .then((res)=>{
       if(res.data.responseCode === -1){
         errorToastMsg(res.data.errorMsg);
         setLoading(false);
       }else if(res.data.responseCode === 0){
         successToastMsg(res.data.successMsg);
         navigate('/login');
         setLoading(false);
       }
    })
    .catch((err)=>{
       errorToastMsg(err.message);
       setLoading(false);
    })
  };

  // Form submission End for Change-Password

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          <div className={styles.logincomponent}>
            <div
              className={`${styles.taxiIcon}`}
              style={{ backgroundImage: `url(${taxiIcon})` }}
            ></div>
            {/* <div
            className={`d-none d-md-block ${styles.bgleftimage}`}
            style={{ backgroundImage: `url(${bgImage})` }}
          >
          </div> */}
            <div className={styles.TaxiCRMImageDiv}>
              <div className={styles.IconAndText}>
                <img src={TaxiIcon} className={styles.TaxiIconImage} />
                <p className={styles.imageText}>CRM Application</p>
              </div>
              <div className={styles.TaxiLeftImage}>
                <img src={TaxiLeftImage} width="100%" />
              </div>
            </div>
            {/* <div className={styles.bgleftimageMediumDiv}>
            <img src={bgImage} className={styles.bgleftimageMedium} />
          </div> */}
            <div className={styles.bgrightloginform}>
              <div className={styles.formBox}>
                <h4 className={`mb-4 ${styles.loginCRM}`}>Change Password</h4>
                <form
                  onSubmit={handleSubmit}
                  autocomplete="off"
                  style={{ marginTop: "35px" }}
                >
                  <div className={`form-group mb-3 ${styles.inputbox}`}>
                    <label
                      className={`form-label fw-bold ${styles.inputlabel}`}
                      htmlFor="exampleInputPassword1"
                    >
                      Password
                    </label>
                    <div className={`input-group`}>
                      <input
                        type={showPassword ? "text" : "password"}
                        className={`form-control ${styles.input}`}
                        id="exampleInputPassword1"
                        placeholder="Password"
                        required
                        onChange={handleChange}
                        value={newPassword}
                        onBlur={password_validate}
                        name="newPassword"
                      />
                      {!showPassword && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye-slash"
                            onClick={handlePassword}
                          ></i>
                        </span>
                      )}
                      {showPassword && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye"
                            onClick={handlePassword}
                          ></i>
                        </span>
                      )}
                    </div>
                  </div>
                  {!isValid && (
                    <div className={`text-danger ${styles.errormessage}`}>
                      {errorMsg}
                    </div>
                  )}
                  <div className={` ${styles.inputbox}`}>
                    <label
                      className={`form-label ${styles.inputlabel}`}
                      htmlFor="exampleInputPassword2"
                    >
                      Confirm Password
                    </label>
                    <div className={`input-group`}>
                      <input
                        type={showChangePassword ? "text" : "password"}
                        className={`form-control ${styles.input}`}
                        id="exampleInputPassword2"
                        placeholder="Password"
                        value={confirmNewPassword}
                        required
                        onChange={handleChange}
                        name="confirmNewPassword"
                      />
                      {!showChangePassword && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye-slash"
                            onClick={handleChangePassword}
                          ></i>
                        </span>
                      )}
                      {showChangePassword && (
                        <span className={`input-group-text ${styles.eyeStyle}`}>
                          <i
                            className="far fa-eye"
                            onClick={handleChangePassword}
                          ></i>
                        </span>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    disabled={!isValid}
                    className={`btn ${styles.signinbutton} mt-4`}
                  >
                    Change Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default ChangePasswordComponent;
