import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FiUpload } from "react-icons/fi";
import { errorToastMsg, successToastMsg } from "../../../../common/ToastMessage";
import { API_ADD_AND_ASSIGN, API_ADD_DRIVER, API_ADD_VEHICLE, API_GET_VEHICLE_TYPE } from "../../../../utils/consts/api";
import { BsPerson } from "react-icons/bs";

import styles from "./AddDriverAndVehicleModal.module.scss";

const AddDriverAndVehicle = ({ callbackFunc1, crmBookingId,gettingDataFun,callbackFunc }) => {
  const [show, setShow] = useState(true);
  const [manufactureErrorMessage,setManufactureErrorMessage] = useState('');
  const [ismanufacturingValid, setIsManufacturingValid] = useState(true);
  const handleClose = () => {
    setShow(false);
    callbackFunc1(false);
    callbackFunc(false);
  };
  const [dropdownList, setDropdownList] = useState([]);
  const [dropdownValue, setdropdownValue] = useState("");

  const addAndAssignInitialValues = {
    bookingInfo: {
      crmBookingId: crmBookingId
    },

    vehicleInfo: {
      vehicleTypeId: "",
      registrationNumber: "",
      yearOfManufacture: "",
      rcImageFront: "rcImageFront",
      rcImageBack: "rcImageBack",
      vehicleImage1: "vehicleImage1",
      vehicleImage2: "vehicleImage2",
      vehicleImage3: "vehicleImage3",
    },

    driverInfo: {
      driverFullName: "",
      driverContactNumber: "",
      email: "",
      driverImageKey: "driverImage",
      licenseImageKeyFront: "lisenceFrontImage",
      licenseImageKeyBack: "lisenceBackImage",
    }
  }

  const [addAndAssignFormValues, setAddAndAssignFormValues] = useState(addAndAssignInitialValues);

  const vehicleImagesIntialValues = {
    rcImageFront: { preview: "", raw: "" },
    rcImageBack: { preview: "", raw: "" },
    vehicleImage1: { preview: "", raw: "" },
    vehicleImage2: { preview: "", raw: "" },
    vehicleImage3: { preview: "", raw: "" },
  };

  const [vehicleImages, setVehicleImages] = useState(vehicleImagesIntialValues);


  const [driverImages, setDriverImages] = useState({
    driverImage: { preview: "", raw: "" },
    lisenceFrontImage: { preview: "", raw: "" },
    lisenceBackImage: { preview: "", raw: "" },
  });

  const token = localStorage.getItem("userToken") || sessionStorage.getItem("userToken");

  const getdropdownList = () => {
    axios
      .get(API_GET_VEHICLE_TYPE, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        setDropdownList(res.data.response.vehicleType);
      }).catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleVehicleImages = (e) => {
    if (e.target.files.length) {
      setVehicleImages({
        ...vehicleImages,
        [e.target.name]: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        },
      });
    }
  };

  const handleVehicleInput = (e) => {
    e.persist();
    const { name,value } = e.target;
    if(name === "yearOfManufacture"){
      if(value.length>=5){
          setAddAndAssignFormValues({...addAndAssignFormValues,[name]:value.slice(0,1)})
          return;
      }
    }
    setAddAndAssignFormValues({ ...addAndAssignFormValues,vehicleInfo:{...addAndAssignFormValues.vehicleInfo,
      [e.target.name]: e.target.value
    }});
  };

  const handleDriverInput = (e) => {
    e.persist();
    setAddAndAssignFormValues({ ...addAndAssignFormValues,driverInfo:{...addAndAssignFormValues.driverInfo,
      [e.target.name]: e.target.value
    }});
  };

  const handleAddDriverAndVehicle = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify(addAndAssignFormValues));
    if (vehicleImages.rcImageFront.raw) {
      formData.append(
        "rcImageFront",
        vehicleImages.rcImageFront.raw,
        vehicleImages.rcImageFront.name
      );
    } else {
      errorToastMsg("Please Upload RC-Front Image");
      return;
    }
    if (vehicleImages.rcImageBack.raw) {
      formData.append(
        "rcImageBack",
        vehicleImages.rcImageBack.raw,
        vehicleImages.rcImageBack.name
      );
    } else {
      errorToastMsg("Please Upload RC-Back Image");
      return;
    }
    if (vehicleImages.vehicleImage1.raw) {
      formData.append(
        "vehicleImage1",
        vehicleImages.vehicleImage1.raw,
        vehicleImages.vehicleImage1.name
      );
    } else {
      errorToastMsg("Please upload the vehicle image");
      return;
    }
    if (vehicleImages.vehicleImage2.raw) {
      formData.append(
        "vehicleImage2",
        vehicleImages.vehicleImage2.raw,
        vehicleImages.vehicleImage2.name
      );
    }
    if (vehicleImages.vehicleImage3.raw) {
      formData.append(
        "vehicleImage3",
        vehicleImages.vehicleImage3.raw,
        vehicleImages.vehicleImage3.name
      );
    };

    if (!driverImages.driverImage.raw) {
      errorToastMsg("Please Upload the Profile-Picture");
      return;
    } else {
      formData.append(
        "driverImage",
        driverImages.driverImage.raw,
        driverImages.driverImage.raw.name
      );
    }

    if (!driverImages.lisenceFrontImage.raw) {
      errorToastMsg("Please upload the Lisence front image");
      return;
    } else {
      formData.append(
        "lisenceFrontImage",
        driverImages.lisenceFrontImage.raw,
        driverImages.lisenceFrontImage.raw.name
      );
    }

    if (!driverImages.lisenceBackImage.raw) {
      errorToastMsg("Please upload the Lisence back image");
      return;
    } else {
      formData.append(
        "lisenceBackImage",
        driverImages.lisenceBackImage.raw,
        driverImages.lisenceBackImage.raw.name
      );
    }

    axios
      .post(API_ADD_AND_ASSIGN, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${token}`,
        },
      })
      .then((res) => {
        if (res.data.responseCode === -1) {
          errorToastMsg(res.data.errorMsg);
        } else {
          successToastMsg(res.data.successMsg);
          handleClose(false);
          gettingDataFun();
          setDriverImages({
            driverImage: { preview: "", raw: "" },
            lisenceFrontImage: { preview: "", raw: "" },
            lisenceBackImage: { preview: "", raw: "" },
          });
        }
      })
      .catch((error) => {
        errorToastMsg(error.message);
      });
  };

  const handleDriverImages = (e) => {
    if (e.target.files.length) {
      setDriverImages({
        ...driverImages,
        [e.target.name]: {
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        },
      });
    }
  };

  useEffect(() => {
    getdropdownList();
  }, []);

  const changeDropdownValue = (newF) => {
    setAddAndAssignFormValues({ ...addAndAssignFormValues,vehicleInfo:{...addAndAssignFormValues.vehicleInfo,
      vehicleTypeId: newF
    }});
    setdropdownValue(newF);
  };

  const handleYearofManufactureMsg = () => {
    if (addAndAssignFormValues.vehicleInfo.yearOfManufacture) {
      if (addAndAssignFormValues.vehicleInfo.yearOfManufacture < 2011 ) {
        setIsManufacturingValid(false);
        setManufactureErrorMessage("Year of manufacturing should be 2011 and above");
      } else {
        setIsManufacturingValid(true);
        setManufactureErrorMessage("");
      }
    } else {
      setIsManufacturingValid(true);
      setManufactureErrorMessage("");
    }
  };
  

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
        className={styles.mainModal}
      >
        <div className={styles.modalDiv}>
          <div className={styles.modalTitle}>Add New Vehicle & Driver</div>
          <div className={styles.modalCross} onClick={handleClose}>
            x
          </div>
        </div>
        <hr className="m-0" />
        <Modal.Body>
          <form onSubmit={handleAddDriverAndVehicle}>
            <div className={styles.selectDiv}>
              <select
                className={styles.select}
                id="exampleFormControlInput1"
                name="vehicleTypeId"
                required
                onChange={(event) => changeDropdownValue(event.target.value)}
                value={dropdownValue}
              >
                <option value="" className={styles.vehicleSelect} style={{ color: '#9299B8', fontSize: '14px' }} >Select Vehicle Type</option>
                {dropdownList.map((single) => {
                  return (
                    <option
                      key={single.vehicleTypeId}
                      value={single.vehicleTypeId}
                      className={`form-control ${styles.dropdownMenu}`}
                    >
                      {single.vehicleTypeName}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-3" style={{ marginTop: '15px' }}>
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold ${styles.inputlabelText}`}
              >
                Registration Number
              </label>
              <input
                type="text"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Registration Number"
                name="registrationNumber"
                required
                value={addAndAssignFormValues.vehicleInfo.registrationNumber}
                onChange={handleVehicleInput}
              />
            </div>
            <div className="mb-3" style={{ marginTop: '15px' }}>
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold`}
              >
                Year of Manufacture
              </label>
              <input
                type="number"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Year Of Manufacture"
                required
                value={addAndAssignFormValues.vehicleInfo.yearOfManufacture}
                name="yearOfManufacture"
                onChange={handleVehicleInput}
                onBlur = {handleYearofManufactureMsg}
              />
              {!ismanufacturingValid && (
                  <div className={`text-danger ${styles.errormessage}`}>
                    {manufactureErrorMessage}
                  </div>
                )}
            </div>

            <div className={`form-label fw-bold ${styles.labelFont}`}>
              <p className={styles.labelUploadFont} >Upload RC Images</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className={styles.uploadRC}>
                  <label
                    for="file-input-rc-front"
                    className="d-flex align-items-center"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadbox}>
                        {vehicleImages.rcImageFront.preview && (
                          <img
                            src={vehicleImages.rcImageFront.preview}
                            alt="rcImageFront"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!vehicleImages.rcImageFront.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload RC Front
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-rc-front"
                        className="d-none"
                        name="rcImageFront"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadRC}>
                  <label
                    for="file-input-rc-back"
                    className="d-flex align-items-center gap-5"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadbox}>
                        {vehicleImages.rcImageBack.preview && (
                          <img
                            src={vehicleImages.rcImageBack.preview}
                            alt="rcImageBack"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!vehicleImages.rcImageBack.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload RC Back
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-rc-back"
                        className="d-none"
                        name="rcImageBack"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className={`form-label fw-bold ${styles.labelFont}`}>
              <p className={styles.labelUploadFont} >Upload actual vehicles images</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className={styles.uploadVImg}>
                  {" "}
                  <label
                    for="file-input-VehicleImage1"
                    className="d-flex align-items-center "
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadboxVehicleImages}>
                        {vehicleImages.vehicleImage1.preview && (
                          <img
                            src={vehicleImages.vehicleImage1.preview}
                            alt="vehicleImage1"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!vehicleImages.vehicleImage1.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-VehicleImage1"
                        className="d-none"
                        name="vehicleImage1"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadVImg}>
                  {" "}
                  <label
                    for="file-input-vehicleImage2"
                    className="d-flex align-items-center"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadboxVehicleImages}>
                        {vehicleImages.vehicleImage2.preview && (
                          <img
                            src={vehicleImages.vehicleImage2.preview}
                            alt="vehicleImage2"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!vehicleImages.vehicleImage2.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-vehicleImage2"
                        className="d-none"
                        name="vehicleImage2"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadVImg}>
                  {" "}
                  <label
                    for="file-input-vehicleImage3"
                    className="d-flex align-items-center"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadboxVehicleImages}>
                        {vehicleImages.vehicleImage3.preview && (
                          <img
                            src={vehicleImages.vehicleImage3.preview}
                            alt="vehicleImage3"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!vehicleImages.vehicleImage3.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-vehicleImage3"
                        className="d-none"
                        name="vehicleImage3"
                        onChange={handleVehicleImages}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold`}
              >
                Driver Name
              </label>
              <input
                type="text"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Driver Name"
                name="driverFullName"
                required
                value={addAndAssignFormValues.driverInfo.driverFullName}
                onChange={handleDriverInput}
              />
            </div>
            <div className="d-flex align-items-center gap-4">
              {driverImages.driverImage.preview ? (
                <img
                  src={driverImages.driverImage.preview}
                  alt="driverImage"
                  className={styles.profileIconImage}
                />
              ) : (
                <div>
                  {" "}
                  <BsPerson className={styles.driverProfileIcon} />
                </div>
              )}
              <div>
                <label htmlFor="file-input" className={styles.uploadImage}>
                  {driverImages.driverImage.preview
                    ? driverImages.driverImage.raw.name
                    : "Upload Picture "}
                </label>
                <input
                  type="file"
                  id="file-input"
                  className="d-none"
                  name="driverImage"
                  onChange={handleDriverImages}
                  accept="image/*"
                />
              </div>
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold`}
              >
                Mobile Number
              </label>
              <input
                type="number"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Mobile Number"
                name="mobileNumber"
                required
                value={addAndAssignFormValues.driverInfo.mobileNumber}
                onChange={handleDriverInput}
              />
            </div>
            <div className="mb-3">
              <label
                htmlFor="exampleFormControlInput1"
                className={`form-label fw-bold`}
              >
                Email (Optional)
              </label>
              <input
                type="email"
                className={`input-group input-group-sm ${styles.input}`}
                id="exampleFormControlInput1"
                placeholder="Enter Email"
                name="email"
                value={addAndAssignFormValues.driverInfo.email}
                onChange={handleDriverInput}
              />
            </div>
            <div className={`form-label fw-bold ${styles.labelFont}`}>
              <p className={styles.labelUploadFont} > Upload Lisence Images </p>
              <div className="d-flex gap-4">
                <div className={styles.uploadLisence}>
                  {" "}
                  <label
                    for="file-input-lisence-front"
                    className="d-flex align-items-center "
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadbox}>
                        {driverImages.lisenceFrontImage.preview && (
                          <img
                            src={driverImages.lisenceFrontImage.preview}
                            alt="lisenceFrontImage"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!driverImages.lisenceFrontImage.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload Lisence Front
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-lisence-front"
                        className="d-none"
                        name="lisenceFrontImage"
                        onChange={handleDriverImages}
                      />
                    </div>
                  </label>
                </div>
                <div className={styles.uploadLisence}>
                  {" "}
                  <label
                    for="file-input-lisence-back"
                    className="d-flex align-items-center gap-5"
                  >
                    <div className={`${styles.upload}`}>
                      <div className={styles.uploadbox}>
                        {driverImages.lisenceBackImage.preview && (
                          <img
                            src={driverImages.lisenceBackImage.preview}
                            alt="lisenceBackImage"
                            className={styles.fiRCUploadImage}
                          />
                        )}
                        {!driverImages.lisenceBackImage.preview && (
                          <div className={styles.fileUploadDiv}>
                            <div className={styles.uploadbox}>
                              <FiUpload className={`${styles.uploadIcon}`} />
                              <p className={`${styles.uploadText}`}>
                                Upload Lisence Back
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <input
                        type="file"
                        id="file-input-lisence-back"
                        className="d-none"
                        name="lisenceBackImage"
                        onChange={handleDriverImages}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div
              className={`d-flex justify-content-start mx-3 mb-3 gap-2 ${styles.buttonDiv}`}
            >
              <button
                type="submit"
                // onClick={handleSubmit}
                className={styles.addAssignButton}
              >
                Add & Assign
              </button>
              <Button variant="light" onClick={handleClose} className={styles.cancelButton}>
                Cancel
              </Button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDriverAndVehicle;
