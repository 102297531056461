import React from "react";
import styles from "./MobileSideBar.module.scss";
import EventIcon from "@mui/icons-material/Event";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import DirectionsCarFilledOutlinedIcon from "@mui/icons-material/DirectionsCarFilledOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Link } from "react-router-dom";
import { IoMdCard } from "react-icons/io";
import { RiLinksFill } from "react-icons/ri";

const MobileSideBar = () => {
  return (
    <div className={styles.sidebar}>
      <Link to={"/dashboard/mybooking"}>
        <div className={styles.sidebarElements}>
          <EventIcon className={styles.sidebarIcon} />
          <p>My Bookings</p>
        </div>
      </Link>
      <Link to={"/dashboard/customer"}>
        <div className={styles.sidebarElements}>
          <PeopleOutlineIcon className={styles.sidebarIcon} />
          <p>My Customers</p>
        </div>
      </Link>
      <Link to={"/dashboard/vehicles"}>
        <div className={styles.sidebarElements}>
          <DirectionsCarFilledOutlinedIcon className={styles.sidebarIcon} />
          <p>My Vehicles</p>
        </div>
      </Link>
      <Link to={"/dashboard/drivers"}>
        <div className={styles.sidebarElements}>
          <PersonOutlineOutlinedIcon className={styles.sidebarIcon} />
          <p>My Drivers</p>
        </div>
      </Link>
      <Link to={"/dashboard/bankaccount"}>
        <div className={styles.sidebarElements}>
          <IoMdCard className={styles.sidebarIcon} />
          <p>Payment Details</p>
        </div>
      </Link>
      <Link
        to="/dashboard/reviewLinks">
        <div className={styles.sidebarElements}>
         <RiLinksFill className={styles.sidebarIcon} />
          <p>Review Links</p>
        </div>
      </Link>
    </div>
  );
};

export default MobileSideBar;
