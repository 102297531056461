import Compressor from "compressorjs";
import { useState } from "react";

export const ImageCompressorHook = () => {
  const [data,setData] = useState({image:null,imageName:null,type:null});
   const imageCompressorFn = (image,imageName,type)=>{
    return new Compressor(image, {
      quality: 0.4,
      convertSize: "500000",
      success: (compressedResult) => {
        setData({image:compressedResult,imageName:imageName,type:type});
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
      },
    });
  } 
  return {
    imageCompressorFn,
    data,
  }
};
